import React, { Component } from 'react'
import { ReactComponent as IDetections } from '../icons/table/activity.svg';
import { ReactComponent as IMalware } from '../icons/tipper/malware-i.svg';
import { ReactComponent as ILogin } from '../icons/covertdna/login.svg';
import { ReactComponent as IPass } from '../icons/covertdna/pass.svg';
import { ReactComponent as ICookie } from '../icons/covertdna/cookie.svg';
import { CircularProgressbar, buildStyles, CircularProgressbarWithChildren } from 'react-circular-progressbar';
import { ReactComponent as ISubmit } from '../icons/history_log/send.svg';
import {createTheme, ThemeProvider, Tooltip as MuiTooltip, Tooltip} from "@mui/material";
import {MaterialReactTable} from "material-react-table";
import 'react-circular-progressbar/dist/styles.css';
import { CircleFlag } from "react-circle-flags"
import ReactModal from 'react-modal';
import {CountryContext} from "react-svg-worldmap";
import MDEditor, {codePreview} from "@uiw/react-md-editor";
import {byFips as country_lookup_fips, byIso as country_lookup} from "country-code-lookup";
import Collapsible from "react-collapsible";

function deepEqual(object1, object2) {
    const keys1 = Object.keys(object1);
    const keys2 = Object.keys(object2);

    if (keys1.length !== keys2.length) {
        return false;
    }

    for (const key of keys1) {
        const val1 = object1[key];
        const val2 = object2[key];
        const areObjects = isObject(val1) && isObject(val2);
        if (
            (areObjects && !deepEqual(val1, val2)) ||
            (!areObjects && val1 !== val2)
        ) {
            return false;
        }
    }

    return true;
}

function getISO(code, iso2) {
    if (Array.isArray(code)) {
        code = code[1]
    }
    if (code === "")return "Unknown"
    try {
        if (code) {
            let lookup = country_lookup(code)
            if (!lookup)lookup = country_lookup_fips(code)
            if (iso2) {
                return lookup.iso2.toLowerCase()
            }
            return lookup.country
        }
    } catch (e) {}
    return ""
}

function get_time_string(time, table) {
    if (time) {
        let date = new Date(time * 1000);
        if (table) return new Date(date - date.getTimezoneOffset() * 60 * 1000).toISOString().replace('.000Z','').replace('T',' ');
        return new Date(date - date.getTimezoneOffset() * 60 * 1000).toISOString().replace('.000Z', '').replaceAll('-', '/').replace('T', ' - ');
    }
    return ""
}

function get_status_color(status, transparent) {
    switch (status) {
        case "New":
            return transparent ? "rgba(244,63,94,0.25)" : "#F43F5E"
        case "Pending":
            return transparent ? "rgba(245,158,11,0.25)" : "#F43F5E"
        case "False Positive":
            return transparent ? "rgba(139,92,246,0.25)" : "#8B5CF6"
        case "Closed":
            return transparent ? "rgba(6,182,212,0.25)" : "#06B6D4"
        default:
            return ""
    }
}

function isObject(object) {
    return object != null && typeof object === 'object';
}

const getStyle = ({
                      countryValue,
                      countryCode,
                      minValue,
                      maxValue,
                      color,
                  }: CountryContext) => ({
    fill: countryValue ? color: "rgb(85,115,85)",
    fillOpacity: 0.5,
    stroke: "white",
    strokeWidth: 1,
    cursor: "pointer",
});


const columns = [
    {
        accessorKey: 'priority',
        header: 'Priority',
        size: 20,
    },
    {
        accessorKey: 'host',
        header: 'Host / User',
        size: 150,
    },
    {
        accessorKey: 'type',
        header: 'Type',
        size: 200,
    },
    {
        accessorKey: 'name',
        header: 'Name / Domain',
        size: 150,
        Cell: ({ cell }) => (
            <div>
                {
                    cell.row.original["name"] ?
                        cell.row.original["name"]
                        :
                        cell.row.original["Credentials"] ?
                            cell.row.original["Credentials"][0]["Malware"]
                            :
                            cell.row.original["domain"]
                }
            </div>
        ),
    },
    {
        header: 'Country',
        size: 150,
        Cell: ({cell}) => (
            <div style={{
                display: "flex",
                flexDirection: "row",
                gap: "8px",
                justifyItems: "center",
                alignItems: "center"
            }}>
                {
                    cell.row.original["priority"].charAt(0) === 'C' || cell.row.original["priority"].charAt(0) === 'B' ?
                        "N/A"
                        :
                        <div style={{
                            display: "flex",
                            flexDirection: "row",
                            gap: "8px",
                            justifyItems: "center",
                            alignItems: "center"
                        }}>
                            <div>
                                {
                                    <CircleFlag height={24} countryCode={getISO(cell.row.original["country"], true)}/>
                                }
                            </div>
                            <div>
                                {getISO(cell.row.original["country"])}
                            </div>
                        </div>
                }
            </div>
        ),
    },
    {
        accessorKey: 'status',
        header: 'Status',
        size: 150,
        Cell: ({cell}) => (
            <div
                style={{
                    borderRadius: "16px",
                    border: "2px solid " + get_status_color(cell.getValue(), false),
                    display: "inline-block",
                    flexDirection: "row",
                    padding: "0px 8px",
                    background: cell.getValue() === "Pending" ? get_status_color(cell.getValue(), false) : "transparent",
                    color: cell.getValue() === "Pending" ? "black" : get_status_color(cell.getValue(), false),
                }}
            >
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        letterSpacing: "0.01em",
                        lineHeight: "20px",
                        width: "100%",
                        height: "100%"
                    }}
                >
                    {cell.getValue() === "Pending" ? "LIVE IR" : cell.getValue()}
                </div>
            </div>
        ),
    },
    {
        accessorKey: 'snow_ticket',
        header: 'SNOW Ticket',
        size: 150,
    },
    {
        accessorKey: 'created',
        header: 'First Detected',
        size: 150,
        Cell: ({ cell }) => (
            <div>
                {get_time_string(cell.getValue(), true)}
            </div>
        ),
    },
    {
        accessorKey: 'updated',
        header: 'Last Updated',
        size: 150,
        Cell: ({ cell }) => (
            <div>
                {get_time_string(cell.getValue(), true)}
            </div>
        ),
    },
];


export default class Detections extends Component {

    tableTheme = createTheme({
        palette: {
            mode: "dark",
            background: {
                default: '#18181b',
            },
        },
        typography: {
            button: {
                textTransform: 'none',
                fontSize: '1.2rem',
            },
        },
        components: {
            MuiTooltip: {
                styleOverrides: {
                    tooltip: {
                        background: "#A1A1AA",
                    },
                },
            },
            MuiSwitch: {
                styleOverrides: {
                    thumb: {
                        color: 'black',
                    },
                },
            },
        },
    });

    constructor(props) {
        super(props);
        this.state = {
            username: '',
            company: '',
            key: '',
            keys: {},
            stats: {},
            d: [0, 0, 0, 0],
            p: [0, 0, 0, 0],
            p_val: [0, 0, 0, 0],
            table_data: [],
            filter_index: 4,
            showModal: false,
            showPlaybook: false,
            modal_data: {},
            playbook: {},
            summary: {},
            tp_rate: 0,
            log_input_text: "",
            show_log_input: false
        };
        this.getTableData = this.getTableData.bind(this);
        this.set_dials = this.set_dials.bind(this);
        this.OpenModal = this.OpenModal.bind(this);
        this.CloseModal = this.CloseModal.bind(this);
        this.getCountryRender = this.getCountryRender.bind(this);
        this.getBreachCountryRender = this.getBreachCountryRender.bind(this)
        this.update_detection = this.update_detection.bind(this);
        this.getBreachDate = this.getBreachDate.bind(this)
        this.getBreachURL = this.getBreachURL.bind(this)
        this.getCredColors = this.getCredColors.bind(this)
        this.update_table = this.update_table.bind(this)
        let data = this.props.page_data()
        //console.log("detections", data)
    }

    getCredColors(creds) {
        switch (creds["CredentialType"].length) {
            case 1:
                return "#EAB308"
            case 2:
                return "#F97316"
            case 3:
                return "#F43F5E"
        }
        return "#71717A"
    }

    OpenModal(data) {
        if (data["group"] === "") {
            data["group"] = "N/A"
        }
        this.setState({modal_data: data, showModal: true});
    }

    getBreachURL() {
        let url = "N/A"
        if (this.state.modal_data["risk"]) {
            if (this.state.modal_data["group"]) {
                url = this.state.modal_data["risk"]["post_url"]
            } else url = this.state.modal_data["risk"]["url"]
        }
        let image

        if (url.includes(".onion")) {
            image = "/main/onion-link-i.svg"
            url = url.replaceAll(".", "[.]")
        } else {
            image = "/main/link-i.svg"
        }

        let full_url = url
        if (url.length > 40) {
            url = url.slice(0, 40) + "..."
        }
        return (
            <div style={{display: "flex", alignItems: "center", gap: "4px"}}>
                <img src={image} alt={"url-icon"}/>
                <a href={full_url} target="_blank"
                   rel="noreferrer"
                   style={{
                       color: "#06B6D4"
                   }}
                >
                    {url}
                </a>
            </div>
        )
    }

    getBreachDate() {
        let time = 0
        if (this.state.modal_data["risk"]) {
            if (this.state.modal_data["risk"]["date"]) {
                time = this.state.modal_data["risk"]["date"]
            } else time = this.state.modal_data["risk"]["published"]
        }
        return (
            <div>
                {get_time_string(time)}
            </div>
        )
    }

    getBreachCountryRender() {
        let code = getISO(this.state.modal_data["country"], true)
        let name = getISO(this.state.modal_data["country"])
        return (
            <div style={{display: "flex", alignItems: "center", gap: "8px"}}>
                {code === "" ? "" : <CircleFlag height={20} countryCode={code}/>}
                {name}
            </div>
        )
    }

    CloseModal() {
        this.setState({modal_data: {}, showModal: false, show_log_input: false, log_input_text: ""});
    }

    getCountryRender() {
        if (this.state.modal_data["country"]) {
            let code = this.state.modal_data["country"][1]
            return (
                <div style={{display: "flex", alignItems: "center", gap: "8px"}}>
                    {this.state.modal_data["country"][0]}
                    {code === "" ? "" : <CircleFlag height={20} countryCode={code}/>}
                </div>
            )
        }
    }

    tick() {
        let data = this.props.page_data()
        let key = data.keys[data.company]
        let company = data.company;
        if (this.state.company !== data.company) {
            this.setState({username: data.username, key: key, company: company, keys: data.keys})
        }
        if (key !== '') {
            let jsonData = {
                "key": key,
            }
            fetch('https://ti-endpoint.cybershadows.co.uk/' + company + "/detections", {
                method: 'POST',
                mode: 'cors',
                body: JSON.stringify(jsonData)
            }).then((response) => {
                if (response.ok) {
                    response.json().then(json => {
                        this.setState({stats: json})
                    });
                }
            }).catch((err) => {
                console.log("dashboard", err)
            })
        }
    }

    componentDidUpdate(prevProps, prevState, Snapshot) {
        this.props.page_state("detections", this.state)
    }

    componentDidMount() {
        let state = this.props.page_state("detections")
        if (state) {
            this.setState(state)
        }

        let data = this.props.page_data()
        if (data.username && data.username !== '') {
            this.setState({
                username: data.username,
                key: data.keys[data.company],
                company: data.company,
                keys: data.keys
            })
        }
        this.interval_update = setInterval(
            () => this.tick(),
            1000
        );
        this.tick()
    }

    componentWillUnmount() {
        clearInterval(this.interval_update);
    }

    update_table(e) {
        this.setState({filter_index: parseInt(e.currentTarget.id)})
    }

    update_detection(e, log) {
        let company = this.state.company;
        let jsonData = {
            "key": this.state.key,
            "id": this.state.modal_data["id"],
            "data": log ? log : parseInt(e.currentTarget.id)
        }
        fetch('https://ti-endpoint.cybershadows.co.uk/' + company + "/update_detection", {
            method: 'POST',
            mode: 'cors',
            body: JSON.stringify(jsonData)
        }).then((response) => {
            if (response.ok) {
                response.json().then(json => {
                    if (json.status) {
                        let modal_data = this.state.modal_data
                        modal_data["status"] = json.status
                        this.setState({modal_data: modal_data})
                    }
                    if (json.log) {
                        let modal_data = this.state.modal_data
                        modal_data["log"] = json.log
                        this.setState({modal_data: modal_data})
                    }
                });
            }
        }).catch((err) => {
            console.log("update_detection", err)
        })
    }

    getTableData() {
        if (this.state.stats.detections) {
            let items = []
            let map_data = []
            let d = [0, 0, 0, 0]
            let tp_rate = 0
            let data = this.state.stats.detections.concat(this.state.stats.risk_detections)
            for (let item of data) {
                items.push(item)
                if (item["status"] === "False Positive") {
                    tp_rate++
                }

                if (item["status"] === "New" || item["status"] === "Pending") {
                    switch (item.priority) {
                        case 'P1':
                            d[0]++
                            break
                        case 'P2':
                            d[1]++
                            break
                        case 'P3':
                            d[2]++
                            break
                        case 'P4':
                            d[3]++
                            break
                    }
                }

                let country_count = []
                if (item.country && item.country[1] !== "") {
                    if (item.country[1] in country_count) {
                        country_count[item.country[1]]++
                    } else {
                        country_count[item.country[1]] = 1
                    }
                }
                Object.keys(country_count).forEach(function (key, index) {
                    map_data.push({
                        country: key,
                        value: this[key]
                    })
                }, country_count);
            }
            if (!deepEqual(this.state.table_data, items)) {
                this.setState({
                    table_data: items,
                    d: d,
                    map_data: map_data,
                    tp_rate: Math.round((tp_rate / this.state.stats.c2) * 10000) / 100
                })
            }
        }
        if (this.state.stats.c2) {
            this.set_dials()
        }

        switch (this.state.filter_index) {
            case 4:
                return this.state.table_data
            case 0:
                return this.state.table_data.filter((entry) => entry.status === "New");
            case 1:
                return this.state.table_data.filter((entry) => entry.status === "Pending");
            case 2:
                return this.state.table_data.filter((entry) => entry.status === "False Positive");
            case 3:
                return this.state.table_data.filter((entry) => entry.status === "Closed");
            default:
                return this.state.table_data
        }
    }

    set_dials() {
        let p1 = (this.state.stats.p1 / this.state.stats.c2) * 100
        if (p1 !== this.state.p[0]) {
            this.setState({
                p: [
                    p1,
                    (this.state.stats.p2 / this.state.stats.c2) * 100,
                    (this.state.stats.p3 / this.state.stats.c2) * 100,
                    (this.state.stats.p4 / this.state.stats.c2) * 100
                ],
                p_val: [
                    this.state.stats.p1,
                    this.state.stats.p2,
                    this.state.stats.p3,
                    this.state.stats.p4
                ]
            })
        }
    }

    render() {
        return (
            <div
                style={{
                    alignSelf: "stretch",
                    flex: "1",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                    padding: "8px 0px 0px",
                    gap: "8px",
                    textAlign: "left",
                    fontSize: "22px",
                    color: "#fafafa",
                    overflow: "auto"
                }}
                id="inner-data"
            >

                <ReactModal
                    ariaHideApp={false}
                    isOpen={this.state.showModal}
                    contentLabel="Tipper"
                    style={{
                        overlay: {
                            zIndex: 500,
                            background: "rgba(19, 19, 19, 0.75)"
                        },
                        content: {
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            width: "1140px",
                            height: "660px",
                            background: "#171717"
                        },
                    }}
                >
                    {
                        this.state.modal_data["priority"] ?
                            this.state.modal_data["priority"].charAt(0) === "B" ?
                                <div style={{
                                    width: "100%",
                                    height: "100%",
                                    display: "flex",
                                    flexDirection: "row",
                                    gap: "16px"
                                }}
                                >
                                    <div style={{
                                        width: "calc(50% - 8px)",
                                        height: "100%",
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "flex-start",
                                        color: "white",
                                        gap: "16px"
                                    }}
                                    >
                                        <div style={{
                                            width: "100%",
                                            display: "flex",
                                            flexDirection: "row",
                                            justifyContent: "flex-start",
                                            lineHeight: "28px",
                                            fontWeight: "600",
                                        }}>
                                            <div style={{color: "#F9B233"}}>
                                                ShadowVault Tipper
                                            </div>
                                        </div>

                                        <div className="inner-tip-box"
                                             style={{
                                                 height: "152px"
                                             }}
                                        >
                                            <div style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                gap: "4px",
                                                lineHeight: "28px",
                                                fontWeight: "600",
                                                alignItems: "center",
                                                height: "24px",
                                            }}>
                                                <img
                                                    style={{width: "24px", height: "24px"}}
                                                    alt="host-icon"
                                                    src="/main/tiphost.svg"
                                                />
                                                <div style={{
                                                    color: "#a1daf8"
                                                }}>Host Information
                                                </div>
                                            </div>

                                            <div style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                marginTop: "4px",
                                                height: "calc(100%-24px)"
                                            }}
                                                 id="host-data">
                                                <div style={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    color: "#A1A1AA",
                                                    gap: "4px",
                                                    width: "138px"
                                                }} id="section1">
                                                    <div>Client IP:</div>
                                                    <div>Hostname:</div>
                                                    <div>Host Severity:</div>
                                                    <div>Netflow:</div>
                                                </div>

                                                <div style={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    gap: "4px"
                                                }} id="section2">
                                                    <div>{this.state.modal_data["src_ip"]}</div>
                                                    <div>{this.state.modal_data["host"]}</div>
                                                    <div>{this.state.modal_data["h_severity"]}</div>
                                                    <div style={{
                                                        display: "flex",
                                                        gap: 8
                                                    }}>
                                                        <button
                                                            style={{
                                                                cursor: "pointer",
                                                                border: "none",
                                                                backgroundColor: "transparent",
                                                                justifyContent: "center",
                                                                display: "flex",
                                                                width: "21px",
                                                                height: "20px"
                                                            }}
                                                            onClick={() => this.props.open_netflow(this.state.modal_data)}
                                                            id="netflow"
                                                        >
                                                            <img src="/main/tipnet.svg" alt="netflow"/>
                                                        </button>
                                                        Forensic Data Analysis
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="inner-tip-box"
                                             style={{
                                                 height: "152px"
                                             }}
                                        >
                                            <div style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                gap: "4px",
                                                lineHeight: "28px",
                                                fontWeight: "600",
                                                alignItems: "center",
                                                height: "24px",
                                            }}>
                                                <img
                                                    style={{width: "24px", height: "24px"}}
                                                    alt=""
                                                    src="/main/dash-bdv-i.svg"
                                                />
                                                <div style={{
                                                    color: "#a1daf8"
                                                }}>Bad Domain Details
                                                </div>
                                            </div>
                                            <div style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                marginTop: "4px",
                                                height: "calc(100%-24px)"
                                            }}
                                                 id="group-data">
                                                <div style={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    color: "#A1A1AA",
                                                    gap: "4px",
                                                    width: "138px"
                                                }} id="section1">
                                                    <div>Type:</div>
                                                    <div>Domain:</div>
                                                    <div>Contacted IP:</div>
                                                    <div>Potential Intent:</div>
                                                </div>

                                                <div style={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    gap: "4px"
                                                }} id="section2">
                                                    <div>{this.state.modal_data["bdv"]["type"]}</div>
                                                    <div>{this.state.modal_data["domain"]}</div>
                                                    <div>{this.state.modal_data["dest_ip"]}</div>
                                                    <div style={{
                                                        flexDirection: 'row',
                                                        justifyContent: 'flex-start',
                                                        alignItems: 'flex-start',
                                                        gap: 4,
                                                        display: 'inline-flex'
                                                    }}
                                                    >
                                                        {this.state.modal_data["bdv"]["tags"].map((tag, i) =>
                                                            <div style={{
                                                                justifyContent: 'flex-start',
                                                                alignItems: 'flex-start',
                                                                gap: 8,
                                                                display: 'inline-flex'
                                                            }}
                                                            >
                                                                <div style={{
                                                                    paddingLeft: 8,
                                                                    paddingRight: 8,
                                                                    background: 'rgba(161, 161, 170, 0.25)',
                                                                    borderRadius: 16,
                                                                    overflow: 'hidden',
                                                                    justifyContent: 'center',
                                                                    alignItems: 'center',
                                                                    display: 'flex'
                                                                }}>
                                                                    <div style={{
                                                                        color: '#A1A1AA',
                                                                        fontSize: 14,
                                                                        fontFamily: 'Inter',
                                                                        fontWeight: '600',
                                                                        letterSpacing: 0.07,
                                                                        wordWrap: 'break-word'
                                                                    }}>{tag}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div
                                            style={{
                                                display: "flex",
                                                gap: "12px",
                                                width: "100%",
                                                height: "40px",
                                                fontSize: "13px",
                                                letterSpacing: "0.01em",
                                                lineHeight: "20px",
                                                fontFamily: "Inter",
                                                marginTop: "auto"
                                            }}
                                        >
                                            <button
                                                className="menu-entry"
                                                onClick={this.update_detection}
                                                id="0"
                                                style={{
                                                    width: "132px",
                                                    minHeight: "100%"
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        backgroundColor: this.state.modal_data["status"] === "New" ?
                                                            "rgba(244, 63, 94, 0.25)" : "rgba(113, 113, 122, 0.25)",
                                                        borderColor: this.state.modal_data["status"] === "New" ?
                                                            "rgba(244, 63, 94, 100)" : "transparent",
                                                        borderRadius: "2px",
                                                        borderWidth: "2px",
                                                        borderStyle: "solid",
                                                        width: "100%",
                                                        height: "100%",
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        alignItems: "center",
                                                        justifyContent: "center",
                                                        padding: "8px 16px",
                                                        boxSizing: "border-box",
                                                    }}
                                                >
                                                    <div
                                                        style={{
                                                            position: "relative",
                                                            color: this.state.modal_data["status"] === "New" ?
                                                                "rgba(244, 63, 94, 100)" : "white",
                                                        }}
                                                    >
                                                        New
                                                    </div>
                                                </div>
                                            </button>
                                            <button
                                                className="menu-entry"
                                                onClick={this.update_detection}
                                                id="1"
                                                style={{
                                                    width: "132px",
                                                    minHeight: "100%"
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        backgroundColor: this.state.modal_data["status"] === "Pending" ?
                                                            "rgba(245, 158, 11, 0.25)" : "rgba(113, 113, 122, 0.25)",
                                                        borderColor: this.state.modal_data["status"] === "Pending" ?
                                                            "rgba(245, 158, 11, 100)" : "transparent",
                                                        borderRadius: "2px",
                                                        borderWidth: "2px",
                                                        borderStyle: "solid",
                                                        width: "100%",
                                                        height: "100%",
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        alignItems: "center",
                                                        justifyContent: "center",
                                                        padding: "8px 16px",
                                                        boxSizing: "border-box",
                                                    }}
                                                >
                                                    <div
                                                        style={{
                                                            position: "relative",
                                                            color: this.state.modal_data["status"] === "Pending" ?
                                                                "rgba(245, 158, 11, 100)" : "white",
                                                        }}
                                                    >
                                                        Live IR
                                                    </div>
                                                </div>
                                            </button>

                                            <button
                                                className="menu-entry"
                                                onClick={this.update_detection}
                                                id="2"
                                                style={{
                                                    width: "132px",
                                                    minHeight: "100%",
                                                    marginLeft: "auto"
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        backgroundColor: this.state.modal_data["status"] === "Closed" ?
                                                            "rgba(6, 182, 212, 0.25)" : "rgba(113, 113, 122, 0.25)",
                                                        borderColor: this.state.modal_data["status"] === "Closed" ?
                                                            "rgba(6, 182, 212, 100)" : "transparent",
                                                        borderRadius: "2px",
                                                        borderWidth: "2px",
                                                        borderStyle: "solid",
                                                        width: "100%",
                                                        height: "100%",
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        alignItems: "center",
                                                        justifyContent: "center",
                                                        padding: "8px 16px",
                                                        boxSizing: "border-box",
                                                    }}
                                                >
                                                    <div
                                                        style={{
                                                            position: "relative",
                                                            color: this.state.modal_data["status"] === "Closed" ?
                                                                "rgba(6, 182, 212, 100)" : "white",
                                                        }}
                                                    >
                                                        Closed
                                                    </div>
                                                </div>
                                            </button>

                                        </div>


                                    </div>


                                    <div style={{
                                        width: "calc(50% - 8px)",
                                        height: "100%",
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "flex-start",
                                        color: "white",
                                        gap: "16px"
                                    }}
                                    >
                                        <div style={{
                                            width: "100%",
                                            display: "flex",
                                            flexDirection: "row",
                                            justifyContent: "flex-start",
                                            lineHeight: "28px",
                                            fontWeight: "600",
                                        }}
                                        >
                                            <div style={{color: "#F9B233"}}>
                                                Action History
                                            </div>
                                            <button
                                                style={{
                                                    cursor: "pointer",
                                                    border: "none",
                                                    backgroundColor: "transparent",
                                                    position: "relative",
                                                    width: "24px",
                                                    height: "24px",
                                                    marginLeft: "auto"
                                                }}
                                                id="close-modal"
                                                onClick={this.CloseModal}
                                            >
                                                <img
                                                    alt=""
                                                    src="/main/white-close.svg"
                                                />
                                            </button>
                                        </div>


                                        <div className="inner-tip-box" style={{
                                            height: "100%",
                                            gap: "16px"
                                        }}>
                                            <div style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                gap: "4px",
                                                lineHeight: "28px",
                                                fontWeight: "600",
                                                alignItems: "center",
                                                height: "24px",
                                            }}
                                            >
                                                <img
                                                    style={{width: "24px", height: "24px"}}
                                                    alt=""
                                                    src="/main/tiphistory.svg"
                                                />
                                                <div style={{
                                                    color: "#a1daf8"
                                                }}>Action History Log
                                                </div>


                                                <button
                                                    style={{
                                                        cursor: "pointer",
                                                        borderRadius: "2px",
                                                        border: "1px solid #06B6D4",
                                                        backgroundColor: "transparent",
                                                        color: "#06B6D4",
                                                        position: "relative",
                                                        marginLeft: "auto"
                                                    }}
                                                    id="Contact"
                                                    onClick={this.props.open_client_playbook}
                                                >
                                                    IR Contact
                                                </button>

                                                <button
                                                    style={{
                                                        cursor: "pointer",
                                                        borderRadius: "2px",
                                                        border: "1px solid #06B6D4",
                                                        backgroundColor: "transparent",
                                                        color: "#06B6D4",
                                                        position: "relative"
                                                    }}
                                                    id="playbook"
                                                    onClick={() => this.setState({
                                                        showPlaybook: true,
                                                        playbook: this.props.get_playbook_data(this.state.modal_data["priority"])
                                                    })}
                                                >
                                                    Playbook
                                                </button>

                                                <button
                                                    style={{
                                                        cursor: "pointer",
                                                        borderRadius: "2px",
                                                        border: "none",
                                                        backgroundColor: "rgba(6,182,212,0.25)",
                                                        color: "#06B6D4",
                                                        position: "relative",
                                                    }}
                                                    id="new-update"
                                                    onClick={() => this.setState({show_log_input: true})}
                                                >
                                                    New Update
                                                </button>
                                            </div>


                                            <div className="scroll-bar-div" style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                padding: "0px 0px 16px 8px",
                                                gap: "8px",
                                                overflowY: "scroll",
                                                overflowX: "hidden",
                                                width: "100%",
                                                height: "472px"
                                            }}
                                            >

                                                {this.state.show_log_input ?
                                                    <div
                                                        style={{
                                                            display: "flex",
                                                            flexDirection: "column",
                                                            marginTop: "16px",
                                                            width: "100%",
                                                            height: "230px",
                                                            marginRight: "8px",
                                                        }}
                                                    >
                                                        <MDEditor
                                                            value={this.state.log_input_text}
                                                            visibleDragbar={false}
                                                            hideToolbar={true}
                                                            preview="edit"
                                                            extraCommands={[]}
                                                            onChange={(data) => {
                                                                this.setState({log_input_text: data})
                                                            }}
                                                            height={200}
                                                        />
                                                        <div style={{marginLeft: "auto", marginTop: "8px"}}>
                                                            <button onClick={() => {
                                                                this.update_detection("", this.state.log_input_text)
                                                                this.setState({
                                                                    show_log_input: false,
                                                                    log_input_text: ""
                                                                })
                                                            }}
                                                                    style={{
                                                                        cursor: "pointer",
                                                                        borderRadius: "2px",
                                                                        background: "transparent",
                                                                        border: "solid 1px " + (this.state.log_input_text === "" ? "#737373" : "#06B6D4"),
                                                                        color: this.state.log_input_text === "" ? "#737373" : "#06B6D4",
                                                                        marginRight: "8px"
                                                                    }}
                                                            >
                                                                <ISubmit style={{
                                                                    color: this.state.log_input_text === "" ? "#737373" : "#06B6D4",
                                                                }}/>
                                                                Submit
                                                            </button>
                                                            <button onClick={() => {
                                                                this.setState({
                                                                    show_log_input: false,
                                                                    log_input_text: ""
                                                                })
                                                            }}
                                                                    style={{
                                                                        cursor: "pointer",
                                                                        borderRadius: "2px",
                                                                        background: "transparent",
                                                                        border: "solid 1px #06B6D4",
                                                                        color: "#06B6D4",
                                                                        marginRight: "8px"
                                                                    }}
                                                            >
                                                                Cancel
                                                            </button>
                                                        </div>
                                                    </div>
                                                    : ""
                                                }

                                                {
                                                    this.state.modal_data["log"] ?

                                                        this.state.modal_data["log"].slice(0).reverse().map((item, i) =>
                                                            <div style={{
                                                                display: "flex",
                                                                flexDirection: "column",
                                                                marginRight: "8px",
                                                                gap: "8px"
                                                            }}
                                                                 id="history-log-entry"
                                                            >
                                                                <div style={{
                                                                    display: "flex",
                                                                    flexDirection: "row",
                                                                    gap: "8px"
                                                                }}
                                                                >
                                                                    <img src="/main/tiphistoryclock-i.svg"
                                                                         alt="tip-clock"/>
                                                                    <div style={{color: "#737373"}}>
                                                                        {get_time_string(item["time"])}
                                                                    </div>
                                                                </div>
                                                                <div style={{
                                                                    display: "flex",
                                                                    flexDirection: "row",
                                                                    marginLeft: "8px",
                                                                    gap: "8px"
                                                                }}
                                                                >
                                                                    <div style={{
                                                                        background: "#71717a",
                                                                        alignSelf: "stretch",
                                                                        flexShrink: "0",
                                                                        width: "1px"
                                                                    }}/>
                                                                    <MDEditor.Markdown source={item["text"]}
                                                                                       style={{
                                                                                           padding: "16px 16px 16px 16px",
                                                                                           color: "#A1A1AA",
                                                                                           background: "#18181B",
                                                                                           borderRadius: "8px",
                                                                                           whiteSpace: 'pre-wrap',
                                                                                           width: "100%",
                                                                                           height: "100%",
                                                                                           maxHeight: "100%",
                                                                                           overflow: "auto"
                                                                                       }}
                                                                    />
                                                                </div>

                                                            </div>
                                                        )
                                                        :
                                                        ""
                                                }

                                                <div style={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    marginRight: "8px",
                                                    gap: "8px"
                                                }}
                                                     id="history-log-entry"
                                                >
                                                    <div style={{
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        gap: "8px"
                                                    }}
                                                    >
                                                        <img src="/main/tiphistoryclock-i.svg" alt="tip-clock"/>
                                                        <div style={{color: "#737373"}}>
                                                            {get_time_string(this.state.modal_data["detected"])}
                                                        </div>
                                                    </div>
                                                    <div style={{
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        marginLeft: "8px",
                                                        gap: "8px"
                                                    }}
                                                    >
                                                        <div style={{
                                                            background: "#71717a",
                                                            alignSelf: "stretch",
                                                            flexShrink: "0",
                                                            width: "1px"
                                                        }}/>
                                                        <MDEditor.Markdown
                                                            source={"New " + this.state.modal_data["priority"] + " Detection Created"}
                                                            style={{
                                                                padding: "16px 16px 16px 16px",
                                                                color: "#A1A1AA",
                                                                background: "#18181B",
                                                                borderRadius: "8px",
                                                                whiteSpace: 'pre-wrap',
                                                                width: "100%",
                                                                height: "100%",
                                                                maxHeight: "100%",
                                                                overflow: "auto"
                                                            }}
                                                        />
                                                    </div>

                                                </div>

                                            </div>
                                        </div>


                                    </div>

                                </div>
                                :
                                this.state.modal_data["priority"].charAt(0) === "S" ?
                                    <div style={{
                                        width: "100%",
                                        height: "100%",
                                        display: "flex",
                                        flexDirection: "row",
                                        gap: "16px"
                                    }}
                                    >
                                        <div style={{
                                            width: "calc(50% - 8px)",
                                            height: "100%",
                                            display: "flex",
                                            flexDirection: "column",
                                            justifyContent: "flex-start",
                                            color: "white",
                                            gap: "16px"
                                        }}
                                        >
                                            <div style={{
                                                width: "100%",
                                                display: "flex",
                                                flexDirection: "row",
                                                justifyContent: "flex-start",
                                                lineHeight: "28px",
                                                fontWeight: "600",
                                            }}>
                                                <div style={{color: "#F9B233"}}>
                                                    Supply Chain Risk Tipper
                                                </div>
                                            </div>

                                            <div className="inner-tip-box"
                                                 style={{
                                                     height: "152px"
                                                 }}
                                            >
                                                <div style={{
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    gap: "4px",
                                                    lineHeight: "28px",
                                                    fontWeight: "600",
                                                    alignItems: "center",
                                                    height: "24px",
                                                }}>
                                                    <img
                                                        style={{width: "24px", height: "24px"}}
                                                        alt="host-icon"
                                                        src="/main/tiphost.svg"
                                                    />
                                                    <div style={{
                                                        color: "#a1daf8"
                                                    }}>Host Information
                                                    </div>
                                                </div>

                                                <div style={{
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    marginTop: "4px",
                                                    height: "calc(100%-24px)"
                                                }}
                                                     id="host-data">
                                                    <div style={{
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        color: "#A1A1AA",
                                                        gap: "4px",
                                                        width: "138px"
                                                    }} id="section1">
                                                        <div>Client IP:</div>
                                                        <div>Hostname:</div>
                                                        <div>Host Severity:</div>
                                                        <div>Netflow:</div>
                                                    </div>

                                                    <div style={{
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        gap: "4px"
                                                    }} id="section2">
                                                        <div>{this.state.modal_data["src_ip"]}</div>
                                                        <div>{this.state.modal_data["host"]}</div>
                                                        <div>{this.state.modal_data["h_severity"]}</div>
                                                        <div style={{
                                                            display: "flex",
                                                            gap: 8
                                                        }}>
                                                            <button
                                                                style={{
                                                                    cursor: "pointer",
                                                                    border: "none",
                                                                    backgroundColor: "transparent",
                                                                    justifyContent: "center",
                                                                    display: "flex",
                                                                    width: "21px",
                                                                    height: "20px"
                                                                }}
                                                                onClick={() => this.props.open_netflow(this.state.modal_data)}
                                                                id="netflow"
                                                            >
                                                                <img src="/main/tipnet.svg" alt="netflow"/>
                                                            </button>
                                                            Forensic Data Analysis
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="inner-tip-box"
                                                 style={{
                                                     height: "268px"
                                                 }}
                                            >
                                                <div style={{
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    gap: "4px",
                                                    lineHeight: "28px",
                                                    fontWeight: "600",
                                                    alignItems: "center",
                                                    height: "24px",
                                                }}>
                                                    <img
                                                        style={{width: "24px", height: "24px"}}
                                                        alt=""
                                                        src="/main/breach-details-i.svg"
                                                    />
                                                    <div style={{
                                                        color: "#a1daf8"
                                                    }}>Breach Details
                                                    </div>
                                                </div>
                                                <div style={{
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    marginTop: "4px",
                                                    height: "calc(100%-24px)"
                                                }}
                                                     id="group-data">
                                                    <div style={{
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        color: "#A1A1AA",
                                                        gap: "4px",
                                                        width: "138px"
                                                    }} id="section1">
                                                        <div>Supplier Domain:</div>
                                                        <div>Contacted IP:</div>
                                                        <div>Country:</div>
                                                        <div>Breach Date:</div>
                                                        <div>Breach Type:</div>
                                                        <div>Threat Group:</div>
                                                        <div>Source:</div>
                                                        <div>Severity:</div>
                                                    </div>

                                                    <div style={{
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        gap: "4px"
                                                    }} id="section2">
                                                        <div>{this.state.modal_data["domain"]}</div>
                                                        <div>{this.state.modal_data["dest_ip"]}</div>
                                                        {this.getBreachCountryRender()}
                                                        {this.getBreachDate()}
                                                        <div>{this.state.modal_data["breach_type"]}</div>
                                                        <div>{this.state.modal_data["group"] ? this.state.modal_data["group"] : "N/A"}</div>
                                                        {this.getBreachURL()}
                                                        <div>{this.state.modal_data["severity"]}</div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div
                                                style={{
                                                    display: "flex",
                                                    gap: "12px",
                                                    width: "100%",
                                                    height: "40px",
                                                    fontSize: "13px",
                                                    letterSpacing: "0.01em",
                                                    lineHeight: "20px",
                                                    fontFamily: "Inter",
                                                    marginTop: "auto"
                                                }}
                                            >
                                                <button
                                                    className="menu-entry"
                                                    onClick={this.update_detection}
                                                    id="0"
                                                    style={{
                                                        width: "132px",
                                                        minHeight: "100%"
                                                    }}
                                                >
                                                    <div
                                                        style={{
                                                            backgroundColor: this.state.modal_data["status"] === "New" ?
                                                                "rgba(244, 63, 94, 0.25)" : "rgba(113, 113, 122, 0.25)",
                                                            borderColor: this.state.modal_data["status"] === "New" ?
                                                                "rgba(244, 63, 94, 100)" : "transparent",
                                                            borderRadius: "2px",
                                                            borderWidth: "2px",
                                                            borderStyle: "solid",
                                                            width: "100%",
                                                            height: "100%",
                                                            display: "flex",
                                                            flexDirection: "row",
                                                            alignItems: "center",
                                                            justifyContent: "center",
                                                            padding: "8px 16px",
                                                            boxSizing: "border-box",
                                                        }}
                                                    >
                                                        <div
                                                            style={{
                                                                position: "relative",
                                                                color: this.state.modal_data["status"] === "New" ?
                                                                    "rgba(244, 63, 94, 100)" : "white",
                                                            }}
                                                        >
                                                            New
                                                        </div>
                                                    </div>
                                                </button>
                                                <button
                                                    className="menu-entry"
                                                    onClick={this.update_detection}
                                                    id="1"
                                                    style={{
                                                        width: "132px",
                                                        minHeight: "100%"
                                                    }}
                                                >
                                                    <div
                                                        style={{
                                                            backgroundColor: this.state.modal_data["status"] === "Pending" ?
                                                                "rgba(245, 158, 11, 0.25)" : "rgba(113, 113, 122, 0.25)",
                                                            borderColor: this.state.modal_data["status"] === "Pending" ?
                                                                "rgba(245, 158, 11, 100)" : "transparent",
                                                            borderRadius: "2px",
                                                            borderWidth: "2px",
                                                            borderStyle: "solid",
                                                            width: "100%",
                                                            height: "100%",
                                                            display: "flex",
                                                            flexDirection: "row",
                                                            alignItems: "center",
                                                            justifyContent: "center",
                                                            padding: "8px 16px",
                                                            boxSizing: "border-box",
                                                        }}
                                                    >
                                                        <div
                                                            style={{
                                                                position: "relative",
                                                                color: this.state.modal_data["status"] === "Pending" ?
                                                                    "rgba(245, 158, 11, 100)" : "white",
                                                            }}
                                                        >
                                                            Live IR
                                                        </div>
                                                    </div>
                                                </button>

                                                <button
                                                    className="menu-entry"
                                                    onClick={this.update_detection}
                                                    id="2"
                                                    style={{
                                                        width: "132px",
                                                        minHeight: "100%",
                                                        marginLeft: "auto"
                                                    }}
                                                >
                                                    <div
                                                        style={{
                                                            backgroundColor: this.state.modal_data["status"] === "Closed" ?
                                                                "rgba(6, 182, 212, 0.25)" : "rgba(113, 113, 122, 0.25)",
                                                            borderColor: this.state.modal_data["status"] === "Closed" ?
                                                                "rgba(6, 182, 212, 100)" : "transparent",
                                                            borderRadius: "2px",
                                                            borderWidth: "2px",
                                                            borderStyle: "solid",
                                                            width: "100%",
                                                            height: "100%",
                                                            display: "flex",
                                                            flexDirection: "row",
                                                            alignItems: "center",
                                                            justifyContent: "center",
                                                            padding: "8px 16px",
                                                            boxSizing: "border-box",
                                                        }}
                                                    >
                                                        <div
                                                            style={{
                                                                position: "relative",
                                                                color: this.state.modal_data["status"] === "Closed" ?
                                                                    "rgba(6, 182, 212, 100)" : "white",
                                                            }}
                                                        >
                                                            Closed
                                                        </div>
                                                    </div>
                                                </button>

                                            </div>


                                        </div>


                                        <div style={{
                                            width: "calc(50% - 8px)",
                                            height: "100%",
                                            display: "flex",
                                            flexDirection: "column",
                                            justifyContent: "flex-start",
                                            color: "white",
                                            gap: "16px"
                                        }}
                                        >
                                            <div style={{
                                                width: "100%",
                                                display: "flex",
                                                flexDirection: "row",
                                                justifyContent: "flex-start",
                                                lineHeight: "28px",
                                                fontWeight: "600",
                                            }}
                                            >
                                                <div style={{color: "#F9B233"}}>
                                                    Action History
                                                </div>
                                                <button
                                                    style={{
                                                        cursor: "pointer",
                                                        border: "none",
                                                        backgroundColor: "transparent",
                                                        position: "relative",
                                                        width: "24px",
                                                        height: "24px",
                                                        marginLeft: "auto"
                                                    }}
                                                    id="close-modal"
                                                    onClick={this.CloseModal}
                                                >
                                                    <img
                                                        alt=""
                                                        src="/main/white-close.svg"
                                                    />
                                                </button>
                                            </div>


                                            <div className="inner-tip-box" style={{
                                                height: "100%",
                                                gap: "16px"
                                            }}>
                                                <div style={{
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    gap: "4px",
                                                    lineHeight: "28px",
                                                    fontWeight: "600",
                                                    alignItems: "center",
                                                    height: "24px",
                                                }}
                                                >
                                                    <img
                                                        style={{width: "24px", height: "24px"}}
                                                        alt=""
                                                        src="/main/tiphistory.svg"
                                                    />
                                                    <div style={{
                                                        color: "#a1daf8"
                                                    }}>Action History Log
                                                    </div>


                                                    <button
                                                        style={{
                                                            cursor: "pointer",
                                                            borderRadius: "2px",
                                                            border: "1px solid #06B6D4",
                                                            backgroundColor: "transparent",
                                                            color: "#06B6D4",
                                                            position: "relative",
                                                            marginLeft: "auto"
                                                        }}
                                                        id="Contact"
                                                        onClick={this.props.open_client_playbook}
                                                    >
                                                        IR Contact
                                                    </button>

                                                    <button
                                                        style={{
                                                            cursor: "pointer",
                                                            borderRadius: "2px",
                                                            border: "1px solid #06B6D4",
                                                            backgroundColor: "transparent",
                                                            color: "#06B6D4",
                                                            position: "relative"
                                                        }}
                                                        id="playbook"
                                                        onClick={() => this.setState({
                                                            showPlaybook: true,
                                                            playbook: this.props.get_playbook_data(this.state.modal_data["priority"])
                                                        })}
                                                    >
                                                        Playbook
                                                    </button>

                                                    <button
                                                        style={{
                                                            cursor: "pointer",
                                                            borderRadius: "2px",
                                                            border: "none",
                                                            backgroundColor: "rgba(6,182,212,0.25)",
                                                            color: "#06B6D4",
                                                            position: "relative",
                                                        }}
                                                        id="new-update"
                                                        onClick={() => this.setState({show_log_input: true})}
                                                    >
                                                        New Update
                                                    </button>
                                                </div>


                                                <div className="scroll-bar-div" style={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    padding: "0px 0px 16px 8px",
                                                    gap: "8px",
                                                    overflowY: "scroll",
                                                    overflowX: "hidden",
                                                    width: "100%",
                                                    height: "472px"
                                                }}
                                                >

                                                    {this.state.show_log_input ?
                                                        <div
                                                            style={{
                                                                display: "flex",
                                                                flexDirection: "column",
                                                                marginTop: "16px",
                                                                width: "100%",
                                                                height: "230px",
                                                                marginRight: "8px",
                                                            }}
                                                        >
                                                            <MDEditor
                                                                value={this.state.log_input_text}
                                                                visibleDragbar={false}
                                                                hideToolbar={true}
                                                                preview="edit"
                                                                extraCommands={[]}
                                                                onChange={(data) => {
                                                                    this.setState({log_input_text: data})
                                                                }}
                                                                height={200}
                                                            />
                                                            <div style={{marginLeft: "auto", marginTop: "8px"}}>
                                                                <button onClick={() => {
                                                                    this.update_detection("", this.state.log_input_text)
                                                                    this.setState({
                                                                        show_log_input: false,
                                                                        log_input_text: ""
                                                                    })
                                                                }}
                                                                        style={{
                                                                            cursor: "pointer",
                                                                            borderRadius: "2px",
                                                                            background: "transparent",
                                                                            border: "solid 1px " + (this.state.log_input_text === "" ? "#737373" : "#06B6D4"),
                                                                            color: this.state.log_input_text === "" ? "#737373" : "#06B6D4",
                                                                            marginRight: "8px"
                                                                        }}
                                                                >
                                                                    <ISubmit style={{
                                                                        color: this.state.log_input_text === "" ? "#737373" : "#06B6D4",
                                                                    }}/>
                                                                    Submit
                                                                </button>
                                                                <button onClick={() => {
                                                                    this.setState({
                                                                        show_log_input: false,
                                                                        log_input_text: ""
                                                                    })
                                                                }}
                                                                        style={{
                                                                            cursor: "pointer",
                                                                            borderRadius: "2px",
                                                                            background: "transparent",
                                                                            border: "solid 1px #06B6D4",
                                                                            color: "#06B6D4",
                                                                            marginRight: "8px"
                                                                        }}
                                                                >
                                                                    Cancel
                                                                </button>
                                                            </div>
                                                        </div>
                                                        : ""
                                                    }

                                                    {
                                                        this.state.modal_data["log"] ?

                                                            this.state.modal_data["log"].slice(0).reverse().map((item, i) =>
                                                                <div style={{
                                                                    display: "flex",
                                                                    flexDirection: "column",
                                                                    marginRight: "8px",
                                                                    gap: "8px"
                                                                }}
                                                                     id="history-log-entry"
                                                                >
                                                                    <div style={{
                                                                        display: "flex",
                                                                        flexDirection: "row",
                                                                        gap: "8px"
                                                                    }}
                                                                    >
                                                                        <img src="/main/tiphistoryclock-i.svg"
                                                                             alt="tip-clock"/>
                                                                        <div style={{color: "#737373"}}>
                                                                            {get_time_string(item["time"])}
                                                                        </div>
                                                                    </div>
                                                                    <div style={{
                                                                        display: "flex",
                                                                        flexDirection: "row",
                                                                        marginLeft: "8px",
                                                                        gap: "8px"
                                                                    }}
                                                                    >
                                                                        <div style={{
                                                                            background: "#71717a",
                                                                            alignSelf: "stretch",
                                                                            flexShrink: "0",
                                                                            width: "1px"
                                                                        }}/>
                                                                        <MDEditor.Markdown source={item["text"]}
                                                                                           style={{
                                                                                               padding: "16px 16px 16px 16px",
                                                                                               color: "#A1A1AA",
                                                                                               background: "#18181B",
                                                                                               borderRadius: "8px",
                                                                                               whiteSpace: 'pre-wrap',
                                                                                               width: "100%",
                                                                                               height: "100%",
                                                                                               maxHeight: "100%",
                                                                                               overflow: "auto"
                                                                                           }}
                                                                        />
                                                                    </div>

                                                                </div>
                                                            )
                                                            :
                                                            ""
                                                    }

                                                    <div style={{
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        marginRight: "8px",
                                                        gap: "8px"
                                                    }}
                                                         id="history-log-entry"
                                                    >
                                                        <div style={{
                                                            display: "flex",
                                                            flexDirection: "row",
                                                            gap: "8px"
                                                        }}
                                                        >
                                                            <img src="/main/tiphistoryclock-i.svg" alt="tip-clock"/>
                                                            <div style={{color: "#737373"}}>
                                                                {get_time_string(this.state.modal_data["detected"])}
                                                            </div>
                                                        </div>
                                                        <div style={{
                                                            display: "flex",
                                                            flexDirection: "row",
                                                            marginLeft: "8px",
                                                            gap: "8px"
                                                        }}
                                                        >
                                                            <div style={{
                                                                background: "#71717a",
                                                                alignSelf: "stretch",
                                                                flexShrink: "0",
                                                                width: "1px"
                                                            }}/>
                                                            <MDEditor.Markdown
                                                                source={"New " + this.state.modal_data["priority"] + " Detection Created"}
                                                                style={{
                                                                    padding: "16px 16px 16px 16px",
                                                                    color: "#A1A1AA",
                                                                    background: "#18181B",
                                                                    borderRadius: "8px",
                                                                    whiteSpace: 'pre-wrap',
                                                                    width: "100%",
                                                                    height: "100%",
                                                                    maxHeight: "100%",
                                                                    overflow: "auto"
                                                                }}
                                                            />
                                                        </div>

                                                    </div>

                                                </div>
                                            </div>


                                        </div>

                                    </div>
                                    :
                                    this.state.modal_data["priority"].charAt(0) === "C" ?
                                        <div style={{
                                            width: "100%",
                                            height: "100%",
                                            display: "flex",
                                            flexDirection: "row",
                                            gap: "16px"
                                        }}
                                        >
                                            <div style={{
                                                width: "calc(50% - 8px)",
                                                height: "100%",
                                                display: "flex",
                                                flexDirection: "column",
                                                justifyContent: "flex-start",
                                                color: "white",
                                                gap: "16px"
                                            }}
                                            >
                                                <div style={{
                                                    width: "100%",
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    justifyContent: "flex-start",
                                                    lineHeight: "28px",
                                                    fontWeight: "600",
                                                }}>
                                                    <div style={{color: "#F9B233"}}>
                                                        ShadowBreach Tipper
                                                    </div>
                                                </div>

                                                <div className="inner-tip-box"
                                                     style={{
                                                         height: "510px",
                                                     }}>
                                                    <div style={{
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        gap: "4px",
                                                        lineHeight: "28px",
                                                        fontWeight: "600",
                                                        alignItems: "center",
                                                    }}
                                                    >
                                                        <img
                                                            style={{width: "24px", height: "24px"}}
                                                            alt=""
                                                            src="/main/tiphistory.svg"
                                                        />
                                                        <div style={{
                                                            color: "#a1daf8"
                                                        }}>Compromised Credentials Log
                                                        </div>
                                                    </div>

                                                    <div className="scroll-bar-div" style={{
                                                        marginTop: "12px",
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        padding: "0px 0px 16px 8px",
                                                        gap: "8px",
                                                        overflowY: "scroll",
                                                        overflowX: "hidden",
                                                        width: "100%",
                                                        height: "472px"
                                                    }}
                                                    >
                                                        {
                                                            this.state.modal_data["Credentials"].map((item, i) =>
                                                                <div style={{
                                                                    display: "flex",
                                                                    flexDirection: "column",
                                                                    marginRight: "8px",
                                                                    gap: "8px"
                                                                }}
                                                                     id="credentials-log-entry"
                                                                >
                                                                    <div style={{
                                                                        display: "flex",
                                                                        flexDirection: "row",
                                                                        gap: "8px"
                                                                    }}
                                                                    >
                                                                        <img src="/main/tipcred-i.svg" alt="tip-cred"/>
                                                                        <div style={{
                                                                            width: "100%",
                                                                            alignItems: "center",
                                                                            display: "flex",
                                                                            gap: "16px",
                                                                        }}>
                                                                            <div>Credential
                                                                                #{(i + 1).toString().padStart(4, '0')}</div>
                                                                            <div style={{
                                                                                marginLeft: "auto",
                                                                                display: "flex",
                                                                                gap: "8px"
                                                                            }}
                                                                            >
                                                                                <MuiTooltip
                                                                                    title={this.props.tooltip_info("ILogin")}
                                                                                    arrow>
                                                                                    <ILogin style={{
                                                                                        color: item["CredentialType"].includes("Login") ? this.getCredColors(item) : "#71717A",
                                                                                    }}/>
                                                                                </MuiTooltip>
                                                                                <MuiTooltip
                                                                                    title={this.props.tooltip_info("IPass")}
                                                                                    arrow>
                                                                                    <IPass style={{
                                                                                        color: item["CredentialType"].includes("Password") ? this.getCredColors(item) : "#71717A"
                                                                                    }}/>
                                                                                </MuiTooltip>
                                                                                <MuiTooltip
                                                                                    title={this.props.tooltip_info("ICookie")}
                                                                                    arrow>
                                                                                    <ICookie style={{
                                                                                        color: item["CredentialType"].includes("Cookie") ? this.getCredColors(item) : "#71717A"
                                                                                    }}/>
                                                                                </MuiTooltip>
                                                                            </div>
                                                                            <div style={{
                                                                                fontWeight: "bold"
                                                                            }}>{item["Price"]}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div style={{
                                                                        display: "flex",
                                                                        flexDirection: "row",
                                                                        marginLeft: "8px",
                                                                        gap: "8px"
                                                                    }}
                                                                    >
                                                                        <div style={{
                                                                            background: "#71717a",
                                                                            alignSelf: "stretch",
                                                                            flexShrink: "0",
                                                                            width: "1px"
                                                                        }}/>
                                                                        <div style={{
                                                                            borderRadius: "8px",
                                                                            padding: "16px 16px 16px 16px",
                                                                            whiteSpace: 'pre-wrap',
                                                                            background: "#18181B",
                                                                            width: "100%"
                                                                        }}>
                                                                            <div style={{
                                                                                display: "flex",
                                                                                flexDirection: "row",
                                                                            }}
                                                                                 id="malware-data">
                                                                                <div style={{
                                                                                    display: "flex",
                                                                                    flexDirection: "column",
                                                                                    color: "#A1A1AA",
                                                                                    gap: "4px",
                                                                                    width: "108px"
                                                                                }} id="section1">
                                                                                    <div>Auth ID:</div>
                                                                                    <div>&nbsp;</div>
                                                                                    <div>Date for Sale:</div>
                                                                                    <div>Domain:</div>
                                                                                    <div>ISP:</div>
                                                                                    <div>Malware:</div>
                                                                                    <div>Vendor:</div>
                                                                                    <div>Device Type:</div>
                                                                                </div>

                                                                                <div style={{
                                                                                    display: "flex",
                                                                                    flexDirection: "column",
                                                                                    gap: "4px"
                                                                                }} id="section2">
                                                                                    <div style={{
                                                                                        display: "flex",
                                                                                        gap: 4
                                                                                    }}>
                                                                                        <button
                                                                                            onClick={() => {
                                                                                                navigator.clipboard.writeText(item["Id"]).then({})
                                                                                            }}
                                                                                            style={{
                                                                                                backgroundColor: "transparent",
                                                                                                display: "flex",
                                                                                                alignItems: "center",
                                                                                                justifyContent: "center",
                                                                                                cursor: "pointer",
                                                                                                width: 24,
                                                                                                height: 24,
                                                                                                border: "none",
                                                                                            }}
                                                                                        >
                                                                                            <img
                                                                                                src="/main/tipcopy-i.svg"
                                                                                                alt="copy"/>
                                                                                        </button>
                                                                                        <div style={{
                                                                                            paddingLeft: 4,
                                                                                            paddingRight: 4,
                                                                                            height: 24,
                                                                                            background: '#27272A',
                                                                                            borderRadius: 4,
                                                                                            alignItems: "center",
                                                                                            justifyContent: "center",
                                                                                            display: 'inline-flex'
                                                                                        }}
                                                                                        >
                                                                                            <div style={{
                                                                                                color: '#F59E0B',
                                                                                                fontSize: 14,
                                                                                                fontWeight: '600',
                                                                                                wordWrap: 'break-word'
                                                                                            }}>
                                                                                                {item["Id"]}
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div>&nbsp;</div>
                                                                                    <div>{item["DateForSale"]}</div>
                                                                                    <div>{item["Domain"]}</div>
                                                                                    <div>{item["ISP"]}</div>
                                                                                    <div>{item["Malware"]}</div>
                                                                                    <div>{item["CriminalVendor"]}</div>
                                                                                    <div>{item["DeviceType"]}</div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )}

                                                    </div>
                                                </div>

                                                <div
                                                    style={{
                                                        display: "flex",
                                                        marginTop: "auto",
                                                        gap: "12px",
                                                        width: "100%",
                                                        height: "40px",
                                                        fontSize: "13px",
                                                        letterSpacing: "0.01em",
                                                        lineHeight: "20px",
                                                        fontFamily: "Inter",
                                                    }}
                                                >
                                                    <button
                                                        className="menu-entry"
                                                        onClick={this.update_detection}
                                                        id="0"
                                                        style={{
                                                            width: "132px",
                                                            minHeight: "100%"
                                                        }}
                                                    >
                                                        <div
                                                            style={{
                                                                backgroundColor: this.state.modal_data["status"] === "New" ?
                                                                    "rgba(244, 63, 94, 0.25)" : "rgba(113, 113, 122, 0.25)",
                                                                borderColor: this.state.modal_data["status"] === "New" ?
                                                                    "rgba(244, 63, 94, 100)" : "transparent",
                                                                borderRadius: "2px",
                                                                borderWidth: "2px",
                                                                borderStyle: "solid",
                                                                width: "100%",
                                                                height: "100%",
                                                                display: "flex",
                                                                flexDirection: "row",
                                                                alignItems: "center",
                                                                justifyContent: "center",
                                                                padding: "8px 16px",
                                                                boxSizing: "border-box",
                                                            }}
                                                        >
                                                            <div
                                                                style={{
                                                                    position: "relative",
                                                                    color: this.state.modal_data["status"] === "New" ?
                                                                        "rgba(244, 63, 94, 100)" : "white",
                                                                }}
                                                            >
                                                                New
                                                            </div>
                                                        </div>
                                                    </button>
                                                    <button
                                                        className="menu-entry"
                                                        onClick={this.update_detection}
                                                        id="1"
                                                        style={{
                                                            width: "132px",
                                                            minHeight: "100%"
                                                        }}
                                                    >
                                                        <div
                                                            style={{
                                                                backgroundColor: this.state.modal_data["status"] === "Pending" ?
                                                                    "rgba(245, 158, 11, 0.25)" : "rgba(113, 113, 122, 0.25)",
                                                                borderColor: this.state.modal_data["status"] === "Pending" ?
                                                                    "rgba(245, 158, 11, 100)" : "transparent",
                                                                borderRadius: "2px",
                                                                borderWidth: "2px",
                                                                borderStyle: "solid",
                                                                width: "100%",
                                                                height: "100%",
                                                                display: "flex",
                                                                flexDirection: "row",
                                                                alignItems: "center",
                                                                justifyContent: "center",
                                                                padding: "8px 16px",
                                                                boxSizing: "border-box",
                                                            }}
                                                        >
                                                            <div
                                                                style={{
                                                                    position: "relative",
                                                                    color: this.state.modal_data["status"] === "Pending" ?
                                                                        "rgba(245, 158, 11, 100)" : "white",
                                                                }}
                                                            >
                                                                Live IR
                                                            </div>
                                                        </div>
                                                    </button>
                                                    <button
                                                        className="menu-entry"
                                                        onClick={this.update_detection}
                                                        id="2"
                                                        style={{
                                                            width: "132px",
                                                            minHeight: "100%",
                                                            marginLeft: "auto"
                                                        }}
                                                    >
                                                        <div
                                                            style={{
                                                                backgroundColor: this.state.modal_data["status"] === "Closed" ?
                                                                    "rgba(6, 182, 212, 0.25)" : "rgba(113, 113, 122, 0.25)",
                                                                borderColor: this.state.modal_data["status"] === "Closed" ?
                                                                    "rgba(6, 182, 212, 100)" : "transparent",
                                                                borderRadius: "2px",
                                                                borderWidth: "2px",
                                                                borderStyle: "solid",
                                                                width: "100%",
                                                                height: "100%",
                                                                display: "flex",
                                                                flexDirection: "row",
                                                                alignItems: "center",
                                                                justifyContent: "center",
                                                                padding: "8px 16px",
                                                                boxSizing: "border-box",
                                                            }}
                                                        >
                                                            <div
                                                                style={{
                                                                    position: "relative",
                                                                    color: this.state.modal_data["status"] === "Closed" ?
                                                                        "rgba(6, 182, 212, 100)" : "white",
                                                                }}
                                                            >
                                                                Closed
                                                            </div>
                                                        </div>
                                                    </button>

                                                </div>


                                            </div>


                                            <div style={{
                                                width: "calc(50% - 8px)",
                                                height: "100%",
                                                display: "flex",
                                                flexDirection: "column",
                                                justifyContent: "flex-start",
                                                color: "white",
                                                gap: "16px"
                                            }}
                                            >
                                                <div style={{
                                                    width: "100%",
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    justifyContent: "flex-start",
                                                    lineHeight: "28px",
                                                    fontWeight: "600",
                                                }}
                                                >
                                                    <div style={{color: "#F9B233"}}>
                                                        Action History
                                                    </div>
                                                    <button
                                                        style={{
                                                            cursor: "pointer",
                                                            border: "none",
                                                            backgroundColor: "transparent",
                                                            position: "relative",
                                                            width: "24px",
                                                            height: "24px",
                                                            marginLeft: "auto"
                                                        }}
                                                        id="close-modal"
                                                        onClick={this.CloseModal}
                                                    >
                                                        <img
                                                            alt=""
                                                            src="/main/white-close.svg"
                                                        />
                                                    </button>
                                                </div>


                                                <div className="inner-tip-box" style={{
                                                    height: "100%",
                                                    gap: "16px"
                                                }}>
                                                    <div style={{
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        gap: "4px",
                                                        lineHeight: "28px",
                                                        fontWeight: "600",
                                                        alignItems: "center",
                                                        height: "24px",
                                                    }}
                                                    >
                                                        <img
                                                            style={{width: "24px", height: "24px"}}
                                                            alt=""
                                                            src="/main/tiphistory.svg"
                                                        />
                                                        <div style={{
                                                            color: "#a1daf8"
                                                        }}>Action History Log
                                                        </div>

                                                        <button
                                                            style={{
                                                                cursor: "pointer",
                                                                borderRadius: "2px",
                                                                border: "1px solid #06B6D4",
                                                                backgroundColor: "transparent",
                                                                color: "#06B6D4",
                                                                position: "relative",
                                                                marginLeft: "auto"
                                                            }}
                                                            id="Contact"
                                                            onClick={this.props.open_client_playbook}
                                                        >
                                                            IR Contact
                                                        </button>

                                                        <button
                                                            style={{
                                                                cursor: "pointer",
                                                                borderRadius: "2px",
                                                                border: "1px solid #06B6D4",
                                                                backgroundColor: "transparent",
                                                                color: "#06B6D4",
                                                                position: "relative"
                                                            }}
                                                            id="playbook"
                                                            onClick={() => this.setState({
                                                                showPlaybook: true,
                                                                playbook: this.props.get_playbook_data(this.state.modal_data["priority"])
                                                            })}
                                                        >
                                                            Playbook
                                                        </button>

                                                        <button
                                                            style={{
                                                                cursor: "pointer",
                                                                borderRadius: "2px",
                                                                border: "none",
                                                                backgroundColor: "rgba(6,182,212,0.25)",
                                                                color: "#06B6D4",
                                                                position: "relative",
                                                            }}
                                                            id="new-update"
                                                            onClick={() => this.setState({show_log_input: true})}
                                                        >
                                                            New Update
                                                        </button>
                                                    </div>


                                                    <div className="scroll-bar-div" style={{
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        padding: "0px 0px 16px 8px",
                                                        gap: "8px",
                                                        overflowY: "scroll",
                                                        overflowX: "hidden",
                                                        width: "100%",
                                                        height: "472px"
                                                    }}
                                                    >

                                                        {this.state.show_log_input ?
                                                            <div
                                                                style={{
                                                                    display: "flex",
                                                                    flexDirection: "column",
                                                                    marginTop: "16px",
                                                                    width: "100%",
                                                                    height: "230px",
                                                                    marginRight: "8px",
                                                                }}
                                                            >
                                                                <MDEditor
                                                                    value={this.state.log_input_text}
                                                                    visibleDragbar={false}
                                                                    hideToolbar={true}
                                                                    preview="edit"
                                                                    extraCommands={[]}
                                                                    onChange={(data) => {
                                                                        this.setState({log_input_text: data})
                                                                    }}
                                                                    height={200}
                                                                />
                                                                <div style={{marginLeft: "auto", marginTop: "8px"}}>
                                                                    <button onClick={() => {
                                                                        this.update_detection("", this.state.log_input_text)
                                                                        this.setState({
                                                                            show_log_input: false,
                                                                            log_input_text: ""
                                                                        })
                                                                    }}
                                                                            style={{
                                                                                cursor: "pointer",
                                                                                borderRadius: "2px",
                                                                                background: "transparent",
                                                                                border: "solid 1px " + (this.state.log_input_text === "" ? "#737373" : "#06B6D4"),
                                                                                color: this.state.log_input_text === "" ? "#737373" : "#06B6D4",
                                                                                marginRight: "8px"
                                                                            }}
                                                                    >
                                                                        <ISubmit style={{
                                                                            color: this.state.log_input_text === "" ? "#737373" : "#06B6D4",
                                                                        }}/>
                                                                        Submit
                                                                    </button>
                                                                    <button onClick={() => {
                                                                        this.setState({
                                                                            show_log_input: false,
                                                                            log_input_text: ""
                                                                        })
                                                                    }}
                                                                            style={{
                                                                                cursor: "pointer",
                                                                                borderRadius: "2px",
                                                                                background: "transparent",
                                                                                border: "solid 1px #06B6D4",
                                                                                color: "#06B6D4",
                                                                                marginRight: "8px"
                                                                            }}
                                                                    >
                                                                        Cancel
                                                                    </button>
                                                                </div>
                                                            </div>
                                                            : ""
                                                        }

                                                        {
                                                            this.state.modal_data["log"] ?

                                                                this.state.modal_data["log"].slice(0).reverse().map((item, i) =>
                                                                    <div style={{
                                                                        display: "flex",
                                                                        flexDirection: "column",
                                                                        marginRight: "8px",
                                                                        gap: "8px"
                                                                    }}
                                                                         id="history-log-entry"
                                                                    >
                                                                        <div style={{
                                                                            display: "flex",
                                                                            flexDirection: "row",
                                                                            gap: "8px"
                                                                        }}
                                                                        >
                                                                            <img src="/main/tiphistoryclock-i.svg"
                                                                                 alt="tip-clock"/>
                                                                            <div style={{color: "#737373"}}>
                                                                                {get_time_string(item["time"])}
                                                                            </div>
                                                                        </div>
                                                                        <div style={{
                                                                            display: "flex",
                                                                            flexDirection: "row",
                                                                            marginLeft: "8px",
                                                                            gap: "8px"
                                                                        }}
                                                                        >
                                                                            <div style={{
                                                                                background: "#71717a",
                                                                                alignSelf: "stretch",
                                                                                flexShrink: "0",
                                                                                width: "1px"
                                                                            }}/>
                                                                            <MDEditor.Markdown source={item["text"]}
                                                                                               style={{
                                                                                                   padding: "16px 16px 16px 16px",
                                                                                                   color: "#A1A1AA",
                                                                                                   background: "#18181B",
                                                                                                   borderRadius: "8px",
                                                                                                   whiteSpace: 'pre-wrap',
                                                                                                   width: "100%",
                                                                                                   height: "100%",
                                                                                                   maxHeight: "100%",
                                                                                                   overflow: "auto"
                                                                                               }}
                                                                            />
                                                                        </div>

                                                                    </div>
                                                                )
                                                                :
                                                                ""
                                                        }

                                                        <div style={{
                                                            display: "flex",
                                                            flexDirection: "column",
                                                            marginRight: "8px",
                                                            gap: "8px"
                                                        }}
                                                             id="history-log-entry"
                                                        >
                                                            <div style={{
                                                                display: "flex",
                                                                flexDirection: "row",
                                                                gap: "8px"
                                                            }}
                                                            >
                                                                <img src="/main/tiphistoryclock-i.svg" alt="tip-clock"/>
                                                                <div style={{color: "#737373"}}>
                                                                    {get_time_string(this.state.modal_data["created"])}
                                                                </div>
                                                            </div>
                                                            <div style={{
                                                                display: "flex",
                                                                flexDirection: "row",
                                                                marginLeft: "8px",
                                                                gap: "8px"
                                                            }}
                                                            >
                                                                <div style={{
                                                                    background: "#71717a",
                                                                    alignSelf: "stretch",
                                                                    flexShrink: "0",
                                                                    width: "1px"
                                                                }}/>
                                                                <MDEditor.Markdown
                                                                    source={"New " + this.state.modal_data["priority"] + " Detection Created"}
                                                                    style={{
                                                                        padding: "16px 16px 16px 16px",
                                                                        color: "#A1A1AA",
                                                                        background: "#18181B",
                                                                        borderRadius: "8px",
                                                                        whiteSpace: 'pre-wrap',
                                                                        width: "100%",
                                                                        height: "100%",
                                                                        maxHeight: "100%",
                                                                        overflow: "auto"
                                                                    }}
                                                                />
                                                            </div>

                                                        </div>

                                                    </div>
                                                </div>


                                            </div>

                                        </div>
                                        :
                                        <div style={{
                                            width: "100%",
                                            height: "100%",
                                            display: "flex",
                                            flexDirection: "row",
                                            gap: "16px"
                                        }}
                                        >
                                            <div style={{
                                                width: "calc(50% - 8px)",
                                                height: "100%",
                                                display: "flex",
                                                flexDirection: "column",
                                                justifyContent: "flex-start",
                                                color: "white",
                                                gap: "16px"
                                            }}
                                            >
                                                <div style={{
                                                    width: "100%",
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    justifyContent: "flex-start",
                                                    lineHeight: "28px",
                                                    fontWeight: "600",
                                                }}>
                                                    <div style={{color: "#F9B233"}}>
                                                        Tactical Threat Intelligence Tipper
                                                    </div>
                                                </div>

                                                <div className="inner-tip-box" style={{
                                                    height: 150
                                                }}>
                                                    <div style={{
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        gap: "4px",
                                                        lineHeight: "28px",
                                                        fontWeight: "600",
                                                        alignItems: "center",
                                                        height: "24px",
                                                    }}>
                                                        <img
                                                            style={{width: "24px", height: "24px"}}
                                                            alt=""
                                                            src="/main/tiphost.svg"
                                                        />
                                                        <div style={{
                                                            color: "#a1daf8"
                                                        }}>Host Information
                                                        </div>
                                                    </div>

                                                    <div style={{
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        marginTop: "4px",
                                                        height: "calc(100%-24px)"
                                                    }}
                                                         id="host-data">
                                                        <div style={{
                                                            display: "flex",
                                                            flexDirection: "column",
                                                            color: "#A1A1AA",
                                                            gap: "4px",
                                                            width: "108px"
                                                        }} id="section1">
                                                            <div>Client IP:</div>
                                                            <div>Hostname:</div>
                                                            <div>Host Severity:</div>
                                                            <div>Netflow:</div>
                                                        </div>

                                                        <div style={{
                                                            display: "flex",
                                                            flexDirection: "column",
                                                            gap: "4px"
                                                        }} id="section2">
                                                            <div>{this.state.modal_data["ip"]}</div>
                                                            <div>{this.state.modal_data["host"]}</div>
                                                            <div>{this.state.modal_data["h_severity"]}</div>
                                                            <div style={{
                                                                display: "flex",
                                                                gap: 8
                                                            }}>
                                                                <button
                                                                    style={{
                                                                        cursor: "pointer",
                                                                        border: "none",
                                                                        backgroundColor: "transparent",
                                                                        justifyContent: "center",
                                                                        display: "flex",
                                                                        width: "21px",
                                                                        height: "20px"
                                                                    }}
                                                                    onClick={() => this.props.open_netflow(this.state.modal_data)}
                                                                    id="netflow"
                                                                >
                                                                    <img src="/main/tipnet.svg" alt="netflow"/>
                                                                </button>
                                                                Forensic Data Analysis
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="inner-tip-box"
                                                >
                                                    <div style={{
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        gap: "4px",
                                                        lineHeight: "28px",
                                                        fontWeight: "600",
                                                        alignItems: "center",
                                                        height: "24px",
                                                    }}>
                                                        <img
                                                            style={{width: "24px", height: "24px"}}
                                                            alt=""
                                                            src="/main/tipgroup.svg"
                                                        />
                                                        <div style={{
                                                            color: "#a1daf8"
                                                        }}>Attack Group
                                                        </div>
                                                    </div>
                                                    <div style={{
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        marginTop: "4px",
                                                        height: "calc(100%-24px)"
                                                    }}
                                                         id="group-data">
                                                        <div style={{
                                                            display: "flex",
                                                            flexDirection: "column",
                                                            color: "#A1A1AA",
                                                            gap: "4px",
                                                            width: "108px"
                                                        }} id="section1">
                                                            <div>IoA:</div>
                                                            <div>Threat Type:</div>
                                                            <div>Country:</div>
                                                        </div>

                                                        <div style={{
                                                            display: "flex",
                                                            flexDirection: "column",
                                                            gap: "4px"
                                                        }} id="section2">
                                                            <div>{this.state.modal_data["ioa"]}</div>
                                                            <div>{this.state.modal_data["group"]}</div>
                                                            {this.getCountryRender()}
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="inner-tip-box"
                                                >
                                                    <div style={{
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        gap: "4px",
                                                        lineHeight: "28px",
                                                        fontWeight: "600",
                                                        alignItems: "center",
                                                        height: "24px",
                                                    }}
                                                    >
                                                        <img
                                                            style={{width: "24px", height: "24px"}}
                                                            alt=""
                                                            src="/main/tipmalware.svg"
                                                        />
                                                        <div style={{
                                                            color: "#a1daf8"
                                                        }}>Malware
                                                        </div>
                                                    </div>
                                                    <div style={{
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        marginTop: "4px",
                                                        height: "calc(100%-24px)"
                                                    }}
                                                         id="malware-data"
                                                    >
                                                        <div style={{
                                                            display: "flex",
                                                            flexDirection: "column",
                                                            color: "#A1A1AA",
                                                            gap: "4px",
                                                            width: "108px"
                                                        }} id="section1">
                                                            <div>Name:</div>
                                                            <div>Confidence:</div>
                                                            <div>Severity:</div>
                                                        </div>

                                                        <div style={{
                                                            display: "flex",
                                                            flexDirection: "column",
                                                            gap: "4px"
                                                        }} id="section2">
                                                            <div>{this.state.modal_data["name"]}</div>
                                                            <div>{this.state.modal_data["confidence"]}</div>
                                                            <div>{this.state.modal_data["severity"]}</div>
                                                        </div>

                                                        <button
                                                            style={{
                                                                cursor: "pointer",
                                                                border: "none",
                                                                backgroundColor: "transparent",
                                                                height: "24px"
                                                            }}
                                                            onClick={() => this.props.open_threat_card(this.state.modal_data["name"])}
                                                            id="threat_card_btn"
                                                        >
                                                            <div
                                                                style={{
                                                                    flex: "1",
                                                                    display: "flex",
                                                                    flexDirection: "row",
                                                                    alignItems: "center",
                                                                    justifyContent: "center",
                                                                    height: "24px",
                                                                }}
                                                            >
                                                                <img
                                                                    style={{
                                                                        position: "relative",
                                                                        width: "20px",
                                                                        height: "20px",
                                                                    }}
                                                                    alt=""
                                                                    src="/main/show-i.svg"
                                                                />
                                                            </div>
                                                        </button>

                                                    </div>

                                                </div>

                                                <div className="inner-tip-box"
                                                     style={{
                                                         height: "70px"
                                                     }}
                                                >
                                                    <div style={{
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        gap: "4px",
                                                        lineHeight: "28px",
                                                        fontWeight: "600",
                                                        alignItems: "center",
                                                        height: "24px",
                                                    }}>
                                                        <img
                                                            style={{width: "24px", height: "24px"}}
                                                            alt=""
                                                            src="/main/tipmitre.svg"
                                                        />
                                                        <div style={{
                                                            color: "#a1daf8"
                                                        }}>ATT&CK Mapping
                                                        </div>
                                                    </div>
                                                    <div style={{
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        marginTop: "4px",
                                                        height: "calc(100%-24px)"
                                                    }}
                                                         id="malware-data">
                                                        <div style={{
                                                            display: "flex",
                                                            flexDirection: "row",
                                                            color: "#A1A1AA",
                                                            gap: "4px",
                                                            width: "108px"
                                                        }} id="section1">
                                                            <div>Reference:</div>
                                                            <button
                                                                style={{
                                                                    cursor: "pointer",
                                                                    border: "none",
                                                                    backgroundColor: "transparent",
                                                                    height: "24px"
                                                                }}
                                                                onClick={() => this.props.open_mitre(this.state.modal_data["name"])}
                                                                id="mitre-btn"
                                                            >
                                                                <div
                                                                    style={{
                                                                        flex: "1",
                                                                        display: "flex",
                                                                        flexDirection: "row",
                                                                        alignItems: "center",
                                                                        justifyContent: "center",
                                                                        height: "24px",
                                                                    }}
                                                                >
                                                                    <img
                                                                        style={{
                                                                            position: "relative",
                                                                            width: "20px",
                                                                            height: "20px",
                                                                        }}
                                                                        alt=""
                                                                        src="/main/show-i.svg"
                                                                    />
                                                                </div>
                                                            </button>
                                                        </div>

                                                    </div>
                                                </div>
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        gap: "12px",
                                                        width: "100%",
                                                        height: "40px",
                                                        fontSize: "13px",
                                                        letterSpacing: "0.01em",
                                                        lineHeight: "20px",
                                                        fontFamily: "Inter",
                                                    }}
                                                >
                                                    <button
                                                        className="menu-entry"
                                                        onClick={this.update_detection}
                                                        id="0"
                                                        style={{
                                                            width: "132px",
                                                            minHeight: "100%"
                                                        }}
                                                    >
                                                        <div
                                                            style={{
                                                                backgroundColor: this.state.modal_data["status"] === "New" ?
                                                                    "rgba(244, 63, 94, 0.25)" : "rgba(113, 113, 122, 0.25)",
                                                                borderColor: this.state.modal_data["status"] === "New" ?
                                                                    "rgba(244, 63, 94, 100)" : "transparent",
                                                                borderRadius: "2px",
                                                                borderWidth: "2px",
                                                                borderStyle: "solid",
                                                                width: "100%",
                                                                height: "100%",
                                                                display: "flex",
                                                                flexDirection: "row",
                                                                alignItems: "center",
                                                                justifyContent: "center",
                                                                padding: "8px 16px",
                                                                boxSizing: "border-box",
                                                            }}
                                                        >
                                                            <div
                                                                style={{
                                                                    position: "relative",
                                                                    color: this.state.modal_data["status"] === "New" ?
                                                                        "rgba(244, 63, 94, 100)" : "white",
                                                                }}
                                                            >
                                                                New
                                                            </div>
                                                        </div>
                                                    </button>
                                                    <button
                                                        className="menu-entry"
                                                        onClick={this.update_detection}
                                                        id="1"
                                                        style={{
                                                            width: "132px",
                                                            minHeight: "100%"
                                                        }}
                                                    >
                                                        <div
                                                            style={{
                                                                backgroundColor: this.state.modal_data["status"] === "Pending" ?
                                                                    "rgba(245, 158, 11, 0.25)" : "rgba(113, 113, 122, 0.25)",
                                                                borderColor: this.state.modal_data["status"] === "Pending" ?
                                                                    "rgba(245, 158, 11, 100)" : "transparent",
                                                                borderRadius: "2px",
                                                                borderWidth: "2px",
                                                                borderStyle: "solid",
                                                                width: "100%",
                                                                height: "100%",
                                                                display: "flex",
                                                                flexDirection: "row",
                                                                alignItems: "center",
                                                                justifyContent: "center",
                                                                padding: "8px 16px",
                                                                boxSizing: "border-box",
                                                            }}
                                                        >
                                                            <div
                                                                style={{
                                                                    position: "relative",
                                                                    color: this.state.modal_data["status"] === "Pending" ?
                                                                        "rgba(245, 158, 11, 100)" : "white",
                                                                }}
                                                            >
                                                                Live IR
                                                            </div>
                                                        </div>
                                                    </button>
                                                    <button
                                                        className="menu-entry"
                                                        onClick={this.update_detection}
                                                        id="3"
                                                        style={{
                                                            width: "132px",
                                                            minHeight: "100%"
                                                        }}
                                                    >
                                                        <div
                                                            style={{
                                                                backgroundColor: this.state.modal_data["status"] === "False Positive" ?
                                                                    "rgba(139, 92, 246, 0.25)" : "rgba(113, 113, 122, 0.25)",
                                                                borderColor: this.state.modal_data["status"] === "False Positive" ?
                                                                    "rgba(139, 92, 246, 100)" : "transparent",
                                                                borderRadius: "2px",
                                                                borderWidth: "2px",
                                                                borderStyle: "solid",
                                                                width: "100%",
                                                                height: "100%",
                                                                display: "flex",
                                                                flexDirection: "row",
                                                                alignItems: "center",
                                                                justifyContent: "center",
                                                                padding: "8px 16px",
                                                                boxSizing: "border-box",
                                                            }}
                                                        >
                                                            <div
                                                                style={{
                                                                    position: "relative",
                                                                    color: this.state.modal_data["status"] === "False Positive" ?
                                                                        "rgba(139, 92, 246, 100)" : "white",
                                                                }}
                                                            >
                                                                False Positive
                                                            </div>
                                                        </div>
                                                    </button>
                                                    <button
                                                        className="menu-entry"
                                                        onClick={this.update_detection}
                                                        id="2"
                                                        style={{
                                                            width: "132px",
                                                            minHeight: "100%"
                                                        }}
                                                    >
                                                        <div
                                                            style={{
                                                                backgroundColor: this.state.modal_data["status"] === "Closed" ?
                                                                    "rgba(6, 182, 212, 0.25)" : "rgba(113, 113, 122, 0.25)",
                                                                borderColor: this.state.modal_data["status"] === "Closed" ?
                                                                    "rgba(6, 182, 212, 100)" : "transparent",
                                                                borderRadius: "2px",
                                                                borderWidth: "2px",
                                                                borderStyle: "solid",
                                                                width: "100%",
                                                                height: "100%",
                                                                display: "flex",
                                                                flexDirection: "row",
                                                                alignItems: "center",
                                                                justifyContent: "center",
                                                                padding: "8px 16px",
                                                                boxSizing: "border-box",
                                                            }}
                                                        >
                                                            <div
                                                                style={{
                                                                    position: "relative",
                                                                    color: this.state.modal_data["status"] === "Closed" ?
                                                                        "rgba(6, 182, 212, 100)" : "white",
                                                                }}
                                                            >
                                                                Closed
                                                            </div>
                                                        </div>
                                                    </button>

                                                </div>


                                            </div>


                                            <div style={{
                                                width: "calc(50% - 8px)",
                                                height: "100%",
                                                display: "flex",
                                                flexDirection: "column",
                                                justifyContent: "flex-start",
                                                color: "white",
                                                gap: "16px"
                                            }}
                                            >
                                                <div style={{
                                                    width: "100%",
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    justifyContent: "flex-start",
                                                    lineHeight: "28px",
                                                    fontWeight: "600",
                                                }}
                                                >
                                                    <div style={{color: "#F9B233"}}>
                                                        Action History
                                                    </div>
                                                    <button
                                                        style={{
                                                            cursor: "pointer",
                                                            border: "none",
                                                            backgroundColor: "transparent",
                                                            position: "relative",
                                                            width: "24px",
                                                            height: "24px",
                                                            marginLeft: "auto"
                                                        }}
                                                        id="close-modal"
                                                        onClick={this.CloseModal}
                                                    >
                                                        <img
                                                            alt=""
                                                            src="/main/white-close.svg"
                                                        />
                                                    </button>
                                                </div>


                                                <div className="inner-tip-box" style={{
                                                    height: "100%",
                                                    gap: "16px"
                                                }}>
                                                    <div style={{
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        gap: "4px",
                                                        lineHeight: "28px",
                                                        fontWeight: "600",
                                                        alignItems: "center",
                                                        height: "24px",
                                                    }}
                                                    >
                                                        <img
                                                            style={{width: "24px", height: "24px"}}
                                                            alt=""
                                                            src="/main/tiphistory.svg"
                                                        />
                                                        <div style={{
                                                            color: "#a1daf8"
                                                        }}>Action History Log
                                                        </div>

                                                        <button
                                                            style={{
                                                                cursor: "pointer",
                                                                borderRadius: "2px",
                                                                border: "1px solid #06B6D4",
                                                                backgroundColor: "transparent",
                                                                color: "#06B6D4",
                                                                position: "relative",
                                                                marginLeft: "auto"
                                                            }}
                                                            id="Contact"
                                                            onClick={this.props.open_client_playbook}
                                                        >
                                                            IR Contact
                                                        </button>

                                                        <button
                                                            style={{
                                                                cursor: "pointer",
                                                                borderRadius: "2px",
                                                                border: "1px solid #06B6D4",
                                                                backgroundColor: "transparent",
                                                                color: "#06B6D4",
                                                                position: "relative"
                                                            }}
                                                            id="playbook"
                                                            onClick={() => this.setState({
                                                                showPlaybook: true,
                                                                playbook: this.props.get_playbook_data(this.state.modal_data["priority"])
                                                            })}
                                                        >
                                                            Playbook
                                                        </button>

                                                        <button
                                                            style={{
                                                                cursor: "pointer",
                                                                borderRadius: "2px",
                                                                border: "none",
                                                                backgroundColor: "rgba(6,182,212,0.25)",
                                                                color: "#06B6D4",
                                                                position: "relative",
                                                            }}
                                                            id="new-update"
                                                            onClick={() => this.setState({show_log_input: true})}
                                                        >
                                                            New Update
                                                        </button>
                                                    </div>


                                                    <div className="scroll-bar-div" style={{
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        padding: "0px 0px 16px 8px",
                                                        gap: "8px",
                                                        overflowY: "scroll",
                                                        overflowX: "hidden",
                                                        width: "100%",
                                                        height: "472px"
                                                    }}
                                                    >

                                                        {this.state.show_log_input ?
                                                            <div
                                                                style={{
                                                                    display: "flex",
                                                                    flexDirection: "column",
                                                                    marginTop: "16px",
                                                                    width: "100%",
                                                                    height: "230px",
                                                                    marginRight: "8px",
                                                                }}
                                                            >
                                                                <MDEditor
                                                                    value={this.state.log_input_text}
                                                                    visibleDragbar={false}
                                                                    hideToolbar={true}
                                                                    preview="edit"
                                                                    extraCommands={[]}
                                                                    onChange={(data) => {
                                                                        this.setState({log_input_text: data})
                                                                    }}
                                                                    height={200}
                                                                />
                                                                <div style={{marginLeft: "auto", marginTop: "8px"}}>
                                                                    <button onClick={() => {
                                                                        this.update_detection("", this.state.log_input_text)
                                                                        this.setState({
                                                                            show_log_input: false,
                                                                            log_input_text: ""
                                                                        })
                                                                    }}
                                                                            style={{
                                                                                cursor: "pointer",
                                                                                borderRadius: "2px",
                                                                                background: "transparent",
                                                                                border: "solid 1px " + (this.state.log_input_text === "" ? "#737373" : "#06B6D4"),
                                                                                color: this.state.log_input_text === "" ? "#737373" : "#06B6D4",
                                                                                marginRight: "8px"
                                                                            }}
                                                                    >
                                                                        <ISubmit style={{
                                                                            color: this.state.log_input_text === "" ? "#737373" : "#06B6D4",
                                                                        }}/>
                                                                        Submit
                                                                    </button>
                                                                    <button onClick={() => {
                                                                        this.setState({
                                                                            show_log_input: false,
                                                                            log_input_text: ""
                                                                        })
                                                                    }}
                                                                            style={{
                                                                                cursor: "pointer",
                                                                                borderRadius: "2px",
                                                                                background: "transparent",
                                                                                border: "solid 1px #06B6D4",
                                                                                color: "#06B6D4",
                                                                                marginRight: "8px"
                                                                            }}
                                                                    >
                                                                        Cancel
                                                                    </button>
                                                                </div>
                                                            </div>
                                                            : ""
                                                        }

                                                        {
                                                            this.state.modal_data["log"] ?

                                                                this.state.modal_data["log"].slice(0).reverse().map((item, i) =>
                                                                    <div style={{
                                                                        display: "flex",
                                                                        flexDirection: "column",
                                                                        marginRight: "8px",
                                                                        gap: "8px"
                                                                    }}
                                                                         id="history-log-entry"
                                                                    >
                                                                        <div style={{
                                                                            display: "flex",
                                                                            flexDirection: "row",
                                                                            gap: "8px"
                                                                        }}
                                                                        >
                                                                            <img src="/main/tiphistoryclock-i.svg"
                                                                                 alt="tip-clock"/>
                                                                            <div style={{color: "#737373"}}>
                                                                                {get_time_string(item["time"])}
                                                                            </div>
                                                                        </div>
                                                                        <div style={{
                                                                            display: "flex",
                                                                            flexDirection: "row",
                                                                            marginLeft: "8px",
                                                                            gap: "8px"
                                                                        }}
                                                                        >
                                                                            <div style={{
                                                                                background: "#71717a",
                                                                                alignSelf: "stretch",
                                                                                flexShrink: "0",
                                                                                width: "1px"
                                                                            }}/>
                                                                            <MDEditor.Markdown source={item["text"]}
                                                                                               style={{
                                                                                                   padding: "16px 16px 16px 16px",
                                                                                                   color: "#A1A1AA",
                                                                                                   background: "#18181B",
                                                                                                   borderRadius: "8px",
                                                                                                   whiteSpace: 'pre-wrap',
                                                                                                   width: "100%",
                                                                                                   height: "100%",
                                                                                                   maxHeight: "100%",
                                                                                                   overflow: "auto"
                                                                                               }}
                                                                            />
                                                                        </div>

                                                                    </div>
                                                                )
                                                                :
                                                                ""
                                                        }

                                                        <div style={{
                                                            display: "flex",
                                                            flexDirection: "column",
                                                            marginRight: "8px",
                                                            gap: "8px"
                                                        }}
                                                             id="history-log-entry"
                                                        >
                                                            <div style={{
                                                                display: "flex",
                                                                flexDirection: "row",
                                                                gap: "8px"
                                                            }}
                                                            >
                                                                <img src="/main/tiphistoryclock-i.svg" alt="tip-clock"/>
                                                                <div style={{color: "#737373"}}>
                                                                    {get_time_string(this.state.modal_data["created"])}
                                                                </div>
                                                            </div>
                                                            <div style={{
                                                                display: "flex",
                                                                flexDirection: "row",
                                                                marginLeft: "8px",
                                                                gap: "8px"
                                                            }}
                                                            >
                                                                <div style={{
                                                                    background: "#71717a",
                                                                    alignSelf: "stretch",
                                                                    flexShrink: "0",
                                                                    width: "1px"
                                                                }}/>
                                                                <MDEditor.Markdown
                                                                    source={"New " + this.state.modal_data["priority"] + " Detection Created"}
                                                                    style={{
                                                                        padding: "16px 16px 16px 16px",
                                                                        color: "#A1A1AA",
                                                                        background: "#18181B",
                                                                        borderRadius: "8px",
                                                                        whiteSpace: 'pre-wrap',
                                                                        width: "100%",
                                                                        height: "100%",
                                                                        maxHeight: "100%",
                                                                        overflow: "auto"
                                                                    }}
                                                                />
                                                            </div>

                                                        </div>

                                                    </div>
                                                </div>


                                            </div>

                                        </div>
                            :
                            ""
                    }


                </ReactModal>


                <ReactModal
                    ariaHideApp={false}
                    isOpen={this.state.showPlaybook}
                    contentLabel="Playbook"
                    style={{
                        overlay: {
                            zIndex: 1000,
                            background: "rgba(19, 19, 19, 0.75)"
                        },
                        content: {
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            width: "570px",
                            height: "620px",
                            background: "#171717"
                        },
                    }}
                >

                    <div style={{
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "flex-start",
                        color: "white",
                        gap: "16px"
                    }}
                    >
                        <div style={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "flex-start",
                            lineHeight: "28px",
                            fontWeight: "600",
                            gap: "8px"
                        }}
                        >
                            <img
                                alt="playbook"
                                src="/main/playbook-i.svg"
                            />
                            <div style={{color: "#F9B233"}}>
                                {this.state.playbook.title}
                            </div>
                            <button
                                style={{
                                    cursor: "pointer",
                                    border: "none",
                                    backgroundColor: "transparent",
                                    position: "relative",
                                    width: "24px",
                                    height: "24px",
                                    marginLeft: "auto"
                                }}
                                id="close-modal"
                                onClick={() => this.setState({showPlaybook: false})}
                            >
                                <img
                                    alt="close-playbook"
                                    src="/main/white-close.svg"
                                />
                            </button>
                        </div>


                        <div className="inner-tip-box" style={{
                            height: "100%",
                            gap: "16px"
                        }}>
                        <div className="scroll-bar-div" style={{
                                display: "flex",
                                flexDirection: "column",
                                padding: "0px 0px 16px 8px",
                                gap: "8px",
                                overflowY: "scroll",
                                overflowX: "hidden",
                                width: "100%",
                                height: "520px"
                            }}
                            >
                                <div style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    gap: "4px",
                                    lineHeight: "28px",
                                    fontWeight: "600",
                                    alignItems: "center",
                                    height: "24px",
                                }}
                                >
                                    <img
                                        style={{width: "24px", height: "24px"}}
                                        alt=""
                                        src="/main/playbook-obj-i.svg"
                                    />
                                    <div style={{
                                        color: "#a1daf8"
                                    }}>Objective
                                    </div>
                                </div>
                                <div>
                                    {this.state.playbook.objective}
                                </div>


                                {
                                    this.state.playbook["steps"] ?
                                        this.state.playbook["steps"].map((item, i) =>
                                            <div style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                marginRight: "8px",
                                                gap: "8px"
                                            }}
                                                 id="history-log-entry"
                                            >
                                                <div style={{
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    gap: "8px"
                                                }}
                                                >
                                                    <img src={"/main/playbook-" + (i + 1).toString() + "-i.svg"}
                                                         alt="playbook-num"/>
                                                    <div style={{color: "#a1daf8"}}>
                                                        {item.title}
                                                    </div>
                                                </div>
                                                <div style={{
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    marginLeft: "8px",
                                                    gap: "8px"
                                                }}
                                                >
                                                    <div style={{
                                                        background: "#71717a",
                                                        alignSelf: "stretch",
                                                        flexShrink: "0",
                                                        width: "1px"
                                                    }}/>
                                                    <MDEditor.Markdown source={item.text}
                                                                       style={{
                                                                           padding: "16px 8px 16px 8px",
                                                                           color: "#A1A1AA",
                                                                           background: "#18181B",
                                                                           borderRadius: "8px",
                                                                           width: "100%",
                                                                           height: "100%",
                                                                           maxHeight: "100%",
                                                                           overflow: "auto"
                                                                       }}
                                                    />
                                                </div>

                                            </div>
                                        )
                                        :
                                        ""
                                }

                                <div style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    marginRight: "8px",
                                    gap: "8px"
                                }}
                                >
                                    <div style={{alignSelf: "stretch", flexShrink: "0"}}>
                                        <img src="/main/playbook-note-i.svg" alt="playbook-note"/>
                                    </div>
                                    <div style={{color: "#A1A1AA"}}>
                                        {this.state.playbook.note}
                                    </div>
                                </div>

                            </div>
                        </div>


                    </div>

                </ReactModal>

                <div style={{
                    width: "100%",
                    height: "1px",
                    alignSelf: "stretch",
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                    gap: "8px",
                    color: "#F9B233",
                }}>
                    <div
                        style={{
                            alignSelf: "stretch",
                            display: "flex",
                            flexDirection: "row",
                            flexWrap: "wrap",
                            alignItems: "flex-start",
                            justifyContent: "flex-start",
                            gap: "8px",
                            width: "100%"
                        }}
                    >
                        <div
                            className="Detection-Card Detection-Card-Detections-Page"
                        >
                            <div
                                style={{
                                    alignSelf: "stretch",
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "flex-start",
                                    justifyContent: "flex-start",
                                }}
                            >
                                <div
                                    style={{
                                        alignSelf: "stretch",
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                        justifyContent: "flex-start",
                                        gap: "4px",
                                        zIndex: "10",
                                    }}
                                >
                                    <div
                                        style={{
                                            flex: "1",
                                            position: "relative",
                                            lineHeight: "28px",
                                            fontWeight: "600"
                                        }}
                                    >
                                        P1 Detections
                                    </div>
                                    <Tooltip title={this.props.tooltip_info("P1")} arrow>
                                        <img
                                            style={{
                                                position: "relative",
                                                width: "24px",
                                                height: "24px",
                                                overflow: "hidden",
                                                flexShrink: "0",
                                            }}
                                            alt="detection-i"
                                            src="/main/detection-i.svg"
                                        />
                                    </Tooltip>
                                </div>
                            </div>
                            <div className="Pulsating-Card"
                                 style={{
                                     border: "20px solid rgba(239,68,68)",
                                     animation: this.state.d[0] > 0 ? "card-pulsate 1.5s ease-out infinite" : ""
                                 }}/>
                            <div
                                style={{
                                    position: "relative",
                                    width: "232px",
                                    height: "232px",
                                    textAlign: "center",
                                    fontSize: "45px",
                                    color: "#fff",
                                    zIndex: "100"
                                }}
                            >


                                <CircularProgressbarWithChildren value={this.state.p[0]} strokeWidth={5}
                                                                 styles={buildStyles({
                                                                     pathTransitionDuration: 0.5,
                                                                     trailColor: '#27272A',
                                                                     pathColor: 'rgba(239,68,68,0.35)'
                                                                 })}
                                >
                                    <div style={{
                                        width: "85%"
                                    }}>
                                        <CircularProgressbarWithChildren value={this.state.d[0]} strokeWidth={5}
                                                                         styles={buildStyles({
                                                                             pathTransitionDuration: 0.5,
                                                                             trailColor: '#27272A',
                                                                             pathColor: 'rgba(239,68,68,100)'
                                                                         })}>

                                            <div style={{marginBottom: -20}}>{this.state.d[0]}</div>
                                            <img alt="" src="/main/dividor.svg"/>
                                            <div style={{
                                                marginTop: -20,
                                                color: 'rgba(239,68,68,0.35)'
                                            }}>{this.state.p_val[0]}</div>
                                        </CircularProgressbarWithChildren>
                                    </div>
                                </CircularProgressbarWithChildren>
                            </div>
                            <div
                                style={{
                                    alignSelf: "stretch",
                                    display: "flex",
                                    flexDirection: "row",
                                    gap: "4px",
                                    fontSize: "12px",
                                    color: "#a1a1aa"
                                }}
                            >
                                <div
                                    style={{
                                        borderRadius: "16px",
                                        backgroundColor: "rgba(220, 38, 38, 0.25)",
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        padding: "0px 8px",
                                        color: "#ef4444"
                                    }}
                                >
                                    <div
                                        style={{
                                            position: "relative",
                                            letterSpacing: "0.01em",
                                            lineHeight: "20px",
                                            fontWeight: "600"
                                        }}
                                    >
                                        CRITICAL
                                    </div>
                                </div>
                                <div style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center"
                                }}>
                                    <img
                                        style={{
                                            display: "flex",
                                            position: "relative",
                                            width: "16px",
                                            height: "16px",
                                            marginRight: "3px"
                                        }}
                                        alt=""
                                        src="/main/icon-l5-grey.svg"
                                    />
                                    <div
                                        style={{
                                            letterSpacing: "0.01em",
                                            lineHeight: "16px",
                                            fontWeight: "500"
                                        }}
                                    >
                                        Realtime Detections
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            className="Detection-Card Detection-Card-Detections-Page"
                        >
                            <div
                                style={{
                                    alignSelf: "stretch",
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "flex-start",
                                    justifyContent: "flex-start",
                                }}
                            >
                                <div
                                    style={{
                                        alignSelf: "stretch",
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                        justifyContent: "flex-start",
                                        gap: "4px",
                                    }}
                                >
                                    <div
                                        style={{
                                            flex: "1",
                                            position: "relative",
                                            lineHeight: "28px",
                                            fontWeight: "600",
                                        }}
                                    >
                                        P2 Detections
                                    </div>
                                    <Tooltip title={this.props.tooltip_info("P2")} arrow>
                                        <img
                                            style={{
                                                position: "relative",
                                                width: "24px",
                                                height: "24px",
                                                overflow: "hidden",
                                                flexShrink: "0",
                                            }}
                                            alt="detection-i"
                                            src="/main/detection-i.svg"
                                        />
                                    </Tooltip>
                                </div>
                            </div>
                            <div className="Pulsating-Card"
                                 style={{
                                     border: "20px solid rgba(249,115,22)",
                                     animation: this.state.d[1] > 0 ? "card-pulsate 1.5s ease-out infinite" : ""
                                 }}/>
                            <div
                                style={{
                                    position: "relative",
                                    width: "232px",
                                    height: "232px",
                                    textAlign: "center",
                                    fontSize: "45px",
                                    color: "#fff",
                                    zIndex: "100"
                                }}
                            >
                                <CircularProgressbarWithChildren value={this.state.p[1]} strokeWidth={5}
                                                                 styles={buildStyles({
                                                                     textSize: '25px',
                                                                     pathTransitionDuration: 0.5,
                                                                     textColor: '#fff',
                                                                     trailColor: '#27272A',
                                                                     pathColor: 'rgba(249,115,22,0.35)'
                                                                 })}>
                                    <div style={{
                                        width: "85%"
                                    }}>
                                        <CircularProgressbarWithChildren value={this.state.d[1]} strokeWidth={5}
                                                                         styles={buildStyles({
                                                                             textSize: '25px',
                                                                             pathTransitionDuration: 0.5,
                                                                             textColor: '#fff',
                                                                             trailColor: '#27272A',
                                                                             pathColor: '#F97316'
                                                                         })}
                                        >
                                            <div style={{marginBottom: -20}}>{this.state.d[1]}</div>
                                            <img alt="" src="/main/dividor.svg"/>
                                            <div style={{
                                                marginTop: -20,
                                                color: 'rgba(249,115,22,0.35)'
                                            }}>{this.state.p_val[1]}</div>
                                        </CircularProgressbarWithChildren>
                                    </div>
                                </CircularProgressbarWithChildren>
                            </div>
                            <div
                                style={{
                                    alignSelf: "stretch",
                                    display: "flex",
                                    flexDirection: "row",
                                    gap: "4px",
                                    fontSize: "12px",
                                    color: "#a1a1aa"
                                }}
                            >
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        flexWrap: "wrap",
                                        alignItems: "flex-start",
                                        justifyContent: "flex-start",
                                        gap: "8px",
                                    }}
                                >
                                    <div
                                        style={{
                                            borderRadius: "16px",
                                            backgroundColor: "rgba(234, 88, 12, 0.25)",
                                            overflow: "hidden",
                                            display: "flex",
                                            flexDirection: "row",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            padding: "0px 8px",
                                            color: "#f97316",
                                        }}
                                    >
                                        <div
                                            style={{
                                                position: "relative",
                                                letterSpacing: "0.01em",
                                                lineHeight: "20px",
                                                fontWeight: "600",
                                            }}
                                        >
                                            HIGH
                                        </div>
                                    </div>
                                </div>
                                <div style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center"
                                }}>
                                    <img
                                        style={{
                                            position: "relative",
                                            width: "16px",
                                            height: "16px",
                                            marginRight: "3px"
                                        }}
                                        alt=""
                                        src="/main/icon-l5-grey.svg"
                                    />
                                    <div
                                        style={{
                                            flex: "1",
                                            position: "relative",
                                            letterSpacing: "0.01em",
                                            lineHeight: "16px",
                                            fontWeight: "500",
                                        }}
                                    >
                                        Realtime Detections
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            className="Detection-Card Detection-Card-Detections-Page"
                        >
                            <div
                                style={{
                                    alignSelf: "stretch",
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "flex-start",
                                    justifyContent: "flex-start",
                                }}
                            >
                                <div
                                    style={{
                                        alignSelf: "stretch",
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                        justifyContent: "flex-start",
                                        gap: "4px",
                                    }}
                                >
                                    <div
                                        style={{
                                            flex: "1",
                                            position: "relative",
                                            lineHeight: "28px",
                                            fontWeight: "600",
                                        }}
                                    >
                                        P3 Detections
                                    </div>
                                    <Tooltip title={this.props.tooltip_info("P3")} arrow>
                                        <img
                                            style={{
                                                position: "relative",
                                                width: "24px",
                                                height: "24px",
                                                overflow: "hidden",
                                                flexShrink: "0",
                                            }}
                                            alt="detection-i"
                                            src="/main/detection-i.svg"
                                        />
                                    </Tooltip>
                                </div>
                            </div>
                            <div className="Pulsating-Card"
                                 style={{
                                     border: "20px solid rgba(234,179,8)",
                                     animation: this.state.d[2] > 0 ? "card-pulsate 1.5s ease-out infinite" : ""
                                 }}/>
                            <div
                                style={{
                                    position: "relative",
                                    width: "232px",
                                    height: "232px",
                                    textAlign: "center",
                                    fontSize: "45px",
                                    color: "#fff",
                                    zIndex: "100"
                                }}
                            >
                                <CircularProgressbarWithChildren value={this.state.p[2]} strokeWidth={5}
                                                                 styles={buildStyles({
                                                                     textSize: '25px',
                                                                     pathTransitionDuration: 0.5,
                                                                     textColor: '#fff',
                                                                     trailColor: '#27272A',
                                                                     pathColor: 'rgba(234,179,8,0.35)'
                                                                 })}>
                                    <div style={{
                                        width: "85%"
                                    }}>
                                        <CircularProgressbarWithChildren value={this.state.d[2]} strokeWidth={5}
                                                                         styles={buildStyles({
                                                                             textSize: '25px',
                                                                             pathTransitionDuration: 0.5,
                                                                             textColor: '#fff',
                                                                             trailColor: '#27272A',
                                                                             pathColor: '#EAB308'
                                                                         })}
                                        >
                                            <div style={{marginBottom: -20}}>{this.state.d[2]}</div>
                                            <img alt="" src="/main/dividor.svg"/>
                                            <div style={{
                                                marginTop: -20,
                                                color: 'rgba(234,179,8,0.35)'
                                            }}>{this.state.p_val[2]}</div>
                                        </CircularProgressbarWithChildren>
                                    </div>
                                </CircularProgressbarWithChildren>
                            </div>
                            <div
                                style={{
                                    alignSelf: "stretch",
                                    display: "flex",
                                    flexDirection: "row",
                                    gap: "4px",
                                    fontSize: "12px",
                                    color: "#a1a1aa",
                                }}
                            >
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        flexWrap: "wrap",
                                        alignItems: "flex-start",
                                        justifyContent: "flex-start",
                                        gap: "8px",
                                    }}
                                >
                                    <div
                                        style={{
                                            borderRadius: "16px",
                                            backgroundColor: "rgba(202, 138, 4, 0.25)",
                                            overflow: "hidden",
                                            display: "flex",
                                            flexDirection: "row",
                                            padding: "0px 8px",
                                            color: "#eab308",
                                        }}
                                    >
                                        <div
                                            style={{
                                                position: "relative",
                                                letterSpacing: "0.01em",
                                                lineHeight: "20px",
                                                fontWeight: "600",
                                            }}
                                        >
                                            MEDIUM
                                        </div>
                                    </div>
                                </div>

                                <div style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center"
                                }}>
                                    <img
                                        style={{
                                            display: "flex",
                                            position: "relative",
                                            width: "16px",
                                            height: "16px",
                                            marginRight: "3px"
                                        }}
                                        alt=""
                                        src="/main/icon-l5-grey.svg"
                                    />
                                    <div
                                        style={{
                                            letterSpacing: "0.01em",
                                            lineHeight: "16px",
                                            fontWeight: "500"
                                        }}
                                    >
                                        Realtime Detections
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            className="Detection-Card Detection-Card-Detections-Page"
                        >
                            <div
                                style={{
                                    alignSelf: "stretch",
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "flex-start",
                                    justifyContent: "flex-start",
                                }}
                            >
                                <div
                                    style={{
                                        alignSelf: "stretch",
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                        justifyContent: "flex-start",
                                        gap: "4px",
                                    }}
                                >
                                    <div
                                        style={{
                                            flex: "1",
                                            position: "relative",
                                            lineHeight: "28px",
                                            fontWeight: "600",
                                        }}
                                    >
                                        P4 Detections
                                    </div>
                                    <Tooltip title={this.props.tooltip_info("P4")} arrow>
                                        <img
                                            style={{
                                                position: "relative",
                                                width: "24px",
                                                height: "24px",
                                                overflow: "hidden",
                                                flexShrink: "0",
                                            }}
                                            alt="detection-i"
                                            src="/main/detection-i.svg"
                                        />
                                    </Tooltip>
                                </div>
                            </div>
                            <div className="Pulsating-Card"
                                 style={{
                                     border: "20px solid rgba(6,182,212)",
                                     animation: this.state.d[3] > 0 ? "card-pulsate 1.5s ease-out infinite" : ""
                                 }}/>
                            <div
                                style={{
                                    position: "relative",
                                    width: "232px",
                                    height: "232px",
                                    textAlign: "center",
                                    fontSize: "45px",
                                    color: "#fff",
                                    zIndex: "100"
                                }}
                            >
                                <CircularProgressbarWithChildren value={this.state.p[3]} strokeWidth={5}
                                                                 styles={buildStyles({
                                                                     textSize: '25px',
                                                                     pathTransitionDuration: 0.5,
                                                                     textColor: '#fff',
                                                                     trailColor: '#27272A',
                                                                     pathColor: 'rgba(6,182,212,0.35)'
                                                                 })}>
                                    <div style={{
                                        width: "85%"
                                    }}>
                                        <CircularProgressbarWithChildren value={this.state.d[3]} strokeWidth={5}
                                                                         styles={buildStyles({
                                                                             textSize: '25px',
                                                                             pathTransitionDuration: 0.5,
                                                                             textColor: '#fff',
                                                                             trailColor: '#27272A',
                                                                             pathColor: '#06B6D4'
                                                                         })}
                                        >
                                            <div style={{marginBottom: -20}}>{this.state.d[3]}</div>
                                            <img alt="" src="/main/dividor.svg"/>
                                            <div style={{
                                                marginTop: -20,
                                                color: 'rgba(6,182,212,0.35)'
                                            }}>{this.state.p_val[3]}</div>
                                        </CircularProgressbarWithChildren>
                                    </div>
                                </CircularProgressbarWithChildren>
                            </div>
                            <div
                                style={{
                                    alignSelf: "stretch",
                                    display: "flex",
                                    flexDirection: "row",
                                    gap: "4px",
                                    fontSize: "12px",
                                    color: "#a1a1aa"
                                }}
                            >
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        flexWrap: "wrap",
                                        alignItems: "flex-start",
                                        justifyContent: "flex-start",
                                        gap: "8px",
                                    }}
                                >
                                    <div
                                        style={{
                                            borderRadius: "16px",
                                            backgroundColor: "rgba(8, 145, 178, 0.25)",
                                            overflow: "hidden",
                                            display: "flex",
                                            flexDirection: "row",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            padding: "0px 8px",
                                            color: "#06b6d4",
                                        }}
                                    >
                                        <div
                                            style={{
                                                position: "relative",
                                                letterSpacing: "0.01em",
                                                lineHeight: "20px",
                                                fontWeight: "600",
                                            }}
                                        >
                                            LOW
                                        </div>
                                    </div>
                                </div>
                                <div style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center"
                                }}>
                                    <img
                                        style={{
                                            display: "flex",
                                            position: "relative",
                                            width: "16px",
                                            height: "16px",
                                            marginRight: "3px"
                                        }}
                                        alt=""
                                        src="/main/icon-l5-grey.svg"
                                    />
                                    <div
                                        style={{
                                            letterSpacing: "0.01em",
                                            lineHeight: "16px",
                                            fontWeight: "500"
                                        }}
                                    >
                                        Realtime Detections
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            className="Detection-Card Detection-Card-Detections-Page"
                        >
                            <div
                                style={{
                                    alignSelf: "stretch",
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "flex-start",
                                    justifyContent: "flex-start",
                                }}
                            >
                                <div
                                    style={{
                                        alignSelf: "stretch",
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                        justifyContent: "flex-start",
                                        gap: "4px",
                                    }}
                                >
                                    <div
                                        style={{
                                            flex: "1",
                                            position: "relative",
                                            lineHeight: "28px",
                                            fontWeight: "600",
                                        }}
                                    >
                                        False Positive Rate
                                    </div>
                                    <Tooltip title={this.props.tooltip_info("False Positive Rate")} arrow>
                                        <img
                                            style={{
                                                position: "relative",
                                                width: "24px",
                                                height: "24px",
                                                overflow: "hidden",
                                                flexShrink: "0",
                                            }}
                                            alt="detection-i"
                                            src="/main/detection-i.svg"
                                        />
                                    </Tooltip>
                                </div>
                            </div>
                            <div
                                style={{
                                    position: "relative",
                                    width: "232px",
                                    height: "232px",
                                    textAlign: "center",
                                    fontSize: "45px",
                                    color: "#fff",
                                    zIndex: "100"
                                }}
                            >
                                <CircularProgressbarWithChildren value={this.state.tp_rate} strokeWidth={5}
                                                                 styles={buildStyles({
                                                                     textSize: '25px',
                                                                     pathTransitionDuration: 0.5,
                                                                     textColor: '#fff',
                                                                     trailColor: '#27272A',
                                                                     pathColor: '#8B5CF6'
                                                                 })}>

                                    <div>{this.state.tp_rate}%</div>
                                </CircularProgressbarWithChildren>
                            </div>

                        </div>
                    </div>
                    <div
                        style={{
                            alignSelf: "stretch",
                            display: "flex",
                            flexDirection: "row",
                            flexWrap: "wrap",
                            alignItems: "flex-start",
                            justifyContent: "flex-start",
                            gap: "8px",
                            width: "100%"
                        }}
                    >
                        <div style={{
                            position: "relative",
                            width: "calc(100% - 8px)",
                            borderRadius: "8px",
                            height: "100%"
                        }}>

                            <div style={{
                                top: "20px",
                                left: "16px",
                                display: "flex",
                                height: "30px",
                                position: "absolute",
                                fontWeight: "600",
                                gap: "16px",
                                fontSize: "12px",
                                letterSpacing: "0.01em",
                                lineHeight: "20px",
                                fontFamily: "Inter",
                                zIndex: "10"
                            }}
                                 id="table-header"
                            >
                                <div style={{
                                    display: "flex",
                                    fontSize: "15px",
                                    borderRadius: "4px 4px 0 0",
                                    alignItems: "center",
                                    backgroundColor: "#27272A",
                                    color: "#F9B233",
                                    padding: "4px 16px",
                                    gap: "4px",
                                    marginBottom: "-10px"
                                }}>
                                    <IDetections style={{
                                        width: "20px",
                                        height: "20px",
                                        color: "#65A30D",
                                    }}/>
                                    Detections
                                </div>

                                <button
                                    className="menu-entry"
                                    onClick={this.update_table}
                                    id="4"
                                    style={{
                                        width: "unset",
                                        minHeight: "100%"
                                    }}
                                >
                                    <div
                                        style={{
                                            backgroundColor: this.state.filter_index === 4 ? "rgba(6,182,212,0.25)" : "rgba(113,113,122,0.25)",
                                            borderRadius: "2px",
                                            width: "100%",
                                            height: "100%",
                                            display: "flex",
                                            flexDirection: "row",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            padding: "8px 12px 8px 8px",
                                            boxSizing: "border-box",
                                            gap: "4px"
                                        }}
                                    >
                                        <img
                                            src={this.state.filter_index === 4 ? "/main/filter-active-i.svg" : "/main/filter-inactive-i.svg"}
                                            alt="filter"/>
                                        <div
                                            style={{
                                                position: "relative",
                                                color: "white",
                                            }}
                                        >
                                            All
                                        </div>
                                    </div>
                                </button>

                                <button
                                    className="menu-entry"
                                    onClick={this.update_table}
                                    id="0"
                                    style={{
                                        width: "unset",
                                        minHeight: "100%"
                                    }}
                                >
                                    <div
                                        style={{
                                            backgroundColor: this.state.filter_index === 0 ? "rgba(6,182,212,0.25)" : "rgba(113,113,122,0.25)",
                                            borderRadius: "2px",
                                            width: "100%",
                                            height: "100%",
                                            display: "flex",
                                            flexDirection: "row",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            padding: "8px 12px 8px 8px",
                                            boxSizing: "border-box",
                                            gap: "4px"
                                        }}
                                    >
                                        <img
                                            src={this.state.filter_index === 0 ? "/main/filter-active-i.svg" : "/main/filter-inactive-i.svg"}
                                            alt="filter"/>
                                        <div
                                            style={{
                                                position: "relative",
                                                color: "white",
                                            }}
                                        >
                                            New
                                        </div>
                                    </div>
                                </button>

                                <button
                                    className="menu-entry"
                                    onClick={this.update_table}
                                    id="1"
                                    style={{
                                        width: "unset",
                                        minHeight: "100%"
                                    }}
                                >
                                    <div
                                        style={{
                                            backgroundColor: this.state.filter_index === 1 ? "rgba(6,182,212,0.25)" : "rgba(113,113,122,0.25)",
                                            borderRadius: "2px",
                                            width: "100%",
                                            height: "100%",
                                            display: "flex",
                                            flexDirection: "row",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            padding: "8px 12px 8px 8px",
                                            boxSizing: "border-box",
                                            gap: "4px"
                                        }}
                                    >
                                        <img
                                            src={this.state.filter_index === 1 ? "/main/filter-active-i.svg" : "/main/filter-inactive-i.svg"}
                                            alt="filter"/>
                                        <div
                                            style={{
                                                position: "relative",
                                                color: "white",
                                            }}
                                        >
                                            Pending
                                        </div>
                                    </div>
                                </button>

                                <button
                                    className="menu-entry"
                                    onClick={this.update_table}
                                    id="2"
                                    style={{
                                        width: "unset",
                                        minHeight: "100%"
                                    }}
                                >
                                    <div
                                        style={{
                                            backgroundColor: this.state.filter_index === 2 ? "rgba(6,182,212,0.25)" : "rgba(113,113,122,0.25)",
                                            borderRadius: "2px",
                                            width: "100%",
                                            height: "100%",
                                            display: "flex",
                                            flexDirection: "row",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            padding: "8px 12px 8px 8px",
                                            boxSizing: "border-box",
                                            gap: "4px"
                                        }}
                                    >
                                        <img
                                            src={this.state.filter_index === 2 ? "/main/filter-active-i.svg" : "/main/filter-inactive-i.svg"}
                                            alt="filter"/>
                                        <div
                                            style={{
                                                position: "relative",
                                                color: "white",
                                            }}
                                        >
                                            False Positive
                                        </div>
                                    </div>
                                </button>

                                <button
                                    className="menu-entry"
                                    onClick={this.update_table}
                                    id="3"
                                    style={{
                                        width: "unset",
                                        minHeight: "100%"
                                    }}
                                >
                                    <div
                                        style={{
                                            backgroundColor: this.state.filter_index === 3 ? "rgba(6,182,212,0.25)" : "rgba(113,113,122,0.25)",
                                            borderRadius: "2px",
                                            width: "100%",
                                            height: "100%",
                                            display: "flex",
                                            flexDirection: "row",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            padding: "8px 12px 8px 8px",
                                            boxSizing: "border-box",
                                            gap: "4px"
                                        }}
                                    >
                                        <img
                                            src={this.state.filter_index === 3 ? "/main/filter-active-i.svg" : "/main/filter-inactive-i.svg"}
                                            alt="filter"/>
                                        <div
                                            style={{
                                                position: "relative",
                                                color: "white",
                                            }}
                                        >
                                            Closed
                                        </div>
                                    </div>
                                </button>


                            </div>
                            <ThemeProvider theme={this.tableTheme}>
                                <MaterialReactTable
                                    enableRowActions={true}
                                    displayColumnDefOptions={{
                                        "mrt-row-actions": {
                                            header: 'Tipper'
                                        },
                                    }}
                                    renderRowActions={({row}) => (
                                        <button
                                            className="menu-entry"
                                            onClick={() => this.OpenModal(row["original"])}
                                            style={{
                                                width: "100px"
                                            }}
                                            id="show-btn"
                                        >
                                            <div
                                                style={{
                                                    flex: "1",
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                    gap: "8px",
                                                    borderRadius: "2px",
                                                    padding: "7px",
                                                    backgroundColor: "#27272A"
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        position: "relative",
                                                        fontSize: "14px",
                                                        letterSpacing: "0.01em",
                                                        lineHeight: "20px",
                                                        fontFamily: "Inter",
                                                        color: "#fff",
                                                        textAlign: "left",
                                                    }}
                                                >
                                                    View
                                                </div>
                                                <img
                                                    style={{
                                                        position: "relative",
                                                        width: "20px",
                                                        height: "20px",
                                                    }}
                                                    alt=""
                                                    src="/main/show-i.svg"
                                                />
                                            </div>
                                        </button>
                                    )}
                                    columns={columns} data={this.getTableData()}
                                    initialState={{
                                        sorting: [{
                                            id: 'updated',
                                            desc: true,
                                        }]
                                    }}
                                >
                                </MaterialReactTable>
                            </ThemeProvider>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}