import React, { Component } from 'react'
import { CircularProgressbar, buildStyles, CircularProgressbarWithChildren } from 'react-circular-progressbar';
import {createTheme, ThemeProvider, Tooltip} from "@mui/material";
import {MaterialReactTable} from "material-react-table";
import 'react-circular-progressbar/dist/styles.css';
import { CircleFlag } from "react-circle-flags"
import ReactModal from 'react-modal';


export default class DomainTakedown extends Component {

    constructor(props) {
        super(props);
        this.state = {username: '', company: '', key: '', keys: {}};
        let data = this.props.page_data()
        console.log("detections", data)
        this.setState({username: data.username, key: data.keys[data.company], company: data.company, keys: data.keys})
    }

    componentDidMount() {
        let data = this.props.page_data()
        if (data.username && data.username !== '') {
            this.setState({
                username: data.username,
                key: data.keys[data.company],
                company: data.company,
                keys: data.keys
            })
        }
    }
    componentWillUnmount() {

    }


    render() {
        return (
            <div
                style={{
                    alignSelf: "stretch",
                    flex: "1",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                    gap: "8px",
                    textAlign: "left",
                    fontSize: "22px",
                    color: "#fafafa",
                    overflow: "auto"
                }}
                id="inner-data"
            >
                <div
                    style={{
                        borderRadius: "8px",
                        backgroundColor: "#131313",
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        justifyContent: "center",
                        padding: "32px 16px",
                        boxSizing: "border-box",
                        gap: "16px",
                        textAlign: "center",
                        fontSize: "24px",
                        color: "#fafafa",
                        fontFamily: "Inter",
                        overflow: "hidden"
                    }}
                >
                    <div
                        style={{
                            alignSelf: "stretch",
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "flex-start",
                            width: "100%",
                            height: "30%",
                            gap: "32px"
                        }}
                        id={"header"}
                    >
                        <div
                            style={{
                                alignSelf: "stretch",
                                flex: "1",
                                borderRadius: "8px",
                                backgroundColor: "#18181b",
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                justifyContent: "flex-start",
                                padding: "16px",
                                gap: "16px",
                                textAlign: "left",
                                fontSize: "16px",
                                color: "#f9b233",
                                minWidth: "280px"
                            }}
                        >
                            <div
                                style={{
                                    alignSelf: "stretch",
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "flex-start",
                                    justifyContent: "flex-start",
                                }}
                            >
                                <div
                                    style={{
                                        alignSelf: "stretch",
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                        justifyContent: "flex-start",
                                        gap: "4px",
                                    }}
                                >
                                    <div
                                        style={{
                                            flex: "1",
                                            position: "relative",
                                            lineHeight: "20px",
                                            fontWeight: "600",
                                        }}
                                    >
                                        Domain Takedown
                                    </div>
                                    <img
                                        style={{
                                            position: "relative",
                                            width: "24px",
                                            height: "24px",
                                            overflow: "hidden",
                                            flexShrink: "0",
                                            cursor: "pointer",
                                        }}
                                        alt=""
                                        src="/main/user-interface--info-circle.svg"
                                    />
                                </div>
                            </div>
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "flex-start",
                                    justifyContent: "flex-start",
                                    textAlign: "center",
                                    fontSize: "60px",
                                    color: "#fff",
                                }}
                            >

                                <CircularProgressbarWithChildren value={25} strokeWidth={5}
                                                                 styles={buildStyles({
                                                                     textSize: '25px',
                                                                     pathTransitionDuration: 0.5,
                                                                     textColor: '#fff',
                                                                     trailColor: '#27272A',
                                                                     pathColor: 'rgba(113,113,122,0.35)'
                                                                 })}>
                                    <div style={{
                                        width: "85%"
                                    }}>
                                        <CircularProgressbarWithChildren value={0} strokeWidth={5}
                                                                         styles={buildStyles({
                                                                             textSize: '25px',
                                                                             pathTransitionDuration: 0.5,
                                                                             textColor: '#fff',
                                                                             trailColor: '#27272A',
                                                                             pathColor: 'rgba(113,113,122,100)'
                                                                         })}
                                        >
                                            <div style={{marginBottom: -20}}>{0}</div>
                                            <img alt="" src="/main/dividor.svg"/>
                                            <div style={{
                                                marginTop: -20,
                                                color: 'rgba(113,113,122,0.35)'
                                            }}>{50}</div>
                                        </CircularProgressbarWithChildren>
                                    </div>
                                </CircularProgressbarWithChildren>

                            </div>
                            <div
                                style={{
                                    alignSelf: "stretch",
                                    display: "flex",
                                    flexDirection: "row",
                                    flexWrap: "wrap",
                                    alignItems: "flex-start",
                                    justifyContent: "flex-start",
                                    gap: "8px",
                                    fontSize: "14px",
                                    color: "#fafafa",
                                }}
                            >
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "flex-start",
                                        justifyContent: "flex-start",
                                        gap: "4px",
                                    }}
                                >
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            flexWrap: "wrap",
                                            alignItems: "flex-start",
                                            justifyContent: "flex-start",
                                            gap: "8px",
                                        }}
                                    >
                                        <div
                                            style={{
                                                borderRadius: "16px",
                                                backgroundColor: "rgba(113,113,122, 0.25)",
                                                overflow: "hidden",
                                                display: "flex",
                                                flexDirection: "row",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                padding: "0px 8px",
                                                color: "#71717A",
                                            }}
                                        >
                                            <div
                                                style={{
                                                    position: "relative",
                                                    letterSpacing: "0.01em",
                                                    lineHeight: "20px",
                                                    fontWeight: "600",
                                                }}
                                            >
                                                INFO
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            style={{
                                flex: "1",
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                justifyContent: "flex-start",
                                gap: "8px",
                            }}
                        >
                            <img
                                style={{position: "relative", width: "88px", height: "88px"}}
                                alt=""
                                src="/main/providers-i.svg"
                            />
                            <div
                                style={{
                                    alignSelf: "stretch",
                                    position: "relative",
                                    letterSpacing: "-0.01em",
                                    lineHeight: "32px",
                                    fontWeight: "600",
                                }}
                            >
                                Tier 1 Providers
                            </div>
                            <div
                                style={{
                                    alignSelf: "stretch",
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    gap: "8px",
                                }}
                            >
                                <div
                                    style={{
                                        position: "relative",
                                        letterSpacing: "-0.01em",
                                        lineHeight: "32px",
                                        fontWeight: "600",
                                    }}
                                >
                                    87
                                </div>
                                <img
                                    style={{position: "relative", width: "48px", height: "48px"}}
                                    alt=""
                                    src="/main/avatar6.svg"
                                />
                                <div
                                    style={{
                                        position: "relative",
                                        letterSpacing: "-0.01em",
                                        lineHeight: "32px",
                                    }}
                                >
                                    -2
                                </div>
                            </div>
                            <div
                                style={{
                                    alignSelf: "stretch",
                                    position: "relative",
                                    fontSize: "16px",
                                    lineHeight: "24px",
                                    color: "#a3a3a3",
                                }}
                            >
                                Last 24 Hours
                            </div>
                        </div>
                        <div
                            style={{
                                flex: "1",
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                justifyContent: "flex-start",
                                gap: "8px",
                            }}
                        >
                            <img
                                style={{position: "relative", width: "88px", height: "88px"}}
                                alt=""
                                src="/main/exposure-i.svg"
                            />
                            <div
                                style={{
                                    alignSelf: "stretch",
                                    position: "relative",
                                    letterSpacing: "-0.01em",
                                    lineHeight: "32px",
                                    fontWeight: "600",
                                }}
                            >
                                Current Exposure
                            </div>
                            <div
                                style={{
                                    alignSelf: "stretch",
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    gap: "8px",
                                }}
                            >
                                <div
                                    style={{
                                        position: "relative",
                                        letterSpacing: "-0.01em",
                                        lineHeight: "32px",
                                        fontWeight: "600",
                                    }}
                                >
                                    364
                                </div>
                                <img
                                    style={{position: "relative", width: "48px", height: "48px"}}
                                    alt=""
                                    src="/main/avatar7.svg"
                                />
                                <div
                                    style={{
                                        position: "relative",
                                        letterSpacing: "-0.01em",
                                        lineHeight: "32px",
                                    }}
                                >
                                    +27
                                </div>
                            </div>
                            <div
                                style={{
                                    alignSelf: "stretch",
                                    position: "relative",
                                    fontSize: "16px",
                                    lineHeight: "24px",
                                    color: "#a3a3a3",
                                }}
                            >
                                Unique TLD’s reached
                            </div>
                        </div>
                        <div
                            style={{
                                position: "relative",
                                borderRight: "1px solid #a1a1aa",
                                boxSizing: "border-box",
                                width: "1px",
                                height: "183px",
                            }}
                        />
                        <div
                            style={{
                                flex: "1",
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                justifyContent: "flex-start",
                                gap: "8px",
                            }}
                        >
                            <img
                                style={{position: "relative", width: "88px", height: "88px"}}
                                alt=""
                                src="/main/alert-i.svg"
                            />
                            <div
                                style={{
                                    alignSelf: "stretch",
                                    position: "relative",
                                    letterSpacing: "-0.01em",
                                    lineHeight: "32px",
                                    fontWeight: "600",
                                }}
                            >
                                Breach Alert
                            </div>
                            <div
                                style={{
                                    alignSelf: "stretch",
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    justifyContent: "center",
                                }}
                            >
                                <div
                                    style={{
                                        position: "relative",
                                        letterSpacing: "-0.01em",
                                        lineHeight: "32px",
                                        fontWeight: "600",
                                    }}
                                >
                                    3
                                </div>
                            </div>
                            <div
                                style={{
                                    alignSelf: "stretch",
                                    position: "relative",
                                    fontSize: "16px",
                                    lineHeight: "24px",
                                    color: "#a3a3a3",
                                }}
                            >
                                Breached Suppliers
                            </div>
                        </div>
                    </div>


                    <div
                        style={{
                            width: "100%",
                            height: "70%",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                            justifyContent: "flex-start",
                            boxSizing: "border-box",
                            textAlign: "left",
                            fontSize: "12px",
                            color: "#a3a3a3",
                            fontFamily: "Inter",
                        }}
                        id={"text-data"}
                    >
                        <div
                            style={{
                                alignSelf: "stretch",
                                borderRadius: "8px",
                                backgroundColor: "#18181b",
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "flex-start",
                                justifyContent: "flex-start",
                                padding: "8px 16px",
                                gap: "16px",
                            }}
                        >
                            <div
                                style={{
                                    alignSelf: "stretch",
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "flex-start",
                                    justifyContent: "flex-start",
                                    gap: "4px",
                                }}
                            >
                                <div
                                    style={{
                                        alignSelf: "stretch",
                                        position: "relative",
                                        fontSize: "24px",
                                        letterSpacing: "-0.01em",
                                        lineHeight: "56px",
                                        fontWeight: "600",
                                        color: "#fff",
                                    }}
                                >
                                    Domain Takedown Service
                                </div>
                            </div>
                            <div
                                style={{
                                    alignSelf: "stretch",
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "flex-start",
                                    justifyContent: "flex-start",
                                    gap: "16px",
                                    fontSize: "18px",
                                }}
                            >
                                <div
                                    style={{
                                        alignSelf: "stretch",
                                        position: "relative",
                                        lineHeight: "28px",
                                    }}
                                >
                                    <p style={{margin: ""}}>
                                        <img src="/main/white-dot.svg" width="12"/>&nbsp;
                                        <b style={{fontFamily: "Inter", color: "#fff"}}>
                                            Swift Domain Takedown
                                        </b>
                                        <span>
                : Provides expedited domain takedown services to combat fraudulent or malicious domains impersonating your brand or conducting malicious activities.
              </span>
                                    </p>
                                    <p style={{margin: ""}}></p>
                                    <p style={{margin: ""}}>
                                        <img src="/main/white-dot.svg" width="12"/>&nbsp;
                                        <b style={{fontFamily: "Inter", color: "#fff"}}>
                                            Legal Compliance Verification
                                        </b>
                                        <span>
                : Ensures that domain takedown requests are compliant with legal and regulatory frameworks, minimizing liability risks.
              </span>
                                    </p>
                                    <p style={{margin: ""}}></p>
                                    <p style={{margin: ""}}>
                                        <img src="/main/white-dot.svg" width="12"/>&nbsp;
                                        <b style={{fontFamily: "Inter", color: "#fff"}}>
                                            Real-Time Monitoring and Alerting
                                        </b>
                                        <span>
                : Monitors the digital space in real-time for unauthorized or malicious use of your brand in domain names, with immediate alerts enabling rapid response.
              </span>
                                    </p>
                                    <p style={{margin: ""}}></p>
                                    <p style={{margin: ""}}>
                                        <img src="/main/white-dot.svg" width="12"/>&nbsp;
                                        <b style={{fontFamily: "Inter", color: "#fff"}}>
                                            Coordination with Registrars and Hosting Providers
                                        </b>
                                        <span>
                <span>
                  : Coordinates with domain registrars and hosting providers to execute domain takedowns efficiently and legally.
                </span>
              </span>
                                    </p>
                                    <p style={{margin: "", color: "#fff"}}>
              <span>
                <span></span>
              </span>
                                    </p>
                                    <p style={{margin: ""}}>
              <span>
                  <img src="/main/white-dot.svg" width="12"/>&nbsp;
                <b style={{fontFamily: "Inter",  color: "#fff"}}>
                  Phishing and Malware Site Takedowns
                </b>
              </span>
                                        <span>
                : Specializes in taking down phishing and malware-hosting sites that misuse your brand, protecting both your reputation and your customers.
              </span>
                                    </p>
                                    <p style={{margin: ""}}></p>
                                    <p style={{margin: ""}}>
                                        <img src="/main/white-dot.svg" width="12"/>&nbsp;
                                        <b style={{fontFamily: "Inter", color: "#fff"}}>
                                            Post-Takedown Monitoring
                                        </b>
                                        <span>
                : Continues monitoring to ensure malicious domains remain inactive and to identify any resurgence of fraudulent activities.
              </span>
                                    </p>

                                </div>
                            </div>
                        </div>
                    </div>


                </div>
            </div>
        )
    }
}