import React, { Component } from 'react'
import { ReactComponent as IActivity } from '../icons/table/activity.svg';
import { ReactComponent as IRFI } from '../icons/table/rfi.svg';
import { ReactComponent as IReporting } from '../icons/table/reporting.svg';
import { ReactComponent as ISubmit } from '../icons/history_log/send.svg';
import { ReactComponent as ILogin } from '../icons/covertdna/login.svg';
import { ReactComponent as IPass } from '../icons/covertdna/pass.svg';
import { ReactComponent as ICookie } from '../icons/covertdna/cookie.svg';

import { CircularProgressbar, buildStyles, CircularProgressbarWithChildren } from 'react-circular-progressbar';
import {createTheme, ThemeProvider, Tooltip as MuiTooltip} from "@mui/material";
import {MaterialReactTable} from "material-react-table";
import 'react-circular-progressbar/dist/styles.css';
import { Line, Bar } from 'react-chartjs-2';
import WorldMap, { CountryContext } from "react-svg-worldmap"
import { CircleFlag } from "react-circle-flags"
import ReactModal from 'react-modal';
import Form from 'react-bootstrap/Form';
import Box from '@mui/material/Box';

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    BarElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend
} from 'chart.js';
import MDEditor from "@uiw/react-md-editor";
import Dropdown from "react-bootstrap/Dropdown";
import {byFips as country_lookup_fips, byIso as country_lookup} from "country-code-lookup";

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend
);

function get_status_color(status, transparent) {
    switch (status) {
        case "New":
            return transparent ? "rgba(244,63,94,0.25)" : "#F43F5E"
        case "Pending":
            return transparent ? "rgba(245,158,11,0.25)" : "#F43F5E"
        case "False Positive":
            return transparent ? "rgba(139,92,246,0.25)" : "#8B5CF6"
        case "Closed":
            return transparent ? "rgba(6,182,212,0.25)" : "#06B6D4"
        default:
            return ""
    }
}

function getISO(code, iso2) {
    if (Array.isArray(code)) {
        code = code[1]
    }
    if (code === "")return "Unknown"
    try {
        if (code) {
            let lookup = country_lookup(code)
            if (!lookup)lookup = country_lookup_fips(code)
            if (iso2) {
                return lookup.iso2.toLowerCase()
            }
            return lookup.country
        }
    } catch (e) {}
    return ""
}

function get_time_string(time, table) {
    if (time) {
        let date = new Date(time * 1000);
        if (table) return new Date(date - date.getTimezoneOffset() * 60 * 1000).toISOString().replace('.000Z','').replace('T',' ');
        return new Date(date - date.getTimezoneOffset() * 60 * 1000).toISOString().replace('.000Z', '').replaceAll('-', '/').replace('T', ' - ');
    }
    return ""
}

function deepEqual(object1, object2) {
    const keys1 = Object.keys(object1);
    const keys2 = Object.keys(object2);

    if (keys1.length !== keys2.length) {
        return false;
    }

    for (const key of keys1) {
        const val1 = object1[key];
        const val2 = object2[key];
        const areObjects = isObject(val1) && isObject(val2);
        if (
            (areObjects && !deepEqual(val1, val2)) ||
            (!areObjects && val1 !== val2)
        ) {
            return false;
        }
    }

    return true;
}

function isObject(object) {
    return object != null && typeof object === 'object';
}

const getStyle = ({
                      countryValue,
                      countryCode,
                      minValue,
                      maxValue,
                      color,
                  }: CountryContext) => ({
    fill: countryValue ? color: "rgb(85,115,85)",
    fillOpacity: 0.5,
    stroke: "white",
    strokeWidth: 1,
    cursor: "pointer",
});

const rfi_columns = [
    {
        accessorKey: 'urgency',
        header: 'Urgency',
        size: 150,
    },
    {
        accessorKey: 'user',
        header: 'User',
        size: 150,
    },
    {
        accessorKey: 'category',
        header: 'Category',
        size: 200,
    },
    {
        accessorKey: 'status',
        header: 'Status',
        size: 150,
        Cell: ({ cell }) => (
            <div
                style={{
                    borderRadius: "16px",
                    border: "2px solid " + get_status_color(cell.getValue(), false),
                    display: "inline-block",
                    flexDirection: "row",
                    padding: "0px 8px",
                    color: get_status_color(cell.getValue(), false),
                }}
            >
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        letterSpacing: "0.01em",
                        lineHeight: "20px",
                        width: "100%",
                        height: "100%"
                    }}
                >
                    {cell.getValue()}
                </div>
            </div>
        ),
    },
    {
        accessorKey: 'submitted',
        header: 'Submitted',
        size: 150,
        Cell: ({ cell }) => (
            <div>
                {get_time_string(cell.getValue(), true)}
            </div>
        ),
    },
];
const columns = [
    {
        accessorKey: 'host',
        header: 'Host / User',
        size: 150,
    },
    {
        accessorKey: 'priority',
        header: 'Priority',
        size: 150,
    },
    {
        accessorKey: 'type',
        header: 'Type',
        size: 200,
    },
    {
        accessorKey: 'name',
        header: 'Name / Domain',
        size: 150,
        Cell: ({ cell }) => (
            <div>
                {
                    cell.row.original["name"] ?
                        cell.row.original["name"]
                        :
                        cell.row.original["Credentials"] ?
                            cell.row.original["Credentials"][0]["Malware"]
                            :
                            cell.row.original["domain"]
                }
            </div>
        ),
    },
    {
        header: 'Country',
        size: 150,
        Cell: ({cell}) => (
            <div style={{
                display: "flex",
                flexDirection: "row",
                gap: "8px",
                justifyItems: "center",
                alignItems: "center"
            }}>
                {
                    cell.row.original["priority"].charAt(0) === 'C' || cell.row.original["priority"].charAt(0) === 'B' ?
                        "N/A"
                        :
                        <div style={{
                            display: "flex",
                            flexDirection: "row",
                            gap: "8px",
                            justifyItems: "center",
                            alignItems: "center"
                        }}>
                            <div>
                                {
                                    <CircleFlag height={24} countryCode={getISO(cell.row.original["country"], true)}/>
                                }
                            </div>
                            <div>
                                {getISO(cell.row.original["country"])}
                            </div>
                        </div>
                }
            </div>
        ),
    },
    {
        accessorKey: 'status',
        header: 'Status',
        size: 150,
        Cell: ({cell}) => (
            <div
                style={{
                    borderRadius: "16px",
                    border: "2px solid " + get_status_color(cell.getValue(), false),
                    display: "inline-block",
                    flexDirection: "row",
                    padding: "0px 8px",
                    background: cell.getValue() === "Pending" ? get_status_color(cell.getValue(), false) : "transparent",
                    color: cell.getValue() === "Pending" ? "black" : get_status_color(cell.getValue(), false),
                }}
            >
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        letterSpacing: "0.01em",
                        lineHeight: "20px",
                        width: "100%",
                        height: "100%"
                    }}
                >
                    {cell.getValue() === "Pending" ? "LIVE IR" : cell.getValue()}
                </div>
            </div>
        ),
    },
    {
        accessorKey: 'snow_ticket',
        header: 'SNOW Ticket',
        size: 150,
    },
    {
        accessorKey: 'created',
        header: 'First Detected',
        size: 150,
        Cell: ({ cell }) => (
            <div>
                {get_time_string(cell.getValue(), true)}
            </div>
        ),
    },
    {
        accessorKey: 'updated',
        header: 'Last Updated',
        size: 150,
        Cell: ({ cell }) => (
            <div>
                {get_time_string(cell.getValue(), true)}
            </div>
        ),
    },
];
const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

export default class Dashboard extends Component {
    search_placeholder = {
        priority: "P",
        ioa: "-",
        country: ["-", ""],
        malware: "-",
        first_seen: "-",
        last_seen: "-"
    };
    tableTheme = createTheme({
        palette: {
            mode: "dark",
            background: {
                default: '#18181b',
            },
        },
        typography: {
            button: {
                textTransform: 'none',
                fontSize: '1.2rem',
            },
        },
        components: {
            MuiTooltip: {
                styleOverrides: {
                    tooltip: {
                        background: "#A1A1AA",
                    },
                },
            },
            MuiSwitch: {
                styleOverrides: {
                    thumb: {
                        color: 'black',
                    },
                },
            },
        },
    });
    wait_backend = false;
    wait_ioa = false;
    last_feed_idx = 0;

    reload = 1

    constructor(props) {
        super(props);
        this.map_ref = React.createRef()
        this.map_box_ref = React.createRef()
        this.eti_feed_ref = React.createRef()
        this.state = {
            username: '',
            company: '',
            key: '',
            keys: {},
            stats: {},
            d: [0, 0, 0, 0, 0, 0, 0],
            p: [0, 0, 0, 0],
            s: [0],
            s_val: [0],
            p_val: [0, 0, 0, 0],
            table_data: [], map_data: [],
            table_tab_idx: 0,
            showModal: false,
            modal_data: {},
            showPlaybook: false,
            showRFIModal: false,
            playbook: {},
            ioa_data: undefined,
            eti_feed: [],
            search_result: this.search_placeholder,
            top10_data: {},
            showTop10: false,
            log_input_text: "",
            show_log_input: false
        };
        this.getOnlineNumber = this.getOnlineNumber.bind(this)
        this.getEndpointLimit = this.getEndpointLimit.bind(this)
        this.getTableData = this.getTableData.bind(this)
        this.set_dials = this.set_dials.bind(this)
        this.getLatestPulse = this.getLatestPulse.bind(this)
        this.getMapWidth = this.getMapWidth.bind(this)
        this.getMapHeight = this.getMapHeight.bind(this)
        this.getMapBoxHeight = this.getMapBoxHeight.bind(this)
        this.OpenModal = this.OpenModal.bind(this)
        this.CloseModal = this.CloseModal.bind(this)
        this.getCountryRender = this.getCountryRender.bind(this)
        this.submitSearch = this.submitSearch.bind(this)
        this.update_detection = this.update_detection.bind(this)
        this.update_rfi = this.update_rfi.bind(this)
        this.update_feed = this.update_feed.bind(this)
        this.getSearchColor = this.getSearchColor.bind(this)
        this.getOnlineHistory = this.getOnlineHistory.bind(this)
        this.getDayLabel = this.getDayLabel.bind(this)
        this.pull_ioa_snapshot = this.pull_ioa_snapshot.bind(this)
        this.update_feed_cache = this.update_feed_cache.bind(this)
        this.get_top_countries = this.get_top_countries.bind(this)
        this.get_trending_down = this.get_trending_down.bind(this)
        this.get_trending_up = this.get_trending_up.bind(this)
        this.getBreachDate = this.getBreachDate.bind(this)
        this.getBreachURL = this.getBreachURL.bind(this)
        this.getBreachCountryRender = this.getBreachCountryRender.bind(this)
        this.top_10 = this.top_10.bind(this)
        this.getCredColors = this.getCredColors.bind(this)
        this.tick = this.tick.bind(this)
        this.feed_cache = []
        let data = this.props.page_data()
        //console.log("dashboard", data)
    }

    getCredColors(creds) {
        switch (creds["CredentialType"].length) {
            case 1:
                return "#EAB308"
            case 2:
                return "#F97316"
            case 3:
                return "#F43F5E"
        }
        return "#71717A"
    }

    getBreachURL() {
        let url = "N/A"
        if (this.state.modal_data["risk"]) {
            if (this.state.modal_data["group"]) {
                url = this.state.modal_data["risk"]["post_url"]
            } else url = this.state.modal_data["risk"]["url"]
        }
        let image

        if (url.includes(".onion")) {
            image = "/main/onion-link-i.svg"
            url = url.replaceAll(".", "[.]")
        } else {
            image = "/main/link-i.svg"
        }

        let full_url = url
        if (url.length > 40) {
            url = url.slice(0, 40) + "..."
        }
        return (
            <div style={{display: "flex", alignItems: "center", gap: "4px"}}>
                <img src={image} alt={"url-icon"}/>
                <a href={full_url} target="_blank"
                   rel="noreferrer"
                   style={{
                       color: "#06B6D4"
                   }}
                >
                    {url}
                </a>
            </div>
        )
    }

    getBreachDate() {
        let time = 0
        if (this.state.modal_data["risk"]) {
            if (this.state.modal_data["risk"]["date"]) {
                time = this.state.modal_data["risk"]["date"]
            } else time = this.state.modal_data["risk"]["published"]
        }
        return (
            <div>
                {get_time_string(time)}
            </div>
        )
    }

    get_trending_up() {
        if (!this.state.ioa_data) return []

        let day_ago = Math.floor(new Date().getTime() / 1000.0) - 86400

        let latest_detections = {}
        let going_up = {}

        for (let item of this.state.ioa_data) {
            if (item["first_seen"] > day_ago) {
                if (item["malware"] in latest_detections) latest_detections[item["malware"]]["count"]++
                else {
                    latest_detections[item["malware"]] = {}
                    latest_detections[item["malware"]]["malware"] = item["malware"]
                    latest_detections[item["malware"]]["group"] = item["group"]
                    latest_detections[item["malware"]]["count"] = 1
                }
            }
        }

        let top_up = []

        let top_up_list = Object.keys(latest_detections).sort(function (a, b) {
            return latest_detections[b]["count"] - latest_detections[a]["count"];
        });

        for (let item of top_up_list) {
            top_up.push(latest_detections[item])
        }

        if (top_up.length > 5) {
            top_up = top_up.slice(0, 5)
        }

        return top_up
    }

    get_trending_down() {
        if (!this.state.ioa_data) return []

        let now = Math.floor(new Date().getTime() / 1000.0)
        let day_ago = now - 86400
        let seven_days_ago = now - (86400 * 14)

        let latest_detections = {}
        let old_detections = {}
        let going_down = {}

        for (let item of this.state.ioa_data) {
            if (item["last_seen"] > day_ago) {
                if (item["malware"] in latest_detections) latest_detections[item["malware"]]["count"]++
                else {
                    latest_detections[item["malware"]] = {}
                    latest_detections[item["malware"]]["malware"] = item["malware"]
                    latest_detections[item["malware"]]["group"] = item["group"]
                    latest_detections[item["malware"]]["count"] = 1
                }
            }
            if (item["last_seen"] < day_ago && item["last_seen"] > seven_days_ago) {
                if (item["malware"] in old_detections) old_detections[item["malware"]]["count"]++
                else {
                    old_detections[item["malware"]] = {}
                    old_detections[item["malware"]]["malware"] = item["malware"]
                    old_detections[item["malware"]]["group"] = item["group"]
                    old_detections[item["malware"]]["count"] = 1
                }
            }
        }

        for (let item of Object.keys(latest_detections)) {
            if (item in old_detections) {
                if (latest_detections[item]["count"] < old_detections[item]["count"]) {
                    going_down[item] = old_detections[item]
                    going_down[item]["diff"] = old_detections[item]["count"] - latest_detections[item]["count"]
                    going_down[item]["current_count"] = latest_detections[item]["count"]
                }
            }
        }


        for (let item of Object.keys(old_detections)) {
            if (!(item in latest_detections)) {
                going_down[item] = old_detections[item]
                going_down[item]["diff"] = old_detections[item]["count"]
                going_down[item]["current_count"] = 0
            }
        }

        for (let item of Object.keys(going_down)) {
            going_down[item]["per"] = Math.ceil(going_down[item]["diff"] * 100 / going_down[item]["count"]) * going_down[item]["count"]
        }


        let top_down = []

        let top_down_list = Object.keys(going_down).sort(function (a, b) {
            return going_down[b]["per"] - going_down[a]["per"];
        });

        for (let item of top_down_list) {
            top_down.push(going_down[item])
        }

        if (top_down.length > 5) {
            top_down = top_down.slice(0, 5)
        }

        return top_down
    }

    get_top_countries() {
        if (!this.state.ioa_data) return []
        let country_map = {}
        let total = 0
        let top = []
        for (let item of this.state.ioa_data) {
            if (item["country"][0] in country_map) country_map[item["country"][0]]["count"]++
            else {
                country_map[item["country"][0]] = {}
                country_map[item["country"][0]]["country"] = item["country"]
                country_map[item["country"][0]]["count"] = 1
            }
            total++
        }
        let top_countries = Object.keys(country_map).sort(function (a, b) {
            return country_map[b]["count"] - country_map[a]["count"];
        });

        for (let item of top_countries) {
            let hold = country_map[item]
            hold["per"] = Math.ceil(hold["count"] * 100 / total)
            top.push(country_map[item])
        }
        return top.slice(0, 5)
    }

    top_10(val, type) {
        if (!this.state.ioa_data) return
        let data = {}
        let malware_map = {}
        data["list"] = []
        data["target"] = val
        let total = 0

        for (let item of this.state.ioa_data) {
            let check = item["priority"] === val
            if (type) {
                check = item["group"] === val
            }
            if (check) {
                total++
                if (item["malware"] in malware_map) malware_map[item["malware"]]["count"]++
                else {
                    malware_map[item["malware"]] = item
                    malware_map[item["malware"]]["count"] = 1
                }
            }

        }

        let top_malware = Object.keys(malware_map).sort(function (a, b) {
            return malware_map[b]["count"] - malware_map[a]["count"];
        });

        for (let item of top_malware) {
            data["list"].push(malware_map[item])
        }

        data["total"] = total
        this.setState({showTop10: true, top10_data: data});
    }

    OpenModal(data) {
        if (data["group"] === "") {
            data["group"] = "N/A"
        }
        this.setState({modal_data: data, showModal: true});
    }

    CloseModal() {
        this.setState({modal_data: {}, showModal: false, show_log_input: false, log_input_text: ""});
    }

    getBreachCountryRender() {
        let code = getISO(this.state.modal_data["country"], true)
        let name = getISO(this.state.modal_data["country"])
        return (
            <div style={{display: "flex", alignItems: "center", gap: "8px"}}>
                {code === "" ? "" : <CircleFlag height={20} countryCode={code}/>}
                {name}
            </div>
        )
    }

    getCountryRender() {
        if (this.state.modal_data["country"]) {
            let code = getISO(this.state.modal_data["country"][1], true)
            let name = getISO(this.state.modal_data["country"][1])
            return (
                <div style={{display: "flex", alignItems: "center", gap: "8px"}}>
                    {code === "" ? "" : <CircleFlag height={20} countryCode={code}/>}
                    {name}
                </div>
            )
        }
    }

    getDayLabel(idx) {
        if (idx < 0) {
            idx += 7
        }
        return days[idx]
    }

    update_feed() {
        if (this.feed_cache.length > 1) {
            let feed = this.state.eti_feed
            let len = 30
            if (this.eti_feed_ref.current !== null) {
                len = Math.ceil(this.eti_feed_ref.current.offsetHeight / 21)
            }
            if (feed.length >= len) {
                feed.splice(0, 1)
            }
            feed.push(this.feed_cache[0])
            this.feed_cache.splice(0, 1)
            if (this.feed_cache.length > 10) {
                this.feed_cache.length = []
            }
            this.setState({eti_feed: feed})
        }
    }

    getSearchColor() {
        switch (this.state.search_result.priority) {
            case "P1":
                return "#EF4444"
            case "P2":
                return "#F97316"
            case "P3":
                return "#EAB308"
            case "P4":
                return "#06B6D4"
            default:
                return "#A1A1AA"
        }
    }

    pull_ioa_snapshot() {
        if (this.wait_ioa) return
        this.wait_ioa = true
        let data = this.props.page_data()
        let company = data.company
        let key = data.keys[data.company]
        if (key && key !== '') {
            let jsonData = {
                "key": key,
            }
            fetch('https://ti-endpoint.cybershadows.co.uk/' + company + "/all_ioa_data", {
                method: 'POST',
                mode: 'cors',
                body: JSON.stringify(jsonData)
            }).then((response) => {
                if (response.ok) {
                    response.json().then(json => {
                        if (json.data) {
                            this.setState({ioa_data: json["data"]})
                        }
                    });
                }
            }).catch((err) => {
                console.log("dashboard", err)
            }).finally(() => {
                this.wait_ioa = false
            })
        } else this.wait_ioa = false
    }

    update_feed_cache() {
        let update = []
        if (!this.state.ioa_data) return
        if (this.last_feed_idx > this.state.ioa_data.length - 15) {
            this.last_feed_idx = 0
        }
        for (let i = this.last_feed_idx; i < this.last_feed_idx + 10; i++) {
            if (this.state.ioa_data[i]) {
                let str = this.state.ioa_data[i]["ip"]
                if (this.state.ioa_data[i]["host"] !== "N/A") {
                    str += " " + this.state.ioa_data[i]["host"]
                } else if (this.state.ioa_data[i]["ssl_cn"] !== "N/A") {
                    str += " " + this.state.ioa_data[i]["ssl_cn"]
                }
                update.push(str)
            }
        }
        this.last_feed_idx = this.last_feed_idx + 10
        this.feed_cache = this.feed_cache.concat(update)
    }

    tick() {
        let data = this.props.page_data()
        let key = data.keys[data.company]
        let company = data.company;
        if (this.state.company !== data.company) {
            this.setState({username: data.username, key: key, company: company, keys: data.keys})
        }
        if (key !== '') {
            let jsonData = {
                "key": key,
            }
            if (this.wait_backend) {
                return
            }
            this.wait_backend = true
            fetch('https://ti-endpoint.cybershadows.co.uk/' + company + "/stats", {
                method: 'POST',
                mode: 'cors',
                body: JSON.stringify(jsonData)
            }).then((response) => {
                if (response.ok) {
                    response.json().then(json => {
                        this.setState({stats: json})
                    });
                }
            }).catch((err) => {
                console.log("dashboard", err)
            }).finally(() => {
                this.wait_backend = false
            })
        }
        this.update_feed_cache()
        if (!this.state.ioa_data) {
            this.pull_ioa_snapshot()
        }
    }

    componentDidUpdate(prevProps, prevState, Snapshot) {
        this.props.page_state("dashboard", this.state)
    }

    componentDidMount() {
        let state = this.props.page_state("dashboard")
        if (state) {
            this.setState(state)
        }

        let data = this.props.page_data()
        if (data.username && data.username !== '') {
            this.setState({
                username: data.username,
                key: data.keys[data.company],
                company: data.company,
                keys: data.keys,
                live_data: {}
            })
        }

        this.interval_update = setInterval(
            () => this.tick(),
            1000
        );

        this.feed_update = setInterval(
            () => this.update_feed(),
            200
        );

        this.pull_ioa_snapshot()
    }

    componentWillUnmount() {
        clearInterval(this.interval_update);
        clearInterval(this.feed_update);
    }

    update_rfi(e, log) {
        this.update_detection(e, log, true)
    }

    update_detection(e, log, rfi) {
        let company = this.state.company;
        let jsonData = {
            "key": this.state.key,
            "id": this.state.modal_data["id"],
            "data": log ? log : parseInt(e.currentTarget.id)
        }
        fetch('https://ti-endpoint.cybershadows.co.uk/' + company + (rfi ? "/update_rfi" : "/update_detection"), {
            method: 'POST',
            mode: 'cors',
            body: JSON.stringify(jsonData)
        }).then((response) => {
            if (response.ok) {
                response.json().then(json => {
                    if (json.status) {
                        let modal_data = this.state.modal_data
                        modal_data["status"] = json.status
                        this.setState({modal_data: modal_data})
                    }
                    if (json.log) {
                        let modal_data = this.state.modal_data
                        modal_data["log"] = json.log
                        this.setState({modal_data: modal_data})
                    }
                });
            }
        }).catch((err) => {
            console.log("update_detection", err)
        })
    }


    submitSearch(e) {
        if (e.target.value !== this.state.search_result.ioa) {
            let company = this.state.company;
            let jsonData = {
                "key": this.state.key,
                "ioa": e.target.value
            }
            fetch('https://ti-endpoint.cybershadows.co.uk/' + company + "/ioa_search", {
                method: 'POST',
                mode: 'cors',
                body: JSON.stringify(jsonData)
            }).then((response) => {
                if (response.ok) {
                    response.json().then(json => {
                        this.setState({search_result: this.search_placeholder})
                        if (json.ioa) {
                            this.setState({search_result: json})
                        }
                    });
                }
            }).catch((err) => {
                console.log("dashboard ioa search", err)
            })
        }
    }

    getMapWidth() {
        if (this.map_ref.current !== null) {
            return this.map_ref.current.offsetWidth
        }
        return 200
    }

    getMapHeight() {
        if (this.map_ref.current !== null) {
            return this.map_ref.current.offsetHeight
        }
        return 200
    }

    getMapBoxHeight() {
        if (this.map_box_ref.current !== null) {
            return this.map_box_ref.current.offsetHeight
        }
        return 1400
    }

    getOnlineHistory() {
        if (this.state.stats.online) {
            let history = Array.from(this.state.stats.online)
            history.splice(0, 1)
            history[0] = this.getOnlineNumber()

            return history.reverse()
        } else {
            return Array.from({length: 28}, this.getOnlineNumber)
        }
    }

    getOnlineNumber() {
        if (this.state.stats.online) {
            return this.state.stats.online[0]
        } else {
            return 0
        }
    }

    getEndpointLimit() {
        if (this.state.stats.limit) {
            return this.state.stats.limit
        } else {
            return 0
        }
    }

    getTableData() {
        if (this.state.stats.detections) {
            let items = []
            let map_data = []
            let d = [0, 0, 0, 0, 0, 0, 0]
            let data = this.state.stats.detections.concat(this.state.stats.risk_detections)
            for (let item of data) {
                items.push(item)
                switch (item.priority) {
                    case 'P1':
                        d[0]++
                        break
                    case 'P2':
                        d[1]++
                        break
                    case 'P3':
                        d[2]++
                        break
                    case 'P4':
                        d[3]++
                        break
                }

                switch (item.priority.charAt(0)) {
                    case 'S':
                        d[4]++
                        break
                    case 'C':
                        d[5]++
                        break
                    case 'B':
                        d[6]++
                        break
                }

                let country_count = []
                if (item.country && item.country[1] !== "") {
                    if (item.country[1] in country_count) {
                        country_count[item.country[1]]++
                    } else {
                        country_count[item.country[1]] = 1
                    }
                }
                Object.keys(country_count).forEach(function (key, index) {
                    map_data.push({
                        country: key,
                        value: this[key]
                    })
                }, country_count);
            }
            if (!deepEqual(this.state.table_data, items)) {
                this.setState({table_data: items, d: d, map_data: map_data})
            }
        }
        if (this.state.stats.c2) {
            this.set_dials()
        }
        return this.state.table_data
    }

    set_dials() {
        let p1 = (this.state.stats.p1 / this.state.stats.c2) * 100
        let s = (this.state.d[4] / this.state.stats.s) * 100
        if (p1 !== this.state.p[0] || s !== this.state.s[0]) {
            this.setState({
                p: [
                    p1,
                    (this.state.stats.p2 / this.state.stats.c2) * 100,
                    (this.state.stats.p3 / this.state.stats.c2) * 100,
                    (this.state.stats.p4 / this.state.stats.c2) * 100
                ],
                p_val: [
                    this.state.stats.p1,
                    this.state.stats.p2,
                    this.state.stats.p3,
                    this.state.stats.p4
                ],
                s: [
                    s
                ],
                s_val: [
                    this.state.stats.s
                ]
            })
        }
    }

    getLatestPulse() {
        if (this.state.stats.pulse) {
            return this.state.stats.pulse
        } else {
            return 0
        }
    }

    render() {
        return (
            <div
                style={{
                    alignSelf: "stretch",
                    flex: "1",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                    padding: "8px 0px 0px",
                    gap: "8px",
                    textAlign: "left",
                    fontSize: "22px",
                    color: "#fafafa",
                    overflow: "auto"
                }}
                id="inner-data"
            >

                <ReactModal
                    ariaHideApp={false}
                    isOpen={this.state.showTop10}
                    contentLabel="Top 10"
                    style={{
                        overlay: {
                            zIndex: 500,
                            background: "rgba(19, 19, 19, 0.75)"
                        },
                        content: {
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            width: "570px",
                            height: "720px",
                            background: "#171717"
                        },
                    }}
                >
                    <div
                        style={{
                            width: "100%",
                            height: "100%",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "flex-start",
                            color: "white",
                            gap: "16px"
                        }}
                    >
                        <div style={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "flex-start",
                            lineHeight: "28px",
                            fontWeight: "600",
                        }}>
                            <div style={{color: "#F9B233"}}>
                                Top 10 Malware: {this.state.top10_data["target"]}
                            </div>
                            <button
                                style={{
                                    cursor: "pointer",
                                    border: "none",
                                    backgroundColor: "transparent",
                                    position: "relative",
                                    width: "24px",
                                    height: "24px",
                                    marginLeft: "auto"
                                }}
                                id="close-modal"
                                onClick={() => this.setState({showTop10: false})}
                            >
                                <img
                                    alt=""
                                    src="/main/white-close.svg"
                                />
                            </button>
                        </div>

                        {
                            this.state.top10_data["list"] ?
                                this.state.top10_data["list"].slice(0, 10).map((entry, i) =>
                                    <div style={{width: "100%", display: "flex", flexDirection: "column", gap: "8px"}}>
                                        <div style={{
                                            width: "100%",
                                            paddingLeft: 4,
                                            paddingRight: 8,
                                            justifyContent: 'flex-start',
                                            alignItems: 'center',
                                            gap: 8,
                                            display: 'inline-flex'
                                        }}
                                        >
                                            <div style={{paddingRight: 4, alignItems: 'center', display: 'flex'}}>
                                                <div style={{
                                                    width: "30px",
                                                    color: 'white',
                                                    fontSize: 16,
                                                    fontFamily: 'Inter',
                                                    fontWeight: '600',
                                                    lineHeight: 1,
                                                    wordWrap: 'break-word'
                                                }}>#{i + 1}</div>
                                            </div>

                                            <button
                                                onClick={() => this.props.open_threat_card(entry["malware"])}
                                                style={{
                                                    backgroundColor: "transparent",
                                                    cursor: "pointer",
                                                    border: "none",
                                                }}
                                            >
                                                <div style={{
                                                    padding: 8,
                                                    background: '#27272A',
                                                    borderRadius: 2,
                                                    justifyContent: 'flex-start',
                                                    alignItems: 'center',
                                                    display: 'flex'
                                                }}>
                                                    <img style={{width: 20, height: 20}} src="/main/show-i.svg"
                                                         alt="show"/>
                                                </div>
                                            </button>


                                            <div style={{
                                                flex: '1 1 0',
                                                height: 40,
                                                paddingRight: 4,
                                                justifyContent: 'flex-start',
                                                alignItems: 'center',
                                                display: 'flex'
                                            }}>
                                                <div style={{
                                                    flexDirection: 'column',
                                                    gap: 8,
                                                    justifyContent: 'flex-start',
                                                    alignItems: 'flex-start',
                                                    display: 'inline-flex'
                                                }}>
                                                    <div style={{
                                                        color: 'white',
                                                        fontSize: 16,
                                                        fontFamily: 'Inter',
                                                        fontWeight: '600',
                                                        lineHeight: 1,
                                                        wordWrap: 'break-word'
                                                    }}>{entry["malware"]}</div>
                                                    <div style={{
                                                        color: 'white',
                                                        fontSize: 12,
                                                        fontFamily: 'Inter',
                                                        fontWeight: '400',
                                                        lineHeight: 1,
                                                        letterSpacing: 0.06,
                                                        wordWrap: 'break-word'
                                                    }}>{entry["group"]}</div>
                                                </div>
                                            </div>
                                            <div style={{
                                                flexDirection: 'column',
                                                justifyContent: 'flex-start',
                                                alignItems: 'flex-start',
                                                display: 'inline-flex'
                                            }}>
                                                <div style={{
                                                    alignSelf: 'stretch',
                                                    height: 20,
                                                    flexDirection: 'column',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    gap: 4,
                                                    display: 'flex'
                                                }}>
                                                    <div style={{
                                                        alignSelf: 'stretch',
                                                        justifyContent: 'flex-end',
                                                        alignItems: 'center',
                                                        gap: 8,
                                                        display: 'inline-flex'
                                                    }}>
                                                        <div style={{
                                                            paddingLeft: 8,
                                                            paddingRight: 8,
                                                            borderRadius: 16,
                                                            overflow: 'hidden',
                                                            justifyContent: 'center',
                                                            alignItems: 'center',
                                                            display: 'flex'
                                                        }}>
                                                            <div style={{
                                                                color: '#A1A1AA',
                                                                fontSize: 14,
                                                                fontFamily: 'Inter',
                                                                fontWeight: '600',
                                                                lineHeight: 1,
                                                                letterSpacing: 0.07,
                                                                wordWrap: 'break-word'
                                                            }}>{entry["count"]}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div style={{
                                                    alignSelf: 'stretch',
                                                    height: 20,
                                                    flexDirection: 'column',
                                                    justifyContent: 'center',
                                                    alignItems: 'flex-end',
                                                    gap: 4,
                                                    display: 'flex'
                                                }}>
                                                    <div style={{
                                                        justifyContent: 'flex-end',
                                                        alignItems: 'center',
                                                        display: 'inline-flex'
                                                    }}>
                                                        <div style={{
                                                            paddingLeft: 8,
                                                            paddingRight: 8,
                                                            paddingTop: 4,
                                                            paddingBottom: 4,
                                                            background: 'rgba(244, 63, 94, 0.25)',
                                                            borderRadius: 16,
                                                            overflow: 'hidden',
                                                            justifyContent: 'center',
                                                            alignItems: 'center',
                                                            display: 'flex'
                                                        }}>
                                                            <div style={{
                                                                color: '#F43F5E',
                                                                fontSize: 14,
                                                                fontFamily: 'Inter',
                                                                fontWeight: '600',
                                                                lineHeight: 1,
                                                                letterSpacing: 0.07,
                                                                wordWrap: 'break-word'
                                                            }}>{Math.round(entry["count"] * 1000 / this.state.top10_data["total"]) / 10}%
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div style={{width: '100%', height: '1px', border: '1px #27272A solid'}}/>
                                    </div>
                                )
                                :
                                <div/>
                        }


                    </div>
                </ReactModal>


                <ReactModal
                    ariaHideApp={false}
                    isOpen={this.state.showRFIModal}
                    contentLabel="RFIModal"
                    style={{
                        overlay: {
                            zIndex: 500,
                            background: "rgba(19, 19, 19, 0.75)"
                        },
                        content: {
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            width: "1140px",
                            height: "620px",
                            background: "#171717"
                        },
                    }}
                >
                    <div style={{
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        flexDirection: "row",
                        gap: "16px"
                    }}
                    >
                        <div style={{
                            width: "calc(50% - 8px)",
                            height: "100%",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "flex-start",
                            color: "white",
                            gap: "16px"
                        }}
                        >
                            <div style={{
                                width: "100%",
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "flex-start",
                                lineHeight: "28px",
                                fontWeight: "600",
                            }}>
                                <div style={{color: "#F9B233"}}>
                                    Request for Intelligence (RFI)
                                </div>
                            </div>


                            <div style={{
                                display: "flex",
                                flexDirection: "column",
                                background: "#27272A",
                                borderRadius: "8px",
                                padding: "8px",
                                width: "100%",
                                height: "90%",
                                gap: "12px",
                            }}
                            >
                                <div style={{display: "flex", gap: "16px"}}>
                                    <div style={{color: "#A1A1AA"}}>
                                        Requester:
                                    </div>
                                    {this.state.modal_data["user"]}
                                </div>

                                <div style={{display: "flex", gap: "16px"}}>
                                    <div style={{color: "#A1A1AA"}}>
                                        Threat Category:
                                    </div>
                                    {this.state.modal_data["category"]}
                                </div>

                                Intelligence Request

                                <div style={{display: "flex", gap: "8px", marginLeft: "8px"}}
                                >
                                    <div style={{
                                        background: "#71717a",
                                        alignSelf: "stretch",
                                        flexShrink: "0",
                                        width: "1px"
                                    }}/>

                                    <MDEditor
                                        className={"scroll-editor"}
                                        value={this.state.modal_data["description"]}
                                        visibleDragbar={false}
                                        hideToolbar={true}
                                        preview="edit"
                                        extraCommands={[]}
                                        style={{
                                            width: "100%"
                                        }}
                                        height={108}
                                    />
                                </div>

                                Indicators of Compromise (IoC) to Investigate

                                <div style={{display: "flex", gap: "8px", marginLeft: "8px"}}
                                >
                                    <div style={{
                                        background: "#71717a",
                                        alignSelf: "stretch",
                                        flexShrink: "0",
                                        width: "1px"
                                    }}/>

                                    <MDEditor
                                        className={"scroll-editor"}
                                        value={this.state.modal_data["investigate"]}
                                        visibleDragbar={false}
                                        hideToolbar={true}
                                        preview="edit"
                                        extraCommands={[]}
                                        style={{
                                            width: "100%"
                                        }}
                                        height={108}
                                    />
                                </div>

                                <div style={{display: "flex", gap: "16px"}}>
                                    <div style={{color: "#A1A1AA"}}>
                                        RFI Urgency:
                                    </div>
                                    {this.state.modal_data["urgency"]}
                                </div>


                                <div style={{display: "flex", gap: "16px"}}>
                                    <div style={{color: "#A1A1AA"}}>
                                        Response Format:
                                    </div>
                                    {this.state.modal_data["format"]}
                                </div>

                            </div>

                            <div
                                style={{
                                    display: "flex",
                                    gap: "12px",
                                    width: "100%",
                                    height: "40px",
                                    fontSize: "13px",
                                    letterSpacing: "0.01em",
                                    lineHeight: "20px",
                                    fontFamily: "Inter",
                                }}
                            >
                                <button
                                    className="menu-entry"
                                    onClick={this.update_rfi}
                                    id="0"
                                    style={{
                                        width: "132px",
                                        minHeight: "100%"
                                    }}
                                >
                                    <div
                                        style={{
                                            backgroundColor: this.state.modal_data["status"] === "New" ?
                                                "rgba(244, 63, 94, 0.25)" : "rgba(113, 113, 122, 0.25)",
                                            borderColor: this.state.modal_data["status"] === "New" ?
                                                "rgba(244, 63, 94, 100)" : "transparent",
                                            borderRadius: "2px",
                                            borderWidth: "2px",
                                            borderStyle: "solid",
                                            width: "100%",
                                            height: "100%",
                                            display: "flex",
                                            flexDirection: "row",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            padding: "8px 16px",
                                            boxSizing: "border-box",
                                        }}
                                    >
                                        <div
                                            style={{
                                                position: "relative",
                                                color: this.state.modal_data["status"] === "New" ?
                                                    "rgba(244, 63, 94, 100)" : "white",
                                            }}
                                        >
                                            New
                                        </div>
                                    </div>
                                </button>
                                <button
                                    className="menu-entry"
                                    onClick={this.update_rfi}
                                    id="1"
                                    style={{
                                        width: "132px",
                                        minHeight: "100%"
                                    }}
                                >
                                    <div
                                        style={{
                                            backgroundColor: this.state.modal_data["status"] === "Pending" ?
                                                "rgba(245, 158, 11, 0.25)" : "rgba(113, 113, 122, 0.25)",
                                            borderColor: this.state.modal_data["status"] === "Pending" ?
                                                "rgba(245, 158, 11, 100)" : "transparent",
                                            borderRadius: "2px",
                                            borderWidth: "2px",
                                            borderStyle: "solid",
                                            width: "100%",
                                            height: "100%",
                                            display: "flex",
                                            flexDirection: "row",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            padding: "8px 16px",
                                            boxSizing: "border-box",
                                        }}
                                    >
                                        <div
                                            style={{
                                                position: "relative",
                                                color: this.state.modal_data["status"] === "Pending" ?
                                                    "rgba(245, 158, 11, 100)" : "white",
                                            }}
                                        >
                                            Pending
                                        </div>
                                    </div>
                                </button>

                                <button
                                    className="menu-entry"
                                    onClick={this.update_rfi}
                                    id="2"
                                    style={{
                                        width: "132px",
                                        minHeight: "100%",
                                        marginLeft: "auto"
                                    }}
                                >
                                    <div
                                        style={{
                                            backgroundColor: this.state.modal_data["status"] === "Closed" ?
                                                "rgba(6, 182, 212, 0.25)" : "rgba(113, 113, 122, 0.25)",
                                            borderColor: this.state.modal_data["status"] === "Closed" ?
                                                "rgba(6, 182, 212, 100)" : "transparent",
                                            borderRadius: "2px",
                                            borderWidth: "2px",
                                            borderStyle: "solid",
                                            width: "100%",
                                            height: "100%",
                                            display: "flex",
                                            flexDirection: "row",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            padding: "8px 16px",
                                            boxSizing: "border-box",
                                        }}
                                    >
                                        <div
                                            style={{
                                                position: "relative",
                                                color: this.state.modal_data["status"] === "Closed" ?
                                                    "rgba(6, 182, 212, 100)" : "white",
                                            }}
                                        >
                                            Closed
                                        </div>
                                    </div>
                                </button>

                            </div>

                        </div>


                        <div style={{
                            width: "calc(50% - 8px)",
                            height: "100%",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "flex-start",
                            color: "white",
                            gap: "16px"
                        }}
                        >
                            <div style={{
                                width: "100%",
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "flex-start",
                                lineHeight: "28px",
                                fontWeight: "600",
                            }}
                            >
                                <div style={{color: "#F9B233"}}>
                                    Action History
                                </div>
                                <button
                                    style={{
                                        cursor: "pointer",
                                        border: "none",
                                        backgroundColor: "transparent",
                                        position: "relative",
                                        width: "24px",
                                        height: "24px",
                                        marginLeft: "auto"
                                    }}
                                    id="close-modal"
                                    onClick={() => {
                                        this.setState({showRFIModal: false})
                                    }}
                                >
                                    <img
                                        alt=""
                                        src="/main/white-close.svg"
                                    />
                                </button>
                            </div>


                            <div className="inner-tip-box" style={{
                                height: "100%",
                                gap: "16px"
                            }}>
                                <div style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    gap: "4px",
                                    lineHeight: "28px",
                                    fontWeight: "600",
                                    alignItems: "center",
                                    height: "24px",
                                }}
                                >
                                    <img
                                        style={{width: "24px", height: "24px"}}
                                        alt=""
                                        src="/main/tiphistory.svg"
                                    />
                                    <div style={{
                                        color: "#a1daf8"
                                    }}>Action History Log
                                    </div>

                                    <button
                                        style={{
                                            cursor: "pointer",
                                            borderRadius: "2px",
                                            border: "none",
                                            backgroundColor: "rgba(6,182,212,0.25)",
                                            color: "#06B6D4",
                                            position: "relative",
                                            marginLeft: "auto"
                                        }}
                                        id="new-update"
                                        onClick={() => this.setState({show_log_input: true})}
                                    >
                                        New Update
                                    </button>
                                </div>


                                <div className="scroll-bar-div" style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    padding: "0px 0px 16px 8px",
                                    gap: "8px",
                                    overflowY: "scroll",
                                    overflowX: "hidden",
                                    width: "100%",
                                    height: "472px"
                                }}
                                >

                                    {this.state.show_log_input ?
                                        <div
                                            style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                marginTop: "16px",
                                                width: "100%",
                                                height: "230px",
                                                marginRight: "8px",
                                            }}
                                        >
                                            <MDEditor
                                                value={this.state.log_input_text}
                                                visibleDragbar={false}
                                                hideToolbar={true}
                                                preview="edit"
                                                extraCommands={[]}
                                                onChange={(data) => {
                                                    this.setState({log_input_text: data})
                                                }}
                                                height={200}
                                            />T
                                            <div style={{marginLeft: "auto", marginTop: "8px"}}>
                                                <button onClick={() => {
                                                    if (this.state.log_input_text !== "") {
                                                        this.update_rfi("", this.state.log_input_text)
                                                        this.setState({show_log_input: false, log_input_text: ""})
                                                    }
                                                }}
                                                        style={{
                                                            cursor: "pointer",
                                                            borderRadius: "2px",
                                                            background: "transparent",
                                                            border: "solid 1px " + (this.state.log_input_text === "" ? "#737373" : "#06B6D4"),
                                                            color: this.state.log_input_text === "" ? "#737373" : "#06B6D4",
                                                            marginRight: "8px"
                                                        }}
                                                >
                                                    <ISubmit style={{
                                                        color: this.state.log_input_text === "" ? "#737373" : "#06B6D4",
                                                    }}/>
                                                    Submit
                                                </button>
                                                <button onClick={() => {
                                                    this.setState({show_log_input: false, log_input_text: ""})
                                                }}
                                                        style={{
                                                            cursor: "pointer",
                                                            borderRadius: "2px",
                                                            background: "transparent",
                                                            border: "solid 1px #06B6D4",
                                                            color: "#06B6D4",
                                                            marginRight: "8px"
                                                        }}
                                                >
                                                    Cancel
                                                </button>
                                            </div>
                                        </div>
                                        : ""
                                    }

                                    {
                                        this.state.modal_data["log"] ?

                                            this.state.modal_data["log"].slice(0).reverse().map((item, i) =>
                                                <div style={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    marginRight: "8px",
                                                    gap: "8px"
                                                }}
                                                     id="history-log-entry"
                                                >
                                                    <div style={{
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        gap: "8px"
                                                    }}
                                                    >
                                                        <img src="/main/tiphistoryclock-i.svg" alt="tip-clock"/>
                                                        <div style={{color: "#737373"}}>
                                                            {get_time_string(item["time"])}
                                                        </div>
                                                    </div>
                                                    <div style={{
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        marginLeft: "8px",
                                                        gap: "8px"
                                                    }}
                                                    >
                                                        <div style={{
                                                            background: "#71717a",
                                                            alignSelf: "stretch",
                                                            flexShrink: "0",
                                                            width: "1px"
                                                        }}/>
                                                        <MDEditor.Markdown source={item["text"]}
                                                                           style={{
                                                                               padding: "16px 16px 16px 16px",
                                                                               color: "#A1A1AA",
                                                                               background: "#18181B",
                                                                               borderRadius: "8px",
                                                                               whiteSpace: 'pre-wrap',
                                                                               width: "100%",
                                                                               height: "100%",
                                                                               maxHeight: "100%",
                                                                               overflow: "auto"
                                                                           }}
                                                        />
                                                    </div>

                                                </div>
                                            )
                                            :
                                            ""
                                    }

                                    <div style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        marginRight: "8px",
                                        gap: "8px"
                                    }}
                                         id="history-log-entry"
                                    >
                                        <div style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            gap: "8px"
                                        }}
                                        >
                                            <img src="/main/tiphistoryclock-i.svg" alt="tip-clock"/>
                                            <div style={{color: "#737373"}}>
                                                {get_time_string(this.state.modal_data["submitted"])}
                                            </div>
                                        </div>
                                        <div style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            marginLeft: "8px",
                                            gap: "8px"
                                        }}
                                        >
                                            <div style={{
                                                background: "#71717a",
                                                alignSelf: "stretch",
                                                flexShrink: "0",
                                                width: "1px"
                                            }}/>
                                            <MDEditor.Markdown
                                                source={"New RFI Created"}
                                                style={{
                                                    padding: "16px 16px 16px 16px",
                                                    color: "#A1A1AA",
                                                    background: "#18181B",
                                                    borderRadius: "8px",
                                                    whiteSpace: 'pre-wrap',
                                                    width: "100%",
                                                    height: "100%",
                                                    maxHeight: "100%",
                                                    overflow: "auto"
                                                }}
                                            />
                                        </div>

                                    </div>

                                </div>
                            </div>


                        </div>

                    </div>

                </ReactModal>


                <ReactModal
                    ariaHideApp={false}
                    isOpen={this.state.showModal}
                    contentLabel="Tipper"
                    style={{
                        overlay: {
                            zIndex: 500,
                            background: "rgba(19, 19, 19, 0.75)"
                        },
                        content: {
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            width: "1140px",
                            height: "660px",
                            background: "#171717"
                        },
                    }}
                >
                    {
                        this.state.modal_data["priority"] ?
                            this.state.modal_data["priority"].charAt(0) === "B" ?
                                <div style={{
                                    width: "100%",
                                    height: "100%",
                                    display: "flex",
                                    flexDirection: "row",
                                    gap: "16px"
                                }}
                                >
                                    <div style={{
                                        width: "calc(50% - 8px)",
                                        height: "100%",
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "flex-start",
                                        color: "white",
                                        gap: "16px"
                                    }}
                                    >
                                        <div style={{
                                            width: "100%",
                                            display: "flex",
                                            flexDirection: "row",
                                            justifyContent: "flex-start",
                                            lineHeight: "28px",
                                            fontWeight: "600",
                                        }}>
                                            <div style={{color: "#F9B233"}}>
                                                ShadowVault Tipper
                                            </div>
                                        </div>

                                        <div className="inner-tip-box"
                                             style={{
                                                 height: "152px"
                                             }}
                                        >
                                            <div style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                gap: "4px",
                                                lineHeight: "28px",
                                                fontWeight: "600",
                                                alignItems: "center",
                                                height: "24px",
                                            }}>
                                                <img
                                                    style={{width: "24px", height: "24px"}}
                                                    alt="host-icon"
                                                    src="/main/tiphost.svg"
                                                />
                                                <div style={{
                                                    color: "#a1daf8"
                                                }}>Host Information
                                                </div>
                                            </div>

                                            <div style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                marginTop: "4px",
                                                height: "calc(100%-24px)"
                                            }}
                                                 id="host-data">
                                                <div style={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    color: "#A1A1AA",
                                                    gap: "4px",
                                                    width: "138px"
                                                }} id="section1">
                                                    <div>Client IP:</div>
                                                    <div>Hostname:</div>
                                                    <div>Host Severity:</div>
                                                    <div>Netflow:</div>
                                                </div>

                                                <div style={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    gap: "4px"
                                                }} id="section2">
                                                    <div>{this.state.modal_data["src_ip"]}</div>
                                                    <div>{this.state.modal_data["host"]}</div>
                                                    <div>{this.state.modal_data["h_severity"]}</div>
                                                    <div style={{
                                                        display: "flex",
                                                        gap: 8
                                                    }}>
                                                        <button
                                                            style={{
                                                                cursor: "pointer",
                                                                border: "none",
                                                                backgroundColor: "transparent",
                                                                justifyContent: "center",
                                                                display: "flex",
                                                                width: "21px",
                                                                height: "20px"
                                                            }}
                                                            onClick={() => this.props.open_netflow(this.state.modal_data)}
                                                            id="netflow"
                                                        >
                                                            <img src="/main/tipnet.svg" alt="netflow"/>
                                                        </button>
                                                        Forensic Data Analysis
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="inner-tip-box"
                                             style={{
                                                 height: "152px"
                                             }}
                                        >
                                            <div style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                gap: "4px",
                                                lineHeight: "28px",
                                                fontWeight: "600",
                                                alignItems: "center",
                                                height: "24px",
                                            }}>
                                                <img
                                                    style={{width: "24px", height: "24px"}}
                                                    alt=""
                                                    src="/main/dash-bdv-i.svg"
                                                />
                                                <div style={{
                                                    color: "#a1daf8"
                                                }}>Bad Domain Details
                                                </div>
                                            </div>
                                            <div style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                marginTop: "4px",
                                                height: "calc(100%-24px)"
                                            }}
                                                 id="group-data">
                                                <div style={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    color: "#A1A1AA",
                                                    gap: "4px",
                                                    width: "138px"
                                                }} id="section1">
                                                    <div>Type:</div>
                                                    <div>Domain:</div>
                                                    <div>Contacted IP:</div>
                                                    <div>Potential Intent:</div>
                                                </div>

                                                <div style={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    gap: "4px"
                                                }} id="section2">
                                                    <div>{this.state.modal_data["bdv"]["type"]}</div>
                                                    <div>{this.state.modal_data["domain"]}</div>
                                                    <div>{this.state.modal_data["dest_ip"]}</div>
                                                    <div style={{
                                                        flexDirection: 'row',
                                                        justifyContent: 'flex-start',
                                                        alignItems: 'flex-start',
                                                        gap: 4,
                                                        display: 'inline-flex'
                                                    }}
                                                    >
                                                        {this.state.modal_data["bdv"]["tags"].map((tag, i) =>
                                                            <div style={{
                                                                justifyContent: 'flex-start',
                                                                alignItems: 'flex-start',
                                                                gap: 8,
                                                                display: 'inline-flex'
                                                            }}
                                                            >
                                                                <div style={{
                                                                    paddingLeft: 8,
                                                                    paddingRight: 8,
                                                                    background: 'rgba(161, 161, 170, 0.25)',
                                                                    borderRadius: 16,
                                                                    overflow: 'hidden',
                                                                    justifyContent: 'center',
                                                                    alignItems: 'center',
                                                                    display: 'flex'
                                                                }}>
                                                                    <div style={{
                                                                        color: '#A1A1AA',
                                                                        fontSize: 14,
                                                                        fontFamily: 'Inter',
                                                                        fontWeight: '600',
                                                                        letterSpacing: 0.07,
                                                                        wordWrap: 'break-word'
                                                                    }}>{tag}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div
                                            style={{
                                                display: "flex",
                                                gap: "12px",
                                                width: "100%",
                                                height: "40px",
                                                fontSize: "13px",
                                                letterSpacing: "0.01em",
                                                lineHeight: "20px",
                                                fontFamily: "Inter",
                                                marginTop: "auto"
                                            }}
                                        >
                                            <button
                                                className="menu-entry"
                                                onClick={this.update_detection}
                                                id="0"
                                                style={{
                                                    width: "132px",
                                                    minHeight: "100%"
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        backgroundColor: this.state.modal_data["status"] === "New" ?
                                                            "rgba(244, 63, 94, 0.25)" : "rgba(113, 113, 122, 0.25)",
                                                        borderColor: this.state.modal_data["status"] === "New" ?
                                                            "rgba(244, 63, 94, 100)" : "transparent",
                                                        borderRadius: "2px",
                                                        borderWidth: "2px",
                                                        borderStyle: "solid",
                                                        width: "100%",
                                                        height: "100%",
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        alignItems: "center",
                                                        justifyContent: "center",
                                                        padding: "8px 16px",
                                                        boxSizing: "border-box",
                                                    }}
                                                >
                                                    <div
                                                        style={{
                                                            position: "relative",
                                                            color: this.state.modal_data["status"] === "New" ?
                                                                "rgba(244, 63, 94, 100)" : "white",
                                                        }}
                                                    >
                                                        New
                                                    </div>
                                                </div>
                                            </button>
                                            <button
                                                className="menu-entry"
                                                onClick={this.update_detection}
                                                id="1"
                                                style={{
                                                    width: "132px",
                                                    minHeight: "100%"
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        backgroundColor: this.state.modal_data["status"] === "Pending" ?
                                                            "rgba(245, 158, 11, 0.25)" : "rgba(113, 113, 122, 0.25)",
                                                        borderColor: this.state.modal_data["status"] === "Pending" ?
                                                            "rgba(245, 158, 11, 100)" : "transparent",
                                                        borderRadius: "2px",
                                                        borderWidth: "2px",
                                                        borderStyle: "solid",
                                                        width: "100%",
                                                        height: "100%",
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        alignItems: "center",
                                                        justifyContent: "center",
                                                        padding: "8px 16px",
                                                        boxSizing: "border-box",
                                                    }}
                                                >
                                                    <div
                                                        style={{
                                                            position: "relative",
                                                            color: this.state.modal_data["status"] === "Pending" ?
                                                                "rgba(245, 158, 11, 100)" : "white",
                                                        }}
                                                    >
                                                        Live IR
                                                    </div>
                                                </div>
                                            </button>

                                            <button
                                                className="menu-entry"
                                                onClick={this.update_detection}
                                                id="2"
                                                style={{
                                                    width: "132px",
                                                    minHeight: "100%",
                                                    marginLeft: "auto"
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        backgroundColor: this.state.modal_data["status"] === "Closed" ?
                                                            "rgba(6, 182, 212, 0.25)" : "rgba(113, 113, 122, 0.25)",
                                                        borderColor: this.state.modal_data["status"] === "Closed" ?
                                                            "rgba(6, 182, 212, 100)" : "transparent",
                                                        borderRadius: "2px",
                                                        borderWidth: "2px",
                                                        borderStyle: "solid",
                                                        width: "100%",
                                                        height: "100%",
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        alignItems: "center",
                                                        justifyContent: "center",
                                                        padding: "8px 16px",
                                                        boxSizing: "border-box",
                                                    }}
                                                >
                                                    <div
                                                        style={{
                                                            position: "relative",
                                                            color: this.state.modal_data["status"] === "Closed" ?
                                                                "rgba(6, 182, 212, 100)" : "white",
                                                        }}
                                                    >
                                                        Closed
                                                    </div>
                                                </div>
                                            </button>

                                        </div>


                                    </div>


                                    <div style={{
                                        width: "calc(50% - 8px)",
                                        height: "100%",
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "flex-start",
                                        color: "white",
                                        gap: "16px"
                                    }}
                                    >
                                        <div style={{
                                            width: "100%",
                                            display: "flex",
                                            flexDirection: "row",
                                            justifyContent: "flex-start",
                                            lineHeight: "28px",
                                            fontWeight: "600",
                                        }}
                                        >
                                            <div style={{color: "#F9B233"}}>
                                                Action History
                                            </div>
                                            <button
                                                style={{
                                                    cursor: "pointer",
                                                    border: "none",
                                                    backgroundColor: "transparent",
                                                    position: "relative",
                                                    width: "24px",
                                                    height: "24px",
                                                    marginLeft: "auto"
                                                }}
                                                id="close-modal"
                                                onClick={this.CloseModal}
                                            >
                                                <img
                                                    alt=""
                                                    src="/main/white-close.svg"
                                                />
                                            </button>
                                        </div>


                                        <div className="inner-tip-box" style={{
                                            height: "100%",
                                            gap: "16px"
                                        }}>
                                            <div style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                gap: "4px",
                                                lineHeight: "28px",
                                                fontWeight: "600",
                                                alignItems: "center",
                                                height: "24px",
                                            }}
                                            >
                                                <img
                                                    style={{width: "24px", height: "24px"}}
                                                    alt=""
                                                    src="/main/tiphistory.svg"
                                                />
                                                <div style={{
                                                    color: "#a1daf8"
                                                }}>Action History Log
                                                </div>


                                                <button
                                                    style={{
                                                        cursor: "pointer",
                                                        borderRadius: "2px",
                                                        border: "1px solid #06B6D4",
                                                        backgroundColor: "transparent",
                                                        color: "#06B6D4",
                                                        position: "relative",
                                                        marginLeft: "auto"
                                                    }}
                                                    id="Contact"
                                                    onClick={this.props.open_client_playbook}
                                                >
                                                    IR Contact
                                                </button>

                                                <button
                                                    style={{
                                                        cursor: "pointer",
                                                        borderRadius: "2px",
                                                        border: "1px solid #06B6D4",
                                                        backgroundColor: "transparent",
                                                        color: "#06B6D4",
                                                        position: "relative"
                                                    }}
                                                    id="playbook"
                                                    onClick={() => this.setState({
                                                        showPlaybook: true,
                                                        playbook: this.props.get_playbook_data(this.state.modal_data["priority"])
                                                    })}
                                                >
                                                    Playbook
                                                </button>

                                                <button
                                                    style={{
                                                        cursor: "pointer",
                                                        borderRadius: "2px",
                                                        border: "none",
                                                        backgroundColor: "rgba(6,182,212,0.25)",
                                                        color: "#06B6D4",
                                                        position: "relative",
                                                    }}
                                                    id="new-update"
                                                    onClick={() => this.setState({show_log_input: true})}
                                                >
                                                    New Update
                                                </button>
                                            </div>


                                            <div className="scroll-bar-div" style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                padding: "0px 0px 16px 8px",
                                                gap: "8px",
                                                overflowY: "scroll",
                                                overflowX: "hidden",
                                                width: "100%",
                                                height: "472px"
                                            }}
                                            >

                                                {this.state.show_log_input ?
                                                    <div
                                                        style={{
                                                            display: "flex",
                                                            flexDirection: "column",
                                                            marginTop: "16px",
                                                            width: "100%",
                                                            height: "230px",
                                                            marginRight: "8px",
                                                        }}
                                                    >
                                                        <MDEditor
                                                            value={this.state.log_input_text}
                                                            visibleDragbar={false}
                                                            hideToolbar={true}
                                                            preview="edit"
                                                            extraCommands={[]}
                                                            onChange={(data) => {
                                                                this.setState({log_input_text: data})
                                                            }}
                                                            height={200}
                                                        />
                                                        <div style={{marginLeft: "auto", marginTop: "8px"}}>
                                                            <button onClick={() => {
                                                                this.update_detection("", this.state.log_input_text)
                                                                this.setState({
                                                                    show_log_input: false,
                                                                    log_input_text: ""
                                                                })
                                                            }}
                                                                    style={{
                                                                        cursor: "pointer",
                                                                        borderRadius: "2px",
                                                                        background: "transparent",
                                                                        border: "solid 1px " + (this.state.log_input_text === "" ? "#737373" : "#06B6D4"),
                                                                        color: this.state.log_input_text === "" ? "#737373" : "#06B6D4",
                                                                        marginRight: "8px"
                                                                    }}
                                                            >
                                                                <ISubmit style={{
                                                                    color: this.state.log_input_text === "" ? "#737373" : "#06B6D4",
                                                                }}/>
                                                                Submit
                                                            </button>
                                                            <button onClick={() => {
                                                                this.setState({
                                                                    show_log_input: false,
                                                                    log_input_text: ""
                                                                })
                                                            }}
                                                                    style={{
                                                                        cursor: "pointer",
                                                                        borderRadius: "2px",
                                                                        background: "transparent",
                                                                        border: "solid 1px #06B6D4",
                                                                        color: "#06B6D4",
                                                                        marginRight: "8px"
                                                                    }}
                                                            >
                                                                Cancel
                                                            </button>
                                                        </div>
                                                    </div>
                                                    : ""
                                                }

                                                {
                                                    this.state.modal_data["log"] ?

                                                        this.state.modal_data["log"].slice(0).reverse().map((item, i) =>
                                                            <div style={{
                                                                display: "flex",
                                                                flexDirection: "column",
                                                                marginRight: "8px",
                                                                gap: "8px"
                                                            }}
                                                                 id="history-log-entry"
                                                            >
                                                                <div style={{
                                                                    display: "flex",
                                                                    flexDirection: "row",
                                                                    gap: "8px"
                                                                }}
                                                                >
                                                                    <img src="/main/tiphistoryclock-i.svg"
                                                                         alt="tip-clock"/>
                                                                    <div style={{color: "#737373"}}>
                                                                        {get_time_string(item["time"])}
                                                                    </div>
                                                                </div>
                                                                <div style={{
                                                                    display: "flex",
                                                                    flexDirection: "row",
                                                                    marginLeft: "8px",
                                                                    gap: "8px"
                                                                }}
                                                                >
                                                                    <div style={{
                                                                        background: "#71717a",
                                                                        alignSelf: "stretch",
                                                                        flexShrink: "0",
                                                                        width: "1px"
                                                                    }}/>
                                                                    <MDEditor.Markdown source={item["text"]}
                                                                                       style={{
                                                                                           padding: "16px 16px 16px 16px",
                                                                                           color: "#A1A1AA",
                                                                                           background: "#18181B",
                                                                                           borderRadius: "8px",
                                                                                           whiteSpace: 'pre-wrap',
                                                                                           width: "100%",
                                                                                           height: "100%",
                                                                                           maxHeight: "100%",
                                                                                           overflow: "auto"
                                                                                       }}
                                                                    />
                                                                </div>

                                                            </div>
                                                        )
                                                        :
                                                        ""
                                                }

                                                <div style={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    marginRight: "8px",
                                                    gap: "8px"
                                                }}
                                                     id="history-log-entry"
                                                >
                                                    <div style={{
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        gap: "8px"
                                                    }}
                                                    >
                                                        <img src="/main/tiphistoryclock-i.svg" alt="tip-clock"/>
                                                        <div style={{color: "#737373"}}>
                                                            {get_time_string(this.state.modal_data["detected"])}
                                                        </div>
                                                    </div>
                                                    <div style={{
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        marginLeft: "8px",
                                                        gap: "8px"
                                                    }}
                                                    >
                                                        <div style={{
                                                            background: "#71717a",
                                                            alignSelf: "stretch",
                                                            flexShrink: "0",
                                                            width: "1px"
                                                        }}/>
                                                        <MDEditor.Markdown
                                                            source={"New " + this.state.modal_data["priority"] + " Detection Created"}
                                                            style={{
                                                                padding: "16px 16px 16px 16px",
                                                                color: "#A1A1AA",
                                                                background: "#18181B",
                                                                borderRadius: "8px",
                                                                whiteSpace: 'pre-wrap',
                                                                width: "100%",
                                                                height: "100%",
                                                                maxHeight: "100%",
                                                                overflow: "auto"
                                                            }}
                                                        />
                                                    </div>

                                                </div>

                                            </div>
                                        </div>


                                    </div>

                                </div>
                                :
                                this.state.modal_data["priority"].charAt(0) === "S" ?
                                    <div style={{
                                        width: "100%",
                                        height: "100%",
                                        display: "flex",
                                        flexDirection: "row",
                                        gap: "16px"
                                    }}
                                    >
                                        <div style={{
                                            width: "calc(50% - 8px)",
                                            height: "100%",
                                            display: "flex",
                                            flexDirection: "column",
                                            justifyContent: "flex-start",
                                            color: "white",
                                            gap: "16px"
                                        }}
                                        >
                                            <div style={{
                                                width: "100%",
                                                display: "flex",
                                                flexDirection: "row",
                                                justifyContent: "flex-start",
                                                lineHeight: "28px",
                                                fontWeight: "600",
                                            }}>
                                                <div style={{color: "#F9B233"}}>
                                                    Supply Chain Risk Tipper
                                                </div>
                                            </div>

                                            <div className="inner-tip-box"
                                                 style={{
                                                     height: "152px"
                                                 }}
                                            >
                                                <div style={{
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    gap: "4px",
                                                    lineHeight: "28px",
                                                    fontWeight: "600",
                                                    alignItems: "center",
                                                    height: "24px",
                                                }}>
                                                    <img
                                                        style={{width: "24px", height: "24px"}}
                                                        alt="host-icon"
                                                        src="/main/tiphost.svg"
                                                    />
                                                    <div style={{
                                                        color: "#a1daf8"
                                                    }}>Host Information
                                                    </div>
                                                </div>

                                                <div style={{
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    marginTop: "4px",
                                                    height: "calc(100%-24px)"
                                                }}
                                                     id="host-data">
                                                    <div style={{
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        color: "#A1A1AA",
                                                        gap: "4px",
                                                        width: "138px"
                                                    }} id="section1">
                                                        <div>Client IP:</div>
                                                        <div>Hostname:</div>
                                                        <div>Host Severity:</div>
                                                        <div>Netflow:</div>
                                                    </div>

                                                    <div style={{
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        gap: "4px"
                                                    }} id="section2">
                                                        <div>{this.state.modal_data["src_ip"]}</div>
                                                        <div>{this.state.modal_data["host"]}</div>
                                                        <div>{this.state.modal_data["h_severity"]}</div>
                                                        <div style={{
                                                            display: "flex",
                                                            gap: 8
                                                        }}>
                                                            <button
                                                                style={{
                                                                    cursor: "pointer",
                                                                    border: "none",
                                                                    backgroundColor: "transparent",
                                                                    justifyContent: "center",
                                                                    display: "flex",
                                                                    width: "21px",
                                                                    height: "20px"
                                                                }}
                                                                onClick={() => this.props.open_netflow(this.state.modal_data)}
                                                                id="netflow"
                                                            >
                                                                <img src="/main/tipnet.svg" alt="netflow"/>
                                                            </button>
                                                            Forensic Data Analysis
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="inner-tip-box"
                                                 style={{
                                                     height: "268px"
                                                 }}
                                            >
                                                <div style={{
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    gap: "4px",
                                                    lineHeight: "28px",
                                                    fontWeight: "600",
                                                    alignItems: "center",
                                                    height: "24px",
                                                }}>
                                                    <img
                                                        style={{width: "24px", height: "24px"}}
                                                        alt=""
                                                        src="/main/breach-details-i.svg"
                                                    />
                                                    <div style={{
                                                        color: "#a1daf8"
                                                    }}>Breach Details
                                                    </div>
                                                </div>
                                                <div style={{
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    marginTop: "4px",
                                                    height: "calc(100%-24px)"
                                                }}
                                                     id="group-data">
                                                    <div style={{
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        color: "#A1A1AA",
                                                        gap: "4px",
                                                        width: "138px"
                                                    }} id="section1">
                                                        <div>Supplier Domain:</div>
                                                        <div>Contacted IP:</div>
                                                        <div>Country:</div>
                                                        <div>Breach Date:</div>
                                                        <div>Breach Type:</div>
                                                        <div>Threat Group:</div>
                                                        <div>Source:</div>
                                                        <div>Severity:</div>
                                                    </div>

                                                    <div style={{
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        gap: "4px"
                                                    }} id="section2">
                                                        <div>{this.state.modal_data["domain"]}</div>
                                                        <div>{this.state.modal_data["dest_ip"]}</div>
                                                        {this.getBreachCountryRender()}
                                                        {this.getBreachDate()}
                                                        <div>{this.state.modal_data["breach_type"]}</div>
                                                        <div>{this.state.modal_data["group"] ? this.state.modal_data["group"] : "N/A"}</div>
                                                        {this.getBreachURL()}
                                                        <div>{this.state.modal_data["severity"]}</div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div
                                                style={{
                                                    display: "flex",
                                                    gap: "12px",
                                                    width: "100%",
                                                    height: "40px",
                                                    fontSize: "13px",
                                                    letterSpacing: "0.01em",
                                                    lineHeight: "20px",
                                                    fontFamily: "Inter",
                                                    marginTop: "auto"
                                                }}
                                            >
                                                <button
                                                    className="menu-entry"
                                                    onClick={this.update_detection}
                                                    id="0"
                                                    style={{
                                                        width: "132px",
                                                        minHeight: "100%"
                                                    }}
                                                >
                                                    <div
                                                        style={{
                                                            backgroundColor: this.state.modal_data["status"] === "New" ?
                                                                "rgba(244, 63, 94, 0.25)" : "rgba(113, 113, 122, 0.25)",
                                                            borderColor: this.state.modal_data["status"] === "New" ?
                                                                "rgba(244, 63, 94, 100)" : "transparent",
                                                            borderRadius: "2px",
                                                            borderWidth: "2px",
                                                            borderStyle: "solid",
                                                            width: "100%",
                                                            height: "100%",
                                                            display: "flex",
                                                            flexDirection: "row",
                                                            alignItems: "center",
                                                            justifyContent: "center",
                                                            padding: "8px 16px",
                                                            boxSizing: "border-box",
                                                        }}
                                                    >
                                                        <div
                                                            style={{
                                                                position: "relative",
                                                                color: this.state.modal_data["status"] === "New" ?
                                                                    "rgba(244, 63, 94, 100)" : "white",
                                                            }}
                                                        >
                                                            New
                                                        </div>
                                                    </div>
                                                </button>
                                                <button
                                                    className="menu-entry"
                                                    onClick={this.update_detection}
                                                    id="1"
                                                    style={{
                                                        width: "132px",
                                                        minHeight: "100%"
                                                    }}
                                                >
                                                    <div
                                                        style={{
                                                            backgroundColor: this.state.modal_data["status"] === "Pending" ?
                                                                "rgba(245, 158, 11, 0.25)" : "rgba(113, 113, 122, 0.25)",
                                                            borderColor: this.state.modal_data["status"] === "Pending" ?
                                                                "rgba(245, 158, 11, 100)" : "transparent",
                                                            borderRadius: "2px",
                                                            borderWidth: "2px",
                                                            borderStyle: "solid",
                                                            width: "100%",
                                                            height: "100%",
                                                            display: "flex",
                                                            flexDirection: "row",
                                                            alignItems: "center",
                                                            justifyContent: "center",
                                                            padding: "8px 16px",
                                                            boxSizing: "border-box",
                                                        }}
                                                    >
                                                        <div
                                                            style={{
                                                                position: "relative",
                                                                color: this.state.modal_data["status"] === "Pending" ?
                                                                    "rgba(245, 158, 11, 100)" : "white",
                                                            }}
                                                        >
                                                            Live IR
                                                        </div>
                                                    </div>
                                                </button>

                                                <button
                                                    className="menu-entry"
                                                    onClick={this.update_detection}
                                                    id="2"
                                                    style={{
                                                        width: "132px",
                                                        minHeight: "100%",
                                                        marginLeft: "auto"
                                                    }}
                                                >
                                                    <div
                                                        style={{
                                                            backgroundColor: this.state.modal_data["status"] === "Closed" ?
                                                                "rgba(6, 182, 212, 0.25)" : "rgba(113, 113, 122, 0.25)",
                                                            borderColor: this.state.modal_data["status"] === "Closed" ?
                                                                "rgba(6, 182, 212, 100)" : "transparent",
                                                            borderRadius: "2px",
                                                            borderWidth: "2px",
                                                            borderStyle: "solid",
                                                            width: "100%",
                                                            height: "100%",
                                                            display: "flex",
                                                            flexDirection: "row",
                                                            alignItems: "center",
                                                            justifyContent: "center",
                                                            padding: "8px 16px",
                                                            boxSizing: "border-box",
                                                        }}
                                                    >
                                                        <div
                                                            style={{
                                                                position: "relative",
                                                                color: this.state.modal_data["status"] === "Closed" ?
                                                                    "rgba(6, 182, 212, 100)" : "white",
                                                            }}
                                                        >
                                                            Closed
                                                        </div>
                                                    </div>
                                                </button>

                                            </div>


                                        </div>


                                        <div style={{
                                            width: "calc(50% - 8px)",
                                            height: "100%",
                                            display: "flex",
                                            flexDirection: "column",
                                            justifyContent: "flex-start",
                                            color: "white",
                                            gap: "16px"
                                        }}
                                        >
                                            <div style={{
                                                width: "100%",
                                                display: "flex",
                                                flexDirection: "row",
                                                justifyContent: "flex-start",
                                                lineHeight: "28px",
                                                fontWeight: "600",
                                            }}
                                            >
                                                <div style={{color: "#F9B233"}}>
                                                    Action History
                                                </div>
                                                <button
                                                    style={{
                                                        cursor: "pointer",
                                                        border: "none",
                                                        backgroundColor: "transparent",
                                                        position: "relative",
                                                        width: "24px",
                                                        height: "24px",
                                                        marginLeft: "auto"
                                                    }}
                                                    id="close-modal"
                                                    onClick={this.CloseModal}
                                                >
                                                    <img
                                                        alt=""
                                                        src="/main/white-close.svg"
                                                    />
                                                </button>
                                            </div>


                                            <div className="inner-tip-box" style={{
                                                height: "100%",
                                                gap: "16px"
                                            }}>
                                                <div style={{
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    gap: "4px",
                                                    lineHeight: "28px",
                                                    fontWeight: "600",
                                                    alignItems: "center",
                                                    height: "24px",
                                                }}
                                                >
                                                    <img
                                                        style={{width: "24px", height: "24px"}}
                                                        alt=""
                                                        src="/main/tiphistory.svg"
                                                    />
                                                    <div style={{
                                                        color: "#a1daf8"
                                                    }}>Action History Log
                                                    </div>


                                                    <button
                                                        style={{
                                                            cursor: "pointer",
                                                            borderRadius: "2px",
                                                            border: "1px solid #06B6D4",
                                                            backgroundColor: "transparent",
                                                            color: "#06B6D4",
                                                            position: "relative",
                                                            marginLeft: "auto"
                                                        }}
                                                        id="Contact"
                                                        onClick={this.props.open_client_playbook}
                                                    >
                                                        IR Contact
                                                    </button>

                                                    <button
                                                        style={{
                                                            cursor: "pointer",
                                                            borderRadius: "2px",
                                                            border: "1px solid #06B6D4",
                                                            backgroundColor: "transparent",
                                                            color: "#06B6D4",
                                                            position: "relative"
                                                        }}
                                                        id="playbook"
                                                        onClick={() => this.setState({
                                                            showPlaybook: true,
                                                            playbook: this.props.get_playbook_data(this.state.modal_data["priority"])
                                                        })}
                                                    >
                                                        Playbook
                                                    </button>

                                                    <button
                                                        style={{
                                                            cursor: "pointer",
                                                            borderRadius: "2px",
                                                            border: "none",
                                                            backgroundColor: "rgba(6,182,212,0.25)",
                                                            color: "#06B6D4",
                                                            position: "relative",
                                                        }}
                                                        id="new-update"
                                                        onClick={() => this.setState({show_log_input: true})}
                                                    >
                                                        New Update
                                                    </button>
                                                </div>


                                                <div className="scroll-bar-div" style={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    padding: "0px 0px 16px 8px",
                                                    gap: "8px",
                                                    overflowY: "scroll",
                                                    overflowX: "hidden",
                                                    width: "100%",
                                                    height: "472px"
                                                }}
                                                >

                                                    {this.state.show_log_input ?
                                                        <div
                                                            style={{
                                                                display: "flex",
                                                                flexDirection: "column",
                                                                marginTop: "16px",
                                                                width: "100%",
                                                                height: "230px",
                                                                marginRight: "8px",
                                                            }}
                                                        >
                                                            <MDEditor
                                                                value={this.state.log_input_text}
                                                                visibleDragbar={false}
                                                                hideToolbar={true}
                                                                preview="edit"
                                                                extraCommands={[]}
                                                                onChange={(data) => {
                                                                    this.setState({log_input_text: data})
                                                                }}
                                                                height={200}
                                                            />
                                                            <div style={{marginLeft: "auto", marginTop: "8px"}}>
                                                                <button onClick={() => {
                                                                    this.update_detection("", this.state.log_input_text)
                                                                    this.setState({
                                                                        show_log_input: false,
                                                                        log_input_text: ""
                                                                    })
                                                                }}
                                                                        style={{
                                                                            cursor: "pointer",
                                                                            borderRadius: "2px",
                                                                            background: "transparent",
                                                                            border: "solid 1px " + (this.state.log_input_text === "" ? "#737373" : "#06B6D4"),
                                                                            color: this.state.log_input_text === "" ? "#737373" : "#06B6D4",
                                                                            marginRight: "8px"
                                                                        }}
                                                                >
                                                                    <ISubmit style={{
                                                                        color: this.state.log_input_text === "" ? "#737373" : "#06B6D4",
                                                                    }}/>
                                                                    Submit
                                                                </button>
                                                                <button onClick={() => {
                                                                    this.setState({
                                                                        show_log_input: false,
                                                                        log_input_text: ""
                                                                    })
                                                                }}
                                                                        style={{
                                                                            cursor: "pointer",
                                                                            borderRadius: "2px",
                                                                            background: "transparent",
                                                                            border: "solid 1px #06B6D4",
                                                                            color: "#06B6D4",
                                                                            marginRight: "8px"
                                                                        }}
                                                                >
                                                                    Cancel
                                                                </button>
                                                            </div>
                                                        </div>
                                                        : ""
                                                    }

                                                    {
                                                        this.state.modal_data["log"] ?

                                                            this.state.modal_data["log"].slice(0).reverse().map((item, i) =>
                                                                <div style={{
                                                                    display: "flex",
                                                                    flexDirection: "column",
                                                                    marginRight: "8px",
                                                                    gap: "8px"
                                                                }}
                                                                     id="history-log-entry"
                                                                >
                                                                    <div style={{
                                                                        display: "flex",
                                                                        flexDirection: "row",
                                                                        gap: "8px"
                                                                    }}
                                                                    >
                                                                        <img src="/main/tiphistoryclock-i.svg"
                                                                             alt="tip-clock"/>
                                                                        <div style={{color: "#737373"}}>
                                                                            {get_time_string(item["time"])}
                                                                        </div>
                                                                    </div>
                                                                    <div style={{
                                                                        display: "flex",
                                                                        flexDirection: "row",
                                                                        marginLeft: "8px",
                                                                        gap: "8px"
                                                                    }}
                                                                    >
                                                                        <div style={{
                                                                            background: "#71717a",
                                                                            alignSelf: "stretch",
                                                                            flexShrink: "0",
                                                                            width: "1px"
                                                                        }}/>
                                                                        <MDEditor.Markdown source={item["text"]}
                                                                                           style={{
                                                                                               padding: "16px 16px 16px 16px",
                                                                                               color: "#A1A1AA",
                                                                                               background: "#18181B",
                                                                                               borderRadius: "8px",
                                                                                               whiteSpace: 'pre-wrap',
                                                                                               width: "100%",
                                                                                               height: "100%",
                                                                                               maxHeight: "100%",
                                                                                               overflow: "auto"
                                                                                           }}
                                                                        />
                                                                    </div>

                                                                </div>
                                                            )
                                                            :
                                                            ""
                                                    }

                                                    <div style={{
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        marginRight: "8px",
                                                        gap: "8px"
                                                    }}
                                                         id="history-log-entry"
                                                    >
                                                        <div style={{
                                                            display: "flex",
                                                            flexDirection: "row",
                                                            gap: "8px"
                                                        }}
                                                        >
                                                            <img src="/main/tiphistoryclock-i.svg" alt="tip-clock"/>
                                                            <div style={{color: "#737373"}}>
                                                                {get_time_string(this.state.modal_data["detected"])}
                                                            </div>
                                                        </div>
                                                        <div style={{
                                                            display: "flex",
                                                            flexDirection: "row",
                                                            marginLeft: "8px",
                                                            gap: "8px"
                                                        }}
                                                        >
                                                            <div style={{
                                                                background: "#71717a",
                                                                alignSelf: "stretch",
                                                                flexShrink: "0",
                                                                width: "1px"
                                                            }}/>
                                                            <MDEditor.Markdown
                                                                source={"New " + this.state.modal_data["priority"] + " Detection Created"}
                                                                style={{
                                                                    padding: "16px 16px 16px 16px",
                                                                    color: "#A1A1AA",
                                                                    background: "#18181B",
                                                                    borderRadius: "8px",
                                                                    whiteSpace: 'pre-wrap',
                                                                    width: "100%",
                                                                    height: "100%",
                                                                    maxHeight: "100%",
                                                                    overflow: "auto"
                                                                }}
                                                            />
                                                        </div>

                                                    </div>

                                                </div>
                                            </div>


                                        </div>

                                    </div>
                                    :
                                    this.state.modal_data["priority"].charAt(0) === "C" ?
                                        <div style={{
                                            width: "100%",
                                            height: "100%",
                                            display: "flex",
                                            flexDirection: "row",
                                            gap: "16px"
                                        }}
                                        >
                                            <div style={{
                                                width: "calc(50% - 8px)",
                                                height: "100%",
                                                display: "flex",
                                                flexDirection: "column",
                                                justifyContent: "flex-start",
                                                color: "white",
                                                gap: "16px"
                                            }}
                                            >
                                                <div style={{
                                                    width: "100%",
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    justifyContent: "flex-start",
                                                    lineHeight: "28px",
                                                    fontWeight: "600",
                                                }}>
                                                    <div style={{color: "#F9B233"}}>
                                                        ShadowBreach Tipper
                                                    </div>
                                                </div>

                                                <div className="inner-tip-box"
                                                     style={{
                                                         height: "510px",
                                                     }}>
                                                    <div style={{
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        gap: "4px",
                                                        lineHeight: "28px",
                                                        fontWeight: "600",
                                                        alignItems: "center",
                                                    }}
                                                    >
                                                        <img
                                                            style={{width: "24px", height: "24px"}}
                                                            alt=""
                                                            src="/main/tiphistory.svg"
                                                        />
                                                        <div style={{
                                                            color: "#a1daf8"
                                                        }}>Compromised Credentials Log
                                                        </div>
                                                    </div>

                                                    <div className="scroll-bar-div" style={{
                                                        marginTop: "12px",
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        padding: "0px 0px 16px 8px",
                                                        gap: "8px",
                                                        overflowY: "scroll",
                                                        overflowX: "hidden",
                                                        width: "100%",
                                                        height: "472px"
                                                    }}
                                                    >
                                                        {
                                                            this.state.modal_data["Credentials"].map((item, i) =>
                                                                <div style={{
                                                                    display: "flex",
                                                                    flexDirection: "column",
                                                                    marginRight: "8px",
                                                                    gap: "8px"
                                                                }}
                                                                     id="credentials-log-entry"
                                                                >
                                                                    <div style={{
                                                                        display: "flex",
                                                                        flexDirection: "row",
                                                                        gap: "8px"
                                                                    }}
                                                                    >
                                                                        <img src="/main/tipcred-i.svg" alt="tip-cred"/>
                                                                        <div style={{
                                                                            width: "100%",
                                                                            alignItems: "center",
                                                                            display: "flex",
                                                                            gap: "16px",
                                                                        }}>
                                                                            <div>Credential
                                                                                #{(i + 1).toString().padStart(4, '0')}</div>
                                                                            <div style={{
                                                                                marginLeft: "auto",
                                                                                display: "flex",
                                                                                gap: "8px"
                                                                            }}
                                                                            >
                                                                                <MuiTooltip
                                                                                    title={this.props.tooltip_info("ILogin")}
                                                                                    arrow>
                                                                                    <ILogin style={{
                                                                                        color: item["CredentialType"].includes("Login") ? this.getCredColors(item) : "#71717A",
                                                                                    }}/>
                                                                                </MuiTooltip>
                                                                                <MuiTooltip
                                                                                    title={this.props.tooltip_info("IPass")}
                                                                                    arrow>
                                                                                    <IPass style={{
                                                                                        color: item["CredentialType"].includes("Password") ? this.getCredColors(item) : "#71717A"
                                                                                    }}/>
                                                                                </MuiTooltip>
                                                                                <MuiTooltip
                                                                                    title={this.props.tooltip_info("ICookie")}
                                                                                    arrow>
                                                                                    <ICookie style={{
                                                                                        color: item["CredentialType"].includes("Cookie") ? this.getCredColors(item) : "#71717A"
                                                                                    }}/>
                                                                                </MuiTooltip>
                                                                            </div>
                                                                            <div style={{
                                                                                fontWeight: "bold"
                                                                            }}>{item["Price"]}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div style={{
                                                                        display: "flex",
                                                                        flexDirection: "row",
                                                                        marginLeft: "8px",
                                                                        gap: "8px"
                                                                    }}
                                                                    >
                                                                        <div style={{
                                                                            background: "#71717a",
                                                                            alignSelf: "stretch",
                                                                            flexShrink: "0",
                                                                            width: "1px"
                                                                        }}/>
                                                                        <div style={{
                                                                            borderRadius: "8px",
                                                                            padding: "16px 16px 16px 16px",
                                                                            whiteSpace: 'pre-wrap',
                                                                            background: "#18181B",
                                                                            width: "100%"
                                                                        }}>
                                                                            <div style={{
                                                                                display: "flex",
                                                                                flexDirection: "row",
                                                                            }}
                                                                                 id="malware-data">
                                                                                <div style={{
                                                                                    display: "flex",
                                                                                    flexDirection: "column",
                                                                                    color: "#A1A1AA",
                                                                                    gap: "4px",
                                                                                    width: "108px"
                                                                                }} id="section1">
                                                                                    <div>Auth ID:</div>
                                                                                    <div>&nbsp;</div>
                                                                                    <div>Date for Sale:</div>
                                                                                    <div>Domain:</div>
                                                                                    <div>ISP:</div>
                                                                                    <div>Malware:</div>
                                                                                    <div>Vendor:</div>
                                                                                    <div>Device Type:</div>
                                                                                </div>

                                                                                <div style={{
                                                                                    display: "flex",
                                                                                    flexDirection: "column",
                                                                                    gap: "4px"
                                                                                }} id="section2">
                                                                                    <div style={{
                                                                                        display: "flex",
                                                                                        gap: 4
                                                                                    }}>
                                                                                        <button
                                                                                            onClick={() => {
                                                                                                navigator.clipboard.writeText(item["Id"]).then({})
                                                                                            }}
                                                                                            style={{
                                                                                                backgroundColor: "transparent",
                                                                                                display: "flex",
                                                                                                alignItems: "center",
                                                                                                justifyContent: "center",
                                                                                                cursor: "pointer",
                                                                                                width: 24,
                                                                                                height: 24,
                                                                                                border: "none",
                                                                                            }}
                                                                                        >
                                                                                            <img
                                                                                                src="/main/tipcopy-i.svg"
                                                                                                alt="copy"/>
                                                                                        </button>
                                                                                        <div style={{
                                                                                            paddingLeft: 4,
                                                                                            paddingRight: 4,
                                                                                            height: 24,
                                                                                            background: '#27272A',
                                                                                            borderRadius: 4,
                                                                                            alignItems: "center",
                                                                                            justifyContent: "center",
                                                                                            display: 'inline-flex'
                                                                                        }}
                                                                                        >
                                                                                            <div style={{
                                                                                                color: '#F59E0B',
                                                                                                fontSize: 14,
                                                                                                fontWeight: '600',
                                                                                                wordWrap: 'break-word'
                                                                                            }}>
                                                                                                {item["Id"]}
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div>&nbsp;</div>
                                                                                    <div>{item["DateForSale"]}</div>
                                                                                    <div>{item["Domain"]}</div>
                                                                                    <div>{item["ISP"]}</div>
                                                                                    <div>{item["Malware"]}</div>
                                                                                    <div>{item["CriminalVendor"]}</div>
                                                                                    <div>{item["DeviceType"]}</div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )}

                                                    </div>
                                                </div>

                                                <div
                                                    style={{
                                                        display: "flex",
                                                        marginTop: "auto",
                                                        gap: "12px",
                                                        width: "100%",
                                                        height: "40px",
                                                        fontSize: "13px",
                                                        letterSpacing: "0.01em",
                                                        lineHeight: "20px",
                                                        fontFamily: "Inter",
                                                    }}
                                                >
                                                    <button
                                                        className="menu-entry"
                                                        onClick={this.update_detection}
                                                        id="0"
                                                        style={{
                                                            width: "132px",
                                                            minHeight: "100%"
                                                        }}
                                                    >
                                                        <div
                                                            style={{
                                                                backgroundColor: this.state.modal_data["status"] === "New" ?
                                                                    "rgba(244, 63, 94, 0.25)" : "rgba(113, 113, 122, 0.25)",
                                                                borderColor: this.state.modal_data["status"] === "New" ?
                                                                    "rgba(244, 63, 94, 100)" : "transparent",
                                                                borderRadius: "2px",
                                                                borderWidth: "2px",
                                                                borderStyle: "solid",
                                                                width: "100%",
                                                                height: "100%",
                                                                display: "flex",
                                                                flexDirection: "row",
                                                                alignItems: "center",
                                                                justifyContent: "center",
                                                                padding: "8px 16px",
                                                                boxSizing: "border-box",
                                                            }}
                                                        >
                                                            <div
                                                                style={{
                                                                    position: "relative",
                                                                    color: this.state.modal_data["status"] === "New" ?
                                                                        "rgba(244, 63, 94, 100)" : "white",
                                                                }}
                                                            >
                                                                New
                                                            </div>
                                                        </div>
                                                    </button>
                                                    <button
                                                        className="menu-entry"
                                                        onClick={this.update_detection}
                                                        id="1"
                                                        style={{
                                                            width: "132px",
                                                            minHeight: "100%"
                                                        }}
                                                    >
                                                        <div
                                                            style={{
                                                                backgroundColor: this.state.modal_data["status"] === "Pending" ?
                                                                    "rgba(245, 158, 11, 0.25)" : "rgba(113, 113, 122, 0.25)",
                                                                borderColor: this.state.modal_data["status"] === "Pending" ?
                                                                    "rgba(245, 158, 11, 100)" : "transparent",
                                                                borderRadius: "2px",
                                                                borderWidth: "2px",
                                                                borderStyle: "solid",
                                                                width: "100%",
                                                                height: "100%",
                                                                display: "flex",
                                                                flexDirection: "row",
                                                                alignItems: "center",
                                                                justifyContent: "center",
                                                                padding: "8px 16px",
                                                                boxSizing: "border-box",
                                                            }}
                                                        >
                                                            <div
                                                                style={{
                                                                    position: "relative",
                                                                    color: this.state.modal_data["status"] === "Pending" ?
                                                                        "rgba(245, 158, 11, 100)" : "white",
                                                                }}
                                                            >
                                                                Live IR
                                                            </div>
                                                        </div>
                                                    </button>
                                                    <button
                                                        className="menu-entry"
                                                        onClick={this.update_detection}
                                                        id="2"
                                                        style={{
                                                            width: "132px",
                                                            minHeight: "100%",
                                                            marginLeft: "auto"
                                                        }}
                                                    >
                                                        <div
                                                            style={{
                                                                backgroundColor: this.state.modal_data["status"] === "Closed" ?
                                                                    "rgba(6, 182, 212, 0.25)" : "rgba(113, 113, 122, 0.25)",
                                                                borderColor: this.state.modal_data["status"] === "Closed" ?
                                                                    "rgba(6, 182, 212, 100)" : "transparent",
                                                                borderRadius: "2px",
                                                                borderWidth: "2px",
                                                                borderStyle: "solid",
                                                                width: "100%",
                                                                height: "100%",
                                                                display: "flex",
                                                                flexDirection: "row",
                                                                alignItems: "center",
                                                                justifyContent: "center",
                                                                padding: "8px 16px",
                                                                boxSizing: "border-box",
                                                            }}
                                                        >
                                                            <div
                                                                style={{
                                                                    position: "relative",
                                                                    color: this.state.modal_data["status"] === "Closed" ?
                                                                        "rgba(6, 182, 212, 100)" : "white",
                                                                }}
                                                            >
                                                                Closed
                                                            </div>
                                                        </div>
                                                    </button>

                                                </div>


                                            </div>


                                            <div style={{
                                                width: "calc(50% - 8px)",
                                                height: "100%",
                                                display: "flex",
                                                flexDirection: "column",
                                                justifyContent: "flex-start",
                                                color: "white",
                                                gap: "16px"
                                            }}
                                            >
                                                <div style={{
                                                    width: "100%",
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    justifyContent: "flex-start",
                                                    lineHeight: "28px",
                                                    fontWeight: "600",
                                                }}
                                                >
                                                    <div style={{color: "#F9B233"}}>
                                                        Action History
                                                    </div>
                                                    <button
                                                        style={{
                                                            cursor: "pointer",
                                                            border: "none",
                                                            backgroundColor: "transparent",
                                                            position: "relative",
                                                            width: "24px",
                                                            height: "24px",
                                                            marginLeft: "auto"
                                                        }}
                                                        id="close-modal"
                                                        onClick={this.CloseModal}
                                                    >
                                                        <img
                                                            alt=""
                                                            src="/main/white-close.svg"
                                                        />
                                                    </button>
                                                </div>


                                                <div className="inner-tip-box" style={{
                                                    height: "100%",
                                                    gap: "16px"
                                                }}>
                                                    <div style={{
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        gap: "4px",
                                                        lineHeight: "28px",
                                                        fontWeight: "600",
                                                        alignItems: "center",
                                                        height: "24px",
                                                    }}
                                                    >
                                                        <img
                                                            style={{width: "24px", height: "24px"}}
                                                            alt=""
                                                            src="/main/tiphistory.svg"
                                                        />
                                                        <div style={{
                                                            color: "#a1daf8"
                                                        }}>Action History Log
                                                        </div>

                                                        <button
                                                            style={{
                                                                cursor: "pointer",
                                                                borderRadius: "2px",
                                                                border: "1px solid #06B6D4",
                                                                backgroundColor: "transparent",
                                                                color: "#06B6D4",
                                                                position: "relative",
                                                                marginLeft: "auto"
                                                            }}
                                                            id="Contact"
                                                            onClick={this.props.open_client_playbook}
                                                        >
                                                            IR Contact
                                                        </button>

                                                        <button
                                                            style={{
                                                                cursor: "pointer",
                                                                borderRadius: "2px",
                                                                border: "1px solid #06B6D4",
                                                                backgroundColor: "transparent",
                                                                color: "#06B6D4",
                                                                position: "relative"
                                                            }}
                                                            id="playbook"
                                                            onClick={() => this.setState({
                                                                showPlaybook: true,
                                                                playbook: this.props.get_playbook_data(this.state.modal_data["priority"])
                                                            })}
                                                        >
                                                            Playbook
                                                        </button>

                                                        <button
                                                            style={{
                                                                cursor: "pointer",
                                                                borderRadius: "2px",
                                                                border: "none",
                                                                backgroundColor: "rgba(6,182,212,0.25)",
                                                                color: "#06B6D4",
                                                                position: "relative",
                                                            }}
                                                            id="new-update"
                                                            onClick={() => this.setState({show_log_input: true})}
                                                        >
                                                            New Update
                                                        </button>
                                                    </div>


                                                    <div className="scroll-bar-div" style={{
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        padding: "0px 0px 16px 8px",
                                                        gap: "8px",
                                                        overflowY: "scroll",
                                                        overflowX: "hidden",
                                                        width: "100%",
                                                        height: "472px"
                                                    }}
                                                    >

                                                        {this.state.show_log_input ?
                                                            <div
                                                                style={{
                                                                    display: "flex",
                                                                    flexDirection: "column",
                                                                    marginTop: "16px",
                                                                    width: "100%",
                                                                    height: "230px",
                                                                    marginRight: "8px",
                                                                }}
                                                            >
                                                                <MDEditor
                                                                    value={this.state.log_input_text}
                                                                    visibleDragbar={false}
                                                                    hideToolbar={true}
                                                                    preview="edit"
                                                                    extraCommands={[]}
                                                                    onChange={(data) => {
                                                                        this.setState({log_input_text: data})
                                                                    }}
                                                                    height={200}
                                                                />
                                                                <div style={{marginLeft: "auto", marginTop: "8px"}}>
                                                                    <button onClick={() => {
                                                                        this.update_detection("", this.state.log_input_text)
                                                                        this.setState({
                                                                            show_log_input: false,
                                                                            log_input_text: ""
                                                                        })
                                                                    }}
                                                                            style={{
                                                                                cursor: "pointer",
                                                                                borderRadius: "2px",
                                                                                background: "transparent",
                                                                                border: "solid 1px " + (this.state.log_input_text === "" ? "#737373" : "#06B6D4"),
                                                                                color: this.state.log_input_text === "" ? "#737373" : "#06B6D4",
                                                                                marginRight: "8px"
                                                                            }}
                                                                    >
                                                                        <ISubmit style={{
                                                                            color: this.state.log_input_text === "" ? "#737373" : "#06B6D4",
                                                                        }}/>
                                                                        Submit
                                                                    </button>
                                                                    <button onClick={() => {
                                                                        this.setState({
                                                                            show_log_input: false,
                                                                            log_input_text: ""
                                                                        })
                                                                    }}
                                                                            style={{
                                                                                cursor: "pointer",
                                                                                borderRadius: "2px",
                                                                                background: "transparent",
                                                                                border: "solid 1px #06B6D4",
                                                                                color: "#06B6D4",
                                                                                marginRight: "8px"
                                                                            }}
                                                                    >
                                                                        Cancel
                                                                    </button>
                                                                </div>
                                                            </div>
                                                            : ""
                                                        }

                                                        {
                                                            this.state.modal_data["log"] ?

                                                                this.state.modal_data["log"].slice(0).reverse().map((item, i) =>
                                                                    <div style={{
                                                                        display: "flex",
                                                                        flexDirection: "column",
                                                                        marginRight: "8px",
                                                                        gap: "8px"
                                                                    }}
                                                                         id="history-log-entry"
                                                                    >
                                                                        <div style={{
                                                                            display: "flex",
                                                                            flexDirection: "row",
                                                                            gap: "8px"
                                                                        }}
                                                                        >
                                                                            <img src="/main/tiphistoryclock-i.svg"
                                                                                 alt="tip-clock"/>
                                                                            <div style={{color: "#737373"}}>
                                                                                {get_time_string(item["time"])}
                                                                            </div>
                                                                        </div>
                                                                        <div style={{
                                                                            display: "flex",
                                                                            flexDirection: "row",
                                                                            marginLeft: "8px",
                                                                            gap: "8px"
                                                                        }}
                                                                        >
                                                                            <div style={{
                                                                                background: "#71717a",
                                                                                alignSelf: "stretch",
                                                                                flexShrink: "0",
                                                                                width: "1px"
                                                                            }}/>
                                                                            <MDEditor.Markdown source={item["text"]}
                                                                                               style={{
                                                                                                   padding: "16px 16px 16px 16px",
                                                                                                   color: "#A1A1AA",
                                                                                                   background: "#18181B",
                                                                                                   borderRadius: "8px",
                                                                                                   whiteSpace: 'pre-wrap',
                                                                                                   width: "100%",
                                                                                                   height: "100%",
                                                                                                   maxHeight: "100%",
                                                                                                   overflow: "auto"
                                                                                               }}
                                                                            />
                                                                        </div>

                                                                    </div>
                                                                )
                                                                :
                                                                ""
                                                        }

                                                        <div style={{
                                                            display: "flex",
                                                            flexDirection: "column",
                                                            marginRight: "8px",
                                                            gap: "8px"
                                                        }}
                                                             id="history-log-entry"
                                                        >
                                                            <div style={{
                                                                display: "flex",
                                                                flexDirection: "row",
                                                                gap: "8px"
                                                            }}
                                                            >
                                                                <img src="/main/tiphistoryclock-i.svg" alt="tip-clock"/>
                                                                <div style={{color: "#737373"}}>
                                                                    {get_time_string(this.state.modal_data["created"])}
                                                                </div>
                                                            </div>
                                                            <div style={{
                                                                display: "flex",
                                                                flexDirection: "row",
                                                                marginLeft: "8px",
                                                                gap: "8px"
                                                            }}
                                                            >
                                                                <div style={{
                                                                    background: "#71717a",
                                                                    alignSelf: "stretch",
                                                                    flexShrink: "0",
                                                                    width: "1px"
                                                                }}/>
                                                                <MDEditor.Markdown
                                                                    source={"New " + this.state.modal_data["priority"] + " Detection Created"}
                                                                    style={{
                                                                        padding: "16px 16px 16px 16px",
                                                                        color: "#A1A1AA",
                                                                        background: "#18181B",
                                                                        borderRadius: "8px",
                                                                        whiteSpace: 'pre-wrap',
                                                                        width: "100%",
                                                                        height: "100%",
                                                                        maxHeight: "100%",
                                                                        overflow: "auto"
                                                                    }}
                                                                />
                                                            </div>

                                                        </div>

                                                    </div>
                                                </div>


                                            </div>

                                        </div>
                                        :
                                        <div style={{
                                            width: "100%",
                                            height: "100%",
                                            display: "flex",
                                            flexDirection: "row",
                                            gap: "16px"
                                        }}
                                        >
                                            <div style={{
                                                width: "calc(50% - 8px)",
                                                height: "100%",
                                                display: "flex",
                                                flexDirection: "column",
                                                justifyContent: "flex-start",
                                                color: "white",
                                                gap: "16px"
                                            }}
                                            >
                                                <div style={{
                                                    width: "100%",
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    justifyContent: "flex-start",
                                                    lineHeight: "28px",
                                                    fontWeight: "600",
                                                }}>
                                                    <div style={{color: "#F9B233"}}>
                                                        Tactical Threat Intelligence Tipper
                                                    </div>
                                                </div>

                                                <div className="inner-tip-box" style={{
                                                    height: 150
                                                }}>
                                                    <div style={{
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        gap: "4px",
                                                        lineHeight: "28px",
                                                        fontWeight: "600",
                                                        alignItems: "center",
                                                        height: "24px",
                                                    }}>
                                                        <img
                                                            style={{width: "24px", height: "24px"}}
                                                            alt=""
                                                            src="/main/tiphost.svg"
                                                        />
                                                        <div style={{
                                                            color: "#a1daf8"
                                                        }}>Host Information
                                                        </div>
                                                    </div>

                                                    <div style={{
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        marginTop: "4px",
                                                        height: "calc(100%-24px)"
                                                    }}
                                                         id="host-data">
                                                        <div style={{
                                                            display: "flex",
                                                            flexDirection: "column",
                                                            color: "#A1A1AA",
                                                            gap: "4px",
                                                            width: "108px"
                                                        }} id="section1">
                                                            <div>Client IP:</div>
                                                            <div>Hostname:</div>
                                                            <div>Host Severity:</div>
                                                            <div>Netflow:</div>
                                                        </div>

                                                        <div style={{
                                                            display: "flex",
                                                            flexDirection: "column",
                                                            gap: "4px"
                                                        }} id="section2">
                                                            <div>{this.state.modal_data["ip"]}</div>
                                                            <div>{this.state.modal_data["host"]}</div>
                                                            <div>{this.state.modal_data["h_severity"]}</div>
                                                            <div style={{
                                                                display: "flex",
                                                                gap: 8
                                                            }}>
                                                                <button
                                                                    style={{
                                                                        cursor: "pointer",
                                                                        border: "none",
                                                                        backgroundColor: "transparent",
                                                                        justifyContent: "center",
                                                                        display: "flex",
                                                                        width: "21px",
                                                                        height: "20px"
                                                                    }}
                                                                    onClick={() => this.props.open_netflow(this.state.modal_data)}
                                                                    id="netflow"
                                                                >
                                                                    <img src="/main/tipnet.svg" alt="netflow"/>
                                                                </button>
                                                                Forensic Data Analysis
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="inner-tip-box"
                                                >
                                                    <div style={{
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        gap: "4px",
                                                        lineHeight: "28px",
                                                        fontWeight: "600",
                                                        alignItems: "center",
                                                        height: "24px",
                                                    }}>
                                                        <img
                                                            style={{width: "24px", height: "24px"}}
                                                            alt=""
                                                            src="/main/tipgroup.svg"
                                                        />
                                                        <div style={{
                                                            color: "#a1daf8"
                                                        }}>Attack Group
                                                        </div>
                                                    </div>
                                                    <div style={{
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        marginTop: "4px",
                                                        height: "calc(100%-24px)"
                                                    }}
                                                         id="group-data">
                                                        <div style={{
                                                            display: "flex",
                                                            flexDirection: "column",
                                                            color: "#A1A1AA",
                                                            gap: "4px",
                                                            width: "108px"
                                                        }} id="section1">
                                                            <div>IoA:</div>
                                                            <div>Threat Type:</div>
                                                            <div>Country:</div>
                                                        </div>

                                                        <div style={{
                                                            display: "flex",
                                                            flexDirection: "column",
                                                            gap: "4px"
                                                        }} id="section2">
                                                            <div>{this.state.modal_data["ioa"]}</div>
                                                            <div>{this.state.modal_data["group"]}</div>
                                                            {this.getCountryRender()}
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="inner-tip-box"
                                                >
                                                    <div style={{
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        gap: "4px",
                                                        lineHeight: "28px",
                                                        fontWeight: "600",
                                                        alignItems: "center",
                                                        height: "24px",
                                                    }}
                                                    >
                                                        <img
                                                            style={{width: "24px", height: "24px"}}
                                                            alt=""
                                                            src="/main/tipmalware.svg"
                                                        />
                                                        <div style={{
                                                            color: "#a1daf8"
                                                        }}>Malware
                                                        </div>
                                                    </div>
                                                    <div style={{
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        marginTop: "4px",
                                                        height: "calc(100%-24px)"
                                                    }}
                                                         id="malware-data"
                                                    >
                                                        <div style={{
                                                            display: "flex",
                                                            flexDirection: "column",
                                                            color: "#A1A1AA",
                                                            gap: "4px",
                                                            width: "108px"
                                                        }} id="section1">
                                                            <div>Name:</div>
                                                            <div>Confidence:</div>
                                                            <div>Severity:</div>
                                                        </div>

                                                        <div style={{
                                                            display: "flex",
                                                            flexDirection: "column",
                                                            gap: "4px"
                                                        }} id="section2">
                                                            <div>{this.state.modal_data["name"]}</div>
                                                            <div>{this.state.modal_data["confidence"]}</div>
                                                            <div>{this.state.modal_data["severity"]}</div>
                                                        </div>

                                                        <button
                                                            style={{
                                                                cursor: "pointer",
                                                                border: "none",
                                                                backgroundColor: "transparent",
                                                                height: "24px"
                                                            }}
                                                            onClick={() => this.props.open_threat_card(this.state.modal_data["name"])}
                                                            id="threat_card_btn"
                                                        >
                                                            <div
                                                                style={{
                                                                    flex: "1",
                                                                    display: "flex",
                                                                    flexDirection: "row",
                                                                    alignItems: "center",
                                                                    justifyContent: "center",
                                                                    height: "24px",
                                                                }}
                                                            >
                                                                <img
                                                                    style={{
                                                                        position: "relative",
                                                                        width: "20px",
                                                                        height: "20px",
                                                                    }}
                                                                    alt=""
                                                                    src="/main/show-i.svg"
                                                                />
                                                            </div>
                                                        </button>

                                                    </div>

                                                </div>

                                                <div className="inner-tip-box"
                                                     style={{
                                                         height: "70px"
                                                     }}
                                                >
                                                    <div style={{
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        gap: "4px",
                                                        lineHeight: "28px",
                                                        fontWeight: "600",
                                                        alignItems: "center",
                                                        height: "24px",
                                                    }}>
                                                        <img
                                                            style={{width: "24px", height: "24px"}}
                                                            alt=""
                                                            src="/main/tipmitre.svg"
                                                        />
                                                        <div style={{
                                                            color: "#a1daf8"
                                                        }}>ATT&CK Mapping
                                                        </div>
                                                    </div>
                                                    <div style={{
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        marginTop: "4px",
                                                        height: "calc(100%-24px)"
                                                    }}
                                                         id="malware-data">
                                                        <div style={{
                                                            display: "flex",
                                                            flexDirection: "row",
                                                            color: "#A1A1AA",
                                                            gap: "4px",
                                                            width: "108px"
                                                        }} id="section1">
                                                            <div>Reference:</div>
                                                            <button
                                                                style={{
                                                                    cursor: "pointer",
                                                                    border: "none",
                                                                    backgroundColor: "transparent",
                                                                    height: "24px"
                                                                }}
                                                                onClick={() => this.props.open_mitre(this.state.modal_data["name"])}
                                                                id="mitre-btn"
                                                            >
                                                                <div
                                                                    style={{
                                                                        flex: "1",
                                                                        display: "flex",
                                                                        flexDirection: "row",
                                                                        alignItems: "center",
                                                                        justifyContent: "center",
                                                                        height: "24px",
                                                                    }}
                                                                >
                                                                    <img
                                                                        style={{
                                                                            position: "relative",
                                                                            width: "20px",
                                                                            height: "20px",
                                                                        }}
                                                                        alt=""
                                                                        src="/main/show-i.svg"
                                                                    />
                                                                </div>
                                                            </button>
                                                        </div>

                                                    </div>
                                                </div>
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        gap: "12px",
                                                        width: "100%",
                                                        height: "40px",
                                                        fontSize: "13px",
                                                        letterSpacing: "0.01em",
                                                        lineHeight: "20px",
                                                        fontFamily: "Inter",
                                                    }}
                                                >
                                                    <button
                                                        className="menu-entry"
                                                        onClick={this.update_detection}
                                                        id="0"
                                                        style={{
                                                            width: "132px",
                                                            minHeight: "100%"
                                                        }}
                                                    >
                                                        <div
                                                            style={{
                                                                backgroundColor: this.state.modal_data["status"] === "New" ?
                                                                    "rgba(244, 63, 94, 0.25)" : "rgba(113, 113, 122, 0.25)",
                                                                borderColor: this.state.modal_data["status"] === "New" ?
                                                                    "rgba(244, 63, 94, 100)" : "transparent",
                                                                borderRadius: "2px",
                                                                borderWidth: "2px",
                                                                borderStyle: "solid",
                                                                width: "100%",
                                                                height: "100%",
                                                                display: "flex",
                                                                flexDirection: "row",
                                                                alignItems: "center",
                                                                justifyContent: "center",
                                                                padding: "8px 16px",
                                                                boxSizing: "border-box",
                                                            }}
                                                        >
                                                            <div
                                                                style={{
                                                                    position: "relative",
                                                                    color: this.state.modal_data["status"] === "New" ?
                                                                        "rgba(244, 63, 94, 100)" : "white",
                                                                }}
                                                            >
                                                                New
                                                            </div>
                                                        </div>
                                                    </button>
                                                    <button
                                                        className="menu-entry"
                                                        onClick={this.update_detection}
                                                        id="1"
                                                        style={{
                                                            width: "132px",
                                                            minHeight: "100%"
                                                        }}
                                                    >
                                                        <div
                                                            style={{
                                                                backgroundColor: this.state.modal_data["status"] === "Pending" ?
                                                                    "rgba(245, 158, 11, 0.25)" : "rgba(113, 113, 122, 0.25)",
                                                                borderColor: this.state.modal_data["status"] === "Pending" ?
                                                                    "rgba(245, 158, 11, 100)" : "transparent",
                                                                borderRadius: "2px",
                                                                borderWidth: "2px",
                                                                borderStyle: "solid",
                                                                width: "100%",
                                                                height: "100%",
                                                                display: "flex",
                                                                flexDirection: "row",
                                                                alignItems: "center",
                                                                justifyContent: "center",
                                                                padding: "8px 16px",
                                                                boxSizing: "border-box",
                                                            }}
                                                        >
                                                            <div
                                                                style={{
                                                                    position: "relative",
                                                                    color: this.state.modal_data["status"] === "Pending" ?
                                                                        "rgba(245, 158, 11, 100)" : "white",
                                                                }}
                                                            >
                                                                Live IR
                                                            </div>
                                                        </div>
                                                    </button>
                                                    <button
                                                        className="menu-entry"
                                                        onClick={this.update_detection}
                                                        id="3"
                                                        style={{
                                                            width: "132px",
                                                            minHeight: "100%"
                                                        }}
                                                    >
                                                        <div
                                                            style={{
                                                                backgroundColor: this.state.modal_data["status"] === "False Positive" ?
                                                                    "rgba(139, 92, 246, 0.25)" : "rgba(113, 113, 122, 0.25)",
                                                                borderColor: this.state.modal_data["status"] === "False Positive" ?
                                                                    "rgba(139, 92, 246, 100)" : "transparent",
                                                                borderRadius: "2px",
                                                                borderWidth: "2px",
                                                                borderStyle: "solid",
                                                                width: "100%",
                                                                height: "100%",
                                                                display: "flex",
                                                                flexDirection: "row",
                                                                alignItems: "center",
                                                                justifyContent: "center",
                                                                padding: "8px 16px",
                                                                boxSizing: "border-box",
                                                            }}
                                                        >
                                                            <div
                                                                style={{
                                                                    position: "relative",
                                                                    color: this.state.modal_data["status"] === "False Positive" ?
                                                                        "rgba(139, 92, 246, 100)" : "white",
                                                                }}
                                                            >
                                                                False Positive
                                                            </div>
                                                        </div>
                                                    </button>
                                                    <button
                                                        className="menu-entry"
                                                        onClick={this.update_detection}
                                                        id="2"
                                                        style={{
                                                            width: "132px",
                                                            minHeight: "100%"
                                                        }}
                                                    >
                                                        <div
                                                            style={{
                                                                backgroundColor: this.state.modal_data["status"] === "Closed" ?
                                                                    "rgba(6, 182, 212, 0.25)" : "rgba(113, 113, 122, 0.25)",
                                                                borderColor: this.state.modal_data["status"] === "Closed" ?
                                                                    "rgba(6, 182, 212, 100)" : "transparent",
                                                                borderRadius: "2px",
                                                                borderWidth: "2px",
                                                                borderStyle: "solid",
                                                                width: "100%",
                                                                height: "100%",
                                                                display: "flex",
                                                                flexDirection: "row",
                                                                alignItems: "center",
                                                                justifyContent: "center",
                                                                padding: "8px 16px",
                                                                boxSizing: "border-box",
                                                            }}
                                                        >
                                                            <div
                                                                style={{
                                                                    position: "relative",
                                                                    color: this.state.modal_data["status"] === "Closed" ?
                                                                        "rgba(6, 182, 212, 100)" : "white",
                                                                }}
                                                            >
                                                                Closed
                                                            </div>
                                                        </div>
                                                    </button>

                                                </div>


                                            </div>


                                            <div style={{
                                                width: "calc(50% - 8px)",
                                                height: "100%",
                                                display: "flex",
                                                flexDirection: "column",
                                                justifyContent: "flex-start",
                                                color: "white",
                                                gap: "16px"
                                            }}
                                            >
                                                <div style={{
                                                    width: "100%",
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    justifyContent: "flex-start",
                                                    lineHeight: "28px",
                                                    fontWeight: "600",
                                                }}
                                                >
                                                    <div style={{color: "#F9B233"}}>
                                                        Action History
                                                    </div>
                                                    <button
                                                        style={{
                                                            cursor: "pointer",
                                                            border: "none",
                                                            backgroundColor: "transparent",
                                                            position: "relative",
                                                            width: "24px",
                                                            height: "24px",
                                                            marginLeft: "auto"
                                                        }}
                                                        id="close-modal"
                                                        onClick={this.CloseModal}
                                                    >
                                                        <img
                                                            alt=""
                                                            src="/main/white-close.svg"
                                                        />
                                                    </button>
                                                </div>


                                                <div className="inner-tip-box" style={{
                                                    height: "100%",
                                                    gap: "16px"
                                                }}>
                                                    <div style={{
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        gap: "4px",
                                                        lineHeight: "28px",
                                                        fontWeight: "600",
                                                        alignItems: "center",
                                                        height: "24px",
                                                    }}
                                                    >
                                                        <img
                                                            style={{width: "24px", height: "24px"}}
                                                            alt=""
                                                            src="/main/tiphistory.svg"
                                                        />
                                                        <div style={{
                                                            color: "#a1daf8"
                                                        }}>Action History Log
                                                        </div>

                                                        <button
                                                            style={{
                                                                cursor: "pointer",
                                                                borderRadius: "2px",
                                                                border: "1px solid #06B6D4",
                                                                backgroundColor: "transparent",
                                                                color: "#06B6D4",
                                                                position: "relative",
                                                                marginLeft: "auto"
                                                            }}
                                                            id="Contact"
                                                            onClick={this.props.open_client_playbook}
                                                        >
                                                            IR Contact
                                                        </button>

                                                        <button
                                                            style={{
                                                                cursor: "pointer",
                                                                borderRadius: "2px",
                                                                border: "1px solid #06B6D4",
                                                                backgroundColor: "transparent",
                                                                color: "#06B6D4",
                                                                position: "relative"
                                                            }}
                                                            id="playbook"
                                                            onClick={() => this.setState({
                                                                showPlaybook: true,
                                                                playbook: this.props.get_playbook_data(this.state.modal_data["priority"])
                                                            })}
                                                        >
                                                            Playbook
                                                        </button>

                                                        <button
                                                            style={{
                                                                cursor: "pointer",
                                                                borderRadius: "2px",
                                                                border: "none",
                                                                backgroundColor: "rgba(6,182,212,0.25)",
                                                                color: "#06B6D4",
                                                                position: "relative",
                                                            }}
                                                            id="new-update"
                                                            onClick={() => this.setState({show_log_input: true})}
                                                        >
                                                            New Update
                                                        </button>
                                                    </div>


                                                    <div className="scroll-bar-div" style={{
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        padding: "0px 0px 16px 8px",
                                                        gap: "8px",
                                                        overflowY: "scroll",
                                                        overflowX: "hidden",
                                                        width: "100%",
                                                        height: "472px"
                                                    }}
                                                    >

                                                        {this.state.show_log_input ?
                                                            <div
                                                                style={{
                                                                    display: "flex",
                                                                    flexDirection: "column",
                                                                    marginTop: "16px",
                                                                    width: "100%",
                                                                    height: "230px",
                                                                    marginRight: "8px",
                                                                }}
                                                            >
                                                                <MDEditor
                                                                    value={this.state.log_input_text}
                                                                    visibleDragbar={false}
                                                                    hideToolbar={true}
                                                                    preview="edit"
                                                                    extraCommands={[]}
                                                                    onChange={(data) => {
                                                                        this.setState({log_input_text: data})
                                                                    }}
                                                                    height={200}
                                                                />
                                                                <div style={{marginLeft: "auto", marginTop: "8px"}}>
                                                                    <button onClick={() => {
                                                                        this.update_detection("", this.state.log_input_text)
                                                                        this.setState({
                                                                            show_log_input: false,
                                                                            log_input_text: ""
                                                                        })
                                                                    }}
                                                                            style={{
                                                                                cursor: "pointer",
                                                                                borderRadius: "2px",
                                                                                background: "transparent",
                                                                                border: "solid 1px " + (this.state.log_input_text === "" ? "#737373" : "#06B6D4"),
                                                                                color: this.state.log_input_text === "" ? "#737373" : "#06B6D4",
                                                                                marginRight: "8px"
                                                                            }}
                                                                    >
                                                                        <ISubmit style={{
                                                                            color: this.state.log_input_text === "" ? "#737373" : "#06B6D4",
                                                                        }}/>
                                                                        Submit
                                                                    </button>
                                                                    <button onClick={() => {
                                                                        this.setState({
                                                                            show_log_input: false,
                                                                            log_input_text: ""
                                                                        })
                                                                    }}
                                                                            style={{
                                                                                cursor: "pointer",
                                                                                borderRadius: "2px",
                                                                                background: "transparent",
                                                                                border: "solid 1px #06B6D4",
                                                                                color: "#06B6D4",
                                                                                marginRight: "8px"
                                                                            }}
                                                                    >
                                                                        Cancel
                                                                    </button>
                                                                </div>
                                                            </div>
                                                            : ""
                                                        }

                                                        {
                                                            this.state.modal_data["log"] ?

                                                                this.state.modal_data["log"].slice(0).reverse().map((item, i) =>
                                                                    <div style={{
                                                                        display: "flex",
                                                                        flexDirection: "column",
                                                                        marginRight: "8px",
                                                                        gap: "8px"
                                                                    }}
                                                                         id="history-log-entry"
                                                                    >
                                                                        <div style={{
                                                                            display: "flex",
                                                                            flexDirection: "row",
                                                                            gap: "8px"
                                                                        }}
                                                                        >
                                                                            <img src="/main/tiphistoryclock-i.svg"
                                                                                 alt="tip-clock"/>
                                                                            <div style={{color: "#737373"}}>
                                                                                {get_time_string(item["time"])}
                                                                            </div>
                                                                        </div>
                                                                        <div style={{
                                                                            display: "flex",
                                                                            flexDirection: "row",
                                                                            marginLeft: "8px",
                                                                            gap: "8px"
                                                                        }}
                                                                        >
                                                                            <div style={{
                                                                                background: "#71717a",
                                                                                alignSelf: "stretch",
                                                                                flexShrink: "0",
                                                                                width: "1px"
                                                                            }}/>
                                                                            <MDEditor.Markdown source={item["text"]}
                                                                                               style={{
                                                                                                   padding: "16px 16px 16px 16px",
                                                                                                   color: "#A1A1AA",
                                                                                                   background: "#18181B",
                                                                                                   borderRadius: "8px",
                                                                                                   whiteSpace: 'pre-wrap',
                                                                                                   width: "100%",
                                                                                                   height: "100%",
                                                                                                   maxHeight: "100%",
                                                                                                   overflow: "auto"
                                                                                               }}
                                                                            />
                                                                        </div>

                                                                    </div>
                                                                )
                                                                :
                                                                ""
                                                        }

                                                        <div style={{
                                                            display: "flex",
                                                            flexDirection: "column",
                                                            marginRight: "8px",
                                                            gap: "8px"
                                                        }}
                                                             id="history-log-entry"
                                                        >
                                                            <div style={{
                                                                display: "flex",
                                                                flexDirection: "row",
                                                                gap: "8px"
                                                            }}
                                                            >
                                                                <img src="/main/tiphistoryclock-i.svg" alt="tip-clock"/>
                                                                <div style={{color: "#737373"}}>
                                                                    {get_time_string(this.state.modal_data["created"])}
                                                                </div>
                                                            </div>
                                                            <div style={{
                                                                display: "flex",
                                                                flexDirection: "row",
                                                                marginLeft: "8px",
                                                                gap: "8px"
                                                            }}
                                                            >
                                                                <div style={{
                                                                    background: "#71717a",
                                                                    alignSelf: "stretch",
                                                                    flexShrink: "0",
                                                                    width: "1px"
                                                                }}/>
                                                                <MDEditor.Markdown
                                                                    source={"New " + this.state.modal_data["priority"] + " Detection Created"}
                                                                    style={{
                                                                        padding: "16px 16px 16px 16px",
                                                                        color: "#A1A1AA",
                                                                        background: "#18181B",
                                                                        borderRadius: "8px",
                                                                        whiteSpace: 'pre-wrap',
                                                                        width: "100%",
                                                                        height: "100%",
                                                                        maxHeight: "100%",
                                                                        overflow: "auto"
                                                                    }}
                                                                />
                                                            </div>

                                                        </div>

                                                    </div>
                                                </div>


                                            </div>

                                        </div>
                            :
                            ""
                    }


                </ReactModal>

                <ReactModal
                    ariaHideApp={false}
                    isOpen={this.state.showPlaybook}
                    contentLabel="Playbook"
                    style={{
                        overlay: {
                            zIndex: 1000,
                            background: "rgba(19, 19, 19, 0.75)"
                        },
                        content: {
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            width: "570px",
                            height: "620px",
                            background: "#171717"
                        },
                    }}
                >

                    <div style={{
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "flex-start",
                        color: "white",
                        gap: "16px"
                    }}
                    >
                        <div style={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "flex-start",
                            lineHeight: "28px",
                            fontWeight: "600",
                            gap: "8px"
                        }}
                        >
                            <img
                                alt="playbook"
                                src="/main/playbook-i.svg"
                            />
                            <div style={{color: "#F9B233"}}>
                                {this.state.playbook.title}
                            </div>
                            <button
                                style={{
                                    cursor: "pointer",
                                    border: "none",
                                    backgroundColor: "transparent",
                                    position: "relative",
                                    width: "24px",
                                    height: "24px",
                                    marginLeft: "auto"
                                }}
                                id="close-modal"
                                onClick={() => this.setState({showPlaybook: false})}
                            >
                                <img
                                    alt="close-playbook"
                                    src="/main/white-close.svg"
                                />
                            </button>
                        </div>


                        <div className="inner-tip-box" style={{
                            height: "100%",
                            gap: "16px"
                        }}>
                            <div className="scroll-bar-div" style={{
                                display: "flex",
                                flexDirection: "column",
                                padding: "0px 0px 16px 8px",
                                gap: "8px",
                                overflowY: "scroll",
                                overflowX: "hidden",
                                width: "100%",
                                height: "520px"
                            }}
                            >
                                <div style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    gap: "4px",
                                    lineHeight: "28px",
                                    fontWeight: "600",
                                    alignItems: "center",
                                    height: "24px",
                                }}
                                >
                                    <img
                                        style={{width: "24px", height: "24px"}}
                                        alt=""
                                        src="/main/playbook-obj-i.svg"
                                    />
                                    <div style={{
                                        color: "#a1daf8"
                                    }}>Objective
                                    </div>
                                </div>
                                <div>
                                    {this.state.playbook.objective}
                                </div>


                                {
                                    this.state.playbook["steps"] ?
                                        this.state.playbook["steps"].map((item, i) =>
                                            <div style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                marginRight: "8px",
                                                gap: "8px"
                                            }}
                                                 id="history-log-entry"
                                            >
                                                <div style={{
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    gap: "8px"
                                                }}
                                                >
                                                    <img src={"/main/playbook-" + (i + 1).toString() + "-i.svg"}
                                                         alt="playbook-num"/>
                                                    <div style={{color: "#a1daf8"}}>
                                                        {item.title}
                                                    </div>
                                                </div>
                                                <div style={{
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    marginLeft: "8px",
                                                    gap: "8px"
                                                }}
                                                >
                                                    <div style={{
                                                        background: "#71717a",
                                                        alignSelf: "stretch",
                                                        flexShrink: "0",
                                                        width: "1px"
                                                    }}/>
                                                    <MDEditor.Markdown source={item.text}
                                                                       style={{
                                                                           padding: "16px 8px 16px 8px",
                                                                           color: "#A1A1AA",
                                                                           background: "#18181B",
                                                                           borderRadius: "8px",
                                                                           width: "100%",
                                                                           height: "100%",
                                                                           maxHeight: "100%",
                                                                           overflow: "auto"
                                                                       }}
                                                    />
                                                </div>

                                            </div>
                                        )
                                        :
                                        ""
                                }

                                <div style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    marginRight: "8px",
                                    gap: "8px"
                                }}
                                >
                                    <div style={{alignSelf: "stretch", flexShrink: "0"}}>
                                        <img src="/main/playbook-note-i.svg" alt="playbook-note"/>
                                    </div>
                                    <div style={{color: "#A1A1AA"}}>
                                        {this.state.playbook.note}
                                    </div>
                                </div>

                            </div>
                        </div>


                    </div>

                </ReactModal>


                <div className="Pulse-Box">
                    <div className="pulse-div" style={{
                        backgroundColor: "rgba(" + 1 + "," + (128 - 1) + ",0,0.3)"
                    }}/>
                    <img
                        alt=""
                        src="/main/pulse-icon.svg"
                    />
                    ETI Pulse: {this.getLatestPulse()}ms
                </div>

                <div style={{
                    width: "100%",
                    height: "1%",
                    alignSelf: "stretch",
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                    gap: "8px",
                    color: "#F9B233",
                }}>
                    <div
                        style={{
                            alignSelf: "stretch",
                            display: "flex",
                            flexDirection: "row",
                            flexWrap: "wrap",
                            alignItems: "flex-start",
                            justifyContent: "flex-start",
                            gap: "8px",
                            width: "100%"
                        }}
                    >
                        <div
                            className="Dashboard-Detection-Card"
                        >
                            <div
                                style={{
                                    alignSelf: "stretch",
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "flex-start",
                                    justifyContent: "flex-start",
                                }}
                            >
                                <div
                                    style={{
                                        alignSelf: "stretch",
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                        justifyContent: "flex-start",
                                        gap: "4px",
                                    }}
                                >
                                    <div
                                        style={{
                                            flex: "1",
                                            position: "relative",
                                            lineHeight: "28px",
                                            fontWeight: "600"
                                        }}
                                    >
                                        P1 Detections
                                    </div>
                                    <MuiTooltip title={this.props.tooltip_info("P1")} arrow>
                                        <img
                                            style={{
                                                position: "relative",
                                                width: "24px",
                                                height: "24px",
                                                overflow: "hidden",
                                                flexShrink: "0",
                                            }}
                                            alt="detection-i"
                                            src="/main/detection-i.svg"
                                        />
                                    </MuiTooltip>
                                </div>
                            </div>
                            <div className="Pulsating-Card"
                                 style={{
                                     border: "20px solid rgba(239,68,68)",
                                     animation: this.state.d[0] > 0 ? "card-pulsate 1.5s ease-out infinite" : ""
                                 }}/>
                            <div
                                style={{
                                    position: "relative",
                                    width: "232px",
                                    height: "232px",
                                    textAlign: "center",
                                    fontSize: "45px",
                                    color: "#fff",
                                    zIndex: "100"
                                }}
                            >


                                <CircularProgressbarWithChildren value={this.state.p[0]} strokeWidth={5}
                                                                 styles={buildStyles({
                                                                     pathTransitionDuration: 0.5,
                                                                     trailColor: '#27272A',
                                                                     pathColor: 'rgba(239,68,68,0.35)'
                                                                 })}
                                >
                                    <div style={{
                                        width: "85%"
                                    }}>
                                        <CircularProgressbarWithChildren value={this.state.d[0]} strokeWidth={5}
                                                                         styles={buildStyles({
                                                                             pathTransitionDuration: 0.5,
                                                                             trailColor: '#27272A',
                                                                             pathColor: 'rgba(239,68,68,100)'
                                                                         })}>

                                            <div style={{marginBottom: -20}}>{this.state.d[0]}</div>
                                            <img alt="" src="/main/dividor.svg"/>
                                            <div style={{
                                                marginTop: -20,
                                                color: 'rgba(239,68,68,0.35)'
                                            }}>{this.state.p_val[0]}</div>
                                        </CircularProgressbarWithChildren>
                                    </div>
                                </CircularProgressbarWithChildren>
                            </div>
                            <div
                                style={{
                                    alignSelf: "stretch",
                                    display: "flex",
                                    flexDirection: "row",
                                    gap: "4px",
                                    fontSize: "12px",
                                    color: "#a1a1aa"
                                }}
                            >
                                <div
                                    style={{
                                        borderRadius: "16px",
                                        backgroundColor: "rgba(220, 38, 38, 0.25)",
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        padding: "0px 8px",
                                        color: "#ef4444"
                                    }}
                                >
                                    <div
                                        style={{
                                            position: "relative",
                                            letterSpacing: "0.01em",
                                            lineHeight: "20px",
                                            fontWeight: "600"
                                        }}
                                    >
                                        CRITICAL
                                    </div>
                                </div>
                                <div style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center"
                                }}
                                >
                                    <img
                                        style={{
                                            display: "flex",
                                            position: "relative",
                                            width: "16px",
                                            height: "16px",
                                            marginRight: "3px"
                                        }}
                                        alt=""
                                        src="/main/icon-l5-grey.svg"
                                    />
                                    <div
                                        style={{
                                            letterSpacing: "0.01em",
                                            lineHeight: "16px",
                                            fontWeight: "500"
                                        }}
                                    >
                                        Realtime Detections
                                    </div>
                                </div>
                                <button
                                    onClick={() => this.top_10("P1")}
                                    style={{
                                        borderRadius: "16px",
                                        backgroundColor: "rgba(82, 82, 91, 0.25)",
                                        display: "flex",
                                        flexDirection: "row",
                                        marginLeft: "auto",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        padding: "0px 8px",
                                        cursor: "pointer",
                                        border: "none",
                                        overflow: "hidden",
                                    }}
                                >
                                    <div style={{
                                        paddingRight: 4,
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        gap: 10,
                                        display: 'flex'
                                    }}>
                                        <img style={{width: 16, height: 16, left: 2, top: 2}}
                                             src="/main/show-i.svg" alt="show"/>
                                    </div>
                                    <div style={{
                                        color: '#A1A1AA',
                                        fontSize: 14,
                                        fontFamily: 'Inter',
                                        fontWeight: '600',
                                        lineHeight: 1,
                                        letterSpacing: 0.07,
                                    }}>Top 10
                                    </div>
                                </button>
                            </div>
                        </div>
                        <div
                            className="Dashboard-Detection-Card"
                        >
                            <div
                                style={{
                                    alignSelf: "stretch",
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "flex-start",
                                    justifyContent: "flex-start",
                                }}
                            >
                                <div
                                    style={{
                                        alignSelf: "stretch",
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                        justifyContent: "flex-start",
                                        gap: "4px",
                                    }}
                                >
                                    <div
                                        style={{
                                            flex: "1",
                                            position: "relative",
                                            lineHeight: "28px",
                                            fontWeight: "600",
                                        }}
                                    >
                                        P2 Detections
                                    </div>
                                    <MuiTooltip title={this.props.tooltip_info("P2")} arrow>
                                        <img
                                            style={{
                                                position: "relative",
                                                width: "24px",
                                                height: "24px",
                                                overflow: "hidden",
                                                flexShrink: "0",
                                            }}
                                            alt="detection-i"
                                            src="/main/detection-i.svg"
                                        />
                                    </MuiTooltip>
                                </div>
                            </div>
                            <div className="Pulsating-Card"
                                 style={{
                                     border: "20px solid rgba(249,115,22)",
                                     animation: this.state.d[1] > 0 ? "card-pulsate 1.5s ease-out infinite" : ""
                                 }}/>
                            <div
                                style={{
                                    position: "relative",
                                    width: "232px",
                                    height: "232px",
                                    textAlign: "center",
                                    fontSize: "45px",
                                    color: "#fff",
                                    zIndex: "100"
                                }}
                            >
                                <CircularProgressbarWithChildren value={this.state.p[1]} strokeWidth={5}
                                                                 styles={buildStyles({
                                                                     textSize: '25px',
                                                                     pathTransitionDuration: 0.5,
                                                                     textColor: '#fff',
                                                                     trailColor: '#27272A',
                                                                     pathColor: 'rgba(249,115,22,0.35)'
                                                                 })}>
                                    <div style={{
                                        width: "85%"
                                    }}>
                                        <CircularProgressbarWithChildren value={this.state.d[1]} strokeWidth={5}
                                                                         styles={buildStyles({
                                                                             textSize: '25px',
                                                                             pathTransitionDuration: 0.5,
                                                                             textColor: '#fff',
                                                                             trailColor: '#27272A',
                                                                             pathColor: '#F97316'
                                                                         })}
                                        >
                                            <div style={{marginBottom: -20}}>{this.state.d[1]}</div>
                                            <img alt="" src="/main/dividor.svg"/>
                                            <div style={{
                                                marginTop: -20,
                                                color: 'rgba(249,115,22,0.35)'
                                            }}>{this.state.p_val[1]}</div>
                                        </CircularProgressbarWithChildren>
                                    </div>
                                </CircularProgressbarWithChildren>
                            </div>
                            <div
                                style={{
                                    alignSelf: "stretch",
                                    display: "flex",
                                    flexDirection: "row",
                                    gap: "4px",
                                    fontSize: "12px",
                                    color: "#a1a1aa"
                                }}
                            >
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        flexWrap: "wrap",
                                        alignItems: "flex-start",
                                        justifyContent: "flex-start",
                                        gap: "8px",
                                    }}
                                >
                                    <div
                                        style={{
                                            borderRadius: "16px",
                                            backgroundColor: "rgba(234, 88, 12, 0.25)",
                                            overflow: "hidden",
                                            display: "flex",
                                            flexDirection: "row",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            padding: "0px 8px",
                                            color: "#f97316",
                                        }}
                                    >
                                        <div
                                            style={{
                                                position: "relative",
                                                letterSpacing: "0.01em",
                                                lineHeight: "20px",
                                                fontWeight: "600",
                                            }}
                                        >
                                            HIGH
                                        </div>
                                    </div>
                                </div>
                                <div style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center"
                                }}
                                >
                                    <img
                                        style={{
                                            position: "relative",
                                            width: "16px",
                                            height: "16px",
                                            marginRight: "3px"
                                        }}
                                        alt=""
                                        src="/main/icon-l5-grey.svg"
                                    />
                                    <div
                                        style={{
                                            flex: "1",
                                            position: "relative",
                                            letterSpacing: "0.01em",
                                            lineHeight: "16px",
                                            fontWeight: "500",
                                        }}
                                    >
                                        Realtime Detections
                                    </div>
                                </div>
                                <button
                                    onClick={() => this.top_10("P2")}
                                    style={{
                                        borderRadius: "16px",
                                        backgroundColor: "rgba(82, 82, 91, 0.25)",
                                        display: "flex",
                                        flexDirection: "row",
                                        marginLeft: "auto",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        padding: "0px 8px",
                                        cursor: "pointer",
                                        border: "none",
                                        overflow: "hidden",
                                    }}
                                >
                                    <div style={{
                                        paddingRight: 4,
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        gap: 10,
                                        display: 'flex'
                                    }}>
                                        <img style={{width: 16, height: 16, left: 2, top: 2}}
                                             src="/main/show-i.svg" alt="show"/>
                                    </div>
                                    <div style={{
                                        color: '#A1A1AA',
                                        fontSize: 14,
                                        fontFamily: 'Inter',
                                        fontWeight: '600',
                                        lineHeight: 1,
                                        letterSpacing: 0.07,
                                    }}>Top 10
                                    </div>
                                </button>
                            </div>
                        </div>
                        <div
                            className="Dashboard-Detection-Card"
                        >
                            <div
                                style={{
                                    alignSelf: "stretch",
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "flex-start",
                                    justifyContent: "flex-start",
                                }}
                            >
                                <div
                                    style={{
                                        alignSelf: "stretch",
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                        justifyContent: "flex-start",
                                        gap: "4px",
                                    }}
                                >
                                    <div
                                        style={{
                                            flex: "1",
                                            position: "relative",
                                            lineHeight: "28px",
                                            fontWeight: "600",
                                        }}
                                    >
                                        P3 Detections
                                    </div>
                                    <MuiTooltip title={this.props.tooltip_info("P3")} arrow>
                                        <img
                                            style={{
                                                position: "relative",
                                                width: "24px",
                                                height: "24px",
                                                overflow: "hidden",
                                                flexShrink: "0",
                                            }}
                                            alt="detection-i"
                                            src="/main/detection-i.svg"
                                        />
                                    </MuiTooltip>
                                </div>
                            </div>
                            <div className="Pulsating-Card"
                                 style={{
                                     border: "20px solid rgba(234,179,8)",
                                     animation: this.state.d[2] > 0 ? "card-pulsate 1.5s ease-out infinite" : ""
                                 }}/>
                            <div
                                style={{
                                    position: "relative",
                                    width: "232px",
                                    height: "232px",
                                    textAlign: "center",
                                    fontSize: "45px",
                                    color: "#fff",
                                    zIndex: "100"
                                }}
                            >
                                <CircularProgressbarWithChildren value={this.state.p[2]} strokeWidth={5}
                                                                 styles={buildStyles({
                                                                     textSize: '25px',
                                                                     pathTransitionDuration: 0.5,
                                                                     textColor: '#fff',
                                                                     trailColor: '#27272A',
                                                                     pathColor: 'rgba(234,179,8,0.35)'
                                                                 })}>
                                    <div style={{
                                        width: "85%"
                                    }}>
                                        <CircularProgressbarWithChildren value={this.state.d[2]} strokeWidth={5}
                                                                         styles={buildStyles({
                                                                             textSize: '25px',
                                                                             pathTransitionDuration: 0.5,
                                                                             textColor: '#fff',
                                                                             trailColor: '#27272A',
                                                                             pathColor: '#EAB308'
                                                                         })}
                                        >
                                            <div style={{marginBottom: -20}}>{this.state.d[2]}</div>
                                            <img alt="" src="/main/dividor.svg"/>
                                            <div style={{
                                                marginTop: -20,
                                                color: 'rgba(234,179,8,0.35)'
                                            }}>{this.state.p_val[2]}</div>
                                        </CircularProgressbarWithChildren>
                                    </div>
                                </CircularProgressbarWithChildren>
                            </div>
                            <div
                                style={{
                                    alignSelf: "stretch",
                                    display: "flex",
                                    flexDirection: "row",
                                    gap: "4px",
                                    fontSize: "12px",
                                    color: "#a1a1aa",
                                }}
                            >
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        flexWrap: "wrap",
                                        alignItems: "flex-start",
                                        justifyContent: "flex-start",
                                        gap: "8px",
                                    }}
                                >
                                    <div
                                        style={{
                                            borderRadius: "16px",
                                            backgroundColor: "rgba(202, 138, 4, 0.25)",
                                            overflow: "hidden",
                                            display: "flex",
                                            flexDirection: "row",
                                            padding: "0px 8px",
                                            color: "#eab308",
                                        }}
                                    >
                                        <div
                                            style={{
                                                position: "relative",
                                                letterSpacing: "0.01em",
                                                lineHeight: "20px",
                                                fontWeight: "600",
                                            }}
                                        >
                                            MEDIUM
                                        </div>
                                    </div>
                                </div>

                                <div style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center"
                                }}
                                >
                                    <img
                                        style={{
                                            display: "flex",
                                            position: "relative",
                                            width: "16px",
                                            height: "16px",
                                            marginRight: "3px"
                                        }}
                                        alt=""
                                        src="/main/icon-l5-grey.svg"
                                    />
                                    <div
                                        style={{
                                            letterSpacing: "0.01em",
                                            lineHeight: "16px",
                                            fontWeight: "500"
                                        }}
                                    >
                                        Realtime Detections
                                    </div>
                                </div>
                                <button
                                    onClick={() => this.top_10("P3")}
                                    style={{
                                        borderRadius: "16px",
                                        backgroundColor: "rgba(82, 82, 91, 0.25)",
                                        display: "flex",
                                        flexDirection: "row",
                                        marginLeft: "auto",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        padding: "0px 8px",
                                        cursor: "pointer",
                                        border: "none",
                                        overflow: "hidden",
                                    }}
                                >
                                    <div style={{
                                        paddingRight: 4,
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        gap: 10,
                                        display: 'flex'
                                    }}>
                                        <img style={{width: 16, height: 16, left: 2, top: 2}}
                                             src="/main/show-i.svg" alt="show"/>
                                    </div>
                                    <div style={{
                                        color: '#A1A1AA',
                                        fontSize: 14,
                                        fontFamily: 'Inter',
                                        fontWeight: '600',
                                        lineHeight: 1,
                                        letterSpacing: 0.07,
                                    }}>Top 10
                                    </div>
                                </button>
                            </div>
                        </div>
                        <div
                            className="Dashboard-Detection-Card"
                        >
                            <div
                                style={{
                                    alignSelf: "stretch",
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "flex-start",
                                    justifyContent: "flex-start",
                                }}
                            >
                                <div
                                    style={{
                                        alignSelf: "stretch",
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                        justifyContent: "flex-start",
                                        gap: "4px",
                                    }}
                                >
                                    <div
                                        style={{
                                            flex: "1",
                                            position: "relative",
                                            lineHeight: "28px",
                                            fontWeight: "600",
                                        }}
                                    >
                                        P4 Detections
                                    </div>
                                    <MuiTooltip title={this.props.tooltip_info("P4")} arrow>
                                        <img
                                            style={{
                                                position: "relative",
                                                width: "24px",
                                                height: "24px",
                                                overflow: "hidden",
                                                flexShrink: "0",
                                            }}
                                            alt="detection-i"
                                            src="/main/detection-i.svg"
                                        />
                                    </MuiTooltip>
                                </div>
                            </div>
                            <div className="Pulsating-Card"
                                 style={{
                                     border: "20px solid rgba(6,182,212)",
                                     animation: this.state.d[3] > 0 ? "card-pulsate 1.5s ease-out infinite" : ""
                                 }}/>
                            <div
                                style={{
                                    position: "relative",
                                    width: "232px",
                                    height: "232px",
                                    textAlign: "center",
                                    fontSize: "45px",
                                    color: "#fff",
                                    zIndex: "100"
                                }}
                            >
                                <CircularProgressbarWithChildren value={this.state.p[3]} strokeWidth={5}
                                                                 styles={buildStyles({
                                                                     textSize: '25px',
                                                                     pathTransitionDuration: 0.5,
                                                                     textColor: '#fff',
                                                                     trailColor: '#27272A',
                                                                     pathColor: 'rgba(6,182,212,0.35)'
                                                                 })}>
                                    <div style={{
                                        width: "85%"
                                    }}>
                                        <CircularProgressbarWithChildren value={this.state.d[3]} strokeWidth={5}
                                                                         styles={buildStyles({
                                                                             textSize: '25px',
                                                                             pathTransitionDuration: 0.5,
                                                                             textColor: '#fff',
                                                                             trailColor: '#27272A',
                                                                             pathColor: '#06B6D4'
                                                                         })}
                                        >
                                            <div style={{marginBottom: -20}}>{this.state.d[3]}</div>
                                            <img alt="" src="/main/dividor.svg"/>
                                            <div style={{
                                                marginTop: -20,
                                                color: 'rgba(6,182,212,0.35)'
                                            }}>{this.state.p_val[3]}</div>
                                        </CircularProgressbarWithChildren>
                                    </div>
                                </CircularProgressbarWithChildren>
                            </div>
                            <div
                                style={{
                                    alignSelf: "stretch",
                                    display: "flex",
                                    flexDirection: "row",
                                    gap: "4px",
                                    fontSize: "12px",
                                    color: "#a1a1aa"
                                }}
                            >
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        flexWrap: "wrap",
                                        alignItems: "flex-start",
                                        justifyContent: "flex-start",
                                        gap: "8px",
                                    }}
                                >
                                    <div
                                        style={{
                                            borderRadius: "16px",
                                            backgroundColor: "rgba(8, 145, 178, 0.25)",
                                            overflow: "hidden",
                                            display: "flex",
                                            flexDirection: "row",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            padding: "0px 8px",
                                            color: "#06b6d4",
                                        }}
                                    >
                                        <div
                                            style={{
                                                position: "relative",
                                                letterSpacing: "0.01em",
                                                lineHeight: "20px",
                                                fontWeight: "600",
                                            }}
                                        >
                                            LOW
                                        </div>
                                    </div>
                                </div>
                                <div style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center"
                                }}
                                >
                                    <img
                                        style={{
                                            display: "flex",
                                            position: "relative",
                                            width: "16px",
                                            height: "16px",
                                            marginRight: "3px"
                                        }}
                                        alt=""
                                        src="/main/icon-l5-grey.svg"
                                    />
                                    <div
                                        style={{
                                            letterSpacing: "0.01em",
                                            lineHeight: "16px",
                                            fontWeight: "500"
                                        }}
                                    >
                                        Realtime Detections
                                    </div>
                                </div>
                                <button
                                    onClick={() => this.top_10("P4")}
                                    style={{
                                        borderRadius: "16px",
                                        backgroundColor: "rgba(82, 82, 91, 0.25)",
                                        display: "flex",
                                        flexDirection: "row",
                                        marginLeft: "auto",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        padding: "0px 8px",
                                        cursor: "pointer",
                                        border: "none",
                                        overflow: "hidden",
                                    }}
                                >
                                    <div style={{
                                        paddingRight: 4,
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        gap: 10,
                                        display: 'flex'
                                    }}>
                                        <img style={{width: 16, height: 16, left: 2, top: 2}}
                                             src="/main/show-i.svg" alt="show"/>
                                    </div>
                                    <div style={{
                                        color: '#A1A1AA',
                                        fontSize: 14,
                                        fontFamily: 'Inter',
                                        fontWeight: '600',
                                        lineHeight: 1,
                                        letterSpacing: 0.07,
                                    }}>Top 10
                                    </div>
                                </button>
                            </div>
                        </div>
                        <div
                            className="Dashboard-Detection-Card"
                            style={{
                                minWidth: "460px"
                            }}
                        >
                            <div
                                style={{
                                    alignSelf: "stretch",
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "flex-start",
                                    justifyContent: "flex-start",
                                }}
                            >
                                <div
                                    style={{
                                        alignSelf: "stretch",
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                        justifyContent: "flex-start",
                                        gap: "4px",
                                    }}
                                >
                                    <div
                                        style={{
                                            flex: "1",
                                            position: "relative",
                                            lineHeight: "28px",
                                            fontWeight: "600",
                                        }}
                                    >
                                        Risk Detections
                                    </div>
                                    <MuiTooltip title={this.props.tooltip_info("Supply Chain Risk")} arrow>
                                        <img
                                            style={{
                                                position: "relative",
                                                width: "24px",
                                                height: "24px",
                                                overflow: "hidden",
                                                flexShrink: "0",
                                            }}
                                            alt="detection-i"
                                            src="/main/detection-i.svg"
                                        />
                                    </MuiTooltip>
                                </div>
                            </div>
                            <div style={{
                                display: "flex",
                                marginLeft: -8,
                                width: "100%"
                            }}
                            >
                                <div
                                    style={{
                                        width: "50%",
                                        display: "flex",
                                        flexDirection: "row",
                                        gap: "4px",
                                        fontSize: "18px",
                                        color: "#a1a1aa",
                                        justifyContent: "center"
                                    }}
                                >
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            flexWrap: "wrap",
                                            alignItems: "flex-start",
                                            justifyContent: "flex-start",
                                            gap: "8px",
                                        }}
                                    >
                                        <div
                                            style={{
                                                borderRadius: "16px",
                                                backgroundColor: "rgba(16,185,129,0.25)",
                                                overflow: "hidden",
                                                display: "flex",
                                                flexDirection: "row",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                padding: "2px 16px",
                                                color: "white",
                                            }}
                                        >
                                            <div
                                                style={{
                                                    position: "relative",
                                                    fontWeight: "600",
                                                }}
                                            >
                                                Supply Chain
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    style={{
                                        width: "50%",
                                        display: "flex",
                                        flexDirection: "row",
                                        gap: "4px",
                                        fontSize: "18px",
                                        color: "#a1a1aa",
                                        justifyContent: "center"
                                    }}
                                >
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            flexWrap: "wrap",
                                            alignItems: "flex-start",
                                            justifyContent: "flex-start",
                                            gap: "8px",
                                        }}
                                    >
                                        <div
                                            style={{
                                                borderRadius: "16px",
                                                backgroundColor: "rgba(139,92,246,0.25)",
                                                overflow: "hidden",
                                                display: "flex",
                                                flexDirection: "row",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                padding: "2px 16px",
                                                color: "white",
                                            }}
                                        >
                                            <div
                                                style={{
                                                    position: "relative",
                                                    fontWeight: "600",
                                                }}
                                            >
                                                ShadowBreach
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div style={{
                                display: "flex",
                                justifyContent: "center",
                                gap: "20px",
                                width: "100%"
                            }}
                            >
                                <div style={{
                                    width: "45%",
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: "24px"
                                }}
                                >
                                    <div className="Pulsating-Card"
                                         style={{
                                             marginTop: "55px",
                                             width: "190px",
                                             height: "190px",
                                             border: "20px solid rgba(16,185,129)",
                                             animation: this.state.d[4] > 0 ? "card-pulsate 1.5s ease-out infinite" : ""
                                         }}/>
                                    <div
                                        style={{
                                            position: "relative",
                                            width: "190px",
                                            height: "190px",
                                            textAlign: "center",
                                            fontSize: "35px",
                                            color: "#fff",
                                        }}
                                    >
                                        <CircularProgressbarWithChildren value={60} strokeWidth={5}
                                                                         styles={buildStyles({
                                                                             textSize: '25px',
                                                                             pathTransitionDuration: 0.5,
                                                                             textColor: '#fff',
                                                                             trailColor: '#27272A',
                                                                             pathColor: 'rgba(16,185,129,0.15)'
                                                                         })}>
                                            <div style={{
                                                width: "85%"
                                            }}>
                                                <CircularProgressbarWithChildren value={this.state.s[0]} strokeWidth={5}
                                                                                 styles={buildStyles({
                                                                                     pathTransitionDuration: 0.5,
                                                                                     trailColor: '#27272A',
                                                                                     pathColor: 'rgba(16,185,129,0.35)'
                                                                                 })}>

                                                    <div style={{marginBottom: -20}}>{this.state.d[4]}</div>
                                                    <img alt="" src="/main/dividor.svg"/>
                                                    <div style={{
                                                        marginTop: -20,
                                                        color: 'rgba(16,185,129,0.35)'
                                                    }}>{this.state.s_val[0]}</div>
                                                </CircularProgressbarWithChildren>
                                            </div>
                                        </CircularProgressbarWithChildren>
                                    </div>
                                </div>
                                <div style={{
                                    width: "45%",
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: "24px"
                                }}
                                >
                                    <div className="Pulsating-Card"
                                         style={{
                                             marginTop: "55px",
                                             width: "190px",
                                             height: "190px",
                                             border: "20px solid rgba(139,92,246)",
                                             animation: this.state.d[5] > 0 ? "card-pulsate 1.5s ease-out infinite" : ""
                                         }}/>
                                    <div
                                        style={{
                                            position: "relative",
                                            width: "190px",
                                            height: "190px",
                                            textAlign: "center",
                                            fontSize: "35px",
                                            color: "#fff",
                                        }}
                                    >
                                        <CircularProgressbarWithChildren value={65} strokeWidth={5}
                                                                         styles={buildStyles({
                                                                             textSize: '25px',
                                                                             pathTransitionDuration: 0.5,
                                                                             textColor: '#fff',
                                                                             trailColor: '#27272A',
                                                                             pathColor: 'rgba(139,92,246,0.15)'
                                                                         })}>
                                            <div style={{
                                                width: "85%"
                                            }}>
                                                <CircularProgressbarWithChildren value={this.state.d[5]} strokeWidth={5}
                                                                                 styles={buildStyles({
                                                                                     pathTransitionDuration: 0.5,
                                                                                     trailColor: '#27272A',
                                                                                     pathColor: 'rgba(139,92,246,0.35)'
                                                                                 })}>

                                                    <div>{this.state.d[5]}</div>
                                                </CircularProgressbarWithChildren>
                                            </div>

                                        </CircularProgressbarWithChildren>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/*<div*/}
                        {/*    className="Detection-Card"*/}
                        {/*>*/}
                        {/*    <div*/}
                        {/*        style={{*/}
                        {/*            alignSelf: "stretch",*/}
                        {/*            display: "flex",*/}
                        {/*            flexDirection: "column",*/}
                        {/*            alignItems: "flex-start",*/}
                        {/*            justifyContent: "flex-start",*/}
                        {/*        }}*/}
                        {/*    >*/}
                        {/*        <div*/}
                        {/*            style={{*/}
                        {/*                alignSelf: "stretch",*/}
                        {/*                display: "flex",*/}
                        {/*                flexDirection: "row",*/}
                        {/*                alignItems: "center",*/}
                        {/*                justifyContent: "flex-start",*/}
                        {/*                gap: "4px",*/}
                        {/*            }}*/}
                        {/*        >*/}
                        {/*            <div*/}
                        {/*                style={{*/}
                        {/*                    flex: "1",*/}
                        {/*                    position: "relative",*/}
                        {/*                    lineHeight: "28px",*/}
                        {/*                    fontWeight: "600",*/}
                        {/*                }}*/}
                        {/*            >*/}
                        {/*                C1 Credential Theft*/}
                        {/*            </div>*/}
                        {/*            <img*/}
                        {/*                style={{*/}
                        {/*                    position: "relative",*/}
                        {/*                    width: "24px",*/}
                        {/*                    height: "24px",*/}
                        {/*                    overflow: "hidden",*/}
                        {/*                    flexShrink: "0",*/}
                        {/*                }}*/}
                        {/*                alt=""*/}
                        {/*                src="/main/detection-i.svg"*/}
                        {/*            />*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*    <div*/}
                        {/*        style={{*/}
                        {/*            position: "relative",*/}
                        {/*            width: "232px",*/}
                        {/*            height: "232px",*/}
                        {/*            textAlign: "center",*/}
                        {/*            fontSize: "45px",*/}
                        {/*            color: "#fff",*/}
                        {/*        }}*/}
                        {/*    >*/}
                        {/*        <CircularProgressbarWithChildren value={2} strokeWidth={5}*/}
                        {/*                                         styles={buildStyles({*/}
                        {/*                                             textSize: '25px',*/}
                        {/*                                             pathTransitionDuration: 0.5,*/}
                        {/*                                             textColor: '#fff',*/}
                        {/*                                             trailColor: '#27272A',*/}
                        {/*                                             pathColor: 'rgba(139,92,246)'*/}
                        {/*                                         })}>*/}

                        {/*            1*/}
                        {/*        </CircularProgressbarWithChildren>*/}
                        {/*    </div>*/}
                        {/*    <div*/}
                        {/*        style={{*/}
                        {/*            alignSelf: "stretch",*/}
                        {/*            display: "flex",*/}
                        {/*            flexDirection: "row",*/}
                        {/*            gap: "4px",*/}
                        {/*            fontSize: "12px",*/}
                        {/*            color: "#a1a1aa"*/}
                        {/*        }}*/}
                        {/*    >*/}
                        {/*        <div*/}
                        {/*            style={{*/}
                        {/*                display: "flex",*/}
                        {/*                flexDirection: "row",*/}
                        {/*                flexWrap: "wrap",*/}
                        {/*                alignItems: "flex-start",*/}
                        {/*                justifyContent: "flex-start",*/}
                        {/*                gap: "8px",*/}
                        {/*            }}*/}
                        {/*        >*/}
                        {/*            <div*/}
                        {/*                style={{*/}
                        {/*                    borderRadius: "16px",*/}
                        {/*                    backgroundColor: "rgba(139,92,246,0.25)",*/}
                        {/*                    overflow: "hidden",*/}
                        {/*                    display: "flex",*/}
                        {/*                    flexDirection: "row",*/}
                        {/*                    alignItems: "center",*/}
                        {/*                    justifyContent: "center",*/}
                        {/*                    padding: "0px 8px",*/}
                        {/*                    color: "#8B5CF6",*/}
                        {/*                }}*/}
                        {/*            >*/}
                        {/*                <div*/}
                        {/*                    style={{*/}
                        {/*                        position: "relative",*/}
                        {/*                        letterSpacing: "0.01em",*/}
                        {/*                        lineHeight: "20px",*/}
                        {/*                        fontWeight: "600",*/}
                        {/*                    }}*/}
                        {/*                >*/}
                        {/*                    DISCOVERY*/}
                        {/*                </div>*/}
                        {/*            </div>*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        {/*<div*/}
                        {/*    className="Detection-Card"*/}
                        {/*>*/}
                        {/*    <div*/}
                        {/*        style={{*/}
                        {/*            alignSelf: "stretch",*/}
                        {/*            display: "flex",*/}
                        {/*            flexDirection: "column",*/}
                        {/*            alignItems: "flex-start",*/}
                        {/*            justifyContent: "flex-start",*/}
                        {/*        }}*/}
                        {/*    >*/}
                        {/*        <div*/}
                        {/*            style={{*/}
                        {/*                alignSelf: "stretch",*/}
                        {/*                display: "flex",*/}
                        {/*                flexDirection: "row",*/}
                        {/*                alignItems: "center",*/}
                        {/*                justifyContent: "flex-start",*/}
                        {/*                gap: "4px",*/}
                        {/*            }}*/}
                        {/*        >*/}
                        {/*            <div*/}
                        {/*                style={{*/}
                        {/*                    flex: "1",*/}
                        {/*                    position: "relative",*/}
                        {/*                    lineHeight: "28px",*/}
                        {/*                    fontWeight: "600",*/}
                        {/*                }}*/}
                        {/*            >*/}
                        {/*                S1 Supply Chain Risk*/}
                        {/*            </div>*/}
                        {/*            <img*/}
                        {/*                style={{*/}
                        {/*                    position: "relative",*/}
                        {/*                    width: "24px",*/}
                        {/*                    height: "24px",*/}
                        {/*                    overflow: "hidden",*/}
                        {/*                    flexShrink: "0",*/}
                        {/*                }}*/}
                        {/*                alt=""*/}
                        {/*                src="/main/detection-i.svg"*/}
                        {/*            />*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*    <div*/}
                        {/*        style={{*/}
                        {/*            position: "relative",*/}
                        {/*            width: "232px",*/}
                        {/*            height: "232px",*/}
                        {/*            textAlign: "center",*/}
                        {/*            fontSize: "45px",*/}
                        {/*            color: "#fff",*/}
                        {/*        }}*/}
                        {/*    >*/}
                        {/*        <CircularProgressbarWithChildren value={55} strokeWidth={5}*/}
                        {/*                                         styles={buildStyles({*/}
                        {/*                                             textSize: '25px',*/}
                        {/*                                             pathTransitionDuration: 0.5,*/}
                        {/*                                             textColor: '#fff',*/}
                        {/*                                             trailColor: '#27272A',*/}
                        {/*                                             pathColor: 'rgba(16,185,129,0.35)'*/}
                        {/*                                         })}>*/}
                        {/*            <div style={{*/}
                        {/*                width: "85%"*/}
                        {/*            }}>*/}
                        {/*                <CircularProgressbarWithChildren value={0} strokeWidth={5}*/}
                        {/*                                                 styles={buildStyles({*/}
                        {/*                                                     textSize: '25px',*/}
                        {/*                                                     pathTransitionDuration: 0.5,*/}
                        {/*                                                     textColor: '#fff',*/}
                        {/*                                                     trailColor: '#27272A',*/}
                        {/*                                                     pathColor: '#10B981'*/}
                        {/*                                                 })}*/}
                        {/*                >*/}
                        {/*                    <div style={{marginBottom: -20}}>{0}</div>*/}
                        {/*                    <img alt="" src="/main/dividor.svg"/>*/}
                        {/*                    <div style={{*/}
                        {/*                        marginTop: -20,*/}
                        {/*                        color: 'rgba(16,185,129,0.35)'*/}
                        {/*                    }}>{650}</div>*/}
                        {/*                </CircularProgressbarWithChildren>*/}
                        {/*            </div>*/}
                        {/*        </CircularProgressbarWithChildren>*/}
                        {/*    </div>*/}
                        {/*    <div*/}
                        {/*        style={{*/}
                        {/*            alignSelf: "stretch",*/}
                        {/*            display: "flex",*/}
                        {/*            flexDirection: "row",*/}
                        {/*            gap: "4px",*/}
                        {/*            fontSize: "12px",*/}
                        {/*            color: "#a1a1aa"*/}
                        {/*        }}*/}
                        {/*    >*/}
                        {/*        <div*/}
                        {/*            style={{*/}
                        {/*                display: "flex",*/}
                        {/*                flexDirection: "row",*/}
                        {/*                flexWrap: "wrap",*/}
                        {/*                alignItems: "flex-start",*/}
                        {/*                justifyContent: "flex-start",*/}
                        {/*                gap: "8px",*/}
                        {/*            }}*/}
                        {/*        >*/}
                        {/*            <div*/}
                        {/*                style={{*/}
                        {/*                    borderRadius: "16px",*/}
                        {/*                    backgroundColor: "rgba(16,185,129,0.25)",*/}
                        {/*                    overflow: "hidden",*/}
                        {/*                    display: "flex",*/}
                        {/*                    flexDirection: "row",*/}
                        {/*                    alignItems: "center",*/}
                        {/*                    justifyContent: "center",*/}
                        {/*                    padding: "0px 8px",*/}
                        {/*                    color: "#10B981",*/}
                        {/*                }}*/}
                        {/*            >*/}
                        {/*                <div*/}
                        {/*                    style={{*/}
                        {/*                        position: "relative",*/}
                        {/*                        letterSpacing: "0.01em",*/}
                        {/*                        lineHeight: "20px",*/}
                        {/*                        fontWeight: "600",*/}
                        {/*                    }}*/}
                        {/*                >*/}
                        {/*                    DISCOVERY*/}
                        {/*                </div>*/}
                        {/*            </div>*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                    </div>
                    <div
                        style={{
                            alignSelf: "stretch",
                            display: "flex",
                            flexDirection: "row",
                            flexWrap: "wrap",
                            alignItems: "flex-start",
                            justifyContent: "flex-start",
                            gap: "8px",
                            width: "100%"
                        }}
                    >
                        <div
                            className="Col-Div"
                            style={{
                                height: this.getMapBoxHeight() - 8,
                                maxHeight: this.getMapBoxHeight() - 8,
                                minWidth: "608px"
                            }}
                        >


                            <div style={{
                                display: "flex",
                                flexDirection: "row",
                                gap: "8px",
                                height: "50%",
                                maxHeight: "50%",
                                width: "100%"
                            }}
                            >
                                <div
                                    className="Data-Card"
                                    id="packet-card"
                                    style={{
                                        maxWidth: "220px",
                                        height: "100%",
                                    }}
                                >
                                    <div
                                        style={{
                                            flex: "1",
                                            display: "flex",
                                            flexDirection: "column",
                                            alignItems: "flex-start",
                                            justifyContent: "flex-start",
                                            padding: "8px 0px",
                                            gap: "24px",
                                            width: "100%",
                                            height: "100%",
                                        }}
                                    >
                                        <div
                                            style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                alignItems: "flex-start",
                                                justifyContent: "flex-start",
                                                width: "100%",
                                                gap: "8px",
                                            }}
                                        >
                                            <div
                                                style={{
                                                    alignSelf: "stretch",
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    alignItems: "center",
                                                    justifyContent: "flex-start",
                                                    gap: "4px",
                                                }}
                                            >
                                                <img
                                                    style={{
                                                        position: "relative",
                                                        width: "24px",
                                                        height: "24px",
                                                    }}
                                                    alt=""
                                                    src="/main/dash-bdv-i.svg"
                                                />
                                                <div
                                                    style={{
                                                        flex: "1",
                                                        position: "relative",
                                                        lineHeight: "28px",
                                                        fontWeight: "600",
                                                        marginLeft: "3px",
                                                    }}
                                                >
                                                    ShadowVault
                                                </div>
                                                {/*<MuiTooltip title={this.props.tooltip_info("Packet Analysis")} arrow>*/}
                                                {/*    <img*/}
                                                {/*        style={{*/}
                                                {/*            position: "relative",*/}
                                                {/*            width: "24px",*/}
                                                {/*            height: "24px",*/}
                                                {/*            overflow: "hidden",*/}
                                                {/*            flexShrink: "0",*/}
                                                {/*        }}*/}
                                                {/*        alt=""*/}
                                                {/*        src="/main/detection-i.svg"*/}
                                                {/*    />*/}
                                                {/*</MuiTooltip>*/}
                                            </div>
                                        </div>
                                        <div
                                            style={{
                                                position: "relative",
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                gap: "8px",
                                                textAlign: "center",
                                                color: "#fff",
                                                width: "100%",
                                                height: "75%"
                                            }}
                                        >
                                            <div className="Pulsating-Card"
                                                 style={{
                                                     marginTop: -77,
                                                     width: "190px",
                                                     height: "190px",
                                                     border: "20px solid rgba(217,70,239)",
                                                     animation: this.state.d[6] > 0 ? "card-pulsate 1.5s ease-out infinite" : ""
                                                 }}/>
                                            <div style={{
                                                width: "70%",
                                                fontSize: 34
                                            }}
                                            >
                                                <CircularProgressbarWithChildren value={this.state.d[6]} strokeWidth={5}
                                                                                 styles={buildStyles({
                                                                                     pathTransitionDuration: 0.5,
                                                                                     textColor: '#fff',
                                                                                     trailColor: '#27272A',
                                                                                     pathColor: '#D946EF'
                                                                                 })}>

                                                    <div style={{marginBottom: -20}}>{this.state.d[6]}</div>
                                                    <img alt="dividor" width={100} src="/main/dividor.svg"/>
                                                    <div style={{
                                                        marginTop: -20,
                                                        color: 'rgba(217,70,239,0.35)'
                                                    }}>{this.state.stats["bdv"] ? this.state.stats["bdv"] : 0}</div>

                                                </CircularProgressbarWithChildren>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className="Data-Card"
                                    id="threat-hunting"
                                    style={{
                                        height: "100%",
                                        maxWidth: "calc(100% - 220px)"
                                    }}
                                >
                                    <div
                                        style={{
                                            alignSelf: "stretch",
                                            flex: "1",
                                            display: "flex",
                                            flexDirection: "column",
                                            alignItems: "flex-start",
                                            justifyContent: "flex-start",
                                            padding: "8px 0px",
                                            gap: "24px",
                                        }}
                                    >
                                        <div
                                            style={{
                                                alignSelf: "stretch",
                                                display: "flex",
                                                flexDirection: "column",
                                                alignItems: "flex-start",
                                                justifyContent: "flex-start",
                                                gap: "8px",
                                            }}
                                        >
                                            <div
                                                style={{
                                                    alignSelf: "stretch",
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    alignItems: "center",
                                                    justifyContent: "flex-start",
                                                    gap: "4px",
                                                }}
                                            >
                                                <img
                                                    style={{
                                                        position: "relative",
                                                        width: "24px",
                                                        height: "24px",
                                                    }}
                                                    alt=""
                                                    src="/main/active-i.svg"
                                                />
                                                <div
                                                    style={{
                                                        flex: "1",
                                                        position: "relative",
                                                        lineHeight: "28px",
                                                        fontWeight: "600",
                                                        marginLeft: "3px",
                                                    }}
                                                >
                                                    ETI Threat Hunting (Zero-Hour)
                                                </div>
                                                <MuiTooltip title={this.props.tooltip_info("ETI Threat Hunting")} arrow>
                                                    <img
                                                        style={{
                                                            position: "relative",
                                                            width: "24px",
                                                            height: "24px",
                                                            overflow: "hidden",
                                                            flexShrink: "0",
                                                        }}
                                                        alt=""
                                                        src="/main/detection-i.svg"
                                                    />
                                                </MuiTooltip>
                                            </div>
                                        </div>
                                        <div
                                            style={{
                                                alignSelf: "stretch",
                                                display: "flex",
                                                flexDirection: "column",
                                                alignItems: "start",
                                                justifyContent: "flex-start",
                                                padding: "10px 10px 10px 10px",
                                                gap: "8px",
                                                textAlign: "start",
                                                fontSize: "12px",
                                                color: "#fff",
                                                height: "100%",
                                                width: "100%",
                                                borderRadius: "8px",
                                                overflow: "hidden",
                                                marginTop: "-15px",
                                                marginRight: "auto",
                                                background: "#27272a"
                                            }}
                                        >
                                            <div
                                                style={{
                                                    width: "100%",
                                                    height: "100%",
                                                    overflow: "hidden"
                                                }}
                                                ref={this.eti_feed_ref}
                                            >
                                                {this.state.eti_feed.map((item, i) =>
                                                    <div style={{
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        overflow: "hidden",
                                                        color: item.includes("kryptocti") || item.includes("kryptokloud") ? "#06b6d4" : "white"
                                                    }}>{item}</div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div
                                className="Data-Card"
                                id="ep-card"
                                style={{
                                    minWidth: "500px"
                                }}
                            >
                                <div
                                    style={{
                                        flex: "1",
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "flex-start",
                                        justifyContent: "flex-start",
                                        padding: "8px 0px",
                                        gap: "24px",
                                        width: "100%",
                                        height: "100%"
                                    }}
                                >
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            alignItems: "flex-start",
                                            justifyContent: "flex-start",
                                            gap: "8px",
                                            width: "100%",
                                        }}
                                    >
                                        <div
                                            style={{
                                                alignSelf: "stretch",
                                                display: "flex",
                                                flexDirection: "row",
                                                alignItems: "center",
                                                justifyContent: "flex-start",
                                                gap: "4px",
                                            }}
                                        >
                                            <img
                                                style={{
                                                    position: "relative",
                                                    width: "24px",
                                                    height: "24px",
                                                }}
                                                alt=""
                                                src="/main/eponline-i.svg"
                                            />
                                            <div
                                                style={{
                                                    flex: "1",
                                                    position: "relative",
                                                    lineHeight: "28px",
                                                    fontWeight: "600",
                                                    marginLeft: "3px",
                                                }}
                                            >
                                                Endpoint Statistics
                                            </div>
                                            <MuiTooltip title={this.props.tooltip_info("Endpoint Statistics")} arrow>
                                                <img
                                                    style={{
                                                        position: "relative",
                                                        width: "24px",
                                                        height: "24px",
                                                        overflow: "hidden",
                                                        flexShrink: "0",
                                                    }}
                                                    alt=""
                                                    src="/main/detection-i.svg"
                                                />
                                            </MuiTooltip>
                                        </div>
                                    </div>
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            alignItems: "center",
                                            justifyContent: "flex-start",
                                            gap: "8px",
                                            textAlign: "center",
                                            fontSize: "16px",
                                            color: "#fff",
                                            width: "100%",
                                            height: "75%"
                                        }}
                                    >
                                        <div style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            alignItems: "left",
                                            gap: "16px"
                                        }}>
                                            <div style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                alignItems: "center",
                                                gap: "16px"
                                            }}
                                            >
                                                <div className="SNumberBox">
                                                    Active
                                                </div>
                                                <img
                                                    alt=""
                                                    src="/main/active-circle.svg"
                                                />

                                                <div
                                                    style={{
                                                        letterSpacing: "0.01em",
                                                        lineHeight: "20px",
                                                        fontWeight: "600",
                                                    }}
                                                >
                                                    {this.getOnlineNumber()} / {this.getEndpointLimit()}
                                                </div>
                                            </div>
                                            <div style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                alignItems: "center",
                                                gap: "16px"
                                            }}
                                            >
                                                <div className="SNumberBox">
                                                    Trend
                                                </div>
                                                <img
                                                    alt=""
                                                    src="/main/trend-circle.svg"
                                                />

                                                <div
                                                    style={{
                                                        letterSpacing: "0.01em",
                                                        lineHeight: "20px",
                                                        fontWeight: "600",
                                                    }}
                                                >
                                                    Week in Review
                                                </div>
                                            </div>
                                        </div>
                                        <div style={{
                                            alignSelf: "stretch",
                                            width: "65%",
                                            display: "flex",
                                            marginLeft: "auto",
                                        }}>

                                            <Line
                                                data={{
                                                    labels: [this.getDayLabel((new Date()).getDay() - 7), '', '', this.getDayLabel((new Date()).getDay() - 6), '', '', '', this.getDayLabel((new Date()).getDay() - 5), '', '', '', this.getDayLabel((new Date()).getDay() - 4), '', '', '', this.getDayLabel((new Date()).getDay() - 3), '', '', '', this.getDayLabel((new Date()).getDay() - 2), '', '', '', this.getDayLabel((new Date()).getDay() - 1), '', '', '', 'current'],
                                                    datasets: [
                                                        {
                                                            fill: false,
                                                            label: 'Live',
                                                            data: Array.from({length: 28}, this.getOnlineNumber),
                                                            borderColor: '#eab308',
                                                            backgroundColor: '#eab308',
                                                        },
                                                        {
                                                            fill: true,
                                                            label: 'Endpoints',
                                                            data: this.getOnlineHistory(),
                                                            borderColor: '#06b6d4',
                                                            backgroundColor: '#164E63',
                                                        },

                                                    ],
                                                }}
                                                options={{
                                                    scales: {
                                                        y: {ticks: {stepSize: 10}, min: 0},
                                                        x: {
                                                            ticks: {
                                                                autoSkip: false
                                                            }
                                                        }
                                                    },
                                                    responsive: true,
                                                    plugins: {
                                                        legend: {
                                                            display: false
                                                        },
                                                        labels: {
                                                            render: 'label',
                                                            arc: true,
                                                            position: 'outside'
                                                        }
                                                    },
                                                    scaleShowValues: true,
                                                    animation: {duration: 500},
                                                    maintainAspectRatio: false
                                                }}
                                            />
                                        </div>

                                    </div>
                                </div>
                            </div>

                        </div>
                        <div
                            className="Col-Div"
                            style={{
                                position: "relative",
                                width: "calc((100%/2.75) * 0.75 - 8px)"
                            }}
                        >
                            <div style={{
                                borderRadius: "8px",
                                justifyContent: "flexStart",
                                boxSizing: "border-box",
                                width: "100%",
                                gap: "8px",
                            }}
                                 ref={this.map_box_ref}
                                 id="Map-Card">
                                <div
                                    style={{
                                        backgroundColor: "#18181b",
                                        borderRadius: "8px",
                                        boxSizing: "border-box",
                                        gap: "24px",
                                        padding: "16px 24px 16px 16px",
                                        height: "100%",
                                    }}
                                >
                                    <div
                                        style={{
                                            alignSelf: "stretch",
                                            flex: "1",
                                            display: "flex",
                                            flexDirection: "column",
                                            alignItems: "flex-start",
                                            justifyContent: "flex-start",
                                            padding: "8px 0px",
                                            gap: "24px",
                                        }}
                                    >
                                        <div
                                            style={{
                                                alignSelf: "stretch",
                                                display: "flex",
                                                flexDirection: "column",
                                                alignItems: "flex-start",
                                                justifyContent: "flex-start",
                                                gap: "8px",
                                            }}
                                        >
                                            <div
                                                style={{
                                                    alignSelf: "stretch",
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    alignItems: "center",
                                                    justifyContent: "flex-start",
                                                    gap: "4px",
                                                }}
                                            >
                                                <img
                                                    style={{
                                                        position: "relative",
                                                        width: "24px",
                                                        height: "24px",
                                                    }}
                                                    alt=""
                                                    src="/main/map-i.svg"
                                                />
                                                <div
                                                    style={{
                                                        flex: "1",
                                                        position: "relative",
                                                        lineHeight: "28px",
                                                        fontWeight: "600",
                                                        marginLeft: "3px",
                                                        color: "#84CC16",
                                                    }}
                                                >
                                                    Geo-Zone Threat Map
                                                </div>
                                                <MuiTooltip title={this.props.tooltip_info("Geo-Zone Threat Map")}
                                                            arrow>
                                                    <img
                                                        style={{
                                                            position: "relative",
                                                            width: "24px",
                                                            height: "24px",
                                                            overflow: "hidden",
                                                            flexShrink: "0",
                                                        }}
                                                        alt=""
                                                        src="/main/detection-i.svg"
                                                    />
                                                </MuiTooltip>
                                            </div>

                                        </div>

                                        <div ref={this.map_ref} style={{
                                            width: "100%",
                                        }}>
                                            <div style={{
                                                position: "absolute",
                                                marginLeft: "20px",
                                                marginTop: "20px"
                                            }}>
                                                <img width={this.getMapWidth() - 19} height={this.getMapHeight() - 60}
                                                     src="/main/crosshair.svg" alt=""/>
                                            </div>
                                            <div style={{
                                                position: "absolute",
                                                marginLeft: "20px",
                                                marginTop: "20px",
                                                transform: "scale(0.8)",
                                                animation: "rotateLine 10s linear infinite",
                                            }}>
                                                <img width={(this.getMapWidth() - 19)}
                                                     height={(this.getMapHeight() - 60)} src="/main/circle-scanner.svg"
                                                     alt=""/>
                                            </div>
                                            <div>
                                                <WorldMap backgroundColor="#18181B" color="red"
                                                          size={this.getMapWidth()} data={this.state.map_data}
                                                          styleFunction={getStyle}

                                                />
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            className="Col-Div"
                            style={{
                                height: this.getMapBoxHeight() - 8,
                            }}
                        >
                            <div
                                className="Data-Card"
                                id="threat-hunting"
                                style={{
                                    minWidth: "850px",
                                    height: "calc(100% - 250px)"
                                }}
                            >
                                <div
                                    style={{
                                        alignSelf: "stretch",
                                        flex: "1",
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "flex-start",
                                        justifyContent: "flex-start",
                                        padding: "8px 0px",
                                        gap: "12px",
                                    }}
                                >
                                    <div
                                        style={{
                                            alignSelf: "stretch",
                                            display: "flex",
                                            flexDirection: "column",
                                            alignItems: "flex-start",
                                            justifyContent: "flex-start",
                                            gap: "8px",
                                        }}
                                    >
                                        <div
                                            style={{
                                                alignSelf: "stretch",
                                                display: "flex",
                                                flexDirection: "row",
                                                alignItems: "center",
                                                justifyContent: "flex-start",
                                                gap: "4px",
                                            }}
                                        >
                                            <img
                                                style={{
                                                    position: "relative",
                                                    width: "24px",
                                                    height: "24px",
                                                }}
                                                alt=""
                                                src="/main/trending-i.svg"
                                            />
                                            <div
                                                style={{
                                                    flex: "1",
                                                    position: "relative",
                                                    lineHeight: "28px",
                                                    fontWeight: "600",
                                                    marginLeft: "3px",
                                                }}
                                            >
                                                Criminal & Nation State Trending Analytics
                                            </div>
                                            <MuiTooltip
                                                title={this.props.tooltip_info("Criminal & Nation State Trending Analytics")}
                                                arrow>
                                                <img
                                                    style={{
                                                        position: "relative",
                                                        width: "24px",
                                                        height: "24px",
                                                        overflow: "hidden",
                                                        flexShrink: "0",
                                                    }}
                                                    alt=""
                                                    src="/main/detection-i.svg"
                                                />
                                            </MuiTooltip>
                                        </div>
                                    </div>
                                    <div style={{
                                        display: "flex",
                                        gap: "32px",
                                        width: "100%"
                                    }}
                                    >
                                        <div
                                            style={{width: "50%", display: "flex", flexDirection: "column", gap: "8px"}}
                                        >
                                            {
                                                Object.entries(this.get_trending_up()).map(([key, value]) =>
                                                    <div style={{
                                                        width: "100%",
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        gap: "8px"
                                                    }}
                                                    >
                                                        <div style={{
                                                            width: "100%",
                                                            paddingLeft: 4,
                                                            paddingRight: 8,
                                                            justifyContent: 'flex-start',
                                                            alignItems: 'center',
                                                            gap: 8,
                                                            display: 'inline-flex'
                                                        }}
                                                        >
                                                            <button
                                                                onClick={() => this.props.open_threat_card(value["malware"])}
                                                                style={{
                                                                    backgroundColor: "transparent",
                                                                    cursor: "pointer",
                                                                    border: "none",
                                                                }}
                                                            >
                                                                <div style={{
                                                                    padding: 8,
                                                                    background: '#27272A',
                                                                    borderRadius: 2,
                                                                    justifyContent: 'flex-start',
                                                                    alignItems: 'center',
                                                                    display: 'flex'
                                                                }}>
                                                                    <img style={{width: 20, height: 20}}
                                                                         src="/main/show-i.svg"
                                                                         alt="show"/>
                                                                </div>
                                                            </button>
                                                            <div style={{
                                                                flex: '1 1 0',
                                                                height: 40,
                                                                paddingRight: 4,
                                                                justifyContent: 'flex-start',
                                                                alignItems: 'center',
                                                                display: 'flex'
                                                            }}
                                                            >
                                                                <div style={{
                                                                    flexDirection: 'column',
                                                                    gap: 8,
                                                                    justifyContent: 'flex-start',
                                                                    alignItems: 'flex-start',
                                                                    display: 'inline-flex'
                                                                }}
                                                                >
                                                                    <div style={{
                                                                        color: 'white',
                                                                        fontSize: 16,
                                                                        fontWeight: '600',
                                                                        lineHeight: 1,
                                                                        wordWrap: 'break-word'
                                                                    }}>{value["malware"]}</div>
                                                                    <div style={{
                                                                        color: 'white',
                                                                        fontSize: 12,
                                                                        fontWeight: '400',
                                                                        lineHeight: 1,
                                                                        letterSpacing: 0.06,
                                                                        wordWrap: 'break-word'
                                                                    }}>{value["group"]}</div>
                                                                </div>
                                                            </div>
                                                            <div style={{
                                                                flexDirection: 'column',
                                                                justifyContent: 'flex-start',
                                                                alignItems: 'flex-start',
                                                                display: 'inline-flex'
                                                            }}>
                                                                <div style={{
                                                                    alignSelf: 'stretch',
                                                                    height: 20,
                                                                    flexDirection: 'column',
                                                                    justifyContent: 'center',
                                                                    alignItems: 'center',
                                                                    gap: 4,
                                                                    display: 'flex'
                                                                }}>
                                                                    <div style={{
                                                                        alignSelf: 'stretch',
                                                                        justifyContent: 'flex-end',
                                                                        alignItems: 'center',
                                                                        gap: 8,
                                                                        display: 'inline-flex'
                                                                    }}>
                                                                        <div style={{
                                                                            paddingLeft: 8,
                                                                            gap: 4,
                                                                            paddingRight: 8,
                                                                            borderRadius: 16,
                                                                            overflow: 'hidden',
                                                                            justifyContent: 'center',
                                                                            alignItems: 'center',
                                                                            display: 'flex'
                                                                        }}>
                                                                            <div style={{
                                                                                color: '#A1A1AA',
                                                                                fontSize: 14,
                                                                                fontWeight: '600',
                                                                                lineHeight: 1,
                                                                                letterSpacing: 0.07,
                                                                                wordWrap: 'break-word'
                                                                            }}>+{value["count"]}</div>
                                                                            <img src={"/main/trending-up-i.svg"}
                                                                                 alt={"trending-up"}/>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            }
                                        </div>

                                        <div
                                            style={{width: "50%", display: "flex", flexDirection: "column", gap: "8px"}}
                                        >
                                            {
                                                Object.entries(this.get_trending_down()).map(([key, value]) =>
                                                    <div style={{
                                                        width: "100%",
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        gap: "8px"
                                                    }}
                                                    >
                                                        <div style={{
                                                            width: "100%",
                                                            paddingLeft: 4,
                                                            paddingRight: 8,
                                                            justifyContent: 'flex-start',
                                                            alignItems: 'center',
                                                            gap: 8,
                                                            display: 'inline-flex'
                                                        }}
                                                        >
                                                            <button
                                                                onClick={() => this.props.open_threat_card(value["malware"])}
                                                                style={{
                                                                    backgroundColor: "transparent",
                                                                    cursor: "pointer",
                                                                    border: "none",
                                                                }}
                                                            >
                                                                <div style={{
                                                                    padding: 8,
                                                                    background: '#27272A',
                                                                    borderRadius: 2,
                                                                    justifyContent: 'flex-start',
                                                                    alignItems: 'center',
                                                                    display: 'flex'
                                                                }}>
                                                                    <img style={{width: 20, height: 20}}
                                                                         src="/main/show-i.svg"
                                                                         alt="show"/>
                                                                </div>
                                                            </button>
                                                            <div style={{
                                                                flex: '1 1 0',
                                                                height: 40,
                                                                paddingRight: 4,
                                                                justifyContent: 'flex-start',
                                                                alignItems: 'center',
                                                                display: 'flex'
                                                            }}
                                                            >
                                                                <div style={{
                                                                    flexDirection: 'column',
                                                                    gap: 8,
                                                                    justifyContent: 'flex-start',
                                                                    alignItems: 'flex-start',
                                                                    display: 'inline-flex'
                                                                }}
                                                                >
                                                                    <div style={{
                                                                        color: 'white',
                                                                        fontSize: 16,
                                                                        fontWeight: '600',
                                                                        lineHeight: 1,
                                                                        wordWrap: 'break-word'
                                                                    }}>{value["malware"]}</div>
                                                                    <div style={{
                                                                        color: 'white',
                                                                        fontSize: 12,
                                                                        fontWeight: '400',
                                                                        lineHeight: 1,
                                                                        letterSpacing: 0.06,
                                                                        wordWrap: 'break-word'
                                                                    }}>{value["group"]}</div>
                                                                </div>
                                                            </div>
                                                            <div style={{
                                                                flexDirection: 'column',
                                                                justifyContent: 'flex-start',
                                                                alignItems: 'flex-start',
                                                                display: 'inline-flex'
                                                            }}>
                                                                <div style={{
                                                                    alignSelf: 'stretch',
                                                                    height: 20,
                                                                    flexDirection: 'column',
                                                                    justifyContent: 'center',
                                                                    alignItems: 'center',
                                                                    gap: 4,
                                                                    display: 'flex'
                                                                }}>
                                                                    <div style={{
                                                                        paddingLeft: 8,
                                                                        gap: 4,
                                                                        paddingRight: 8,
                                                                        borderRadius: 16,
                                                                        overflow: 'hidden',
                                                                        justifyContent: 'center',
                                                                        alignItems: 'center',
                                                                        display: 'flex'
                                                                    }}>
                                                                        <div style={{
                                                                            color: '#A1A1AA',
                                                                            fontSize: 14,
                                                                            fontWeight: '600',
                                                                            lineHeight: 1,
                                                                            letterSpacing: 0.07,
                                                                            wordWrap: 'break-word'
                                                                        }}>-{value["diff"]}</div>
                                                                        <img src={"/main/trending-down-i.svg"}
                                                                             alt={"trending-down"}/>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div style={{
                                display: "flex",
                                flexDirection: "row",
                                gap: "8px",
                                height: "250px",
                                width: "100%"
                            }}
                            >
                                <div
                                    className="Data-Card"
                                    id="ioa-search"
                                    style={{
                                        height: "100%",
                                        width: "50%"
                                    }}
                                >
                                    <div
                                        style={{
                                            alignSelf: "stretch",
                                            flex: "1",
                                            display: "flex",
                                            flexDirection: "column",
                                            alignItems: "flex-start",
                                            justifyContent: "flex-start",
                                            padding: "8px 0px",
                                            gap: "24px",
                                        }}
                                    >
                                        <div
                                            style={{
                                                alignSelf: "stretch",
                                                display: "flex",
                                                flexDirection: "column",
                                                alignItems: "flex-start",
                                                justifyContent: "flex-start",
                                                gap: "8px",
                                            }}
                                        >
                                            <div
                                                style={{
                                                    alignSelf: "stretch",
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    alignItems: "center",
                                                    justifyContent: "flex-start",
                                                    gap: "4px",
                                                }}
                                            >
                                                <img
                                                    style={{
                                                        position: "relative",
                                                        width: "24px",
                                                        height: "24px",
                                                    }}
                                                    alt=""
                                                    src="/main/hijack-i.svg"
                                                />
                                                <div
                                                    style={{
                                                        flex: "1",
                                                        position: "relative",
                                                        lineHeight: "28px",
                                                        fontWeight: "600",
                                                        marginLeft: "3px",
                                                    }}
                                                >
                                                    C2 IoA Search
                                                </div>
                                                <MuiTooltip title={this.props.tooltip_info("C2 IoA Search")} arrow>
                                                    <img
                                                        style={{
                                                            position: "relative",
                                                            width: "24px",
                                                            height: "24px",
                                                            overflow: "hidden",
                                                            flexShrink: "0",
                                                        }}
                                                        alt=""
                                                        src="/main/detection-i.svg"
                                                    />
                                                </MuiTooltip>
                                            </div>
                                        </div>
                                        <div
                                            style={{
                                                alignSelf: "stretch",
                                                display: "flex",
                                                flexDirection: "column",
                                                alignItems: "start",
                                                justifyContent: "flex-start",
                                                gap: "8px",
                                                textAlign: "start",
                                                fontSize: "10px",
                                                color: "#fff",
                                                maxHeight: "100%",
                                                overflow: "hidden",
                                                marginTop: "-15px",
                                                marginBottom: "-10px",
                                                marginRight: "auto",
                                                width: "100%"
                                            }}
                                        >
                                            <div style={{
                                                display: "flex",
                                                width: "100%"
                                            }}
                                            >
                                                <Form.Control style={{
                                                    background: "#27272A",
                                                    color: "white",
                                                    width: "calc(100% - 40px)"
                                                }} type="text" placeholder="Search IoA"
                                                              defaultValue={this.state.search_result.ioa === "-" ? "" : this.state.search_result.ioa}
                                                              onKeyPress={event => event.key === "Enter" && this.submitSearch(event)}/>


                                                <button
                                                    onClick={() => this.props.open_threat_card(this.state.search_result.malware)}
                                                    id="threat_card_btn"
                                                    style={{
                                                        backgroundColor: "transparent",
                                                        cursor: "pointer",
                                                        border: "none",
                                                    }}
                                                >
                                                    <div style={{
                                                        padding: 8,
                                                        background: '#27272A',
                                                        borderRadius: 2,
                                                        justifyContent: 'flex-start',
                                                        alignItems: 'center',
                                                        display: 'flex'
                                                    }}>
                                                        <img style={{width: 20, height: 20}} src="/main/show-i.svg"
                                                             alt="show"/>
                                                    </div>
                                                </button>

                                            </div>

                                            <div className="console-div" style={{
                                                border: "1px solid " + this.getSearchColor()
                                            }}
                                            >

                                                <div className="inner-console-div">
                                                    <div>
                                                        Priority:
                                                    </div>
                                                    <div>
                                                        Malware:
                                                    </div>
                                                    <div>
                                                        Country:
                                                    </div>
                                                    <div>
                                                        First Seen:
                                                    </div>
                                                    <div>
                                                        Last Seen:
                                                    </div>
                                                </div>
                                                <div className="inner-console-div" style={{
                                                    color: "white"
                                                }}>
                                                    <div>
                                                        {this.state.search_result.priority}
                                                    </div>
                                                    <div>
                                                        {this.state.search_result.malware}
                                                    </div>
                                                    <div style={{
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        gap: "5px"
                                                    }}>
                                                        {
                                                            this.state.search_result.country[1] === "" ?
                                                                <img alt="" height={20} src="/main/no-flag.svg"/>
                                                                :
                                                                <CircleFlag height={20}
                                                                            countryCode={this.state.search_result.country[1]}/>
                                                        }

                                                        {this.state.search_result.country[0]}
                                                    </div>
                                                    <div>
                                                        {
                                                            this.state.search_result.first_seen && this.state.search_result.first_seen !== "-" ?
                                                                get_time_string(parseInt(this.state.search_result.first_seen), true)
                                                                :
                                                                "-"
                                                        }
                                                    </div>
                                                    <div>
                                                        {
                                                            this.state.search_result.last_seen && this.state.search_result.last_seen !== "-" ?
                                                                get_time_string(parseInt(this.state.search_result.last_seen), true)
                                                                :
                                                                "-"
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className="Data-Card"
                                    id="top-country"
                                    style={{
                                        width: "50%",
                                        height: "100%",
                                    }}
                                >
                                    <div
                                        style={{
                                            flex: "1",
                                            display: "flex",
                                            flexDirection: "column",
                                            alignItems: "flex-start",
                                            justifyContent: "flex-start",
                                            padding: "8px 0px",
                                            gap: "12px",
                                            width: "100%",
                                            height: "100%",
                                        }}
                                    >
                                        <div
                                            style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                alignItems: "flex-start",
                                                justifyContent: "flex-start",
                                                width: "100%",
                                                gap: "8px",
                                            }}
                                        >
                                            <div
                                                style={{
                                                    alignSelf: "stretch",
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    alignItems: "center",
                                                    justifyContent: "flex-start",
                                                    gap: "4px",
                                                }}
                                            >
                                                <img
                                                    style={{
                                                        position: "relative",
                                                        width: "24px",
                                                        height: "24px",
                                                    }}
                                                    alt=""
                                                    src="/main/c2-country-i.svg"
                                                />
                                                <div
                                                    style={{
                                                        flex: "1",
                                                        position: "relative",
                                                        lineHeight: "28px",
                                                        fontWeight: "600",
                                                        marginLeft: "3px",
                                                    }}
                                                >
                                                    Top 5 Countries Hosting Malware
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                alignItems: "center",
                                                justifyContent: "flex-start",
                                                gap: "8px",
                                                textAlign: "center",
                                                fontSize: "16px",
                                                color: "#fff",
                                                width: "100%",
                                                height: "75%"
                                            }}
                                        >
                                            <div style={{
                                                width: "100%",
                                                display: "flex",
                                                flexDirection: "column",
                                                alignItems: "flex-start",
                                                justifyContent: "flex-start",
                                                gap: "8px",
                                                color: "white"
                                            }}
                                            >
                                                {
                                                    this.get_top_countries().map((entry, i) =>
                                                        <div style={{
                                                            alignSelf: "stretch",
                                                            display: "flex",
                                                            flexDirection: "row",
                                                            alignItems: "center",
                                                            justifyContent: "flex-start",
                                                            gap: "8px"
                                                        }}>
                                                            <CircleFlag height={24} countryCode={entry["country"][1]}/>
                                                            <div>
                                                                {entry["country"][0]}
                                                            </div>

                                                            <div style={{
                                                                marginLeft: 'auto',
                                                                alignItems: 'center',
                                                                display: 'inline-flex'
                                                            }}>
                                                                <div style={{
                                                                    paddingLeft: 8,
                                                                    paddingRight: 8,
                                                                    paddingTop: 4,
                                                                    paddingBottom: 4,
                                                                    background: 'rgba(244, 63, 94, 0.25)',
                                                                    borderRadius: 16,
                                                                    overflow: 'hidden',
                                                                    justifyContent: 'center',
                                                                    alignItems: 'center',
                                                                    display: 'flex'
                                                                }}>
                                                                    <div style={{
                                                                        color: '#F43F5E',
                                                                        fontSize: 16,
                                                                        fontWeight: '600',
                                                                        lineHeight: 1,
                                                                        letterSpacing: 0.07,
                                                                        wordWrap: 'break-word'
                                                                    }}>{entry["per"]}%
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    )
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>


                        </div>
                    </div>
                    <div
                        style={{
                            alignSelf: "stretch",
                            display: "flex",
                            flexDirection: "row",
                            flexWrap: "wrap",
                            alignItems: "flex-start",
                            justifyContent: "flex-start",
                            gap: "8px",
                            width: "calc(100% - 8px)"
                        }}
                    >
                        <div style={{
                            position: "relative",
                            width: "100%",
                            borderRadius: "8px",
                            height: "100%"
                        }}
                        >

                            <div style={{
                                padding: "20px 24px 16px 16px",
                                display: "flex",
                                alignItems: "center",
                                position: "absolute",
                                lineHeight: "28px",
                                fontWeight: "600",
                                fontSize: "20px",
                                gap: "16px",
                                zIndex: "10"
                            }}
                            >
                                <button onClick={() => this.setState({table_tab_idx: 0})}
                                        style={{
                                            display: "flex",
                                            fontSize: "15px",
                                            cursor: "pointer",
                                            borderRadius: "4px 4px 0 0",
                                            alignItems: "center",
                                            border: "none",
                                            backgroundColor: this.state.table_tab_idx === 0 ? "#27272A" : "#18181B",
                                            color: "#F9B233",
                                            padding: "4px 16px",
                                            gap: "4px"
                                        }}
                                >
                                    <IActivity style={{
                                        width: "20px",
                                        height: "20px",
                                        color: this.state.table_tab_idx === 0 ? "#65A30D" : "#365314",
                                    }}/>
                                    Activity Log
                                </button>
                                <button onClick={() => this.setState({table_tab_idx: 1})}
                                        style={{
                                            display: "flex",
                                            fontSize: "15px",
                                            cursor: "pointer",
                                            borderRadius: "4px 4px 0 0",
                                            alignItems: "center",
                                            border: "none",
                                            backgroundColor: this.state.table_tab_idx === 1 ? "#27272A" : "#18181B",
                                            color: "#F9B233",
                                            padding: "4px 16px",
                                            gap: "4px"
                                        }}
                                >
                                    <IRFI style={{
                                        width: "20px",
                                        height: "20px",
                                        color: this.state.table_tab_idx === 1 ? "#65A30D" : "#365314",
                                    }}/>
                                    RFI Log
                                </button>
                                <button onClick={() => {
                                }}
                                        style={{
                                            display: "flex",
                                            fontSize: "15px",
                                            cursor: "pointer",
                                            borderRadius: "4px 4px 0 0",
                                            alignItems: "center",
                                            border: "none",
                                            backgroundColor: this.state.table_tab_idx === 2 ? "#27272A" : "#18181B",
                                            color: "#F9B233",
                                            padding: "4px 16px",
                                            gap: "4px"
                                        }}
                                >
                                    <IReporting style={{
                                        width: "20px",
                                        height: "20px",
                                        color: this.state.table_tab_idx === 2 ? "#65A30D" : "#365314",
                                    }}/>
                                    Reporting
                                </button>
                            </div>

                            <ThemeProvider theme={this.tableTheme}
                            >
                                {
                                    this.state.table_tab_idx === 1 ?
                                        <MaterialReactTable
                                            enableRowActions={true}
                                            displayColumnDefOptions={{
                                                "mrt-row-actions": {
                                                    header: 'RFI'
                                                },
                                            }}
                                            renderRowActions={({row}) => (
                                                <button
                                                    className="menu-entry"
                                                    onClick={() => this.setState({
                                                        showRFIModal: true,
                                                        modal_data: row["original"]
                                                    })}
                                                    style={{
                                                        width: "100px"
                                                    }}
                                                    id="show-btn"
                                                >
                                                    <div
                                                        style={{
                                                            flex: "1",
                                                            display: "flex",
                                                            flexDirection: "row",
                                                            alignItems: "center",
                                                            justifyContent: "center",
                                                            gap: "8px",
                                                            borderRadius: "2px",
                                                            padding: "7px",
                                                            backgroundColor: "#27272A"
                                                        }}
                                                    >
                                                        <div
                                                            style={{
                                                                position: "relative",
                                                                fontSize: "14px",
                                                                letterSpacing: "0.01em",
                                                                lineHeight: "20px",
                                                                fontFamily: "Inter",
                                                                color: "#fff",
                                                                textAlign: "left",
                                                            }}
                                                        >
                                                            View
                                                        </div>
                                                        <img
                                                            style={{
                                                                position: "relative",
                                                                width: "20px",
                                                                height: "20px",
                                                            }}
                                                            alt=""
                                                            src="/main/show-i.svg"
                                                        />
                                                    </div>
                                                </button>
                                            )}
                                            columns={rfi_columns} data={this.state.stats["rfi"]}
                                            initialState={{
                                                sorting: [{
                                                    id: 'urgency',
                                                    desc: false
                                                }]
                                            }}
                                        />
                                        :
                                        <MaterialReactTable
                                            enableRowActions={true}
                                            displayColumnDefOptions={{
                                                "mrt-row-actions": {
                                                    header: 'Tipper'
                                                },
                                            }}
                                            renderRowActions={({row}) => (
                                                <button
                                                    className="menu-entry"
                                                    onClick={() => this.OpenModal(row["original"])}
                                                    style={{
                                                        width: "100px"
                                                    }}
                                                    id="show-btn"
                                                >
                                                    <div
                                                        style={{
                                                            flex: "1",
                                                            display: "flex",
                                                            flexDirection: "row",
                                                            alignItems: "center",
                                                            justifyContent: "center",
                                                            gap: "8px",
                                                            borderRadius: "2px",
                                                            padding: "7px",
                                                            backgroundColor: "#27272A",
                                                            width: "50px"
                                                        }}
                                                    >
                                                        <div
                                                            style={{
                                                                position: "relative",
                                                                fontSize: "14px",
                                                                letterSpacing: "0.01em",
                                                                lineHeight: "20px",
                                                                fontFamily: "Inter",
                                                                color: "#fff",
                                                                textAlign: "left",
                                                            }}
                                                        >
                                                            View
                                                        </div>
                                                        <img
                                                            style={{
                                                                position: "relative",
                                                                width: "20px",
                                                                height: "20px",
                                                            }}
                                                            alt=""
                                                            src="/main/show-i.svg"
                                                        />
                                                    </div>
                                                </button>
                                            )}
                                            columns={columns} data={this.getTableData()}
                                            initialState={{
                                                sorting: [{
                                                    id: 'priority',
                                                    desc: false
                                                }]
                                            }}
                                        />
                                }
                            </ThemeProvider>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}