import React, { Component } from 'react'
import { ReactComponent as IVictims } from '../icons/table/victims.svg';
import { ReactComponent as ICyberattacks } from '../icons/table/cyberattacks.svg';
import { CircularProgressbar, buildStyles, CircularProgressbarWithChildren } from 'react-circular-progressbar';
import {byFips as country_lookup_fips, byIso as country_lookup, byCountry as country_by_name} from "country-code-lookup";
import {createTheme, ThemeProvider, Tooltip as MUITooltip} from "@mui/material";
import {MaterialReactTable} from "material-react-table";
import 'react-circular-progressbar/dist/styles.css';
import { CircleFlag } from "react-circle-flags"
import ReactModal from 'react-modal';
import {CountryContext} from "react-svg-worldmap";
import MDEditor, { commands } from '@uiw/react-md-editor';
import Switch from "react-switch";
import { Pie } from 'react-chartjs-2';

import {
    Chart as ChartJS,
    ArcElement,
    Tooltip,
    Legend
} from 'chart.js';

ChartJS.register(ArcElement, Tooltip, Legend);

function get_time_string(time, table) {
    if (time) {
        let date = new Date(time * 1000);
        if (table) return new Date(date - date.getTimezoneOffset() * 60 * 1000).toISOString().replace('.000Z','').replace('T',' ');
        return new Date(date - date.getTimezoneOffset() * 60 * 1000).toISOString().replace('.000Z', '').replaceAll('-', '/').replace('T', ' - ');
    }
    return ""
}

function getISO(code, iso2) {
    if (code === "")return "Unknown"
    try {
        if (code) {
            let lookup = country_by_name(code)
            if (!lookup)lookup = country_lookup(code)
            if (!lookup)lookup = country_lookup_fips(code)
            if (iso2) {
                return lookup.iso2.toLowerCase()
            }
            return lookup.country
        }
    } catch (e) {}
    return ""
}

function isObject(object) {
    return object != null && typeof object === 'object';
}

function deepEqual(object1, object2) {
    const keys1 = Object.keys(object1);
    const keys2 = Object.keys(object2);

    if (keys1.length !== keys2.length) {
        return false;
    }

    for (const key of keys1) {
        const val1 = object1[key];
        const val2 = object2[key];
        const areObjects = isObject(val1) && isObject(val2);
        if (
            (areObjects && !deepEqual(val1, val2)) ||
            (!areObjects && val1 !== val2)
        ) {
            return false;
        }
    }

    return true;
}

export default class SupplyChainRisk extends Component {
    pending_request = false

    tableTheme = createTheme({
        palette: {
            mode: "dark",
            background: {
                default: '#18181b',
            },
        },
        typography: {
            button: {
                textTransform: 'none',
                fontSize: '1.2rem',
            },
        },
        components: {
            MuiTooltip: {
                styleOverrides: {
                    tooltip: {
                        background: "#A1A1AA",
                    },
                },
            },
            MuiSwitch: {
                styleOverrides: {
                    thumb: {
                        color: 'black',
                    },
                },
            },
        },
    });


    cyberattacks_columns = [
        {
            accessorKey: 'victim',
            header: 'Victim',
            size: 100,
        },
        {
            accessorKey: 'domain',
            header: 'Domain',
            size: 100,
        },
        {
            accessorKey: 'country',
            header: 'Country',
            size: 100,
            Cell: ({ cell }) => (
                <div style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "8px",
                    justifyItems: "center",
                    alignItems: "center"
                }}>
                    <div>
                        {
                            cell.row.original["country"] === "" ?
                                <img alt="" height={24} src="/main/no-flag.svg"/>
                                :
                                <CircleFlag height={24} countryCode={getISO(cell.row.original["country"], true)}/>
                        }
                    </div>
                    <div>
                        {getISO(cell.row.original["country"])}
                    </div>
                </div>
            ),
        },
        {
            accessorKey: 'url',
            header: 'URL',
            size: 100,
        },
        {
            accessorKey: 'date',
            header: 'Date',
            size: 100,
            Cell: ({cell}) => (
                <div>
                    {get_time_string(cell.getValue(), true)}
                </div>
            ),
        }
    ]

    victims_columns = [
        {
            accessorKey: 'group_name',
            header: 'Group',
            size: 100,
            Cell: ({ cell }) => (
                <div>
                    {
                        cell.getValue().charAt(0).toUpperCase() +  cell.getValue().slice(1)
                    }
                </div>
            ),
        },
        {
            accessorKey: 'post_title',
            header: 'Post Title',
            size: 150,
        },
        {
            accessorKey: 'country',
            header: 'Country',
            size: 150,
            Cell: ({ cell }) => (
                <div style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "8px",
                    justifyItems: "center",
                    alignItems: "center"
                }}>
                    <div>
                        {
                            cell.row.original["country"] === "" ?
                                <img alt="" height={24} src="/main/no-flag.svg"/>
                                :
                                <CircleFlag height={24} countryCode={getISO(cell.row.original["country"], true)}/>
                        }
                    </div>
                    <div>
                        {getISO(cell.row.original["country"])}
                    </div>
                </div>
            ),
        },
        {
            accessorKey: 'post_url',
            header: 'Post URL',
            size: 150,
            Cell: ({ cell }) => (
                <div>
                    {
                        cell.row.original["post_url"] === "" ?
                            "Hidden Service - Post not available"
                            :
                            cell.row.original["post_url"]
                    }
                </div>
            ),
        },
        {
            accessorKey: 'published',
            header: 'Date',
            size: 150,
            Cell: ({cell}) => (
                <div>
                    {get_time_string(cell.getValue(), true)}
                </div>
            ),
        },
    ];

    constructor(props) {
        super(props);
        this.state = {
            username: '',
            company: '',
            key: '',
            keys: {},
            stats: {},
            d: [0, 0, 0, 0],
            p: [0, 0, 0, 0],
            p_val: [0, 0, 0, 0],
            card_stats: {},
            last_cyberattacks_data: [],
            last_victims_data: [],
            victims_data: [],
            cyberattacks_data: [],
            all_cyberattacks_data: [],
            all_victims_data: [],
            table_tab_idx: 0,
            showModal: false,
            modal_data: {backend:{}, stats:{}},
            show_editor: false,
            show_sector_editor: false,
            show_chain_editor: false,
            show_mitre_editor: false,
            show_countries_editor: false,
            all_time: false,
        };
        this.set_dials = this.set_dials.bind(this);
        this.CloseModal = this.CloseModal.bind(this);
        this.getCountryRender = this.getCountryRender.bind(this);
        this.getCardStats = this.getCardStats.bind(this);
        this.update_table = this.update_table.bind(this);
        this.update_threat_data = this.update_threat_data.bind(this);
        this.show_threat_editor = this.show_threat_editor.bind(this);
        this.get_threat_sectors = this.get_threat_sectors.bind(this);
        this.get_threat_countries = this.get_threat_countries.bind(this);
        this.get_chain_sectors = this.get_chain_sectors.bind(this);
        this.get_mitre_data = this.get_mitre_data.bind(this);
        this.check_threat_card = this.check_threat_card.bind(this);
        this.get_top_countries = this.get_top_countries.bind(this);
        this.separate_victims_attacks = this.separate_victims_attacks.bind(this);
        this.tick = this.tick.bind(this);
    }

    get_top_countries() {
        let country_map = {}
        let total = 0
        let top = []
        for (let item of this.state.latest_sc_data) {
            if (item["country"][0] in country_map) country_map[item["country"][0]]["count"]++
            else {
                country_map[item["country"][0]] = {}
                country_map[item["country"][0]]["country"] = item["country"]
                country_map[item["country"][0]]["count"] = 1
            }
            total++
        }
        let top_countries = Object.keys(country_map).sort(function(a,b) { return country_map[b]["count"] - country_map[a]["count"]; });

        for (let item of top_countries) {
            let hold = country_map[item]
            hold["per"] = Math.ceil(hold["count"] * 100 / total)
            top.push(country_map[item])
        }
        return top.slice(0,5)
    }

    show_threat_editor(val) {
        if (this.state.show_editor !== val) {
            this.setState({show_editor: val})
        }
    }

    get_chain_sectors() {
        if (this.state.modal_data["backend"]["chain"]) {
            return this.state.modal_data["backend"]["chain"]
        }
        return ""
    }

    get_threat_sectors() {
        if (this.state.modal_data["backend"]["sectors"]) {
            return this.state.modal_data["backend"]["sectors"]
        }
        return ""
    }

    get_threat_countries() {
        if (this.state.modal_data["backend"]["countries"]) {
            return this.state.modal_data["backend"]["countries"]
        }
        return ""
    }

    get_mitre_data() {
        if (this.state.modal_data["backend"]["mitre"]) {
            return this.state.modal_data["backend"]["mitre"].split(",")
        }
        return ["TA0011", "https://attack.mitre.org/tactics/TA0011/"]
    }

    separate_victims_attacks(data, attack) {
        let ret = []
        for (let item of data) {
            if (attack) {
                if (!("group_name" in item)) {
                    ret.push(item)
                }
            } else {
                if ("group_name" in item) {
                    ret.push(item)
                }
            }
        }
        return ret
    }

    check_threat_card() {
        this.set_dials()
    }

    CloseModal () {
        if (this.state.key !== '') {
            let jsonData = {
                "key": this.state.key,
                "malware": this.state.modal_data["malware"],
                "data": this.state.modal_data["backend"]
            }
            // fetch('https://ti-endpoint.cybershadows.co.uk/' + this.state.company + "/update_threat_card", {
            //     method: 'POST',
            //     mode: 'cors',
            //     body: JSON.stringify(jsonData)
            // }).then((response) => {
            //     if (response.ok) {
            //         response.json().then(json => {
            //             if (json.success) {
            //                 this.setState({ modal_data: {backend:{}, stats:{}}, showModal: false });
            //             } else {
            //                 console.log("threat landscape", "failed to update", json)
            //                 if (this.state.company !== "KryptoKloud") {
            //                     this.setState({ modal_data: {backend:{}, stats:{}}, showModal: false });
            //                 }
            //             }
            //         });
            //     }
            // }).catch((err) => {
            //     console.log("threat landscape", err)
            // })
        }
    }

    getCountryRender() {
        if (this.state.modal_data["country"]) {
            let code = this.state.modal_data["country"][1]
            return (
                <div style={{display: "flex", alignItems: "center", gap: "8px"}}>
                    {this.state.modal_data["country"][0]}
                    {code === "" ? "": <CircleFlag height={20} countryCode={code} />}
                </div>
            )
        }
    }

    update_threat_data(key, value) {
        let modal_data = this.state.modal_data
        modal_data["backend"][key] = value
        this.setState({ modal_data: modal_data});
    }

    tick(all_time) {
        if (this.pending_request)return
        else this.pending_request = true
        let data = this.props.page_data()
        let key = data.keys[data.company]
        let company = data.company
        if (this.state.company !== data.company) {
            this.setState({username: data.username, key: key, company: company, keys: data.keys})
        }
        if (key !== '') {
            let jsonData = {
                "key": key,
            }
            fetch('https://ti-endpoint.cybershadows.co.uk/' + company + "/latest_sc_data", {
                method: 'POST',
                mode: 'cors',
                body: JSON.stringify(jsonData)
            }).then((response) => {
                if (response.ok) {
                    response.json().then(json => {
                        if (json.data) {
                            this.setState({stats: json,
                                last_cyberattacks_data: this.separate_victims_attacks(json["data"], true),
                                last_victims_data: this.separate_victims_attacks(json["data"], false)
                            })
                            if (!all_time) {
                                this.setState({
                                    cyberattacks_data: this.separate_victims_attacks(json["data"], true),
                                    victims_data: this.separate_victims_attacks(json["data"], false)
                                })
                            }
                        }
                    });
                }
            }).catch((err) => {
                console.log("threat landscape", err)
            }).finally(() => {
                this.pending_request = false
            })
            fetch('https://ti-endpoint.cybershadows.co.uk/' + company + "/all_sc_data", {
                method: 'POST',
                mode: 'cors',
                body: JSON.stringify(jsonData)
            }).then((response) => {
                if (response.ok) {
                    response.json().then(json => {
                        if (json.data) {
                            this.setState({
                                all_cyberattacks_data: this.separate_victims_attacks(json["data"], true),
                                all_victims_data: this.separate_victims_attacks(json["data"], false)
                            })
                            if (all_time) {
                                this.setState({
                                    cyberattacks_data: this.separate_victims_attacks(json["data"], true),
                                    victims_data: this.separate_victims_attacks(json["data"], false)
                                })
                            }
                        }
                    });
                }
            }).catch((err) => {
                console.log("threat landscape", err)
            }).finally(() => {
                this.pending_request = false
            })
        }
    }

    componentDidUpdate(prevProps, prevState, Snapshot) {
        this.props.page_state("supplychainrisk", this.state)
    }

    componentDidMount() {
        let state = this.props.page_state("supplychainrisk")
        if (state) {
            this.setState(state)
        }

        let data = this.props.page_data()
        if (data.username && data.username !== '') {
            this.setState({
                username: data.username,
                key: data.keys[data.company],
                company: data.company,
                keys: data.keys
            })
        }
        this.tick()
        this.interval_update = setInterval(
            () => this.check_threat_card(),
            1000
        );
    }
    componentWillUnmount() {
        clearInterval(this.interval_update);
    }

    update_table(e) {
        console.log(parseInt(e.currentTarget.id))
    }

    getCardStats() {
        let now = Math.floor(new Date().getTime()/1000.0)
        let group_map = {}
        let country_map = {}
        let victimology = {}
        let new_groups = {}
        let ransomware_country = {}
        let total = 0
        let total_year = 0
        let total_thirty_days = 0
        let total_seven_days = 0
        let total_24_hours = 0
        let hours_24_ago = now - (86400)
        let seven_days_ago = now - (86400 * 7)
        let thirty_days_ago = now - (86400 * 30)
        let one_year_ago = now - (86400 * 365)
        for (let item of this.state.all_victims_data) {
            if (!(item["group_name"] in group_map)) {
                group_map[item["group_name"]] = {
                    "total": 0,
                    "seven_days": 0,
                    "published": item["published"],
                }
            }
            total++
            if (item["published"] > hours_24_ago) {
                total_24_hours++
            }
            if (item["published"] > seven_days_ago) {
                group_map[item["group_name"]]["seven_days"]++
                total_seven_days++
            }
            if (item["published"] > thirty_days_ago) {
                group_map[item["group_name"]]["total"]++
                if (getISO(item["country"]) in country_map) {
                    country_map[getISO(item["country"])]++
                } else {
                    country_map[getISO(item["country"])] = 1
                }
                total_thirty_days++
            }
            if (item["published"] > one_year_ago) {
                total_year++
            }
            if (item["published"] < group_map[item["group_name"]]["published"]) {
                group_map[item["group_name"]]["published"] = item["published"]
            }
        }
        let total_sorted_group = Object.keys(group_map).sort(function(a,b) { return group_map[b]["total"] - group_map[a]["total"]; }).slice(0, 5);

        let time_sorted_group = Object.keys(group_map).sort(function(a,b) { return group_map[b]["published"] - group_map[a]["published"]; }).slice(0, 5);

        let top_sorted_countries = Object.keys(country_map).sort(function(a,b) { return country_map[b] - country_map[a]; }).slice(0, 5);

        for (let group of total_sorted_group) {
            victimology[group] = group_map[group]
        }

        for (let group of time_sorted_group) {
            new_groups[group] = group_map[group]
            new_groups[group]["published"] = get_time_string(new_groups[group]["published"], true).split(" ")[0]
        }

        for (let country of top_sorted_countries) {
            ransomware_country[country] = {
                "count": country_map[country],
                "per": Math.floor(country_map[country] / total_thirty_days * 100)
            }
        }

        return {
            victimology: victimology,
            new_groups: new_groups,
            ransomware_country: ransomware_country,
            indexed: {
                "Total": total,
                "Year": total_year,
                "Month": total_thirty_days,
                "Week": total_seven_days,
                "24h": total_24_hours
            }
        }
    }

    set_dials() {
        let breaches = 0

        let groups = {}
        let active_groups = []
        let now = Math.floor(new Date().getTime()/1000.0)
        let thirty_days_ago = now - (86400 * 30)
        let ninety_days_ago = now - (86400 * 90)

        for (let item of this.state.all_victims_data) {
            let name = item["group_name"].charAt(0).toUpperCase() + item["group_name"].slice(1)
            if (!(name in groups)) {
                groups[name] = item
            }
            if (item["published"] > thirty_days_ago) {
                breaches++
            }
        }
        let ninety_days_group_count = 0
        for (let item of Object.keys(groups)) {
            if (groups[item]["published"] > ninety_days_ago) {
                if (groups[item]["published"] > thirty_days_ago) {
                    if (!(item in active_groups)) {
                        active_groups.push(item)
                    }
                }
                ninety_days_group_count++
            }
        }

        let d = [breaches, active_groups.length]
        let p = [(breaches / this.state.all_victims_data.length) * 100, (active_groups.length / ninety_days_group_count) * 100]
        let p_val = [this.state.all_victims_data.length, ninety_days_group_count]
        let card_stats = this.getCardStats()
        if (!deepEqual(this.state.d, d) || !deepEqual(this.state.p, p) || !deepEqual(this.state.p_val, p_val) || !deepEqual(this.state.card_stats, card_stats)) {
            this.setState({
                d: d,
                p: p,
                p_val: p_val,
                card_stats: card_stats
            })

        }
    }

    render() {
        return (
            <div
                style={{
                    alignSelf: "stretch",
                    flex: "1",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                    padding: "8px 0px 0px",
                    gap: "8px",
                    textAlign: "left",
                    fontSize: "22px",
                    color: "#fafafa",
                    overflow: "auto"
                }}
                id="inner-data"
            >

                <ReactModal
                    ariaHideApp={false}
                    isOpen={this.state.showModal}
                    contentLabel="Threat Card"
                    style={{
                        overlay: {
                            zIndex: 1000,
                            background: "rgba(19, 19, 19, 0.75)"
                        },
                        content: {
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            width: "1140px",
                            height: "680px",
                            background: "#171717"
                        },
                    }}
                >
                    <div style={{
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        flexDirection: "row",
                        gap: "16px"
                    }}
                    >

                        <div
                            style={{
                                width: "calc(50% - 8px)",
                                height: "100%",
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "flex-start",
                                color: "white",
                                gap: "16px"
                            }}
                        >
                            <div style={{
                                width: "100%",
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "flex-start",
                                lineHeight: "28px",
                                fontWeight: "600",
                            }}>
                                <div style={{color: "#F9B233"}}>
                                    Threat Intelligence
                                </div>
                            </div>

                            <div className="inner-tip-box"
                                 style={{
                                     height: "474px",
                                 }}
                            >
                                <div style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    gap: "4px",
                                    lineHeight: "28px",
                                    fontWeight: "600",
                                    alignItems: "center",
                                    height: "24px",
                                }}>
                                    <img
                                        style={{width: "24px", height: "24px"}}
                                        alt=""
                                        src="/main/threat-i.svg"
                                    />
                                    <div style={{
                                        color: "#a1daf8"
                                    }}>Threat Card
                                    </div>
                                </div>

                                <div style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    marginTop: "4px",
                                    height: "calc(100%-24px)"
                                }}
                                     id="host-data"
                                >
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        color: "#A1A1AA",
                                        gap: "4px",
                                        width: "180px"
                                    }} id="section1">
                                        <div>Threat:</div>
                                        <div>Type:</div>
                                        <div>First Identified:</div>
                                    </div>

                                    <div style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        gap: "4px",
                                        width: "280px"
                                    }} id="section2">
                                        <div>{this.state.modal_data["malware"]}</div>
                                        <div>
                                            <input className="transparent-input" type='text' name='type'
                                                   value={this.state.modal_data["backend"]["type"]}
                                                   onChange={(data) => {
                                                       this.update_threat_data("type", data.target.value)
                                                   }}
                                            />
                                        </div>
                                        <div>
                                            <input className="transparent-input" type='text' name='first_identified'
                                                   value={this.state.modal_data["backend"]["first_identified"]}
                                                   onChange={(data) => {
                                                       this.update_threat_data("first_identified", data.target.value)
                                                   }}
                                            />
                                        </div>
                                    </div>
                                </div>
                                {
                                    this.state.show_editor ?
                                        <div
                                            style={{
                                                marginTop: "16px",
                                                width: "100%",
                                                height: "330px"
                                            }}
                                        >
                                            <MDEditor
                                                value={this.state.modal_data["backend"]["threat_text"]}
                                                visibleDragbar={false}
                                                onChange={(data) => {
                                                    this.update_threat_data("threat_text", data)
                                                }}
                                                height={300}
                                            />
                                            <button onClick={() => {
                                                this.show_threat_editor(false)
                                            }}
                                                    style={{
                                                        background: "#171717",
                                                        color: "white",
                                                        borderRadius: "8px",
                                                    }}
                                            >
                                                done
                                            </button>
                                        </div>
                                        :
                                        <div
                                            onClick={() => {
                                                this.show_threat_editor(true)
                                            }}
                                            style={{
                                                marginTop: "16px",
                                                width: "100%",
                                                height: "330px"
                                            }}>
                                            <MDEditor.Markdown source={this.state.modal_data["backend"]["threat_text"]}
                                                               style={{
                                                                   padding: "8px 12px 8px 8px",
                                                                   color: "white",
                                                                   background: "#171717",
                                                                   borderRadius: "8px",
                                                                   whiteSpace: 'pre-wrap',
                                                                   width: "100%",
                                                                   height: "100%",
                                                                   maxHeight: "100%",
                                                                   overflow: "auto"
                                                               }}
                                            />
                                        </div>

                                }

                            </div>

                            <div className="inner-tip-box"
                                 style={{
                                     height: "104px"
                                 }}
                            >
                                <div style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    gap: "4px",
                                    lineHeight: "28px",
                                    fontWeight: "600",
                                    alignItems: "center",
                                    height: "24px",
                                }}>
                                    <img
                                        style={{width: "24px", height: "24px"}}
                                        alt=""
                                        src="/main/victimology-i.svg"
                                    />
                                    <div style={{
                                        color: "#a1daf8"
                                    }}>Victimology
                                    </div>
                                </div>
                                <div style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    marginTop: "4px",
                                    height: "calc(100%-24px)"
                                }}
                                     id="malware-data">
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        color: "#A1A1AA",
                                        gap: "4px",
                                        width: "108px"
                                    }} id="section1">
                                        <div>Impacting:</div>
                                        <div>Countries:</div>
                                    </div>

                                    <div style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        gap: "4px"
                                    }} id="section2">
                                        <div style={{
                                            height: "24px",
                                            width: "100%",
                                            display: "flex",
                                            flexDirection: "row",
                                            gap: "8px"
                                        }}
                                             onClick={() => {
                                                 this.setState({show_sector_editor: true})
                                             }}
                                        >
                                            {this.state.show_sector_editor ?
                                                <input className="transparent-input" type='text' name='first_identified'
                                                       value={this.state.modal_data["backend"]["sectors"]}
                                                       onChange={(data) => {
                                                           this.update_threat_data("sectors", data.target.value)
                                                       }}
                                                       onBlur={() => {
                                                           this.setState({show_sector_editor: false})
                                                       }}
                                                />
                                                :
                                                this.get_threat_sectors().split(",").map((item, i) =>
                                                    <div style={{
                                                        padding: "0px 8px 0px 8px",
                                                        borderRadius: "16px",
                                                        background: "rgba(161,161,170, 0.25)",
                                                        color: "rgba(161,161,170, 100)",
                                                        fontWeight: "bold",
                                                    }}>{item}</div>
                                                )
                                            }
                                        </div>
                                        <div style={{
                                            height: "24px",
                                            width: "100%",
                                            display: "flex",
                                            flexDirection: "row",
                                            gap: "8px"
                                        }}
                                             onClick={() => {
                                                 this.setState({show_countries_editor: true})
                                             }}
                                        >
                                            {this.state.show_countries_editor ?
                                                <input className="transparent-input" type='text' name='first_identified'
                                                       value={this.state.modal_data["backend"]["countries"]}
                                                       onChange={(data) => {
                                                           this.update_threat_data("countries", data.target.value)
                                                       }}
                                                       onBlur={() => {
                                                           this.setState({show_countries_editor: false})
                                                       }}
                                                />
                                                :
                                                this.get_threat_countries().split(",").map((item, i) =>
                                                    <div style={{
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        gap: "8px"
                                                    }}
                                                    >
                                                        {
                                                            item.split(":")[0] === "Global" ?
                                                                <img alt="" height={24} src="/main/no-flag.svg"/>
                                                                :
                                                                <CircleFlag height={20}
                                                                            countryCode={item.split(":")[1]}/>
                                                        }
                                                        <div>{item.split(":")[0]}</div>
                                                    </div>
                                                )
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div style={{
                            width: "calc(50% - 8px)",
                            height: "100%",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "flex-start",
                            color: "white",
                            gap: "16px"
                        }}
                        >
                            <div style={{
                                width: "100%",
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "flex-start",
                                lineHeight: "28px",
                                fontWeight: "600",
                            }}>
                                <div style={{color: "#F9B233"}}>
                                    Live {this.state.modal_data["malware"]} Infrastructure ITW
                                </div>
                                <button
                                    style={{
                                        cursor: "pointer",
                                        border: "none",
                                        backgroundColor: "transparent",
                                        position: "relative",
                                        width: "24px",
                                        height: "24px",
                                        marginLeft: "auto"
                                    }}
                                    id="close-modal"
                                    onClick={this.CloseModal}
                                >
                                    <img
                                        alt=""
                                        src="/main/white-close.svg"
                                    />
                                </button>
                            </div>
                            <div
                                style={{
                                    display: "flex",
                                    height: "250px",
                                    flexDirection: "column",
                                    gap: "8px"
                                }}
                            >
                                <div style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    height: "230px",
                                    gap: "16px",
                                }}>
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        gap: "8px"
                                    }}>
                                        <CircularProgressbarWithChildren value={this.state.modal_data["stats"]["live_percent"]} strokeWidth={5}
                                                                         styles={buildStyles({
                                                                             pathTransitionDuration: 0.5,
                                                                             textColor: '#fff',
                                                                             trailColor: '#27272A',
                                                                             pathColor: 'rgba(244,63,94,100)'
                                                                         })}
                                        >
                                            <div style={{
                                                fontSize: "40px"
                                            }}>{this.state.modal_data["stats"]["live_count"]}</div>
                                        </CircularProgressbarWithChildren>
                                        <div style={{
                                            width: "100%",
                                            display: "flex",
                                            justifyContent: "center"
                                        }}>
                                            <div style={{
                                                padding: "0px 8px 0px 8px",
                                                borderRadius: "16px",
                                                background: "rgba(244,63,94,0.25)",
                                                color: "rgba(244,63,94, 100)",
                                                fontWeight: "bold",
                                                alignItems: "center",
                                                textAlign: "center",
                                                display: "inline-block"
                                            }}>
                                                Live
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        gap: "8px"
                                    }}>
                                        <CircularProgressbarWithChildren value={this.state.modal_data["stats"]["last_week_percent"]} strokeWidth={5}
                                                                         styles={buildStyles({
                                                                             pathTransitionDuration: 0.5,
                                                                             textColor: '#fff',
                                                                             trailColor: '#27272A',
                                                                             pathColor: 'rgba(245,158,11,100)'
                                                                         })}
                                        >
                                            <div style={{
                                                fontSize: "40px"
                                            }}>{this.state.modal_data["stats"]["last_week_count"]}</div>
                                        </CircularProgressbarWithChildren>
                                        <div style={{
                                            width: "100%",
                                            display: "flex",
                                            justifyContent: "center"
                                        }}>
                                            <div style={{
                                                padding: "0px 8px 0px 8px",
                                                display: "inline-block",
                                                borderRadius: "16px",
                                                background: "rgba(245,158,11,0.25)",
                                                color: "rgba(245,158,11, 100)",
                                                fontWeight: "bold",
                                                alignItems: "center",
                                                textAlign: "center",
                                            }}>
                                                Total Last Week
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        gap: "8px"
                                    }}>

                                        <CircularProgressbarWithChildren value={this.state.modal_data["stats"]["last_month_percent"]} strokeWidth={5}
                                                                         styles={buildStyles({
                                                                             pathTransitionDuration: 0.5,
                                                                             textColor: '#fff',
                                                                             trailColor: '#27272A',
                                                                             pathColor: 'rgba(6,182,212,100)'
                                                                         })}
                                        >
                                            <div style={{
                                                fontSize: "40px"
                                            }}>{this.state.modal_data["stats"]["last_month_count"]}</div>
                                        </CircularProgressbarWithChildren>
                                        <div style={{
                                            width: "100%",
                                            display: "flex",
                                            justifyContent: "center"
                                        }}>
                                            <div style={{
                                                padding: "0px 8px 0px 8px",
                                                display: "inline-block",
                                                borderRadius: "16px",
                                                background: "rgba(6,182,212,0.25)",
                                                color: "rgba(6,182,212, 100)",
                                                fontWeight: "bold",
                                                alignItems: "center",
                                                textAlign: "center",
                                            }}>
                                                Total Last Month
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className="inner-tip-box"
                                 style={{
                                     height: "180px"
                                 }}
                            >
                                <div style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    gap: "4px",
                                    lineHeight: "28px",
                                    fontWeight: "600",
                                    alignItems: "center",
                                    height: "24px",
                                }}>
                                    <img
                                        style={{width: "24px", height: "24px"}}
                                        alt=""
                                        src="/main/infrastructure-i.svg"
                                    />
                                    <div style={{
                                        color: "#a1daf8"
                                    }}>Infrastructure Analysis
                                    </div>
                                </div>
                                <div style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    marginTop: "4px",
                                    height: "calc(100%-24px)"
                                }}
                                     id="malware-data">
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        color: "#A1A1AA",
                                        gap: "4px",
                                        width: "100px"
                                    }} id="section1">
                                        <div>Top ASN:</div>
                                        <div>Top ISP:</div>
                                        <div>Last Active:</div>
                                        <div>Average Life:</div>
                                        <div>Top Country:</div>
                                    </div>

                                    <div style={{
                                        display: "flex",
                                        flexDirection: "column",

                                        gap: "4px"
                                    }} id="section2">
                                        <div>{this.state.modal_data["stats"]["top_asn"]}</div>
                                        <div>{this.state.modal_data["stats"]["top_isp"]}</div>
                                        <div>{this.state.modal_data["stats"]["last_active"]}</div>
                                        <div>{this.state.modal_data["stats"]["average_up"]}</div>
                                        <div>{this.state.modal_data["stats"]["top_country"]}</div>
                                    </div>
                                </div>
                            </div>

                            <div className="inner-tip-box"
                                 style={{
                                     height: "70px"
                                 }}
                            >
                                <div style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    gap: "4px",
                                    lineHeight: "28px",
                                    fontWeight: "600",
                                    alignItems: "center",
                                    height: "24px",
                                }}>
                                    <img
                                        style={{width: "24px", height: "24px"}}
                                        alt=""
                                        src="/main/threat-mitre-i.svg"
                                    />
                                    <div style={{
                                        color: "#a1daf8"
                                    }}
                                         onClick={() => {
                                             this.setState({show_mitre_editor: true})
                                         }}
                                    >ATT&CK Mapping
                                    </div>
                                </div>
                                <div style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    marginTop: "4px",
                                    height: "calc(100%-24px)"
                                }}
                                     id="malware-data">
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        color: "#A1A1AA",
                                        gap: "4px",
                                        width: "108px"
                                    }} id="section1">
                                        <div>Reference:</div>
                                    </div>

                                    <div style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        width: "100%",
                                        gap: "4px"
                                    }} id="section2"
                                    >

                                        {this.state.show_mitre_editor ?
                                            <input className="transparent-input" type='text' name='mitre'
                                                   value={this.state.modal_data["backend"]["mitre"]}
                                                   onChange={(data) => {
                                                       this.update_threat_data("mitre", data.target.value)
                                                   }}
                                                   onBlur={() => {
                                                       this.setState({show_mitre_editor: false})
                                                   }}
                                            />
                                            :
                                            <button
                                                onClick={() => this.props.open_mitre(this.state.modal_data["malware"])}
                                                style={{
                                                    width: "80px",
                                                    cursor: "pointer",
                                                    border: "none",
                                                    padding: 0,
                                                    color: "#06B6D4",
                                                    overflow: "hidden",
                                                    backgroundColor: "transparent",
                                                    display: "inline-block"

                                            }}
                                            >
                                                {this.get_mitre_data()[0]}
                                            </button>
                                        }

                                    </div>
                                </div>
                            </div>
                            <div className="inner-tip-box"
                                 style={{
                                     height: "70px"
                                 }}
                            >
                                <div style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    gap: "4px",
                                    lineHeight: "28px",
                                    fontWeight: "600",
                                    alignItems: "center",
                                    height: "24px",
                                }}>
                                    <img
                                        style={{width: "24px", height: "24px"}}
                                        alt=""
                                        src="/main/attack-chain-i.svg"
                                    />
                                    <div style={{
                                        color: "#a1daf8"
                                    }}>Attack Chain
                                    </div>
                                </div>
                                <div style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    marginTop: "4px",
                                    height: "calc(100%-24px)"
                                }}
                                     id="malware-data">
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        color: "#A1A1AA",
                                        gap: "4px",
                                        width: "148px"
                                    }} id="section1">
                                        <div>Attack Method:</div>
                                    </div>

                                    <div style={{
                                        height: "24px",
                                        width: "100%",
                                        display: "flex",
                                        flexDirection: "row",
                                        gap: "8px"
                                    }}
                                         onClick={() => {
                                             this.setState({show_chain_editor: true})
                                         }}
                                         id="section2">
                                        {this.state.show_chain_editor ?
                                            <input className="transparent-input" type='text' name='attack_chain'
                                                   value={this.state.modal_data["backend"]["chain"]}
                                                   onChange={(data) => {
                                                       this.update_threat_data("chain", data.target.value)
                                                   }}
                                                   onBlur={() => {
                                                       this.setState({show_chain_editor: false})
                                                   }}
                                            />
                                            :
                                            this.get_chain_sectors().split(",").map((item, i) =>
                                                <div style={{
                                                    padding: "0px 8px 0px 8px",
                                                    borderRadius: "16px",
                                                    background: "rgba(161,161,170, 0.25)",
                                                    color: "rgba(161,161,170, 100)",
                                                    fontWeight: "bold",
                                                }}>{item}</div>
                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ReactModal>


                <div style={{
                    width: "100%",
                    height: "1px",
                    alignSelf: "stretch",
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                    gap: "8px",
                    color: "#F9B233",
                }}>
                    <div
                        style={{
                            alignSelf: "stretch",
                            display: "flex",
                            flexDirection: "row",
                            flexWrap: "wrap",
                            alignItems: "flex-start",
                            justifyContent: "flex-start",
                            gap: "8px",
                            width: "100%"
                        }}
                    >
                        <div
                            className="Detection-Card Detection-Card-SupplyChain-Page"
                        >
                            <div
                                style={{
                                    alignSelf: "stretch",
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "flex-start",
                                    justifyContent: "flex-start",
                                }}
                            >
                                <div
                                    style={{
                                        alignSelf: "stretch",
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                        justifyContent: "flex-start",
                                        gap: "4px",
                                        zIndex: "10",
                                    }}
                                >
                                    <div
                                        style={{
                                            flex: "1",
                                            position: "relative",
                                            lineHeight: "28px",
                                            fontWeight: "600"
                                        }}
                                    >
                                        Ransomware Breaches
                                    </div>
                                    <MUITooltip title={this.props.tooltip_info("Ransomware Breaches")} arrow>
                                        <img
                                            style={{
                                                position: "relative",
                                                width: "24px",
                                                height: "24px",
                                                overflow: "hidden",
                                                flexShrink: "0",
                                            }}
                                            alt="detection-i"
                                            src="/main/detection-i.svg"
                                        />
                                    </MUITooltip>
                                </div>
                            </div>
                            <div
                                style={{
                                    position: "relative",
                                    width: "232px",
                                    height: "232px",
                                    textAlign: "center",
                                    fontSize: "45px",
                                    color: "#fff",
                                    zIndex: "100"
                                }}
                            >

                                <CircularProgressbarWithChildren value={80} strokeWidth={5}
                                                                 styles={buildStyles({
                                                                     textSize: '25px',
                                                                     pathTransitionDuration: 0.5,
                                                                     textColor: '#fff',
                                                                     trailColor: '#27272A',
                                                                     pathColor: '#F43F5E'
                                                                 })}
                                >
                                    <div>
                                        {this.state.d[0]}
                                    </div>
                                </CircularProgressbarWithChildren>
                            </div>
                            <div
                                style={{
                                    alignSelf: "stretch",
                                    display: "flex",
                                    flexDirection: "row",
                                    gap: "4px",
                                    fontSize: "12px",
                                    color: "#a1a1aa",
                                    justifyContent: "center",
                                    height: "20px"
                                }}
                            >
                                <div
                                    style={{
                                        borderRadius: "16px",
                                        backgroundColor: "rgba(244,63,94,0.25)",
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        padding: "0px 8px",
                                        color: "#F43F5E"
                                    }}
                                >
                                    <div
                                        style={{
                                            position: "relative",
                                            letterSpacing: "0.01em",
                                            lineHeight: "20px",
                                            fontWeight: "600"
                                        }}
                                    >
                                        Last 30 Days
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            className="Detection-Card Detection-Card-SupplyChain-Page"
                        >
                            <div
                                style={{
                                    alignSelf: "stretch",
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "flex-start",
                                    justifyContent: "flex-start",
                                }}
                            >
                                <div
                                    style={{
                                        alignSelf: "stretch",
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                        justifyContent: "flex-start",
                                        gap: "4px",
                                        zIndex: "10",
                                    }}
                                >
                                    <div
                                        style={{
                                            flex: "1",
                                            position: "relative",
                                            lineHeight: "28px",
                                            fontWeight: "600"
                                        }}
                                    >
                                        Active Groups (Count)
                                    </div>
                                    <MUITooltip title={this.props.tooltip_info("Active Groups (Count)")} arrow>
                                        <img
                                            style={{
                                                position: "relative",
                                                width: "24px",
                                                height: "24px",
                                                overflow: "hidden",
                                                flexShrink: "0",
                                            }}
                                            alt="detection-i"
                                            src="/main/detection-i.svg"
                                        />
                                    </MUITooltip>
                                </div>
                            </div>
                            <div
                                style={{
                                    position: "relative",
                                    width: "232px",
                                    height: "232px",
                                    textAlign: "center",
                                    fontSize: "45px",
                                    color: "#fff",
                                    zIndex: "100"
                                }}
                            >

                                <CircularProgressbarWithChildren value={this.state.p[1]} strokeWidth={5}
                                                                 styles={buildStyles({
                                                                     textSize: '25px',
                                                                     pathTransitionDuration: 0.5,
                                                                     textColor: '#fff',
                                                                     trailColor: '#27272A',
                                                                     pathColor: '#84CC16'
                                                                 })}
                                >
                                    <div style={{marginBottom: -20}}>{this.state.d[1]}</div>
                                    <img alt="" src="/main/dividor.svg"/>
                                    <div style={{
                                        marginTop: -20,
                                        color: 'rgba(132,204,22,0.35)'
                                    }}>{this.state.p_val[1]}</div>
                                </CircularProgressbarWithChildren>
                            </div>
                            <div
                                style={{
                                    alignSelf: "stretch",
                                    display: "flex",
                                    flexDirection: "row",
                                    gap: "4px",
                                    fontSize: "12px",
                                    color: "#a1a1aa",
                                    justifyContent: "center",
                                    height: "20px"
                                }}
                            >
                                <div
                                    style={{
                                        borderRadius: "16px",
                                        backgroundColor: "rgba(132,204,22,0.25)",
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        padding: "0px 8px",
                                        color: "#84CC16"
                                    }}
                                >
                                    <div
                                        style={{
                                            position: "relative",
                                            letterSpacing: "0.01em",
                                            lineHeight: "20px",
                                            fontWeight: "600"
                                        }}
                                    >
                                        Active Groups / 30 Days
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            className="Detection-Card Detection-Card-SupplyChain-Page"
                        >
                            <div
                                style={{
                                    alignSelf: "stretch",
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "flex-start",
                                    justifyContent: "flex-start",
                                }}
                            >
                                <div
                                    style={{
                                        alignSelf: "stretch",
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "flex-start",
                                        gap: "4px",
                                        zIndex: "10",
                                    }}
                                >
                                    <div
                                        style={{
                                            flex: "1",
                                            position: "relative",
                                            lineHeight: "28px",
                                            fontWeight: "600"
                                        }}
                                    >
                                        Victimology Analysis
                                    </div>
                                    <MUITooltip title={this.props.tooltip_info("Victimology Analysis")} arrow>
                                        <img
                                            style={{
                                                position: "relative",
                                                width: "24px",
                                                height: "24px",
                                                overflow: "hidden",
                                                flexShrink: "0",
                                            }}
                                            alt="detection-i"
                                            src="/main/detection-i.svg"
                                        />
                                    </MUITooltip>
                                </div>
                            </div>
                            <div
                                style={{
                                    position: "relative",
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: "12px",
                                    width: "100%",
                                    height: "100%",
                                    textAlign: "",
                                    fontSize: "45px",
                                    color: "#fff",
                                    zIndex: "100"
                                }}
                            >
                                <div style={{
                                    marginTop: "-12px",
                                    color: '#A3A3A3',
                                    fontSize: 14,
                                    wordWrap: 'break-word'
                                }}>Victims by Group (Last 30 Days)
                                </div>

                                {
                                    this.state.card_stats.victimology ?
                                        Object.entries(this.state.card_stats.victimology).map(([key, value]) =>
                                            <div style={{
                                                width: "100%",
                                                height: 40,
                                                justifyContent: 'flex-start',
                                                alignItems: 'center',
                                                gap: 8,
                                                display: 'inline-flex'
                                            }}
                                            >
                                                <div style={{
                                                    flex: '1 1 0',
                                                    height: 40,
                                                    paddingRight: 4,
                                                    justifyContent: 'flex-start',
                                                    alignItems: 'center',
                                                    gap: 8,
                                                    display: 'flex'
                                                }}
                                                >
                                                    <div style={{
                                                        flexDirection: 'column',
                                                        justifyContent: 'flex-start',
                                                        alignItems: 'flex-start',
                                                        display: 'inline-flex'
                                                    }}>
                                                        <div style={{
                                                            color: 'white',
                                                            fontSize: 16,
                                                            fontWeight: '600',
                                                            wordWrap: 'break-word'
                                                        }}>{key.charAt(0).toUpperCase() + key.slice(1)}
                                                        </div>
                                                        <div style={{
                                                            color: '#A3A3A3',
                                                            fontSize: 12,
                                                            fontWeight: '400',
                                                            letterSpacing: 0.06,
                                                            wordWrap: 'break-word'
                                                        }}>Last 7 Days
                                                        </div>
                                                    </div>
                                                </div>
                                                <div style={{
                                                    flexDirection: 'column',
                                                    justifyContent: 'flex-start',
                                                    alignItems: 'flex-start',
                                                    display: 'inline-flex'
                                                }}
                                                >
                                                    <div style={{
                                                        alignSelf: 'stretch',
                                                        height: 20,
                                                        flexDirection: 'column',
                                                        justifyContent: 'center',
                                                        alignItems: 'flex-end',
                                                        gap: 4,
                                                        display: 'flex'
                                                    }}
                                                    >
                                                        <div style={{
                                                            justifyContent: 'flex-end',
                                                            alignItems: 'center',
                                                            gap: 8,
                                                            display: 'inline-flex'
                                                        }}>
                                                            <div style={{
                                                                paddingLeft: 8,
                                                                paddingRight: 8,
                                                                background: 'rgba(244, 63, 94, 0.25)',
                                                                borderRadius: 16,
                                                                overflow: 'hidden',
                                                                justifyContent: 'center',
                                                                alignItems: 'center',
                                                                display: 'flex'
                                                            }}>
                                                                <div style={{
                                                                    color: '#F43F5E',
                                                                    fontSize: 14,
                                                                    fontWeight: '600',
                                                                    letterSpacing: 0.07,
                                                                    wordWrap: 'break-word'
                                                                }}>{value["total"]}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div style={{
                                                        alignSelf: 'stretch',
                                                        height: 20,
                                                        flexDirection: 'column',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        gap: 4,
                                                        display: 'flex'
                                                    }}
                                                    >
                                                        <div style={{
                                                            alignSelf: 'stretch',
                                                            justifyContent: 'right',
                                                            alignItems: 'right',
                                                            gap: 8,
                                                            display: 'inline-flex'
                                                        }}>
                                                            <div style={{
                                                                paddingLeft: 8,
                                                                paddingRight: 8,
                                                                borderRadius: 16,
                                                                overflow: 'hidden',
                                                                justifyContent: 'center',
                                                                alignItems: 'center',
                                                                display: 'flex'
                                                            }}>
                                                                <div style={{
                                                                    color: '#A1A1AA',
                                                                    fontSize: 14,
                                                                    fontWeight: '600',
                                                                    letterSpacing: 0.07,
                                                                    wordWrap: 'break-word'
                                                                }}>+{value["seven_days"]}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ) : ""
                                }


                            </div>
                        </div>
                        <div
                            className="Detection-Card Detection-Card-SupplyChain-Page"
                        >
                            <div
                                style={{
                                    alignSelf: "stretch",
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "flex-start",
                                    justifyContent: "flex-start",
                                }}
                            >
                                <div
                                    style={{
                                        alignSelf: "stretch",
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "flex-start",
                                        gap: "4px",
                                        zIndex: "10",
                                    }}
                                >
                                    <div
                                        style={{
                                            flex: "1",
                                            position: "relative",
                                            lineHeight: "28px",
                                            fontWeight: "600"
                                        }}
                                    >
                                        New Ransomware Groups
                                    </div>
                                    <MUITooltip title={this.props.tooltip_info("New Ransomware Groups")} arrow>
                                        <img
                                            style={{
                                                position: "relative",
                                                width: "24px",
                                                height: "24px",
                                                overflow: "hidden",
                                                flexShrink: "0",
                                            }}
                                            alt="detection-i"
                                            src="/main/detection-i.svg"
                                        />
                                    </MUITooltip>
                                </div>
                            </div>
                            <div
                                style={{
                                    position: "relative",
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: "12px",
                                    width: "100%",
                                    height: "100%",
                                    textAlign: "",
                                    fontSize: "45px",
                                    color: "#fff",
                                    zIndex: "100"
                                }}
                            >
                                <div style={{
                                    marginTop: "-12px",
                                    color: '#A3A3A3',
                                    fontSize: 14,
                                    wordWrap: 'break-word'
                                }}>Victims by Group (Cumulative)
                                </div>

                                {
                                    this.state.card_stats.new_groups ?
                                        Object.entries(this.state.card_stats.new_groups).map(([key, value]) =>
                                            <div style={{
                                                width: "100%",
                                                height: 40,
                                                justifyContent: 'flex-start',
                                                alignItems: 'center',
                                                gap: 8,
                                                display: 'inline-flex'
                                            }}
                                            >
                                                <div style={{
                                                    flex: '1 1 0',
                                                    height: 40,
                                                    paddingRight: 4,
                                                    justifyContent: 'flex-start',
                                                    alignItems: 'center',
                                                    gap: 8,
                                                    display: 'flex'
                                                }}
                                                >
                                                    <div style={{
                                                        flexDirection: 'column',
                                                        justifyContent: 'flex-start',
                                                        alignItems: 'flex-start',
                                                        display: 'inline-flex'
                                                    }}>
                                                        <div style={{
                                                            color: 'white',
                                                            fontSize: 16,
                                                            fontWeight: '600',
                                                            wordWrap: 'break-word'
                                                        }}>{key.charAt(0).toUpperCase() + key.slice(1)}
                                                        </div>
                                                        <div style={{
                                                            color: '#A3A3A3',
                                                            fontSize: 12,
                                                            fontWeight: '400',
                                                            letterSpacing: 0.06,
                                                            wordWrap: 'break-word'
                                                        }}>First Seen
                                                        </div>
                                                    </div>
                                                </div>
                                                <div style={{
                                                    flexDirection: 'column',
                                                    justifyContent: 'flex-end',
                                                    alignItems: 'flex-end',
                                                    display: 'inline-flex'
                                                }}
                                                >
                                                    <div style={{
                                                        alignSelf: 'stretch',
                                                        height: 20,
                                                        flexDirection: 'column',
                                                        justifyContent: 'right',
                                                        alignItems: 'flex-end',
                                                        gap: 4,
                                                        display: 'flex'
                                                    }}>
                                                        <div style={{
                                                            justifyContent: 'right',
                                                            alignItems: 'right',
                                                            gap: 8,
                                                            display: 'inline-flex'
                                                        }}>
                                                            <div style={{
                                                                paddingLeft: 8,
                                                                paddingRight: 8,
                                                                background: 'rgba(244, 63, 94, 0.25)',
                                                                borderRadius: 16,
                                                                overflow: 'hidden',
                                                                justifyContent: 'right',
                                                                alignItems: 'right',
                                                                display: 'flex'
                                                            }}>
                                                                <div style={{
                                                                    color: '#F43F5E',
                                                                    fontSize: 14,
                                                                    fontWeight: '600',
                                                                    letterSpacing: 0.07,
                                                                    wordWrap: 'break-word'
                                                                }}>{value["total"]}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div style={{
                                                        alignSelf: 'stretch',
                                                        height: 20,
                                                        flexDirection: 'column',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        gap: 4,
                                                        display: 'flex'
                                                    }}>
                                                        <div style={{
                                                            alignSelf: 'stretch',
                                                            justifyContent: 'center',
                                                            alignItems: 'center',
                                                            gap: 8,
                                                            display: 'inline-flex'
                                                        }}>
                                                            <div style={{
                                                                paddingLeft: 8,
                                                                paddingRight: 8,
                                                                borderRadius: 16,
                                                                overflow: 'hidden',
                                                                justifyContent: 'center',
                                                                alignItems: 'center',
                                                                display: 'flex'
                                                            }}>
                                                                <div style={{
                                                                    color: '#A1A1AA',
                                                                    fontSize: 14,
                                                                    fontWeight: '600',
                                                                    letterSpacing: 0.07,
                                                                    wordWrap: 'break-word'
                                                                }}>{value["published"]}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ) : ""
                                }


                            </div>
                        </div>

                        <div
                            className="Detection-Card Detection-Card-SupplyChain-Page"
                        >
                            <div
                                style={{
                                    alignSelf: "stretch",
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "flex-start",
                                    justifyContent: "flex-start",
                                }}
                            >
                                <div
                                    style={{
                                        alignSelf: "stretch",
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "flex-start",
                                        gap: "4px",
                                        zIndex: "10",
                                    }}
                                >
                                    <div
                                        style={{
                                            flex: "1",
                                            position: "relative",
                                            lineHeight: "28px",
                                            fontWeight: "600"
                                        }}
                                    >
                                        Attacks By Country
                                    </div>
                                    <MUITooltip title={this.props.tooltip_info("Attacks By Country")} arrow>
                                        <img
                                            style={{
                                                position: "relative",
                                                width: "24px",
                                                height: "24px",
                                                overflow: "hidden",
                                                flexShrink: "0",
                                            }}
                                            alt="detection-i"
                                            src="/main/detection-i.svg"
                                        />
                                    </MUITooltip>
                                </div>
                            </div>
                            <div
                                style={{
                                    position: "relative",
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: "12px",
                                    width: "100%",
                                    height: "100%",
                                    textAlign: "",
                                    fontSize: "45px",
                                    color: "#fff",
                                    zIndex: "100"
                                }}
                            >
                                <div style={{
                                    marginTop: "-12px",
                                    color: '#A3A3A3',
                                    fontSize: 14,
                                    wordWrap: 'break-word'
                                }}>Ransomware Attacks (Last 30 Days)
                                </div>

                                {
                                    this.state.card_stats.ransomware_country ?
                                        Object.entries(this.state.card_stats.ransomware_country).map(([key, value]) =>
                                            <div style={{
                                                width: "100%",
                                                height: 40,
                                                justifyContent: 'flex-start',
                                                alignItems: 'center',
                                                gap: 8,
                                                display: 'inline-flex'
                                            }}
                                            >
                                                <div style={{
                                                    flex: '1 1 0',
                                                    height: 40,
                                                    paddingRight: 4,
                                                    justifyContent: 'flex-start',
                                                    alignItems: 'center',
                                                    gap: 8,
                                                    display: 'flex'
                                                }}
                                                >
                                                    <div style={{
                                                        flexDirection: 'column',
                                                        justifyContent: 'flex-start',
                                                        alignItems: 'flex-start',
                                                        display: 'inline-flex'
                                                    }}>
                                                        <div style={{
                                                            display: "flex",
                                                            flexDirection: "row",
                                                            gap: "6px",
                                                            justifyItems: "center",
                                                            alignItems: "center",
                                                            color: 'white',
                                                            fontSize: 16,
                                                            fontWeight: '600',
                                                            wordWrap: 'break-word'
                                                        }}>
                                                            {
                                                                key === "" ?
                                                                    <img alt="" height={24} src="/main/no-flag.svg"/>
                                                                    :
                                                                    <CircleFlag height={24}
                                                                                countryCode={getISO(key, true)}/>
                                                            }
                                                            {key}
                                                        </div>
                                                        <div style={{
                                                            color: '#A3A3A3',
                                                            fontSize: 12,
                                                            fontWeight: '400',
                                                            letterSpacing: 0.06,
                                                            wordWrap: 'break-word'
                                                        }}>Count of Attacks
                                                        </div>
                                                    </div>
                                                </div>
                                                <div style={{
                                                    flexDirection: 'column',
                                                    justifyContent: 'flex-start',
                                                    alignItems: 'flex-start',
                                                    display: 'inline-flex'
                                                }}
                                                >
                                                    <div style={{
                                                        alignSelf: 'stretch',
                                                        height: 20,
                                                        flexDirection: 'column',
                                                        justifyContent: 'center',
                                                        alignItems: 'flex-end',
                                                        gap: 4,
                                                        display: 'flex'
                                                    }}>
                                                        <div style={{
                                                            justifyContent: 'flex-end',
                                                            alignItems: 'center',
                                                            gap: 8,
                                                            display: 'inline-flex'
                                                        }}>
                                                            <div style={{
                                                                paddingLeft: 8,
                                                                paddingRight: 8,
                                                                background: 'rgba(244, 63, 94, 0.25)',
                                                                borderRadius: 16,
                                                                overflow: 'hidden',
                                                                justifyContent: 'center',
                                                                alignItems: 'center',
                                                                display: 'flex'
                                                            }}>
                                                                <div style={{
                                                                    color: '#F43F5E',
                                                                    fontSize: 14,
                                                                    fontWeight: '600',
                                                                    letterSpacing: 0.07,
                                                                    wordWrap: 'break-word'
                                                                }}>{value["per"]}%
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div style={{
                                                        alignSelf: 'stretch',
                                                        height: 20,
                                                        flexDirection: 'column',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        gap: 4,
                                                        display: 'flex'
                                                    }}>
                                                        <div style={{
                                                            alignSelf: 'stretch',
                                                            justifyContent: 'right',
                                                            alignItems: 'right',
                                                            gap: 8,
                                                            display: 'inline-flex'
                                                        }}>
                                                            <div style={{
                                                                paddingLeft: 8,
                                                                paddingRight: 8,
                                                                borderRadius: 16,
                                                                overflow: 'hidden',
                                                                justifyContent: 'center',
                                                                alignItems: 'center',
                                                                display: 'flex'
                                                            }}>
                                                                <div style={{
                                                                    color: '#A1A1AA',
                                                                    fontSize: 14,
                                                                    fontWeight: '600',
                                                                    letterSpacing: 0.07,
                                                                    wordWrap: 'break-word'
                                                                }}>{value["count"]}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ) : ""
                                }


                            </div>
                        </div>

                        <div
                            className="Detection-Card Detection-Card-SupplyChain-Page"
                        >
                            <div
                                style={{
                                    alignSelf: "stretch",
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "flex-start",
                                    justifyContent: "flex-start",
                                }}
                            >
                                <div
                                    style={{
                                        alignSelf: "stretch",
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "flex-start",
                                        gap: "4px",
                                        zIndex: "10",
                                    }}
                                >
                                    <div
                                        style={{
                                            flex: "1",
                                            position: "relative",
                                            lineHeight: "28px",
                                            fontWeight: "600"
                                        }}
                                    >
                                        Victims Indexed
                                    </div>
                                    <MUITooltip title={this.props.tooltip_info("Victims Indexed")} arrow>
                                        <img
                                            style={{
                                                position: "relative",
                                                width: "24px",
                                                height: "24px",
                                                overflow: "hidden",
                                                flexShrink: "0",
                                            }}
                                            alt="detection-i"
                                            src="/main/detection-i.svg"
                                        />
                                    </MUITooltip>
                                </div>
                            </div>
                            <div
                                style={{
                                    position: "relative",
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: "12px",
                                    width: "100%",
                                    height: "100%",
                                    textAlign: "",
                                    fontSize: "45px",
                                    color: "#fff",
                                    zIndex: "100"
                                }}
                            >
                                <div style={{
                                    display: "flex",
                                    marginTop: "-12px",
                                    color: '#A3A3A3',
                                    fontSize: 14,
                                    wordWrap: 'break-word'
                                }}>

                                    <div>Period</div>
                                    <div style={{
                                        marginLeft: "auto"
                                    }}>Count</div>
                                </div>

                                {
                                    this.state.card_stats.indexed ?
                                        Object.entries(this.state.card_stats.indexed).map(([key, value]) =>
                                            <div style={{
                                                width: "100%",
                                                height: 40,
                                                justifyContent: 'flex-start',
                                                alignItems: 'center',
                                                gap: 8,
                                                display: 'inline-flex'
                                            }}
                                            >
                                                <div style={{
                                                    flex: '1 1 0',
                                                    height: 40,
                                                    paddingRight: 4,
                                                    justifyContent: 'flex-start',
                                                    alignItems: 'center',
                                                    gap: 8,
                                                    display: 'flex'
                                                }}
                                                >
                                                    <div style={{
                                                        flexDirection: 'column',
                                                        justifyContent: 'flex-start',
                                                        alignItems: 'flex-start',
                                                        display: 'inline-flex'
                                                    }}>
                                                        <div style={{
                                                            display: "flex",
                                                            flexDirection: "row",
                                                            gap: "8px",
                                                            justifyItems: "center",
                                                            alignItems: "center",
                                                            color: 'white',
                                                            fontSize: 16,
                                                            fontWeight: '600',
                                                            wordWrap: 'break-word'
                                                        }}>
                                                            {key}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div style={{
                                                    flexDirection: 'column',
                                                    justifyContent: 'flex-start',
                                                    alignItems: 'flex-start',
                                                    display: 'inline-flex'
                                                }}
                                                >
                                                    <div style={{
                                                        alignSelf: 'stretch',
                                                        height: 20,
                                                        flexDirection: 'column',
                                                        justifyContent: 'center',
                                                        alignItems: 'flex-end',
                                                        gap: 4,
                                                        display: 'flex'
                                                    }}>
                                                        <div style={{
                                                            justifyContent: 'flex-end',
                                                            fontSize: 16,
                                                            alignItems: 'center',
                                                            gap: 8,
                                                            display: 'inline-flex'
                                                        }}>
                                                            {value}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ) : ""
                                }


                            </div>
                        </div>
                    </div>
                    <div
                        style={{
                            alignSelf: "stretch",
                            display: "flex",
                            flexDirection: "row",
                            flexWrap: "wrap",
                            alignItems: "flex-start",
                            justifyContent: "flex-start",
                            gap: "8px",
                            width: "100%"
                        }}
                    >
                        <div style={{
                            position: "relative",
                            width: "calc(100% - 8px)",
                            borderRadius: "8px",
                            height: "100%"
                        }}>

                            <div style={{
                                top: "20px",
                                left: "16px",
                                display: "flex",
                                height: "30px",
                                position: "absolute",
                                fontWeight: "600",
                                gap: "24px",
                                fontSize: "12px",
                                letterSpacing: "0.01em",
                                lineHeight: "20px",
                                fontFamily: "Inter",
                                zIndex: "10"
                            }}
                                 id="table-header"
                            >
                                <button onClick={() => this.setState({table_tab_idx: 0})}
                                        style={{
                                            display: "flex",
                                            fontSize: "15px",
                                            cursor: "pointer",
                                            borderRadius: "4px 4px 0 0",
                                            alignItems: "center",
                                            border: "none",
                                            backgroundColor: this.state.table_tab_idx === 0 ? "#27272A" : "#18181B",
                                            color: "#F9B233",
                                            padding: "4px 16px",
                                            gap: "4px",
                                            marginBottom: "-6px"
                                        }}
                                >
                                    <IVictims style={{
                                        width: "20px",
                                        height: "20px",
                                        color: this.state.table_tab_idx === 0 ? "#65A30D" : "#365314",
                                    }}/>
                                    Ransomware Victims
                                </button>
                                <button onClick={() => this.setState({table_tab_idx: 1})}
                                        style={{
                                            display: "flex",
                                            fontSize: "15px",
                                            cursor: "pointer",
                                            borderRadius: "4px 4px 0 0",
                                            alignItems: "center",
                                            border: "none",
                                            backgroundColor: this.state.table_tab_idx === 1 ? "#27272A" : "#18181B",
                                            color: "#F9B233",
                                            padding: "4px 16px",
                                            gap: "4px",
                                            marginBottom: "-6px"
                                        }}
                                >
                                    <ICyberattacks style={{
                                        width: "20px",
                                        height: "20px",
                                        color: this.state.table_tab_idx === 1 ? "#65A30D" : "#365314",
                                    }}/>
                                    Recent Cyberattacks
                                </button>
                                <div style={{
                                    display: "flex",
                                    gap: "8px",
                                    lineHeight: "28px",
                                    fontSize: "22px",
                                    alignItems: "center"
                                }}>
                                    <button
                                        onClick={this.tick}
                                        style={{
                                            width: "28px",
                                            height: "28px",
                                            display: "flex",
                                            cursor: "pointer",
                                            border: "none",
                                            paddingLeft: "16px",
                                            overflow: "hidden",
                                            backgroundColor: "transparent",
                                            alignItems: "center",
                                            justifyContent: "center"
                                        }}>
                                        <img src="/main/refresh-i.svg" alt=""/>
                                    </button>

                                    <div style={{
                                        paddingLeft: "16px",
                                        fontSize: "18px",
                                        color: this.state.all_time ? "white" : "#A1A1AA"
                                    }}>
                                        All Time
                                    </div>
                                    <div style={{
                                        display: "flex",
                                        width: "35px",
                                        height: "20px",
                                    }}>
                                        <Switch
                                            uncheckedIcon={false}
                                            checkedIcon={false}
                                            onChange={(checked) => {
                                                this.setState({all_time: !checked})
                                                this.tick(!checked)
                                            }}
                                            checked={!this.state.all_time}
                                            offColor="#27272A"
                                            onColor="#27272A"
                                            offHandleColor="#06B6D4"
                                            onHandleColor="#10B981"
                                            draggable={false}
                                            width={35}
                                            height={20}
                                        />
                                    </div>

                                    <div style={{
                                        fontSize: "18px",
                                        color: this.state.all_time ? "#A1A1AA" : "white"
                                    }}>
                                        Last 30 Days
                                    </div>
                                </div>

                            </div>
                            <ThemeProvider theme={this.tableTheme}>
                                {
                                    this.state.table_tab_idx === 0 ?
                                        <MaterialReactTable
                                            columns={this.victims_columns} data={this.state.victims_data}
                                        />
                                        :
                                        <MaterialReactTable
                                            columns={this.cyberattacks_columns} data={this.state.cyberattacks_data}
                                        />
                                }

                            </ThemeProvider>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}