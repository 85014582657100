import React, { Component } from 'react'
import { ReactComponent as IDetections } from '../icons/table/activity.svg';
import { CircularProgressbar, buildStyles, CircularProgressbarWithChildren } from 'react-circular-progressbar';
import {createTheme, ThemeProvider, Tooltip as MUITooltip} from "@mui/material";
import {MaterialReactTable} from "material-react-table";
import 'react-circular-progressbar/dist/styles.css';
import { CircleFlag } from "react-circle-flags"
import ReactModal from 'react-modal';
import {CountryContext} from "react-svg-worldmap";
import MDEditor, { commands } from '@uiw/react-md-editor';
import Switch from "react-switch";
import { Doughnut } from 'react-chartjs-2';

import {
    Chart as ChartJS,
    ArcElement,
    Tooltip,
    Legend, Chart
} from 'chart.js';

ChartJS.register(ArcElement, Tooltip, Legend);

function get_time_string(time, table) {
    if (time) {
        let date = new Date(time * 1000);
        if (table) return new Date(date - date.getTimezoneOffset() * 60 * 1000).toISOString().replace('.000Z','').replace('T',' ');
        return new Date(date - date.getTimezoneOffset() * 60 * 1000).toISOString().replace('.000Z', '').replaceAll('-', '/').replace('T', ' - ');
    }
    return ""
}

export default class ThreatLandscape extends Component {
    pending_request = false




    tableTheme = createTheme({
        palette: {
            mode: "dark",
            background: {
                default: '#18181b',
            },
        },
        typography: {
            button: {
                textTransform: 'none',
                fontSize: '1.2rem',
            },
        },
        components: {
            MuiTooltip: {
                styleOverrides: {
                    tooltip: {
                        background: "#A1A1AA",
                    },
                },
            },
            MuiSwitch: {
                styleOverrides: {
                    thumb: {
                        color: 'black',
                    },
                },
            },
        },
    });



    columns = [
        {
            accessorKey: 'priority',
            header: 'Priority',
            size: 10,
        },
        {
            accessorKey: 'group',
            header: 'Threat Type',
            size: 150,
            Cell: ({ cell }) => (
                <div style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "8px",
                    justifyItems: "center",
                    alignItems: "center"
                }}
                >
                    <button
                        onClick={() => this.top_10(cell.row.original["group"], true)}
                        style={{
                            backgroundColor: "transparent",
                            cursor: "pointer",
                            border: "none",
                        }}
                    >
                        <div style={{padding: 8, background: '#27272A', borderRadius: 2, justifyContent: 'flex-start', alignItems: 'center', display: 'flex'}}>
                            <img style={{width: 20, height: 20}} src="/main/show-i.svg" alt="show" />
                        </div>
                    </button>
                    <div>
                        {cell.getValue() === "" ? "N/A" : cell.getValue()}
                    </div>
                </div>
            ),
        },
        {
            accessorKey: 'malware',
            header: 'Malware',
            size: 150,
        },
        {
            accessorFn: (row) => row.country[0],
            header: 'Country',
            size: 150,
            Cell: ({ cell }) => (
                <div style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "8px",
                    justifyItems: "center",
                    alignItems: "center"
                }}>
                    <div>
                        {
                            cell.row.original["country"][1] === "" ?
                                <img alt="" height={24} src="/main/no-flag.svg"/>
                                :
                                <CircleFlag height={24} countryCode={cell.row.original["country"][1]} />
                        }
                    </div>
                    <div>
                        {cell.getValue()}
                    </div>
                </div>
            ),
        },
        {
            accessorKey: 'ip',
            header: 'IoA',
            size: 150,
        },
        {
            accessorKey: 'isp',
            header: 'ISP',
            size: 150,
        },
        {
            accessorKey: 'ssl_cn',
            header: 'SSL CN',
            size: 50,
        },
        {
            accessorKey: 'first_seen',
            header: 'First Detected',
            size: 150,
            Cell: ({ cell }) => (
                <div>
                    {get_time_string(cell.getValue(), true)}
                </div>
            ),
        },
        {
            accessorKey: 'last_seen',
            header: 'Last Updated',
            size: 150,
            Cell: ({ cell }) => (
                <div>
                    {get_time_string(cell.getValue(), true)}
                </div>
            ),
        },
    ];

    constructor(props) {
        super(props);
        this.state = {username: '', company: '', key: '', keys: {}, stats: {}, p: [0, 0, 0, 0], p_val: [0, 0, 0, 0], table_data: [], all_ioa: [],
            showTop10: false,
            top10_data: {},
            showModal: false,
            modal_data: {backend:{}, stats:{}},
            show_editor: false,
            show_sector_editor: false,
            show_chain_editor: false,
            show_mitre_editor: false,
            show_countries_editor: false,
            all_time: false,
        };
        this.getTableData = this.getTableData.bind(this);
        this.set_dials = this.set_dials.bind(this);
        this.OpenModal = this.OpenModal.bind(this);
        this.CloseModal = this.CloseModal.bind(this);
        this.getCountryRender = this.getCountryRender.bind(this);
        this.update_detection = this.update_detection.bind(this);
        this.update_table = this.update_table.bind(this);
        this.update_threat_data = this.update_threat_data.bind(this);
        this.show_threat_editor = this.show_threat_editor.bind(this);
        this.get_threat_sectors = this.get_threat_sectors.bind(this);
        this.get_threat_countries = this.get_threat_countries.bind(this);
        this.get_chain_sectors = this.get_chain_sectors.bind(this);
        this.get_mitre_data = this.get_mitre_data.bind(this);
        this.get_card_live = this.get_card_live.bind(this);
        this.check_threat_card = this.check_threat_card.bind(this);
        this.get_top_countries = this.get_top_countries.bind(this);
        this.tick = this.tick.bind(this);
        this.top_10 = this.top_10.bind(this);
        let data = this.props.page_data()
        //console.log("detections", data)
    }

    get_top_countries() {
        let country_map = {}
        let total = 0
        let top = []
        for (let item of this.state.table_data) {
            if (item["country"][0] in country_map) country_map[item["country"][0]]["count"]++
            else {
                country_map[item["country"][0]] = {}
                country_map[item["country"][0]]["country"] = item["country"]
                country_map[item["country"][0]]["count"] = 1
            }
            total++
        }
        let top_countries = Object.keys(country_map).sort(function(a,b) { return country_map[b]["count"] - country_map[a]["count"]; });

        for (let item of top_countries) {
            let hold = country_map[item]
            hold["per"] = Math.ceil(hold["count"] * 100 / total)
            top.push(country_map[item])
        }
        return top.slice(0,5)
    }

    top_10(val, type) {
        let data = {}
        let malware_map = {}
        data["list"] = []
        data["target"] = val
        let total = 0

        for (let item of this.state.table_data) {
            let check = item["priority"] === val
            if (type) {
                check = item["group"] === val
            }
            if (check) {
                total++
                if (item["malware"] in malware_map) malware_map[item["malware"]]["count"]++
                else {
                    malware_map[item["malware"]] = item
                    malware_map[item["malware"]]["count"] = 1
                }
            }

        }

        let top_malware = Object.keys(malware_map).sort(function(a,b) { return malware_map[b]["count"] - malware_map[a]["count"]; });

        for (let item of top_malware) {
            data["list"].push(malware_map[item])
        }

        data["total"] = total
        this.setState({showTop10: true, top10_data: data});
    }

    show_threat_editor(val) {
        if (this.state.show_editor !== val) {
            this.setState({show_editor: val})
        }
    }

    get_chain_sectors() {
        if (this.state.modal_data["backend"]["chain"]) {
            return this.state.modal_data["backend"]["chain"]
        }
        return ""
    }

    get_threat_sectors() {
        if (this.state.modal_data["backend"]["sectors"]) {
            return this.state.modal_data["backend"]["sectors"]
        }
        return ""
    }

    get_threat_countries() {
        if (this.state.modal_data["backend"]["countries"]) {
            return this.state.modal_data["backend"]["countries"]
        }
        return ""
    }

    get_mitre_data() {
        if (this.state.modal_data["backend"]["mitre"]) {
            return this.state.modal_data["backend"]["mitre"].split(",")
        }
        return ["TA0011", "https://attack.mitre.org/tactics/TA0011/"]
    }

    get_card_live(data) {
        let asn_map = {}
        let isp_map = {}
        let country_map = {}
        let average_up = 0
        let all_count = 0
        let live_count = 0
        let last_week_count = 0
        let last_month_count = 0
        let last_active = "Today"

        let now = Math.floor(new Date().getTime()/1000.0)
        let seven_days_ago = now - (86400 * 7)
        let fourteen_days_ago = now - (86400 * 14)
        let thirty_days_ago = now - (86400 * 30)

        for (let item of this.state.all_ioa) {
            if (item["malware"] === data["malware"]) {
                if (item["last_seen"] > seven_days_ago) {
                    live_count++
                }

                if (item["last_seen"] > fourteen_days_ago) {
                    last_week_count++
                }

                if (item["last_seen"] > thirty_days_ago) {
                    last_month_count++
                }

                average_up += item["last_seen"] - item["first_seen"]
                all_count++
                if (item["asn"] in asn_map)asn_map[item["asn"]]++
                else asn_map[item["asn"]] = 1

                if (item["isp"] in isp_map)isp_map[item["isp"]]++
                else isp_map[item["isp"]] = 1

                if (item["country"][0] in country_map)country_map[item["country"][0]]++
                else country_map[item["country"][0]] = 1
            }
        }
        average_up = Math.ceil((average_up / all_count) / 86400)
        average_up = average_up + " Day(s)"

        asn_map = Object.keys(asn_map).sort(function(a,b) { return asn_map[b] - asn_map[a]; });
        isp_map = Object.keys(isp_map).sort(function(a,b) { return isp_map[b] - isp_map[a]; });
        country_map = Object.keys(country_map).sort(function(a,b) { return country_map[b] - country_map[a]; });


        let last_seen = new Date(parseInt(data["last_seen"]) * 1000)
        let diff = Math.floor((new Date().getTime() - last_seen.getTime()) / (1000 * 60 * 60 * 24));
        if (diff > 0) {
            last_active = diff + " Day(s) ago"
        }

        return {
            live_count: live_count,
            last_week_count: last_week_count,
            last_month_count: last_month_count,
            live_percent: (live_count/all_count) * 100,
            last_week_percent: (last_week_count/all_count) * 100,
            last_month_percent: (last_month_count/all_count) * 100,
            top_asn: asn_map[0] ? asn_map[0] : "N/A",
            top_isp: isp_map[0] ? isp_map[0] : "N/A",
            top_country: country_map[0] ? country_map[0] : "N/A",
            last_active: last_active,
            average_up: average_up
        }
    }

    check_threat_card() {
        let data = this.props.page_data()
        if (data["threat_card_malware"] && data["threat_card_malware"] !== "" && this.state.stats.c2 && this.state.all_ioa.length > 0) {
            this.props.open_threat_card("")
            this.OpenModal({malware: data["threat_card_malware"]})
        }
    }

    OpenModal (data) {
        if (data["group"] === "") {
            data["group"] = "N/A"
        }
        data["backend"] = {}
        data["stats"] = this.get_card_live(data)
        this.setState({ modal_data: data, showModal: true });
        let page_data = this.props.page_data()
        let key = page_data.keys[page_data.company]
        let company = page_data.company
        let jsonData = {
            "key": key,
            "malware": data["malware"]
        }
        fetch('https://ti-endpoint.cybershadows.co.uk/' + company + "/threat_card", {
            method: 'POST',
            mode: 'cors',
            body: JSON.stringify(jsonData)
        }).then((response) => {
            if (response.ok) {
                response.json().then(json => {
                    if (json.data) {
                        data["backend"] = json.data
                        this.setState({modal_data: data})
                    }
                });
            }
        }).catch((err) => {
            console.log("threat landscape", err)
        })
    }

    CloseModal () {
        if (this.state.key !== '') {
            let jsonData = {
                "key": this.state.key,
                "malware": this.state.modal_data["malware"],
                "data": this.state.modal_data["backend"]
            }
            fetch('https://ti-endpoint.cybershadows.co.uk/' + this.state.company + "/update_threat_card", {
                method: 'POST',
                mode: 'cors',
                body: JSON.stringify(jsonData)
            }).then((response) => {
                if (response.ok) {
                    response.json().then(json => {
                        if (json.success) {
                            this.setState({ modal_data: {backend:{}, stats:{}}, showModal: false });
                        } else {
                            console.log("threat landscape", "failed to update", json)
                            if (this.state.company !== "KryptoKloud") {
                                this.setState({ modal_data: {backend:{}, stats:{}}, showModal: false });
                            }
                        }
                    });
                }
            }).catch((err) => {
                console.log("threat landscape", err)
            })
        }
    }

    getCountryRender() {
        if (this.state.modal_data["country"]) {
            let code = this.state.modal_data["country"][1]
            return (
                <div style={{display: "flex", alignItems: "center", gap: "8px"}}>
                    {this.state.modal_data["country"][0]}
                    {code === "" ? "": <CircleFlag height={20} countryCode={code} />}
                </div>
            )
        }
    }

    update_threat_data(key, value) {
        let modal_data = this.state.modal_data
        modal_data["backend"][key] = value
        this.setState({ modal_data: modal_data});
    }

    tick(all_time) {
        if (this.pending_request)return
        else this.pending_request = true
        let data = this.props.page_data()
        let key = data.keys[data.company]
        let company = data.company
        if (this.state.company !== data.company) {
            this.setState({username: data.username, key: key, company: company, keys: data.keys})
        }
        if (key !== '') {
            let jsonData = {
                "key": key,
            }
            fetch('https://ti-endpoint.cybershadows.co.uk/' + company + (all_time ? "/all_ioa_data" : "/latest_ioa_data"), {
                method: 'POST',
                mode: 'cors',
                body: JSON.stringify(jsonData)
            }).then((response) => {
                if (response.ok) {
                    response.json().then(json => {
                        if (json.data) {
                            this.setState({stats: json, table_data: json["data"]})
                        }
                    });
                }
            }).catch((err) => {
                console.log("threat landscape", err)
            }).finally(() => {
                this.pending_request = false
            })
            fetch('https://ti-endpoint.cybershadows.co.uk/' + company + "/all_ioa_data", {
                method: 'POST',
                mode: 'cors',
                body: JSON.stringify(jsonData)
            }).then((response) => {
                if (response.ok) {
                    response.json().then(json => {
                        if (json.data) {
                            this.setState({all_ioa: json["data"]})
                        }
                    });
                }
            }).catch((err) => {
                console.log("threat landscape", err)
            }).finally(() => {
                this.pending_request = false
            })
        }
    }

    componentDidUpdate(prevProps, prevState, Snapshot) {
        this.props.page_state("threatlandscape", this.state)
    }

    componentDidMount() {
        let state = this.props.page_state("threatlandscape")
        if (state) {
            this.setState(state)
        }

        let data = this.props.page_data()
        if (data.username && data.username !== '') {
            this.setState({
                username: data.username,
                key: data.keys[data.company],
                company: data.company,
                keys: data.keys
            })
        }
        this.tick()
        this.interval_update = setInterval(
            () => this.check_threat_card(),
            1000
        );
    }
    componentWillUnmount() {
        clearInterval(this.interval_update);
    }

    update_table(e) {
        console.log(parseInt(e.currentTarget.id))
    }

    update_detection(e) {
        let company = this.state.company;
        let jsonData = {
            "key": this.state.key,
            "id": this.state.modal_data["id"],
            "status": parseInt(e.currentTarget.id)
        }
        fetch('https://ti-endpoint.cybershadows.co.uk/' + company + "/update_detection", {
            method: 'POST',
            mode: 'cors',
            body: JSON.stringify(jsonData)
        }).then((response) => {
            if (response.ok) {
                response.json().then(json => {
                    if (json.status) {
                        let modal_data = this.state.modal_data
                        modal_data["status"] = json.status
                        this.setState({modal_data: modal_data})
                    }
                });
            }
        }).catch((err) => {
            console.log("update_detection", err)
        })
    }

    getTableData() {
        if (this.state.stats.c2) {
            this.set_dials()
        }
        return this.state.table_data
    }

    set_dials() {
        let c2 = this.state.stats.c2
        let p1 = this.state.stats.p1
        let p2 = this.state.stats.p2
        let p3 = this.state.stats.p3
        let p4 = this.state.stats.p4

        if (p1 !== this.state.p_val[0]) {
            this.setState({
                p: [
                    (p1 / c2) * 100,
                    (p2 / c2) * 100,
                    (p3 / c2) * 100,
                    (p4 / c2) * 100
                ],
                p_val: [
                    p1,
                    p2,
                    p3,
                    p4
                ]
            })
        }
    }

    render() {
        return (
            <div
                style={{
                    alignSelf: "stretch",
                    flex: "1",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                    padding: "8px 0px 0px",
                    gap: "8px",
                    textAlign: "left",
                    fontSize: "22px",
                    color: "#fafafa",
                    overflow: "auto"
                }}
                id="inner-data"
            >

                <ReactModal
                    ariaHideApp={false}
                    isOpen={this.state.showModal}
                    contentLabel="Threat Card"
                    style={{
                        overlay: {
                            zIndex: 1000,
                            background: "rgba(19, 19, 19, 0.75)"
                        },
                        content: {
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            width: "1140px",
                            height: "680px",
                            background: "#171717"
                        },
                    }}
                >
                    <div style={{
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        flexDirection: "row",
                        gap: "16px"
                    }}
                    >

                        <div
                            style={{
                                width: "calc(50% - 8px)",
                                height: "100%",
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "flex-start",
                                color: "white",
                                gap: "16px"
                            }}
                        >
                            <div style={{
                                width: "100%",
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "flex-start",
                                lineHeight: "28px",
                                fontWeight: "600",
                            }}>
                                <div style={{color: "#F9B233"}}>
                                    Threat Intelligence
                                </div>
                            </div>

                            <div className="inner-tip-box"
                                 style={{
                                     height: "474px",
                                 }}
                            >
                                <div style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    gap: "4px",
                                    lineHeight: "28px",
                                    fontWeight: "600",
                                    alignItems: "center",
                                    height: "24px",
                                }}>
                                    <img
                                        style={{width: "24px", height: "24px"}}
                                        alt=""
                                        src="/main/threat-i.svg"
                                    />
                                    <div style={{
                                        color: "#a1daf8"
                                    }}>Threat Card
                                    </div>
                                </div>

                                <div style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    marginTop: "4px",
                                    height: "calc(100%-24px)"
                                }}
                                     id="host-data"
                                >
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        color: "#A1A1AA",
                                        gap: "4px",
                                        width: "180px"
                                    }} id="section1">
                                        <div>Threat:</div>
                                        <div>Type:</div>
                                        <div>First Identified:</div>
                                    </div>

                                    <div style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        gap: "4px",
                                        width: "280px"
                                    }} id="section2">
                                        <div>{this.state.modal_data["malware"]}</div>
                                        <div>
                                            <input className="transparent-input" type='text' name='type'
                                                   value={this.state.modal_data["backend"]["type"]}
                                                   onChange={(data) => {
                                                       this.update_threat_data("type", data.target.value)
                                                   }}
                                            />
                                        </div>
                                        <div>
                                            <input className="transparent-input" type='text' name='first_identified'
                                                   value={this.state.modal_data["backend"]["first_identified"]}
                                                   onChange={(data) => {
                                                       this.update_threat_data("first_identified", data.target.value)
                                                   }}
                                            />
                                        </div>
                                    </div>
                                </div>
                                {
                                    this.state.show_editor ?
                                        <div
                                            style={{
                                                marginTop: "16px",
                                                width: "100%",
                                                height: "330px"
                                            }}
                                        >
                                            <MDEditor
                                                value={this.state.modal_data["backend"]["threat_text"]}
                                                visibleDragbar={false}
                                                onChange={(data) => {
                                                    this.update_threat_data("threat_text", data)
                                                }}
                                                height={300}
                                            />
                                            <button onClick={() => {
                                                this.show_threat_editor(false)
                                            }}
                                                    style={{
                                                        background: "#171717",
                                                        color: "white",
                                                        borderRadius: "8px",
                                                    }}
                                            >
                                                done
                                            </button>
                                        </div>
                                        :
                                        <div
                                            onClick={() => {
                                                this.show_threat_editor(true)
                                            }}
                                            style={{
                                                marginTop: "16px",
                                                width: "100%",
                                                height: "330px"
                                            }}>
                                            <MDEditor.Markdown source={this.state.modal_data["backend"]["threat_text"]}
                                                               style={{
                                                                   padding: "8px 12px 8px 8px",
                                                                   color: "white",
                                                                   background: "#171717",
                                                                   borderRadius: "8px",
                                                                   whiteSpace: 'pre-wrap',
                                                                   width: "100%",
                                                                   height: "100%",
                                                                   maxHeight: "100%",
                                                                   overflow: "auto"
                                                               }}
                                            />
                                        </div>

                                }

                            </div>

                            <div className="inner-tip-box"
                                 style={{
                                     height: "104px"
                                 }}
                            >
                                <div style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    gap: "4px",
                                    lineHeight: "28px",
                                    fontWeight: "600",
                                    alignItems: "center",
                                    height: "24px",
                                }}>
                                    <img
                                        style={{width: "24px", height: "24px"}}
                                        alt=""
                                        src="/main/victimology-i.svg"
                                    />
                                    <div style={{
                                        color: "#a1daf8"
                                    }}>Victimology
                                    </div>
                                </div>
                                <div style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    marginTop: "4px",
                                    height: "calc(100%-24px)"
                                }}
                                     id="malware-data">
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        color: "#A1A1AA",
                                        gap: "4px",
                                        width: "108px"
                                    }} id="section1">
                                        <div>Impacting:</div>
                                        <div>Countries:</div>
                                    </div>

                                    <div style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        gap: "4px"
                                    }} id="section2">
                                        <div style={{
                                            height: "24px",
                                            width: "100%",
                                            display: "flex",
                                            flexDirection: "row",
                                            gap: "8px"
                                        }}
                                             onClick={() => {
                                                 this.setState({show_sector_editor: true})
                                             }}
                                        >
                                            {this.state.show_sector_editor ?
                                                <input className="transparent-input" type='text' name='first_identified'
                                                       value={this.state.modal_data["backend"]["sectors"]}
                                                       onChange={(data) => {
                                                           this.update_threat_data("sectors", data.target.value)
                                                       }}
                                                       onBlur={() => {
                                                           this.setState({show_sector_editor: false})
                                                       }}
                                                />
                                                :
                                                this.get_threat_sectors().split(",").map((item, i) =>
                                                    <div style={{
                                                        padding: "0px 8px 0px 8px",
                                                        borderRadius: "16px",
                                                        background: "rgba(161,161,170, 0.25)",
                                                        color: "rgba(161,161,170, 100)",
                                                        fontWeight: "bold",
                                                    }}>{item}</div>
                                                )
                                            }
                                        </div>
                                        <div style={{
                                            height: "24px",
                                            width: "100%",
                                            display: "flex",
                                            flexDirection: "row",
                                            gap: "8px"
                                        }}
                                             onClick={() => {
                                                 this.setState({show_countries_editor: true})
                                             }}
                                        >
                                            {this.state.show_countries_editor ?
                                                <input className="transparent-input" type='text' name='first_identified'
                                                       value={this.state.modal_data["backend"]["countries"]}
                                                       onChange={(data) => {
                                                           this.update_threat_data("countries", data.target.value)
                                                       }}
                                                       onBlur={() => {
                                                           this.setState({show_countries_editor: false})
                                                       }}
                                                />
                                                :
                                                this.get_threat_countries().split(",").map((item, i) =>
                                                    <div style={{
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        gap: "8px"
                                                    }}
                                                    >
                                                        {
                                                            item.split(":")[0] === "Global" ?
                                                                <img alt="" height={24} src="/main/no-flag.svg"/>
                                                                :
                                                                <CircleFlag height={20}
                                                                            countryCode={item.split(":")[1]}/>
                                                        }
                                                        <div>{item.split(":")[0]}</div>
                                                    </div>
                                                )
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div style={{
                            width: "calc(50% - 8px)",
                            height: "100%",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "flex-start",
                            color: "white",
                            gap: "16px"
                        }}
                        >
                            <div style={{
                                width: "100%",
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "flex-start",
                                lineHeight: "28px",
                                fontWeight: "600",
                            }}>
                                <div style={{color: "#F9B233"}}>
                                    Live {this.state.modal_data["malware"]} Infrastructure ITW
                                </div>
                                <button
                                    style={{
                                        cursor: "pointer",
                                        border: "none",
                                        backgroundColor: "transparent",
                                        position: "relative",
                                        width: "24px",
                                        height: "24px",
                                        marginLeft: "auto"
                                    }}
                                    id="close-modal"
                                    onClick={this.CloseModal}
                                >
                                    <img
                                        alt=""
                                        src="/main/white-close.svg"
                                    />
                                </button>
                            </div>
                            <div
                                style={{
                                    display: "flex",
                                    height: "250px",
                                    flexDirection: "column",
                                    gap: "8px"
                                }}
                            >
                                <div style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    height: "230px",
                                    gap: "16px",
                                }}>
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        gap: "8px"
                                    }}>
                                        <CircularProgressbarWithChildren value={this.state.modal_data["stats"]["live_percent"]} strokeWidth={5}
                                                                         styles={buildStyles({
                                                                             pathTransitionDuration: 0.5,
                                                                             textColor: '#fff',
                                                                             trailColor: '#27272A',
                                                                             pathColor: 'rgba(244,63,94,100)'
                                                                         })}
                                        >
                                            <div style={{
                                                fontSize: "40px"
                                            }}>{this.state.modal_data["stats"]["live_count"]}</div>
                                        </CircularProgressbarWithChildren>
                                        <div style={{
                                            width: "100%",
                                            display: "flex",
                                            justifyContent: "center"
                                        }}>
                                            <div style={{
                                                padding: "0px 8px 0px 8px",
                                                borderRadius: "16px",
                                                background: "rgba(244,63,94,0.25)",
                                                color: "rgba(244,63,94, 100)",
                                                fontWeight: "bold",
                                                alignItems: "center",
                                                textAlign: "center",
                                                display: "inline-block"
                                            }}>
                                                Live
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        gap: "8px"
                                    }}>
                                        <CircularProgressbarWithChildren value={this.state.modal_data["stats"]["last_week_percent"]} strokeWidth={5}
                                                                         styles={buildStyles({
                                                                             pathTransitionDuration: 0.5,
                                                                             textColor: '#fff',
                                                                             trailColor: '#27272A',
                                                                             pathColor: 'rgba(245,158,11,100)'
                                                                         })}
                                        >
                                            <div style={{
                                                fontSize: "40px"
                                            }}>{this.state.modal_data["stats"]["last_week_count"]}</div>
                                        </CircularProgressbarWithChildren>
                                        <div style={{
                                            width: "100%",
                                            display: "flex",
                                            justifyContent: "center"
                                        }}>
                                            <div style={{
                                                padding: "0px 8px 0px 8px",
                                                display: "inline-block",
                                                borderRadius: "16px",
                                                background: "rgba(245,158,11,0.25)",
                                                color: "rgba(245,158,11, 100)",
                                                fontWeight: "bold",
                                                alignItems: "center",
                                                textAlign: "center",
                                            }}>
                                                Total Last Week
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        gap: "8px"
                                    }}>

                                        <CircularProgressbarWithChildren value={this.state.modal_data["stats"]["last_month_percent"]} strokeWidth={5}
                                                                         styles={buildStyles({
                                                                             pathTransitionDuration: 0.5,
                                                                             textColor: '#fff',
                                                                             trailColor: '#27272A',
                                                                             pathColor: 'rgba(6,182,212,100)'
                                                                         })}
                                        >
                                            <div style={{
                                                fontSize: "40px"
                                            }}>{this.state.modal_data["stats"]["last_month_count"]}</div>
                                        </CircularProgressbarWithChildren>
                                        <div style={{
                                            width: "100%",
                                            display: "flex",
                                            justifyContent: "center"
                                        }}>
                                            <div style={{
                                                padding: "0px 8px 0px 8px",
                                                display: "inline-block",
                                                borderRadius: "16px",
                                                background: "rgba(6,182,212,0.25)",
                                                color: "rgba(6,182,212, 100)",
                                                fontWeight: "bold",
                                                alignItems: "center",
                                                textAlign: "center",
                                            }}>
                                                Total Last Month
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className="inner-tip-box"
                                 style={{
                                     height: "180px"
                                 }}
                            >
                                <div style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    gap: "4px",
                                    lineHeight: "28px",
                                    fontWeight: "600",
                                    alignItems: "center",
                                    height: "24px",
                                }}>
                                    <img
                                        style={{width: "24px", height: "24px"}}
                                        alt=""
                                        src="/main/infrastructure-i.svg"
                                    />
                                    <div style={{
                                        color: "#a1daf8"
                                    }}>Infrastructure Analysis
                                    </div>
                                </div>
                                <div style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    marginTop: "4px",
                                    height: "calc(100%-24px)"
                                }}
                                     id="malware-data">
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        color: "#A1A1AA",
                                        gap: "4px",
                                        width: "100px"
                                    }} id="section1">
                                        <div>Top ASN:</div>
                                        <div>Top ISP:</div>
                                        <div>Last Active:</div>
                                        <div>Average Life:</div>
                                        <div>Top Country:</div>
                                    </div>

                                    <div style={{
                                        display: "flex",
                                        flexDirection: "column",

                                        gap: "4px"
                                    }} id="section2">
                                        <div>{this.state.modal_data["stats"]["top_asn"]}</div>
                                        <div>{this.state.modal_data["stats"]["top_isp"]}</div>
                                        <div>{this.state.modal_data["stats"]["last_active"]}</div>
                                        <div>{this.state.modal_data["stats"]["average_up"]}</div>
                                        <div>{this.state.modal_data["stats"]["top_country"]}</div>
                                    </div>
                                </div>
                            </div>

                            <div className="inner-tip-box"
                                 style={{
                                     height: "70px"
                                 }}
                            >
                                <div style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    gap: "4px",
                                    lineHeight: "28px",
                                    fontWeight: "600",
                                    alignItems: "center",
                                    height: "24px",
                                }}>
                                    <img
                                        style={{width: "24px", height: "24px"}}
                                        alt=""
                                        src="/main/threat-mitre-i.svg"
                                    />
                                    <div style={{
                                        color: "#a1daf8"
                                    }}
                                         onClick={() => {
                                             this.setState({show_mitre_editor: true})
                                         }}
                                    >ATT&CK Mapping
                                    </div>
                                </div>
                                <div style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    marginTop: "4px",
                                    height: "calc(100%-24px)"
                                }}
                                     id="malware-data">
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        color: "#A1A1AA",
                                        gap: "4px",
                                        width: "108px"
                                    }} id="section1">
                                        <div>Reference:</div>
                                    </div>

                                    <div style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        width: "100%",
                                        gap: "4px"
                                    }} id="section2"
                                    >

                                        {this.state.show_mitre_editor ?
                                            <input className="transparent-input" type='text' name='mitre'
                                                   value={this.state.modal_data["backend"]["mitre"]}
                                                   onChange={(data) => {
                                                       this.update_threat_data("mitre", data.target.value)
                                                   }}
                                                   onBlur={() => {
                                                       this.setState({show_mitre_editor: false})
                                                   }}
                                            />
                                            :
                                            <button
                                                onClick={() => this.props.open_mitre(this.state.modal_data["malware"])}
                                                style={{
                                                    width: "80px",
                                                    cursor: "pointer",
                                                    border: "none",
                                                    padding: 0,
                                                    color: "#06B6D4",
                                                    overflow: "hidden",
                                                    backgroundColor: "transparent",
                                                    display: "inline-block"

                                            }}
                                            >
                                                {this.get_mitre_data()[0]}
                                            </button>
                                        }

                                    </div>
                                </div>
                            </div>
                            <div className="inner-tip-box"
                                 style={{
                                     height: "70px"
                                 }}
                            >
                                <div style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    gap: "4px",
                                    lineHeight: "28px",
                                    fontWeight: "600",
                                    alignItems: "center",
                                    height: "24px",
                                }}>
                                    <img
                                        style={{width: "24px", height: "24px"}}
                                        alt=""
                                        src="/main/attack-chain-i.svg"
                                    />
                                    <div style={{
                                        color: "#a1daf8"
                                    }}>Attack Chain
                                    </div>
                                </div>
                                <div style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    marginTop: "4px",
                                    height: "calc(100%-24px)"
                                }}
                                     id="malware-data">
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        color: "#A1A1AA",
                                        gap: "4px",
                                        width: "148px"
                                    }} id="section1">
                                        <div>Attack Method:</div>
                                    </div>

                                    <div style={{
                                        height: "24px",
                                        width: "100%",
                                        display: "flex",
                                        flexDirection: "row",
                                        gap: "8px"
                                    }}
                                         onClick={() => {
                                             this.setState({show_chain_editor: true})
                                         }}
                                         id="section2">
                                        {this.state.show_chain_editor ?
                                            <input className="transparent-input" type='text' name='attack_chain'
                                                   value={this.state.modal_data["backend"]["chain"]}
                                                   onChange={(data) => {
                                                       this.update_threat_data("chain", data.target.value)
                                                   }}
                                                   onBlur={() => {
                                                       this.setState({show_chain_editor: false})
                                                   }}
                                            />
                                            :
                                            this.get_chain_sectors().split(",").map((item, i) =>
                                                <div style={{
                                                    padding: "0px 8px 0px 8px",
                                                    borderRadius: "16px",
                                                    background: "rgba(161,161,170, 0.25)",
                                                    color: "rgba(161,161,170, 100)",
                                                    fontWeight: "bold",
                                                }}>{item}</div>
                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ReactModal>



                <ReactModal
                    ariaHideApp={false}
                    isOpen={this.state.showTop10}
                    contentLabel="Top 10"
                    style={{
                        overlay: {
                            zIndex: 500,
                            background: "rgba(19, 19, 19, 0.75)"
                        },
                        content: {
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            width: "570px",
                            height: "720px",
                            background: "#171717"
                        },
                    }}
                >
                        <div
                            style={{
                                width: "100%",
                                height: "100%",
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "flex-start",
                                color: "white",
                                gap: "16px"
                            }}
                        >
                            <div style={{
                                width: "100%",
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "flex-start",
                                lineHeight: "28px",
                                fontWeight: "600",
                            }}
                            >
                                <div style={{color: "#F9B233"}}>
                                    Top 10 Malware: {this.state.top10_data["target"]}
                                </div>
                                <button
                                    style={{
                                        cursor: "pointer",
                                        border: "none",
                                        backgroundColor: "transparent",
                                        position: "relative",
                                        width: "24px",
                                        height: "24px",
                                        marginLeft: "auto"
                                    }}
                                    id="close-modal"
                                    onClick={() => this.setState({showTop10: false})}
                                >
                                    <img
                                        alt=""
                                        src="/main/white-close.svg"
                                    />
                                </button>
                            </div>

                            {
                                this.state.top10_data["list"] ?
                                this.state.top10_data["list"].slice(0, 10).map((entry, i) => {
                                    return <div style={{width: "100%", display: "flex", flexDirection: "column", gap: "8px"}}>
                                        <div style={{width: "100%", paddingLeft: 4, paddingRight: 8, justifyContent: 'flex-start', alignItems: 'center', gap: 8, display: 'inline-flex'}}
                                        >
                                            <div style={{paddingRight: 4, alignItems: 'center', display: 'flex'}}>
                                                <div style={{width: "30px", color: 'white', fontSize: 16, fontFamily: 'Inter', fontWeight: '600', lineHeight: 1, wordWrap: 'break-word'}}>#{i+1}</div>
                                            </div>

                                            <button
                                                onClick={() => this.OpenModal(entry)}
                                                style={{
                                                    backgroundColor: "transparent",
                                                    cursor: "pointer",
                                                    border: "none",
                                                }}
                                            >
                                                <div style={{padding: 8, background: '#27272A', borderRadius: 2, justifyContent: 'flex-start', alignItems: 'center', display: 'flex'}}>
                                                    <img style={{width: 20, height: 20}} src="/main/show-i.svg" alt="show" />
                                                </div>
                                            </button>


                                            <div style={{flex: '1 1 0', height: 40, paddingRight: 4, justifyContent: 'flex-start', alignItems: 'center', display: 'flex'}}>
                                                <div style={{flexDirection: 'column', gap: 8, justifyContent: 'flex-start', alignItems: 'flex-start', display: 'inline-flex'}}>
                                                    <div style={{color: 'white', fontSize: 16, fontFamily: 'Inter', fontWeight: '600', lineHeight: 1, wordWrap: 'break-word'}}>{entry["malware"]}</div>
                                                    <div style={{color: 'white', fontSize: 12, fontFamily: 'Inter', fontWeight: '400', lineHeight: 1, letterSpacing: 0.06, wordWrap: 'break-word'}}>{entry["group"]}</div>
                                                </div>
                                            </div>
                                            <div style={{flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', display: 'inline-flex'}}>
                                                <div style={{alignSelf: 'stretch', height: 20, flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: 4, display: 'flex'}}>
                                                    <div style={{alignSelf: 'stretch', justifyContent: 'flex-end', alignItems: 'center', gap: 8, display: 'inline-flex'}}>
                                                        <div style={{paddingLeft: 8, paddingRight: 8, borderRadius: 16, overflow: 'hidden', justifyContent: 'center', alignItems: 'center', display: 'flex'}}>
                                                            <div style={{color: '#A1A1AA', fontSize: 14, fontFamily: 'Inter', fontWeight: '600', lineHeight: 1, letterSpacing: 0.07, wordWrap: 'break-word'}}>{entry["count"]}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div style={{alignSelf: 'stretch', height: 20, flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-end', gap: 4, display: 'flex'}}>
                                                    <div style={{justifyContent: 'flex-end', alignItems: 'center', display: 'inline-flex'}}>
                                                        <div style={{paddingLeft: 8, paddingRight: 8, paddingTop: 4, paddingBottom: 4, background: 'rgba(244, 63, 94, 0.25)', borderRadius: 16, overflow: 'hidden', justifyContent: 'center', alignItems: 'center', display: 'flex'}}>
                                                            <div style={{color: '#F43F5E', fontSize: 14, fontFamily: 'Inter', fontWeight: '600', lineHeight: 1, letterSpacing: 0.07, wordWrap: 'break-word'}}>{Math.round(entry["count"]*1000/this.state.top10_data["total"])/10}%</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div style={{width: '100%', height: '1px', border: '1px #27272A solid'}} />
                                    </div>
                                })
                                    :
                                    <div />
                            }


                        </div>
                </ReactModal>

                <div style={{
                    width: "100%",
                    height: "1px",
                    alignSelf: "stretch",
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                    gap: "8px",
                    color: "#F9B233",
                }}>
                    <div
                        style={{
                            alignSelf: "stretch",
                            display: "flex",
                            flexDirection: "row",
                            flexWrap: "wrap",
                            alignItems: "flex-start",
                            justifyContent: "flex-start",
                            gap: "8px",
                            width: "100%"
                        }}
                    >
                        <div
                            className="Detection-Card Detection-Card-Detections-Page"
                        >
                            <div
                                style={{
                                    alignSelf: "stretch",
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "flex-start",
                                    justifyContent: "flex-start",
                                }}
                            >
                                <div
                                    style={{
                                        alignSelf: "stretch",
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                        justifyContent: "flex-start",
                                        gap: "4px",
                                        zIndex: "10",
                                    }}
                                >
                                    <div
                                        style={{
                                            flex: "1",
                                            position: "relative",
                                            lineHeight: "28px",
                                            fontWeight: "600"
                                        }}
                                    >
                                        P1
                                    </div>
                                    <MUITooltip title={this.props.tooltip_info("P1")} arrow>
                                        <img
                                            style={{
                                                position: "relative",
                                                width: "24px",
                                                height: "24px",
                                                overflow: "hidden",
                                                flexShrink: "0",
                                            }}
                                            alt="detection-i"
                                            src="/main/detection-i.svg"
                                        />
                                    </MUITooltip>
                                </div>
                            </div>
                            <div
                                style={{
                                    position: "relative",
                                    width: "232px",
                                    height: "232px",
                                    textAlign: "center",
                                    fontSize: "45px",
                                    color: "#fff",
                                    zIndex: "100"
                                }}
                            >

                                <CircularProgressbarWithChildren value={this.state.p[0]} strokeWidth={5}
                                                                 styles={buildStyles({
                                                                     textSize: '25px',
                                                                     pathTransitionDuration: 0.5,
                                                                     textColor: '#fff',
                                                                     trailColor: '#27272A',
                                                                     pathColor: 'rgba(239,68,68,100)'
                                                                 })}
                                >
                                    <div style={{
                                        color: 'rgba(239,68,68,100)'
                                    }}>{this.state.p_val[0]}</div>
                                </CircularProgressbarWithChildren>
                            </div>
                            <div
                                style={{
                                    alignSelf: "stretch",
                                    display: "flex",
                                    flexDirection: "row",
                                    gap: "4px",
                                    fontSize: "12px",
                                    color: "#a1a1aa",
                                    height: "20px"
                                }}
                            >
                                <div
                                    style={{
                                        borderRadius: "16px",
                                        backgroundColor: "rgba(220, 38, 38, 0.25)",
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        padding: "0px 8px",
                                        color: "#ef4444"
                                    }}
                                >
                                    <div
                                        style={{
                                            position: "relative",
                                            letterSpacing: "0.01em",
                                            lineHeight: "20px",
                                            fontWeight: "600"
                                        }}
                                    >
                                        CRITICAL
                                    </div>
                                </div>
                                <button
                                    onClick={() => this.top_10("P1")}
                                    style={{
                                        borderRadius: "16px",
                                        backgroundColor: "rgba(82, 82, 91, 0.25)",
                                        display: "flex",
                                        flexDirection: "row",
                                        marginLeft: "auto",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        padding: "0px 8px",
                                        cursor: "pointer",
                                        border: "none",
                                        overflow: "hidden",
                                    }}
                                >
                                    <div style={{
                                        paddingRight: 4,
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        gap: 10,
                                        display: 'flex'
                                    }}>
                                        <img style={{width: 16, height: 16, left: 2, top: 2}}
                                                 src="/main/show-i.svg" alt="show"/>
                                    </div>
                                    <div style={{
                                        color: '#A1A1AA',
                                        fontSize: 14,
                                        fontFamily: 'Inter',
                                        fontWeight: '600',
                                        lineHeight: 1,
                                        letterSpacing: 0.07,
                                    }}>Top 10
                                    </div>
                                </button>
                            </div>
                        </div>
                        <div
                            className="Detection-Card Detection-Card-Detections-Page"
                        >
                            <div
                                style={{
                                    alignSelf: "stretch",
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "flex-start",
                                    justifyContent: "flex-start",
                                }}
                            >
                                <div
                                    style={{
                                        alignSelf: "stretch",
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                        justifyContent: "flex-start",
                                        gap: "4px",
                                    }}
                                >
                                    <div
                                        style={{
                                            flex: "1",
                                            position: "relative",
                                            lineHeight: "28px",
                                            fontWeight: "600",
                                        }}
                                    >
                                        P2
                                    </div>
                                    <MUITooltip title={this.props.tooltip_info("P2")} arrow>
                                        <img
                                            style={{
                                                position: "relative",
                                                width: "24px",
                                                height: "24px",
                                                overflow: "hidden",
                                                flexShrink: "0",
                                            }}
                                            alt="detection-i"
                                            src="/main/detection-i.svg"
                                        />
                                    </MUITooltip>
                                </div>
                            </div>
                            <div
                                style={{
                                    position: "relative",
                                    width: "232px",
                                    height: "232px",
                                    textAlign: "center",
                                    fontSize: "45px",
                                    color: "#fff",
                                    zIndex: "100"
                                }}
                            >
                                <CircularProgressbarWithChildren value={this.state.p[1]} strokeWidth={5}
                                                                 styles={buildStyles({
                                                                     textSize: '25px',
                                                                     pathTransitionDuration: 0.5,
                                                                     textColor: '#fff',
                                                                     trailColor: '#27272A',
                                                                     pathColor: '#F97316'
                                                                 })}
                                >
                                    <div style={{
                                        color: '#F97316'
                                    }}>{this.state.p_val[1]}</div>
                                </CircularProgressbarWithChildren>
                            </div>
                            <div
                                style={{
                                    alignSelf: "stretch",
                                    display: "flex",
                                    flexDirection: "row",
                                    gap: "4px",
                                    fontSize: "12px",
                                    color: "#a1a1aa"
                                }}
                            >
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        flexWrap: "wrap",
                                        alignItems: "flex-start",
                                        justifyContent: "flex-start",
                                        gap: "8px",
                                    }}
                                >
                                    <div
                                        style={{
                                            borderRadius: "16px",
                                            backgroundColor: "rgba(234, 88, 12, 0.25)",
                                            overflow: "hidden",
                                            display: "flex",
                                            flexDirection: "row",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            padding: "0px 8px",
                                            color: "#f97316",
                                        }}
                                    >
                                        <div
                                            style={{
                                                position: "relative",
                                                letterSpacing: "0.01em",
                                                lineHeight: "20px",
                                                fontWeight: "600",
                                            }}
                                        >
                                            HIGH
                                        </div>
                                    </div>
                                </div>
                                <button
                                    onClick={() => this.top_10("P2")}
                                    style={{
                                        borderRadius: "16px",
                                        backgroundColor: "rgba(82, 82, 91, 0.25)",
                                        display: "flex",
                                        flexDirection: "row",
                                        marginLeft: "auto",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        padding: "0px 8px",
                                        cursor: "pointer",
                                        border: "none",
                                        overflow: "hidden",
                                    }}
                                >
                                    <div style={{
                                        paddingRight: 4,
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        gap: 10,
                                        display: 'flex'
                                    }}>
                                        <img style={{width: 16, height: 16, left: 2, top: 2}}
                                             src="/main/show-i.svg" alt="show"/>
                                    </div>
                                    <div style={{
                                        color: '#A1A1AA',
                                        fontSize: 14,
                                        fontFamily: 'Inter',
                                        fontWeight: '600',
                                        lineHeight: 1,
                                        letterSpacing: 0.07,
                                    }}>Top 10
                                    </div>
                                </button>
                            </div>
                        </div>
                        <div
                            className="Detection-Card Detection-Card-Detections-Page"
                        >
                            <div
                                style={{
                                    alignSelf: "stretch",
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "flex-start",
                                    justifyContent: "flex-start",
                                }}
                            >
                                <div
                                    style={{
                                        alignSelf: "stretch",
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                        justifyContent: "flex-start",
                                        gap: "4px",
                                    }}
                                >
                                    <div
                                        style={{
                                            flex: "1",
                                            position: "relative",
                                            lineHeight: "28px",
                                            fontWeight: "600",
                                        }}
                                    >
                                        P3
                                    </div>
                                    <MUITooltip title={this.props.tooltip_info("P3")} arrow>
                                        <img
                                            style={{
                                                position: "relative",
                                                width: "24px",
                                                height: "24px",
                                                overflow: "hidden",
                                                flexShrink: "0",
                                            }}
                                            alt="detection-i"
                                            src="/main/detection-i.svg"
                                        />
                                    </MUITooltip>
                                </div>
                            </div>
                            <div
                                style={{
                                    position: "relative",
                                    width: "232px",
                                    height: "232px",
                                    textAlign: "center",
                                    fontSize: "45px",
                                    color: "#fff",
                                    zIndex: "100"
                                }}
                            >
                                <CircularProgressbarWithChildren value={this.state.p[2]} strokeWidth={5}
                                                                 styles={buildStyles({
                                                                     textSize: '25px',
                                                                     pathTransitionDuration: 0.5,
                                                                     textColor: '#fff',
                                                                     trailColor: '#27272A',
                                                                     pathColor: '#EAB308'
                                                                 })}
                                >
                                    <div style={{
                                        color: '#EAB308'
                                    }}>{this.state.p_val[2]}</div>
                                </CircularProgressbarWithChildren>

                            </div>
                            <div
                                style={{
                                    alignSelf: "stretch",
                                    display: "flex",
                                    flexDirection: "row",
                                    gap: "4px",
                                    fontSize: "12px",
                                    color: "#a1a1aa",
                                }}
                            >
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        flexWrap: "wrap",
                                        alignItems: "flex-start",
                                        justifyContent: "flex-start",
                                        gap: "8px",
                                    }}
                                >
                                    <div
                                        style={{
                                            borderRadius: "16px",
                                            backgroundColor: "rgba(202, 138, 4, 0.25)",
                                            overflow: "hidden",
                                            display: "flex",
                                            flexDirection: "row",
                                            padding: "0px 8px",
                                            color: "#eab308",
                                        }}
                                    >
                                        <div
                                            style={{
                                                position: "relative",
                                                letterSpacing: "0.01em",
                                                lineHeight: "20px",
                                                fontWeight: "600",
                                            }}
                                        >
                                            MEDIUM
                                        </div>
                                    </div>
                                </div>
                                <button
                                    onClick={() => this.top_10("P3")}
                                    style={{
                                        borderRadius: "16px",
                                        backgroundColor: "rgba(82, 82, 91, 0.25)",
                                        display: "flex",
                                        flexDirection: "row",
                                        marginLeft: "auto",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        padding: "0px 8px",
                                        cursor: "pointer",
                                        border: "none",
                                        overflow: "hidden",
                                    }}
                                >
                                    <div style={{
                                        paddingRight: 4,
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        gap: 10,
                                        display: 'flex'
                                    }}>
                                        <img style={{width: 16, height: 16, left: 2, top: 2}}
                                             src="/main/show-i.svg" alt="show"/>
                                    </div>
                                    <div style={{
                                        color: '#A1A1AA',
                                        fontSize: 14,
                                        fontFamily: 'Inter',
                                        fontWeight: '600',
                                        lineHeight: 1,
                                        letterSpacing: 0.07,
                                    }}>Top 10
                                    </div>
                                </button>
                            </div>
                        </div>
                        <div
                            className="Detection-Card Detection-Card-Detections-Page"
                        >
                            <div
                                style={{
                                    alignSelf: "stretch",
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "flex-start",
                                    justifyContent: "flex-start",
                                }}
                            >
                                <div
                                    style={{
                                        alignSelf: "stretch",
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                        justifyContent: "flex-start",
                                        gap: "4px",
                                    }}
                                >
                                    <div
                                        style={{
                                            flex: "1",
                                            position: "relative",
                                            lineHeight: "28px",
                                            fontWeight: "600",
                                        }}
                                    >
                                        P4
                                    </div>
                                    <MUITooltip title={this.props.tooltip_info("P4")} arrow>
                                        <img
                                            style={{
                                                position: "relative",
                                                width: "24px",
                                                height: "24px",
                                                overflow: "hidden",
                                                flexShrink: "0",
                                            }}
                                            alt="detection-i"
                                            src="/main/detection-i.svg"
                                        />
                                    </MUITooltip>
                                </div>
                            </div>
                            <div
                                style={{
                                    position: "relative",
                                    width: "232px",
                                    height: "232px",
                                    textAlign: "center",
                                    fontSize: "45px",
                                    color: "#fff",
                                    zIndex: "100"
                                }}
                            >


                                <CircularProgressbarWithChildren value={this.state.p[3]} strokeWidth={5}
                                                                 styles={buildStyles({
                                                                     textSize: '25px',
                                                                     pathTransitionDuration: 0.5,
                                                                     textColor: '#fff',
                                                                     trailColor: '#27272A',
                                                                     pathColor: '#06B6D4'
                                                                 })}
                                >
                                    <div style={{
                                        color: '#06B6D4'
                                    }}>{this.state.p_val[3]}</div>
                                </CircularProgressbarWithChildren>

                            </div>
                            <div
                                style={{
                                    alignSelf: "stretch",
                                    display: "flex",
                                    flexDirection: "row",
                                    gap: "4px",
                                    fontSize: "12px",
                                    color: "#a1a1aa"
                                }}
                            >
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        flexWrap: "wrap",
                                        alignItems: "flex-start",
                                        justifyContent: "flex-start",
                                        gap: "8px",
                                    }}
                                >
                                    <div
                                        style={{
                                            borderRadius: "16px",
                                            backgroundColor: "rgba(8, 145, 178, 0.25)",
                                            overflow: "hidden",
                                            display: "flex",
                                            flexDirection: "row",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            padding: "0px 8px",
                                            color: "#06b6d4",
                                        }}
                                    >
                                        <div
                                            style={{
                                                position: "relative",
                                                letterSpacing: "0.01em",
                                                lineHeight: "20px",
                                                fontWeight: "600",
                                            }}
                                        >
                                            LOW
                                        </div>
                                    </div>
                                </div>
                                <button
                                    onClick={() => this.top_10("P4")}
                                    style={{
                                        borderRadius: "16px",
                                        backgroundColor: "rgba(82, 82, 91, 0.25)",
                                        display: "flex",
                                        flexDirection: "row",
                                        marginLeft: "auto",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        padding: "0px 8px",
                                        cursor: "pointer",
                                        border: "none",
                                        overflow: "hidden",
                                    }}
                                >
                                    <div style={{
                                        paddingRight: 4,
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        gap: 10,
                                        display: 'flex'
                                    }}>
                                        <img style={{width: 16, height: 16, left: 2, top: 2}}
                                             src="/main/show-i.svg" alt="show"/>
                                    </div>
                                    <div style={{
                                        color: '#A1A1AA',
                                        fontSize: 14,
                                        fontFamily: 'Inter',
                                        fontWeight: '600',
                                        lineHeight: 1,
                                        letterSpacing: 0.07,
                                    }}>Top 10
                                    </div>
                                </button>
                            </div>
                        </div>

                        <div
                            className="Detection-Card Detection-Card-Detections-Page"
                        >
                            <div
                                style={{
                                    alignSelf: "stretch",
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "flex-start",
                                    justifyContent: "flex-start",
                                }}
                            >
                                <div
                                    style={{
                                        alignSelf: "stretch",
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                        justifyContent: "flex-start",
                                        gap: "4px",
                                    }}
                                >
                                    <div
                                        style={{
                                            flex: "1",
                                            position: "relative",
                                            lineHeight: "28px",
                                            fontWeight: "600",
                                        }}
                                    >
                                       IoAs By Priority
                                    </div>
                                    <MUITooltip title={this.props.tooltip_info("IoAs By Priority")} arrow>
                                        <img
                                            style={{
                                                position: "relative",
                                                width: "24px",
                                                height: "24px",
                                                overflow: "hidden",
                                                flexShrink: "0",
                                            }}
                                            alt="detection-i"
                                            src="/main/detection-i.svg"
                                        />
                                    </MUITooltip>
                                </div>
                            </div>


                            <div style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                justifyContent: "center",
                                width: "100%",
                                height: "232px",
                                gap: "16px",
                                color: "white"
                            }}
                            >
                                <div
                                    style={{
                                        position: "relative",
                                        width: "232px",
                                        height: "232px",
                                        textAlign: "center",
                                        fontSize: "45px",
                                        color: "#fff",
                                        zIndex: "100"
                                    }}
                                >
                                    <Doughnut
                                        options={{
                                        responsive: true,
                                        cutout: 100,
                                        plugins: {
                                            legend: {
                                                display: false
                                            },
                                        },
                                    }}
                                                data={{
                                                    labels: ['P1', 'P2', 'P3', 'P4'],
                                                    datasets: [
                                                        {
                                                            label: 'IoA Count',
                                                            data: [this.state.p_val[0], this.state.p_val[1], this.state.p_val[2], this.state.p_val[3]],
                                                            hoverBackgroundColor: [
                                                                'rgba(239,68,68,1)',
                                                                'rgba(249,115,22,1)',
                                                                'rgba(234,179,8,1)',
                                                                'rgba(6,182,212,1)',
                                                            ],
                                                            backgroundColor: [
                                                                'rgba(239,68,68,1)',
                                                                'rgba(249,115,22,1)',
                                                                'rgba(234,179,8,1)',
                                                                'rgba(6,182,212,1)',
                                                            ],
                                                            borderColor: [
                                                                '#ef4444',
                                                                '#f97316',
                                                                '#eab308',
                                                                '#06b6d4',
                                                            ],
                                                            hoverBorderWidth: 1,
                                                            borderRadius: 8,
                                                            borderWidth: 0,
                                                            spacing: 4,
                                                        },
                                                    ],
                                                }}/>
                                    <div style={{
                                        color: "white",
                                        marginTop: "-65%"
                                    }}>{this.state.table_data.length}</div>
                                </div>
                            </div>

                            <div
                                style={{
                                    alignSelf: "stretch",
                                    display: "flex",
                                    flexDirection: "row",
                                    gap: "4px",
                                    fontSize: "12px",
                                    color: "#a1a1aa",
                                    height: "20px"
                                }}
                            >
                                <div
                                    style={{
                                        borderRadius: "16px",
                                        backgroundColor: "rgba(220, 38, 38, 0.25)",
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        padding: "0px 8px",
                                        color: "#ef4444"
                                    }}
                                >
                                    <div
                                        style={{
                                            position: "relative",
                                            letterSpacing: "0.01em",
                                            lineHeight: "20px",
                                            fontWeight: "600"
                                        }}
                                    >
                                        CRITICAL
                                    </div>
                                </div>
                                <div
                                    style={{
                                        borderRadius: "16px",
                                        backgroundColor: "rgba(234, 88, 12, 0.25)",
                                        overflow: "hidden",
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        padding: "0px 8px",
                                        color: "#f97316",
                                    }}
                                >
                                    <div
                                        style={{
                                            position: "relative",
                                            letterSpacing: "0.01em",
                                            lineHeight: "20px",
                                            fontWeight: "600",
                                        }}
                                    >
                                        HIGH
                                    </div>
                                </div>
                                <div
                                    style={{
                                        borderRadius: "16px",
                                        backgroundColor: "rgba(202, 138, 4, 0.25)",
                                        overflow: "hidden",
                                        display: "flex",
                                        flexDirection: "row",
                                        padding: "0px 8px",
                                        color: "#eab308",
                                    }}
                                >
                                    <div
                                        style={{
                                            position: "relative",
                                            letterSpacing: "0.01em",
                                            lineHeight: "20px",
                                            fontWeight: "600",
                                        }}
                                    >
                                        MEDIUM
                                    </div>
                                </div>
                                <div
                                    style={{
                                        borderRadius: "16px",
                                        backgroundColor: "rgba(8, 145, 178, 0.25)",
                                        overflow: "hidden",
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        padding: "0px 8px",
                                        color: "#06b6d4",
                                    }}
                                >
                                    <div
                                        style={{
                                            position: "relative",
                                            letterSpacing: "0.01em",
                                            lineHeight: "20px",
                                            fontWeight: "600",
                                        }}
                                    >
                                        LOW
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        style={{
                            alignSelf: "stretch",
                            display: "flex",
                            flexDirection: "row",
                            flexWrap: "wrap",
                            alignItems: "flex-start",
                            justifyContent: "flex-start",
                            gap: "8px",
                            width: "100%"
                        }}
                    >
                        <div style={{
                            position: "relative",
                            width: "calc(100% - 8px)",
                            borderRadius: "8px",
                            height: "100%"
                        }}>

                            <div style={{
                                top: "20px",
                                left: "16px",
                                display: "flex",
                                height: "30px",
                                position: "absolute",
                                fontWeight: "600",
                                gap: "24px",
                                fontSize: "12px",
                                letterSpacing: "0.01em",
                                lineHeight: "20px",
                                fontFamily: "Inter",
                                zIndex: "10"
                            }}
                                 id="table-header"
                            >
                                <div style={{
                                    display: "flex",
                                    fontSize: "15px",
                                    borderRadius: "4px 4px 0 0",
                                    alignItems: "center",
                                    backgroundColor: "#27272A",
                                    color: "#F9B233",
                                    padding: "4px 16px",
                                    gap: "4px",
                                    marginBottom: "-10px"
                                }}>
                                    <IDetections style={{
                                        width: "20px",
                                        height: "20px",
                                        color: "#65A30D",
                                    }}/>
                                    Threat Landscape
                                </div>
                                <div style={{
                                    display: "flex",
                                    gap: "8px",
                                    lineHeight: "28px",
                                    fontSize: "22px",
                                    alignItems: "center"
                                }}>
                                    <button
                                        onClick={this.tick}
                                        style={{
                                            width: "28px",
                                            height: "28px",
                                            display: "flex",
                                            cursor: "pointer",
                                            border: "none",
                                            paddingLeft: "16px",
                                            overflow: "hidden",
                                            backgroundColor: "transparent",
                                            alignItems: "center",
                                            justifyContent: "center"
                                        }}>
                                        <img src="/main/refresh-i.svg" alt=""/>
                                    </button>

                                    <div style={{
                                        paddingLeft: "16px",
                                        fontSize: "18px",
                                        color: this.state.all_time ? "white" : "#A1A1AA"
                                    }}>
                                        All Time
                                    </div>
                                    <div style={{
                                        display: "flex",
                                        width: "35px",
                                        height: "20px",
                                    }}>
                                        <Switch
                                            uncheckedIcon={false}
                                            checkedIcon={false}
                                            onChange={(checked) => {
                                                this.setState({all_time: !checked})
                                                this.tick(!checked)
                                            }}
                                            checked={!this.state.all_time}
                                            offColor="#27272A"
                                            onColor="#27272A"
                                            offHandleColor="#06B6D4"
                                            onHandleColor="#10B981"
                                            draggable={false}
                                            width={35}
                                            height={20}
                                        />
                                    </div>

                                    <div style={{
                                        fontSize: "18px",
                                        color: this.state.all_time ? "#A1A1AA" : "white"
                                    }}>
                                        Live
                                    </div>

                                </div>


                            </div>
                            <ThemeProvider theme={this.tableTheme}>
                                <MaterialReactTable
                                    enableRowActions={true}
                                    displayColumnDefOptions={{
                                        "mrt-row-actions": {
                                            header: 'Threat Card'
                                        },
                                    }}
                                    renderRowActions={({row}) => (
                                        <button
                                            className="menu-entry"
                                            onClick={() => this.OpenModal(row["original"])}
                                            style={{
                                                width: "100px"
                                            }}
                                            id="show-btn"
                                        >
                                            <div
                                                style={{
                                                    flex: "1",
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                    gap: "8px",
                                                    borderRadius: "2px",
                                                    padding: "7px",
                                                    backgroundColor: "#27272A"
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        position: "relative",
                                                        fontSize: "14px",
                                                        letterSpacing: "0.01em",
                                                        lineHeight: "20px",
                                                        fontFamily: "Inter",
                                                        color: "#fff",
                                                        textAlign: "left",
                                                    }}
                                                >
                                                    View
                                                </div>
                                                <img
                                                    style={{
                                                        position: "relative",
                                                        width: "20px",
                                                        height: "20px",
                                                    }}
                                                    alt=""
                                                    src="/main/show-i.svg"
                                                />
                                            </div>
                                        </button>
                                    )}
                                    columns={this.columns} data={this.getTableData()}
                                >
                                </MaterialReactTable>
                            </ThemeProvider>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}