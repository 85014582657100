import React, { Component } from 'react'
import {Link} from "react-router-dom";
export default class SignUp extends Component {


    render() {
        return (
            <div className="App">
                <div className="auth-wrapper" style={{
                    backgroundImage: "url(/main/background-art.png)",
                    height: "100vh",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "contain"
                }}>
                    <div className="auth-inner">
                        <div style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                            marginLeft: "-5%",
                            marginBottom: "20px"
                        }}>
                            <img src="/main/CyberShadows-Logo.svg" alt="banner logo" width={350} height={120}/>
                        </div>
                        <br/><br/>
                        <h3>Sign up</h3>
                        <div className="mb-2">
                            Already registered? <Link className="links" to={'/sign-in'}>Sign in</Link>
                        </div>

                        <div className="mb-3">
                            <label>Username</label>
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Username"
                            />
                        </div>
                        <div className="mb-3">
                            <label>Email address</label>
                            <input
                                type="email"
                                className="form-control"
                                placeholder="Enter email"
                            />
                        </div>
                        <div className="mb-3">
                            <label>Password</label>
                            <input
                                type="password"
                                className="form-control"
                                placeholder="Enter password"
                            />
                        </div>
                        <div className="d-grid">
                            <button type="submit" className="btn btn-primary btn-submit">
                                Sign up
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}